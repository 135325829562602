import {
  Button,
  CircularProgress,
  Grid,
  Paper,
  Typography,
  ListItemAvatar,
  Avatar,
  Divider,
  ListItem,
  Card,
  List,
  IconButton,
  ListItemText,
} from "@material-ui/core";
import React, { Component } from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import axios from "axios";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
// import Rating from "@material-ui/lab/Rating";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormLabel from "@material-ui/core/FormLabel";

import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import Chip from "@material-ui/core/Chip";
import PriorityHighIcon from "@material-ui/icons/PriorityHigh";
import EditIcon from "@material-ui/icons/Edit";
import HistoryIcon from "@material-ui/icons/History";
import WorkIcon from "@material-ui/icons/Work";

import { red, green, orange } from "@material-ui/core/colors";

import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Box from "@material-ui/core/Box";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import PublishIcon from '@material-ui/icons/Publish';
import ClearIcon from '@material-ui/icons/Clear';

let token = "";
let id = "";
const api = "http://3.22.17.212:9000";

let companyChoices = [];
let positionCategories = [];
let reasonsChoices = [];

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class myJobProfile extends Component {
  constructor(props) {
    super(props);
    this.addJobProfile = this.addJobProfile.bind(this);
    this.getTableOfEmployees = this.getTableOfEmployees.bind(this);
  }

  state = {
    myJobHistory: [],
    viewMyJobHistories: [],

    addDialogOpen: false,
    editActionsOpen: false,

    viewDialogOpen: false,

    tabularBoolean: false,

    selectedIndex: -1,
    updateJobId: -1,
    id: "",
    companies: [],
    positions: [],
    leavingReasons: [],
    updateMyJobData: [],

    snackbar: "",
    snackbarresponse: "",

    //ADD DIALOG STATES
    addJobDialogCompany: "",
    addJobDialogCompanyIndex: 0,
    addJobDialogCheck: false,
    addJobDialogOtherCompany: "",
    addJobDialogStartDate: new Date(),
    addJobDialogEndDate: new Date(),
    addJobDialogPosition: "",
    addJobDialogPositionIndex: 0,
    addJobDialogJobTitle: "",
    addJobDialogJobDescription: "",
    addJobDialogReasonForLeaving: "",
    addJobDialogReasonForLeavingIndex: null,
    addJobDialogRating: 0,
    employeeByRadio: "currentEmployee",
    addNewJob: false,

    //EDIT DIALOG STATES
    editJobDialogCompany: "",
    editJobDialogCompanyField: "",
    editJobDialogCheck: false,
    editJobDialogOtherCompany: "",
    editJobDialogStartDate: new Date(),
    editJobDialogEndDate: new Date(),
    editJobDialogPosition: 0,
    editJobDialogPositionField: "",
    editJobDialogJobTitle: "",
    editJobDialogJobDescription: "",
    editJobDialogReasonForLeaving: 0,
    editJobDialogReasonForLeavingField: "",
    editJobDialogRating: 0,
    editJobDialogUpdateReason: "",

    editDisplay: false,
    historyDisplay: false,
    viewHistoryId: -1,

    verificationData: [],
    verificationEmployeeID: "",

    availableCompanies: [],
    check: false,
    companyOptionDisable: false,
    isloading: true,

    addsnackbar: false,
    updatesnackbar: false,
    currentid: "",
    walletdialog: false,
    addWindow: false,

    comapanyNameCheck: false,
    startDateCheck:false,
    positionCheck: false,
    jobTitleCheck: false,
    jobDescriptionCheck: false,
    reasonForLeavingCheck: false
  };

  addsnackbar() {
    return this.state.addresponse === 200 ? (
      <div>
        <Snackbar
          open={this.state.addsnackbar}
          autoHideDuration={3000}
          onClick={() => {
            this.setState({ addsnackbar: !this.state.addsnackbar });
          }}
        >
          <Alert
            onClose={() => {
              this.setState({ addsnackbar: !this.state.addsnackbar });
            }}
            severity="error"
          >
            Something went wrong please try again
          </Alert>
        </Snackbar>
      </div>
    ) : (
        <Snackbar
          open={this.state.addsnackbar}
          autoHideDuration={3000}
          onClick={() => this.setState({ addsnackbar: false })}
        >
          <Alert
            onClose={() => {
              this.setState({ addsnackbar: !this.state.addasnackbar });
            }}
            severity="success"
          >
            Job added sucessfully
        </Alert>
        </Snackbar>
      );
  }
  updatesnackbar() {
    return this.state.updateresponse === 200 ? (
      <div>
        <Snackbar
          open={this.state.updatesnackbar}
          autoHideDuration={3000}
          onClick={() => this.setState({ updatesnackbar: false })}
        >
          <Alert
            onClose={() => {
              this.setState({ updatesnackbar: !this.state.updatesnackbar });
            }}
            severity="success"
          >
            Job updated sucessfully
          </Alert>
        </Snackbar>
      </div>
    ) : (
        <Snackbar
          open={this.state.updatesnackbar}
          autoHideDuration={3000}
          onClick={() => {
            this.setState({ updatesnackbar: !this.state.updatesnackbar });
          }}
        >
          <Alert
            onClose={() => {
              this.setState({ updatesnackbar: !this.state.updatesnackbar });
            }}
            severity="error"
          >
            Something went wrong please try again
        </Alert>
        </Snackbar>
      );
  }

  async getJobProfiles() {
    this.setState({ isLoading: true });
    let response = await fetch(api + "/api/v1/employees/" + id + "/jobs", {
      headers: {
        Authorization: token,
      },
    });
    response = await response.json();
    this.setState({ myJobHistory: response });
    console.log(this.state.myJobHistory);
  }

  async getMyJobProfileHistory(index) {
    let response = await fetch(
      api + "/api/v1/employees/" + id + "/jobs/" + index + "/history",
      {
        headers: {
          Authorization: token,
        },
      }
    );
    response = await response.json();
    this.setState({ viewMyJobHistories: response });
    this.setState({ isLoading: false });
  }
  updateMyJob(index) {
    this.setState({ editActionsOpen: true });
  }
  async getamount() {
    await axios
      .get(
        "http://3.22.17.212:9000/api/v1/resManager/serviceAPI/?serviceName=myjobprofile"
      )
      .then((res) => this.setState({ amount: res.data[0].serviceCharge }));
  }
  async pay() {
    let transactionid = Math.floor(
      10000000000000000 + Math.random() * 9000000000000000
    );
    let headers1 = {
      headers: {
        Authorization: token,
        "Content-Type": "multipart/form-data",
      },
    };

    await axios
      .post(
        "http://3.22.17.212:9000/wallet/transaction?type=DEBIT&amount=" +
        this.state.amount +
        "&description=" +
        transactionid,
        "",
        headers1
      )
      .then((response) => {
        if (response.status === 200) {
          this.verification();
        }
      });
  }
  async verification() {
    let headers = {
      headers: {
        Authorization: token,
      },
    };
    let bodyFormData = {
      verType: "Job",
      objId: this.state.currentid,
    };
    await axios
      .post(
        "http://3.22.17.212:9000/api/v1/codes/evaluation/new-code",
        bodyFormData,
        headers
      )
      .then((res) => {
        window.location.reload(false);
      });
  }

  async componentDidMount() {
    this.setState({ isLoading: true });

    token = localStorage.getItem("Token");
    id = localStorage.getItem("id");

    await this.getJobProfiles();

    await axios
      .get("http://3.22.17.212:9000/api/v1/employers/", {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        companyChoices = res.data;
        this.setState({ companies: companyChoices });
      });

    await axios
      .get("http://3.22.17.212:9000/api/v1/resManager/job/categories/", {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        positionCategories = res.data;
        this.setState({ positions: positionCategories });
        // this.setState({ positions: positionCategories.map(position => position.id) })
      });

    await axios
      .get("http://3.22.17.212:9000/api/v1/resManager/job/leaving-reasons/", {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        reasonsChoices = res.data;
        this.setState({ leavingReasons: reasonsChoices });
      });

    this.setState({ isloading: false });
  }

  isloading() {
    return (
      <>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          display="flex"
          style={{ minHeight: "0vh" }}
        >
          <CircularProgress />
        </Grid>
      </>
    );
  }
  details(row, index) {
    return (
      <Grid item container spacing={2}>
        <Grid item container xs={12} justify="center">
          <Avatar
            // src={}
            style={{ height: "6rem", width: "6rem" }}
          >
            {/* <img src="/images/sampleuserphoto.jpg" width="185" height="185" alt="" /> */}
          </Avatar>
        </Grid>

        <Grid
          item
          container
          xs={12}
          justify="center"
          alignItems="center"
          direction="column"
        >
          <Grid item>
            <Typography
              variant="h4"
              style={{
                fontWeight: "bold",
                textTransform: "capitalize",
              }}
            >
              {row.company_name_field}
            </Typography>
          </Grid>

          <Grid item>
            <Typography
              variant="subtitle1"
              color="textSecondary"
              style={{
                fontWeight: "bold",
                textTransform: "capitalize",
              }}
            >
              {row.jobTitle}
            </Typography>
          </Grid>
        </Grid>

        <Grid item container xs={12} justify="center">
          {row.vonStatus === "Employer Verified" ? (
            <Chip
              icon={<CheckCircleOutlineIcon style={{ color: "white" }} />}
              label={row.vonStatus}
              // color={green}

              style={{ background: "#8bc34a" }}
            />
          ) : (
              <Chip
                icon={<PriorityHighIcon style={{ color: "white" }} />}
                label={row.vonStatus}
                // color={green}

                style={{ background: "#ff9800" }}
              />
            )}
        </Grid>

        <Grid container item xs={12} spacing={2}>
          <Grid item container xs={12} direction="column">
            <Grid
              item
              container
              direction="row"
              alignItems="center"
              spacing={1}
            >
              <Grid item>
                <WorkIcon />
              </Grid>

              <Grid item>
                <Typography
                  variant="h5"
                  style={{
                    fontWeight: "bold",
                    textTransform: "capitalize",
                  }}
                >
                  Job Position
                </Typography>
              </Grid>
            </Grid>

            <Grid item>
              {row.endDate === null ? (
                <Typography
                  variant="subtitle1"
                  color="textSecondary"
                  style={{
                    fontWeight: "bold",
                    textTransform: "capitalize",
                  }}
                >
                  Since&nbsp;&nbsp;{row.startDate}
                </Typography>
              ) : (
                  <Typography
                    variant="subtitle1"
                    color="textSecondary"
                    style={{
                      fontWeight: "bold",
                      textTransform: "capitalize",
                    }}
                  >
                    {row.startDate} - {row.endDate}
                  </Typography>
                )}
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Typography>
              {row.jobDescription}
            </Typography>
          </Grid>

          <Grid
            item
            container
            xs={12}
            justify="center"
            alignItems="center"
            direction="column"
            spacing={2}
          >
            <Grid item xs={12} md={6}>
              <Button
                // style={{ marginTop: 5, minWidth: 200 }}
                fullWidth
                variant="outlined"
                disabled={!row.show_employer_ver_field}
                color="primary"
                // onClick={() => {this.setState({ verificationData: this.state.myJobHistory[index] },
                onClick={() => {
                  this.setState({
                    verificationEmployeeID: this.state.myJobHistory[index]
                      .employee,
                    verificationData: this.state.myJobHistory[index],
                  });
                  this.employerVerification(
                    row.employee,
                    row.employer_id_field,
                    row.id
                  );
                }}
              >
                Get Employer Verification
              </Button>
            </Grid>

            <Grid item xs={12} md={6}>
              <Button
                fullWidth
                style={{ marginTop: 5 }}
                disabled={!row.showVerifyOnTrac_btn}
                variant="outlined"
                color="primary"
                onClick={() => {
                  this.setState({
                    currentid: row.id,
                    walletdialog: true,
                  });
                  this.getamount();
                }}
              >
                Request for Verification
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
  render() {
    return (
      <div>
        {/* {this.state.myJobHistory.length === 0 ? (this.titleHeaderWhenNoRecordsExist()) : (this.titleHeaderWhenRecordsExist())} */}
        {this.state.isloading
          ? this.isloading()
          : this.state.myJobHistory.length === 0
            ? this.titleHeaderWhenNoRecordsExist()
            : this.titleHeaderWhenRecordsExist()}
        {/* {this.addJoBHistoryDialog()} */}
        {/* {this.editJobHistoryDialog()} */}
        {this.addsnackbar()}
        {this.updatesnackbar()}
      </div>
    );
  }

  titleHeaderWhenNoRecordsExist() {
    return (
      <>
        {this.state.addWindow ? this.addJoBHistoryDialog() : (
          <Grid container spacing={3} justify="space-between">
            <Grid item xs={12}>
              <Paper style={{ padding: 20 }} elevation={3}>
                <Box
                  p={1}
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  style={{ height: "50vh" }}
                >
                  <Typography variant="h4" gutterBottom align="center">
                    Add job profile to improve ratings
                    </Typography>

                  <Grid container justify="center" style={{ marginTop: 50 }}>
                    <Button
                      size='large'
                      startIcon={<AddCircleOutlineIcon />}
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        this.setState({ addWindow: !this.state.addWindow });
                      }}
                    >
                      Add Job Profile
                      </Button>
                  </Grid>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        )}
      </>
    );
  }

  titleHeaderWhenRecordsExist() {
    return (
      <Grid container spacing={4} justify="space-between" alignItems="center">
        <Grid item container justify="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h3">My Job Profile</Typography>
          </Grid>

          <Grid item>
            <Button
              color="Primary"
              size='large'
              startIcon={this.state.addDialogOpen ? (<ArrowBackIcon/>):(<AddCircleOutlineIcon/>)}
              style={{ marginTop: 25, marginLeft: 32 }}
              variant="contained"
              onClick={() => {
                this.setState({
                  addDialogOpen: !this.state.addDialogOpen,
                  addNewJob: !this.state.addNewJob
                });
              }}
            >
              {this.state.addDialogOpen ? "Back" : "Add New Job Profile"}
            </Button>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item container xs={12}>
          {this.state.addNewJob
            ? this.addJoBHistoryDialog()
            : this.getTableOfEmployees()}
          {/* {this.getTableOfEmployees()} */}
        </Grid>
      </Grid>
    );
  }

  addJoBHistoryDialog() {
    return (
      // <Dialog
      //   open={this.state.addDialogOpen}
      //   onClose={() => this.setState({ addDialogOpen: false })}
      //   aria-labelledby="form-dialog-title"
      // >
      //   <DialogTitle id="form-dialog-title">Add new job profile</DialogTitle>
      //   <DialogContent>
      //     <DialogContentText>
      //       Enter the details of your Job profile to be added
      //     </DialogContentText>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card>
            <Grid
              container
              justify="center"
              direction="row"
              alignItems="center"
              spacing={3}
              style={{ padding: 20 }}
            >
              <Grid item xs={12} style={{ background: "#263238" }}>
                <Typography variant='h5' style={{ color: "white" }}>
                  Add New Job Profile
        </Typography>
              </Grid>

              <Grid item xs={12}>
                <FormControl component="fieldset">
                  <FormLabel component="legend" required>
                    Are you currently at this job:
                </FormLabel>
                  <RadioGroup
                    name="searchCategory"
                    value={this.state.employeeByRadio}
                    onChange={(event) => {
                      this.setState({ employeeByRadio: event.target.value });
                    }}
                  >
                    <Grid container direction="row" style={{ marginTop: 10 }}>
                      <FormControlLabel
                        value="currentEmployee"
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="oldEmployee"
                        control={<Radio />}
                        label="No"
                      />
                    </Grid>
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={9}>
                <FormControl fullWidth size="small" required error={this.state.comapanyNameCheck}>
                  <InputLabel id="companyLabel">Company</InputLabel>
                  <Select
                    labelId="companyLabel"
                    id="company"
                    disabled={this.state.companyOptionDisable}
                    value={this.state.addJobDialogCompany}
                    onChange={(event) => {
                      this.setState({ addJobDialogCompany: event.target.value });
                    }}
                    fullWidth
                  >
                    {
                      this.state.companies.map((company) => (
                        <MenuItem key={company} value={company}>
                          {company.companyName}
                        </MenuItem>
                      ))
                      // this.state.positions.map(position => <MenuItem key={position} value={position}>{position.positionCategory}</MenuItem>)
                    }
                  </Select>
                </FormControl>
              </Grid>

              <Grid item md={3} xs={12} style={{ marginTop: 15 }}>
                <FormControlLabel
                  required
                  control={
                    <Checkbox
                      checked={this.state.addJobDialogCheck}
                      onChange={(event) => {
                        this.setState({
                          addJobDialogCheck: !this.state.addJobDialogCheck,
                        });
                        this.setState({
                          companyOptionDisable: !this.state.companyOptionDisable,
                        });
                      }}
                      name="checkedB"
                      color="primary"
                    />
                  }
                  label="Other"
                />
              </Grid>

              {this.state.addJobDialogCheck ? (
                <>
                  <Grid item fullWidth xs={12}>
                    <TextField
                      required
                      id="otherCompany"
                      label="Other Company"
                      value={this.state.addJobDialogOtherCompany}
                      onChange={(event) =>
                        this.setState({
                          addJobDialogOtherCompany: event.target.value,
                        })
                      }
                      type="text"
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                </>
              ) : null}

              {this.state.employeeByRadio !== "currentEmployee" ? (
                <>
                  <Grid item md={6} xs={12}>
                    <TextField
                      required
                      fullWidth
                      type="month"
                      onChange={(event) => {
                        this.setState({
                          addJobDialogStartDate: event.target.value,
                        });
                      }}
                      helperText="Start Date"
                    />
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <TextField
                      required
                      fullWidth
                      type="month"
                      onChange={(event) => {
                        this.setState({
                          addJobDialogEndDate: event.target.value,
                        });
                      }}
                      helperText="End Date"
                    />
                  </Grid>
                </>
              ) : (
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      required
                      type="month"
                      onChange={(event) => {
                        this.setState({
                          addJobDialogStartDate: event.target.value,
                        });
                      }}
                      helperText="Start Date"
                    />
                  </Grid>
                )}

              <Grid item xs={12}>
                <FormControl fullWidth size="small" error={this.state.positionCheck}>
                  <InputLabel id="positionLabel" required>
                    Position
                </InputLabel>
                  <Select
                    labelId="positionLabel"
                    id="position"
                    value={this.state.addJobDialogPosition}
                    onChange={(event) => {
                      this.setState({ addJobDialogPosition: event.target.value });
                    }}
                    fullWidth
                  >
                    {this.state.positions.map((position) => (
                      <MenuItem key={position} value={position}>
                        {position.positionCategory}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <TextField
                error={this.state.jobTitleCheck}
                  required
                  id="jobTitle"
                  label="Job Title"
                  value={this.state.addJobDialogJobTitle}
                  onChange={(event) =>
                    this.setState({ addJobDialogJobTitle: event.target.value })
                  }
                  type="text"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                error={this.state.jobDescriptionCheck}
                  required
                  id="jobDescription"
                  label="Job Description"
                  type="text"
                  fullWidth
                  multiline
                  rows={3}
                  value={this.state.addJobDialogJobDescription}
                  onChange={(event) =>
                    this.setState({
                      addJobDialogJobDescription: event.target.value,
                    })
                  }
                />
              </Grid>

              {this.state.employeeByRadio !== "currentEmployee" ? (
                <Grid item xs={12}>
                  <FormControl fullWidth size="small" error={this.state.reasonForLeavingCheck}>
                    <InputLabel id="reasonForLeaving" required>
                      Reason for leaving
                  </InputLabel>
                    <Select
                      labelId="reasonForLeaving"
                      id="reasonForLeaving"
                      value={this.state.addJobDialogReasonForLeaving}
                      onChange={(event) => {
                        this.setState({
                          addJobDialogReasonForLeaving: event.target.value,
                        });
                      }}
                      label="resonForLeaving"
                      fullWidth
                    >
                      {this.state.leavingReasons.map((leavingReason) => (
                        <MenuItem key={leavingReason} value={leavingReason}>
                          {leavingReason.reason}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              ) : (
                  <div />
                )}

              {/* <Grid item xs={6} style={{ marginTop: 15 }} >
                            <Typography >How do you rate this company?</Typography>
                        </Grid> */}

              {/* <Grid item xs={6} style={{ marginTop: 15 }} >
                            <Rating
                                name="simple-controlled"
                                value={this.state.addJobDialogRating}
                                onChange={(event, newValue) => this.setState({ addJobDialogRating: newValue })}
                                max={10}
                            />
                        </Grid> */}
              {this.state.addJobDialogCheck ? (
                <Grid item container justify='center' xs={12} direction='row' spacing={3}>
                  <Grid item>
                    <Button
                      startIcon={<PublishIcon />}
                      size='large'
                      style={{ width: 85 }}
                      onClick={()=> this.addJobProfileOther()}
                      color="primary"
                      variant="contained"
                    >
                      Submit
              </Button>
                  </Grid>

                  <Grid item>
                    <Button
                      startIcon={<ClearIcon />}
                      size='large'
                      style={{ width: 85 }}
                      onClick={() => {
                        this.setState({
                          addDialogOpen: !this.state.addDialogOpen,
                          addNewJob: !this.state.addNewJob,
                          addWindow: !this.state.addWindow
                        });
                      }}
                      color="secondary"
                      variant="contained"
                    >
                      Cancel
              </Button>
                  </Grid>
                </Grid>
              ) : (
                  <Grid item container justify='center' xs={12} direction='row' spacing={3}>
                    <Grid item>
                      <Button
                        startIcon={<PublishIcon />}
                        size='large'
                        style={{ width: 85 }}
                        onClick={()=> this.addJobProfile()}
                        color="primary"
                        variant="contained"
                      >
                        Submit
              </Button>
                    </Grid>

                    <Grid item>
                    <Button
                      startIcon={<ClearIcon />}
                      size='large'
                      style={{ width: 85 }}
                      onClick={() => {
                        this.setState({
                          addDialogOpen: !this.state.addDialogOpen,
                          addNewJob: !this.state.addNewJob,
                          addWindow: !this.state.addWindow
                        });
                      }}
                      color="secondary"
                      variant="contained"
                    >
                      Cancel
              </Button>
                  </Grid>
                  </Grid>
                )}

            </Grid>
          </Card>
        </Grid>
      </Grid>
      // </DialogContent>

      //   <DialogActions>

      //   </DialogActions>
      // </Dialog>
    );
  }

  editJobHistoryDialog(index) {
    return (
      // <Dialog
      //   open={this.state.editActionsOpen}
      //   onClose={() => this.setState({ editActionsOpen: false })}
      //   aria-labelledby="form-dialog-title"
      // >
      //   <DialogTitle id="form-dialog-title">Edit your job profile</DialogTitle>
      //   <DialogContent>
      //     <DialogContentText>
      //       Enter the details of your Job profile to be updated
      //     </DialogContentText>

      <Grid
        container
        justify="flex-start"
        direction="row"
        alignItems="center"
        spacing={3}
      >
        <Grid item xs={12} md={9}>
          <FormControl fullWidth size="small">
            <InputLabel id="demo-simple-select-outlined-label" required>
              Company
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              defaultValue={this.state.updateMyJobData.company}
              onChange={(event) =>
                this.setState({ editJobDialogCompany: event.target.value })
              }
              label="company"
            >
              {this.state.companies.map((row) => (
                <MenuItem value={row.id} key={row.id}>
                  {row.companyName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={3} style={{ marginTop: 15 }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={this.state.editJobDialogCheck}
                onChange={(event) => {
                  this.setState({
                    editJobDialogCheck: !this.state.editJobDialogCheck,
                  });
                  this.setState({
                    companyOptionDisable: !this.state.companyOptionDisable,
                  });
                }}
                name="checkedA"
                color="primary"
              />
            }
            label="Other"
          />
        </Grid>

        {this.state.editJobDialogCheck ? (
          <>
            <Grid item fullWidth xs={12}>
              <TextField
                required
                id="otherCompany"
                label="Other Company"
                value={this.state.editJobDialogOtherCompany}
                onChange={(event) =>
                  this.setState({
                    editJobDialogOtherCompany: event.target.value,
                  })
                }
                type="text"
                fullWidth
              />
              {/* } */}
            </Grid>
          </>
        ) : null}

        <Grid item xs={12} md={6}>
          <TextField
            required
            fullWidth
            type="month"
            // defaultValue={this.state.updateMyJobData.startDate.substring(
            //   0,
            //   this.state.updateMyJobData.startDate.length - 3
            // )}
            onChange={(event) => {
              this.setState({ editJobDialogStartDate: event.target.value });
            }}
            helperText="Start Date"
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            required
            fullWidth
            type="month"
            // defaultValue={this.state.updateMyJobData.endDate.substring(
            //   0,
            //   this.state.updateMyJobData.endDate-3
            // )}
            onChange={(event) => {
              this.setState({ editJobDialogEndDate: event.target.value });
            }}
            helperText="End Date"
          />
        </Grid>

        <Grid item xs={12}>
          <FormControl fullWidth size="small">
            <InputLabel id="demo-simple-select-outlined-label" required>
              Job Position
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              defaultValue={this.state.updateMyJobData.jobCategory}
              onChange={(event) =>
                this.setState({ editJobDialogPosition: event.target.value })
              }
              label="company"
            >
              {this.state.positions.map((row) => (
                <MenuItem value={row.id} key={row.id}>
                  {row.positionCategory}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <TextField
            required
            id="jobTitle"
            label="Job Title"
            // value={this.state.editJobDialogJobTitle}
            defaultValue={this.state.updateMyJobData.jobTitle}
            onChange={(event) =>
              this.setState({ editJobDialogJobTitle: event.target.value })
            }
            type="text"
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            required
            id="jobDescription"
            label="Job Description"
            type="text"
            fullWidth
            multiline
            rows={3}
            // value={this.state.editJobDialogJobDescription}
            defaultValue={this.state.updateMyJobData.jobDescription}
            onChange={(event) =>
              this.setState({
                editJobDialogJobDescription: event.target.value,
              })
            }
          />
        </Grid>

        <Grid item xs={12}>
          <FormControl fullWidth size="small">
            <InputLabel id="demo-simple-select-outlined-label">
              Leaving Reason
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              defaultValue={this.state.updateMyJobData.leavingReason}
              onChange={(event) =>
                this.setState({
                  editJobDialogReasonForLeaving: event.target.value,
                })
              }
              label="company"
            >
              {this.state.leavingReasons.map((row) => (
                <MenuItem value={row.id} key={row.id}>
                  {row.reason}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {/* <Grid item xs={6} style={{ marginTop: 15 }} >
                            <Typography >How do you rate this company?</Typography>
                        </Grid>

                        <Grid item xs={6} style={{ marginTop: 15 }} >
                            <Rating
                                name="simple-controlled"
                                // value={this.state.editJobDialogRating}
                                defaultValue={this.state.updateMyJobData.companyRating}
                                onChange={(event, newValue) => this.setState({ editJobDialogRating: newValue })}
                                max={10}
                            />
                        </Grid> */}

        <Grid item xs={12}>
          <TextField
            required
            id="reasonForUpdate"
            label="Reason for Update"
            type="text"
            fullWidth
            multiline
            rows={3}
            value={this.state.editJobDialogUpdateReason}
            onChange={(event) =>
              this.setState({
                editJobDialogUpdateReason: event.target.value,
              })
            }
          />
        </Grid>

        <Grid
          item
          xs={12}
          container
          direction="row"
          justify="flex-end"
          alignItems="flex-end"
          spacing={2}
        >
          <Grid item>
            <Button
              // disabled={this.state.editJobDialogUpdateReason}
              style={{ width: 85 }}
              onClick={() => {
                this.editJobProfile(this.state.updateJobId);
              }}
              color="primary"
              variant="contained"
            >
              Save
            </Button>
          </Grid>

          {/* <Grid item >
            <Button
              color="secondary"
              variant="contained"
              onClick={() =>
                this.setState({ editActionsOpen: false, selectedIndex: -1 })
              }
            >
              Cancel
          </Button>
          </Grid> */}
        </Grid>
      </Grid>

      // </DialogContent>
      // <DialogActions>
      //   <Button
      //     // disabled={this.state.editJobDialogUpdateReason}
      //     style={{ width: 85 }}
      //     onClick={() => {
      //       this.editJobProfile(this.state.updateJobId);
      //     }}
      //     color="primary"
      //     variant="contained"
      //   >
      //     Edit
      //   </Button>
      //   <Button
      //     color="secondary"
      //     variant="contained"
      //     onClick={() =>
      //       this.setState({ editActionsOpen: false, selectedIndex: -1 })
      //     }
      //   >
      //     Cancel
      //   </Button>
      // </DialogActions>
      // </Dialog>
    );
  }

  viewDetailsDialog(row, index) {
    return (
      // <Dialog
      //   open={this.state.viewDialogOpen}
      //   onClose={() => this.setState({ viewDialogOpen: false })}
      //   aria-labelledby="form-dialog-title"
      // >
      <>
        {/* <DialogTitle id="form-dialog-title">Job History</DialogTitle> */}
        {/* <DialogContent> */}

        {this.state.isloading ? (
          this.isloading()
        ) : (
            <>
              {/* <Table stickyHeader>
                <TableHead>
                  <TableRow style={{ backgroundColor: "black" }}>
                    <TableCell align="left">Created on</TableCell>
                    <TableCell align="left">Update Reason</TableCell>
                    <TableCell align="left">Job Description</TableCell>
                    <TableCell align="left">Job Category</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {this.state.viewMyJobHistories.map((row, index) => (
                    <TableRow key={row.id}>
                      <TableCell align="left">
                        {new Date(row.created_on).toDateString()}
                      </TableCell>
                      <TableCell align="left">{row.update_reason}</TableCell>
                      <TableCell align="left">{row.jobDescription}</TableCell>
                      <TableCell align="left">{row.job_category_field}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table> */}

              {/* <Grid item>
                    <TextField
                    fullWidth
                      id="jobDescription"
                      label="Job Description"
                      defaultValue={row.jobDescription}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                 </Grid> */}

              {this.state.viewMyJobHistories.map((row, index) => (
                //  <Paper>
                <Grid item container direction="column" spacing={2}>
                  <Grid item>
                    <Typography variant="h6" style={{ fontWeight: "bold" }}>
                      Job Edit History
                  </Typography>
                  </Grid>

                  <Grid item>
                    <TextField
                      fullWidth
                      variant="outlined"
                      id="editDate"
                      label="Edited on"
                      defaultValue={new Date(row.created_on).toDateString()}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      variant="outlined"
                      fullWidth
                      id="jobPosition"
                      label="Job Position"
                      defaultValue={row.job_category_field}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      variant="outlined"
                      fullWidth
                      id="jobDescription"
                      label="Job Description"
                      defaultValue={row.jobDescription}
                      InputProps={{
                        readOnly: true,
                      }}
                      multiline
                      rows={3}
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      variant="outlined"
                      fullWidth
                      id="editReason"
                      label="Edit Reason"
                      defaultValue={row.update_reason}
                      InputProps={{
                        readOnly: true,
                      }}
                      multiline
                      rows={3}
                    />
                  </Grid>
                </Grid>
                // </Paper>
              ))}

              {/* </DialogContent> */}
              {/* <DialogActions>
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={() =>
                    this.setState({ viewDialogOpen: false, selectedIndex: -1 })
                  }
                >
                  Close
              </Button>
              </DialogActions> */}
            </>
          )}
      </>
      // </Dialog>
    );
  }

  getTableOfEmployees() {
    return (
      <div>
        {
          // <TableContainer component={Paper} elevation={16}>
          //   <Table stickyHeader>
          //     <TableHead>
          //       <TableRow style={{ backgroundColor: "black" }}>
          //         <TableCell align="left">Start Date</TableCell>
          //         <TableCell align="left">End Date</TableCell>
          //         <TableCell align="left">Employer</TableCell>
          //         <TableCell align="left">Position</TableCell>
          //         <TableCell align="left">VON-Status</TableCell>
          //         <TableCell align="left">View</TableCell>
          //         <TableCell align="center">Actions</TableCell>
          //         <TableCell align="center">Verification</TableCell>
          //       </TableRow>
          //     </TableHead>
          //     <TableBody>
          //       {this.state.myJobHistory.map((row, index) => (

          //         <TableRow key={row.id}>
          //           <TableCell align="left">{row.startDate}</TableCell>
          //           <TableCell align="left">{row.endDate}</TableCell>
          //           <TableCell align="left">{row.company_name_field}</TableCell>
          //           <TableCell align="left">{row.jobTitle}</TableCell>
          //           <TableCell align="left">{row.vonStatus}</TableCell>
          //           <TableCell align="left">
          //             <Button
          //               color="primary"
          //               variant="outlined"
          //               fullWidth
          //               onClick={() => {
          //                 this.getMyJobProfileHistory(row.id);
          //                 this.setState({
          //                   viewDialogOpen: true,
          //                 });
          //               }}
          //             >
          //               History
          //             </Button>
          //           </TableCell>
          //           <TableCell>
          //             <Grid container justify="flex-end">
          //               {row.show_update_field ? (
          //                 <Grid item xs={12}>
          //                   <Button
          //                     disabled={row.vonStatus === "Audit In Progress"}
          //                     style={{ minWidth: 200 }}
          //                     variant="outlined"
          //                     color="secondary"
          //                     onClick={() => {
          //                       this.setState(
          //                         {
          //                           updateMyJobData: this.state.myJobHistory[
          //                             index
          //                           ],
          //                           updateJobId: row.id,

          //                                                           }
          //                       );
          //                       this.updateMyJob(index);
          //                     }}
          //                   >
          //                     Update
          //                   </Button>
          //                 </Grid>
          //               ) : (
          //                 "NA"
          //               )}

          //               <Grid item xs={12}>
          //                 {row.show_employer_ver_field === true ? (
          //                   <Button
          //                     style={{ marginTop: 5, minWidth: 200 }}
          //                     variant="outlined"
          //                     color="secondary"
          //                     // onClick={() => {this.setState({ verificationData: this.state.myJobHistory[index] },
          //                     onClick={() => {
          //                       this.setState(
          //                         {
          //                           verificationEmployeeID: this.state
          //                             .myJobHistory[index].employee,
          //                           verificationData: this.state.myJobHistory[
          //                             index
          //                           ],
          //                         },

          //                       );
          //                       this.employerVerification(
          //                         row.employee,
          //                         row.employer_id_field,
          //                         row.id
          //                       );
          //                     }}
          //                   >
          //                     Get Employer Verification
          //                   </Button>
          //                 ) : (
          //                   <div />
          //                 )}
          //               </Grid>
          //             </Grid>
          //           </TableCell>
          //           {row.showVerifyOnTrac_btn === true ? (
          //             <TableCell align="center">
          //               <Button
          //                 variant="outlined"
          //                 color="default"
          //                 onClick={() => {
          //                   this.setState({
          //                     currentid: row.id,
          //                     walletdialog: true,
          //                   });
          //                   this.getamount();
          //                 }}
          //               >
          //                 Request for Verification
          //               </Button>
          //             </TableCell>
          //           ) : null}
          //         </TableRow>
          //       ))}
          //     </TableBody>
          //   </Table>
          // </TableContainer>

          <Grid
            item
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={3}
          >
            {this.state.myJobHistory.map((row, index) => (
              <Grid
                item
                container
                direction="row"
                justify="center"
                alignItems="center"
                xs={12}
              >
                <Card>
                  <Grid
                    item
                    container
                    style={{ padding: 20 }}
                    justify="center"
                    alignItems="center"
                    spacing={2}
                  >
                    <Grid
                      item
                      container
                      xs={12}
                      alignItems="center"
                      justify="space-between"
                      style={{background: "#263238"}}
                    >
                      <Grid item>
                        <IconButton
                          color="primary"
                          aria-label="upload picture"
                          component="span"
                          onClick={() => {
                            this.getMyJobProfileHistory(row.id);
                            this.setState({
                              viewDialogOpen: true,
                              viewHistoryId: index,
                              historyDisplay: !this.state.historyDisplay,
                            });
                          }}
                        >
                          {this.state.historyDisplay? (<ArrowBackIcon style={{color: "white"}}/>):(<HistoryIcon style={{color: "white"}}/>)}
                        </IconButton>
                      </Grid>

                      <Grid item>
                        <IconButton
                          color="primary"
                          aria-label="edit"
                          component="span"
                          // onClick={() => {this.setState({editDisplay: !this.state.editDisplay}); }}
                          onClick={() => {
                            this.setState({
                              updateMyJobData: this.state.myJobHistory[index],
                              updateJobId: index,
                              editDisplay: !this.state.editDisplay,
                            });
                            this.updateMyJob(index);
                          }}
                        >
                          {this.state.editDisplay? (<ArrowBackIcon style={{color: "white"}}/>):(<EditIcon style={{color: "white"}}/>)}
                        </IconButton>
                      </Grid>
                    </Grid>

                    {this.state.editDisplay ? (
                      <>
                        {this.state.editDisplay &&
                          this.state.updateJobId === index
                          ? this.editJobHistoryDialog(index)
                          : this.details(row, index)}
                      </>
                    ) : (
                        <>
                          {this.state.historyDisplay &&
                            this.state.viewHistoryId === index
                            ? this.viewDetailsDialog(index)
                            : this.details(row, index)}
                        </>
                      )}
                  </Grid>
                </Card>
              </Grid>
            ))}
          </Grid>
        }
        {/* {this.viewDetailsDialog()} */}
        {
          <Dialog
            open={this.state.walletdialog}
            onClose={() => this.setState({ walletdialog: false })}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title" align="center" justify="center">
              You need to pay {this.state.amount} for this service from wallet
            </DialogTitle>
            <DialogContent></DialogContent>
            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                onClick={() =>
                  this.setState(
                    {
                      walletdialog: false,
                    },
                    this.pay
                  )
                }
              >
                Pay
              </Button>
              <Button
                color="secondary"
                variant="contained"
                onClick={() =>
                  this.setState({
                    walletdialog: false,
                  })
                }
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        }
      </div>
    );
  }

  async addJobProfile() {
    console.log("ConditionBegins")
    if (
      this.state.addJobDialogCompany.length 
      // || this.state.addJobDialogOtherCompany.length 
      !== 0 && 
      // this.state.addJobDialogStartDate !== 0 &&
      this.state.addJobDialogPosition.length !== 0 && 
      this.state.addJobDialogJobTitle.length !== 0 &&
      this.state.addJobDialogJobDescription.length !== 0
      // this.state.addJobDialogReasonForLeaving.length !== 0
    ) {
      console.log("ConditionSuccess")
  
      let bodyData = {
        employee: id,
        company: this.state.addJobDialogCompany.id,
        // 'company_other': this.state.addJobDialogOtherCompany,
        startDate: this.state.addJobDialogStartDate + "-01",
        // endDate: this.state.addJobDialogEndDate,
        jobCategory: this.state.addJobDialogPosition.id,
        jobTitle: this.state.addJobDialogJobTitle,
        jobDescription: this.state.addJobDialogJobDescription,
        leavingReason: this.state.addJobDialogReasonForLeaving.id,
        // companyRating: this.state.addJobDialogRating,
      };
  
      try {
        let response = await fetch(
          "http://3.22.17.212:9000/api/v1/employees/post-job",
          {
            method: "POST",
            headers: {
              Authorization: token,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(bodyData),
          }
        );
        response = await response.json();
  
        this.setState({ addresponse: response.status, addsnackbar: true,
          addDialogOpen: !this.state.addDialogOpen,
          addNewJob: !this.state.addNewJob,
          addWindow: !this.state.addWindow });
  
        await this.getJobProfiles();
  
        this.setState({ addDialogOpen: false });
        this.setState({ addJobDialogCompany: "" });
        this.setState({ addJobDialogOtherCompany: "" });
        this.setState({ addJobDialogStartDate: "" });
        this.setState({ addJobDialogEndDate: "" });
        this.setState({ addJobDialogPosition: "" });
        this.setState({ addJobDialogJobTitle: "" });
        this.setState({ addJobDialogJobDescription: "" });
        this.setState({ addJobDialogReasonForLeaving: "" });
        this.setState({ addJobDialogRating: "" });
      } catch (error) {
        console.log("[!ON_REGISTER] " + error);
        this.setState({ snackbar: true, snackbarresponse: error.response });
      }
    }

    else {
      if (this.state.addJobDialogCompany.length === 0 || this.state.addJobDialogOtherCompany === 0) {
        this.setState({comapanyNameCheck: true})
      }
      if (this.state.addJobDialogStartDate.length === 0) {
        this.setState({startDateCheck: true})
      }
      if (this.state.addJobDialogPosition.length === 0) {
        this.setState({positionCheck: true})
      }
      if (this.state.addJobDialogJobTitle.length === 0) {
        this.setState({ jobTitleCheck: true})
      }
      if (this.state.addJobDialogJobDescription.length === 0) {
        this.setState({jobDescriptionCheck: true})
      }
      if (this.state.employeeByRadio !== "currentEmployee" && this.state.addJobDialogReasonForLeaving.length === 0) {
        this.setState({reasonForLeavingCheck: true})
      }
    }
  }

  async addJobProfileOther() {
    let bodyData = {
      employee: id,
      // 'company': this.state.addJobDialogCompany.id,
      company_other: this.state.addJobDialogOtherCompany,
      startDate: this.state.addJobDialogStartDate + "-01",
      endDate: this.state.addJobDialogEndDate + "-01",
      jobCategory: this.state.addJobDialogPosition.id,
      jobTitle: this.state.addJobDialogJobTitle,
      jobDescription: this.state.addJobDialogJobDescription,
      leavingReason: this.state.addJobDialogReasonForLeaving.id,
      // companyRating: this.state.addJobDialogRating,
    };

    try {
      let response = await fetch(
        "http://3.22.17.212:9000/api/v1/employees/post-job",
        {
          method: "POST",
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(bodyData),
        }
      );
      response = await response.json();

      await this.getJobProfiles();
      this.setState({ addresponse: response.status, addsnackbar: true });

      this.setState({ addDialogOpen: false });
      this.setState({ addJobDialogCompany: "" });
      this.setState({ addJobDialogOtherCompany: "" });
      this.setState({ addJobDialogStartDate: "" });
      this.setState({ addJobDialogEndDate: "" });
      this.setState({ addJobDialogPosition: "" });
      this.setState({ addJobDialogJobTitle: "" });
      this.setState({ addJobDialogJobDescription: "" });
      this.setState({ addJobDialogReasonForLeaving: "" });
      this.setState({ addJobDialogRating: "" });
    } catch (error) {
      this.setState({ addresponse: error.response, addsnackbar: true });
    }
  }

  async editJobProfile(id) {
    let bodyData = {
      // 'employee': id,
      company: this.state.editJobDialogCompany.id,
      // 'company_other': this.state.editJobDialogOtherCompany,
      startDate: this.state.editJobDialogStartDate,
      // 'startDate': "2020-12-30",
      endDate: this.state.editJobDialogEndDate,
      jobCategory: this.state.editJobDialogPosition.id,
      jobTitle: this.state.editJobDialogJobTitle,
      jobDescription: this.state.editJobDialogJobDescription,
      leavingReason: this.state.editJobDialogReasonForLeaving.id,
      companyRating: this.state.editJobDialogRating,
      update_reason: this.state.editJobDialogUpdateReason,
    };

    let response = await fetch(
      "http://3.22.17.212:9000/api/v1/employees/update-job/" + id,
      {
        method: "POST",
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(bodyData),
      }
    );
    response = await response.json(bodyData);

    await this.getJobProfiles();
    await this.setState({ editActionsOpen: false });
    this.setState({ updateresponse: response.status, updatesnackbar: true });
  }

  async employerVerification(employeeId, employerId, rowId) {
    let bodyData = {
      ee_employer: employerId,
      ee_employee: employeeId,
      category: "EmploymentVerification",
      requestJobProfile: true,
      jobProfile: rowId,
    };

    let response = await fetch(
      "http://3.22.17.212:9000/api/v1/employers/newEmpVerification",
      {
        method: "POST",
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(bodyData),
      }
    );
    response = await response.json(bodyData);
    this.setState({ snackbar: true, snackbarresponse: response });

    await this.getJobProfiles();
  }
}

export default myJobProfile;
