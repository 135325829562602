import React from 'react'


import Datatable from "./Datatable";

export default function Tabs(props) {

    return (
        <div>
            <Datatable data={props}/>
        </div>
    )
}
