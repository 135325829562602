import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Tabs,
  Tab,
  Box,
  Button,
  ButtonGroup,
  Divider
} from "@material-ui/core/";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import EmployeeChoiceQuestions from '../Employee/EmployeeChoiceQuestions'
import EmployeeRatingQuestions from '../Employee/EmployeeRatingQuestions'
import EmployerChoiceQuestions from '../Employer/EmployerChoiceQuestions'
import EmployerRatingQuestions from '../Employer/EmployerRatingQuestions'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 500,
  },
  profileCardHeight: {
    minHeight: 300,
    // minWidth: window.innerWidth/3
  },
  tabs: {
    flexGrow: 1,
    flexBasis: 'auto'
  },
  expansionWidth: {
    width: "100%",
  },
  marginTop: {
    marginTop: 20,
  },
  demo2: {
    backgroundColor: "#fff",
  },
}));
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Grid
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </Grid>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}
const StyledTabs = withStyles({
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    "& > span": {
      maxWidth: 40,
      width: "100%",
      backgroundColor: '#f5f5f5',
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    color: "#000000",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    "&:focus": {
      opacity: 1,
    },
  },
}))((props) => <Tab disableRipple {...props} />);
export default function Index(props) {

  useEffect(() => {

  }, []);
  const [value, setValue] = React.useState(0);

  const [employeeChoiceDisplay, setEmployeeChoice] = useState(true);
  const [employeeRatingDisplay, setEmployeeRating] = useState(false);
  const [employerChoiceDisplay, setEmployerChoice] = useState(false);
  const [employerRatingDisplay, setEmployerRatings] = useState(false);

  const handleChoiceQuestions = () => {
    setEmployeeChoice(true);
    setEmployeeRating(false);
    setEmployerChoice(false);
    setEmployerRatings(false);
  };
  const handleEmployeeRatingQuestions = () => {
    setEmployeeChoice(false);
    setEmployeeRating(true);
    setEmployerChoice(false);
    setEmployerRatings(false);
  };
  const handleEmployerChoices = () => {
    setEmployeeChoice(false);
    setEmployeeRating(false);
    setEmployerChoice(true);
    setEmployerRatings(false);
  };
  const handleEmployerRatingQuestions = () => {
    setEmployeeChoice(false);
    setEmployeeRating(false);
    setEmployerChoice(false);
    setEmployerRatings(true);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  // const { classes } = this.props;

  return (

    <Grid
      container
      spacing={2}
      justify="flex-start"
    >
      <Grid
        item
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        spacing={2}
      >

        <Grid item xs={6} md={6}>
          <Typography
            component={"span"}
            variant="h4"
            display="block"
            align="left"
            style={{ padding: 20 }}
          >
            Rating &amp; Survey Settings
              </Typography>
        </Grid>

        <Grid item>
          <ButtonGroup
            color="primary"
            aria-label="contained primary button group"
            style={{ padding: 10 }}
          >
            <Button
              variant={employeeChoiceDisplay === true ? "contained" : null}
              onClick={() => handleChoiceQuestions()}
              style={{ minWidth: 100 }}
            >
              Employee Choices
                    </Button>
            <Button
              // disabled={employeeRatingDisplay}
              variant={employeeRatingDisplay === true ? "contained" : null}
              onClick={() => handleEmployeeRatingQuestions()}
              style={{ minWidth: 100 }}
            >
              Employee Ratings
                    </Button>
            <Button
              variant={
                employerChoiceDisplay === true ? "contained" : null
              }
              style={{ minWidth: 100 }}
              // disabled={employerChoiceDisplay}
              onClick={() => handleEmployerChoices()}
            >
              Employer Choices
                    </Button>
            <Button
              variant={
                employerRatingDisplay === true ? "contained" : null
              }
              style={{ minWidth: 100 }}
              // disabled={employerChoiceDisplay}
              onClick={() => handleEmployerRatingQuestions()}
            >
              Employer Ratings
                    </Button>
          </ButtonGroup>
        </Grid>

        <Grid item xs={12} >
          <Divider />
        </Grid>

        <Grid item xs={12} md={12} style={{ marginLeft: 20, marginRight: 20 }}>
          {employeeChoiceDisplay ? <EmployeeChoiceQuestions /> : null}
          {employeeRatingDisplay ? <EmployeeRatingQuestions /> : null}
          {employerChoiceDisplay ? <EmployerChoiceQuestions /> : null}
          {employerRatingDisplay ? <EmployerRatingQuestions /> : null}
        </Grid>
      </Grid>
      <Grid>
      </Grid>
    </Grid>
  );
}