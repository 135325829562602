import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Tabs,
  Tab,
  Box,
  Button,
  ButtonGroup,
  Divider
} from "@material-ui/core/";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";


import ManageAddressReasons from '../ManageAddressReasons'
import ManageAddressTypes from '../ManageAddressTypes'
import ManageCities from '../ManageCities'
import ManageLGAs from '../ManageLGAs'
import ManageStates from '../ManageStates'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 500,
  },
  profileCardHeight: {
    minHeight: 300,
    // minWidth: window.innerWidth/3
  },
  tabs: {
    flexGrow: 1,
    flexBasis: 'auto'
  },
  expansionWidth: {
    width: "100%",
  },
  marginTop: {
    marginTop: 20,
  },
  demo2: {
    backgroundColor: "#fff",
  },
}));
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Grid
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </Grid>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}
const StyledTabs = withStyles({
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    "& > span": {
      maxWidth: 40,
      width: "100%",
      backgroundColor: '#f5f5f5',
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    color: "#000000",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    "&:focus": {
      opacity: 1,
    },
  },
}))((props) => <Tab disableRipple {...props} />);
export default function Index(props) {

  useEffect(() => {

  }, []);
  const [value, setValue] = React.useState(0);

  const [statesDisplay, setStates] = useState(true);
  const [lgasDisplay, setLGAs] = useState(false);
  const [citiesDisplay, setCities] = useState(false);
  const [addressTypesDisplay, setAddressTypes] = useState(false);
  const [addressReasonsDisplay, setAddressReasons] = useState(false);

  const handleStates = () => {
    setStates(true);
    setLGAs(false);
    setCities(false);
    setAddressTypes(false);
    setAddressReasons(false);
  };
  const handleLGAs = () => {
    setStates(false);
    setLGAs(true);
    setCities(false);
    setAddressTypes(false);
    setAddressReasons(false);
  };
  const handleCities = () => {
    setStates(false);
    setLGAs(false);
    setCities(true);
    setAddressTypes(false);
    setAddressReasons(false);
  };
  const handleAddressTypes = () => {
    setStates(false);
    setLGAs(false);
    setCities(false);
    setAddressTypes(true);
    setAddressReasons(false);
  };
  const handleAddressReasons = () => {
    setStates(false);
    setLGAs(false);
    setCities(false);
    setAddressTypes(false);
    setAddressReasons(true);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  // const { classes } = this.props;

  return (

      <Grid
        container
        spacing={2}
        justify="flex-start"
      >
            <Grid
              item
              container
              direction="row"
              justify="space-between"
              alignItems="center"
              spacing={2}
            >

              <Grid item xs={6} md={6}>
              <Typography
                component={"span"}
                variant="h4"
                display="block"
                align="left"
                style={{ padding: 20}}
              >
                Address Settings
              </Typography>
              </Grid>

              <Grid item>
              <ButtonGroup
                    color="primary"
                    aria-label="contained primary button group"
                    style={{padding: 10}}
                  >
                    <Button
                      variant={statesDisplay === true ? "contained" : null}
                      onClick={() => handleStates()}
                      style={{minWidth: 100}}
                    >
                    States
                    </Button>
                    <Button
                      // disabled={lgasDisplay}
                      variant={lgasDisplay === true ? "contained" : null}
                      onClick={() => handleLGAs()}
                      style={{minWidth: 100}}
                    >
                      LGAs
                    </Button>
                    <Button
                      variant={
                        citiesDisplay === true ? "contained" : null
                      }
                      style={{minWidth: 100}}
                      // disabled={citiesDisplay}
                      onClick={() => handleCities()}
                    >
                      Cities
                    </Button>
                    <Button
                      variant={
                        addressTypesDisplay === true ? "contained" : null
                      }
                      style={{minWidth: 100}}
                      // disabled={citiesDisplay}
                      onClick={() => handleAddressTypes()}
                    >
                      Address Types
                    </Button>
                    <Button
                      variant={
                        addressReasonsDisplay === true ? "contained" : null
                      }
                      style={{minWidth: 100}}
                      // disabled={citiesDisplay}
                      onClick={() => handleAddressReasons()}
                    >
                      Address Reasons
                    </Button>
                  </ButtonGroup>
              </Grid>
              <Grid item xs={12}><Divider/></Grid>
              {/* <Grid item xs={12} md={12}> */}
              {/* </Grid> */}
              <Grid item xs={12} md={12} style={{marginLeft:20, marginRight: 20}}>
                {statesDisplay ? <ManageStates /> : null}
                {lgasDisplay ? <ManageLGAs /> : null}
                {citiesDisplay ? <ManageCities /> : null}
                {addressTypesDisplay ? <ManageAddressTypes /> : null}
                {addressReasonsDisplay ? <ManageAddressReasons /> : null}
              </Grid>
            </Grid>
        <Grid>
        </Grid>
      </Grid>
  );
}