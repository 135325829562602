import React, { useEffect } from "react";
import { Grid, Typography } from "@material-ui/core/";
import { Divider, CircularProgress } from "@material-ui/core/";
import Tabs from "./tabs";
import Axios from "axios";
export default function Index() {
  const [Response, setResponse] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const fetchInboxMessages = async () => {
    const Token = await localStorage.getItem("Token");
    // console.log("Token",Token)
    const Id = localStorage.getItem("id");
    await Axios.get("http://3.22.17.212:9000/api/v1/messages/", {
      headers: {
        Authorization: Token,
      },
    }).then((response) => {
      //  console.log("messages",response);

      setResponse(response.data);
      setLoading(false);
    });
  };
  useEffect(() => {
    fetchInboxMessages();
  }, []);
  return (
    <div style={{ marginTop: 20 }}>
      {/* <Paper style={{ padding: 20, height: '100vh' }}> */}
      <Grid container justify="space-between" alignItems="center" spacing={4}>
        <Grid item xs={12}>
          <Typography variant="h4">Messages</Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Grid>
      {loading ? (
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          display="flex"
          style={{ minHeight: "0vh" }}
        >
          <CircularProgress />
        </Grid>
      ) : Response.length === 0 ? (
        <Grid container align="center" justify="center">
          <h1>No Messages</h1>
        </Grid>
      ) : (
        <Tabs data={Response} refresh={fetchInboxMessages} />
      )}
    </div>
  );
}
