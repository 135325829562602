import React, { Component } from "react";
import {
  TextField,
  Paper,
  Grid,
  Typography,
  Button,
  TableContainer,
} from "@material-ui/core/";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { CircularProgress } from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import MaterialTable from "material-table";
import Search from "@material-ui/icons/Search";
import { forwardRef } from "react";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import ViewColumn from "@material-ui/icons/ViewColumn";
import SearchBar from "material-ui-search-bar";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import WorkOutlineIcon from "@material-ui/icons/WorkOutline";
import PinDropIcon from "@material-ui/icons/PinDrop";
import AccountBalanceWalletOutlinedIcon from "@material-ui/icons/AccountBalanceWalletOutlined";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import NotInterestedOutlinedIcon from "@material-ui/icons/NotInterestedOutlined";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import Tooltip from "@material-ui/core/Tooltip";
import axios from "axios";

let states = [];
let Lga = [];
let categoriesdata = {};
let token = "";
let id = "";
let result = [];

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class index extends Component {
  state = {
    states: "",
    deleteDialogBox: false,
    loading: true,
    newLga: "",
    selectedstate: "",
    selectedLga: [],
    sloading: false,
    disabled: true,
    buttondiabled: true,
    deleteid: "",
    snackbar: "",
    snackbarresponse: "",
    categoriesdata: [],
  };
  async getLga() {
    await axios
      .get("http://3.22.17.212:9000/api/v1/resManager/address/states/", {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        states = res.data;
        res.data.map((index) => {
          categoriesdata[index.id] = index.stateName;
          this.setState({ categoriesdata: categoriesdata });
          // console.log("states", states);
        });
      });
    await axios
      .get("http://3.22.17.212:9000/api/v1/resManager/address/lgas/", {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        Lga = res.data;
        this.setState({ selectedLga: Lga });
        // console.log("lga", Lga);
      });
  }
  async componentDidMount() {
    this.setState({ loading: true });

    token = localStorage.getItem("Token");
    id = localStorage.getItem("id");
    await this.getLga();
    this.setState({ loading: false });
  }
  async filterStates(state) {
    this.setState({ selectedstate: state });

    if (state !== "none") {
      await axios
        .get(
          "http://3.22.17.212:9000/api/v1/resManager/address/lgas/?stateId=" +
          state,
          {
            headers: {
              Authorization: token,
            },
          }
        )
        .then((res) => {
          this.setState({ selectedLga: res.data });
          // console.log("lga", res.data);
          this.setState({ disabled: false });
        });
    } else {
      this.setState({ selectedLga: Lga });
      this.setState({ disabled: true });
    }
  }
  async deleteLga(id) {
    this.setState({ deleteDialogBox: false, selectedIndex: -1 });
    axios
      .delete(
        "http://3.22.17.212:9000/api/v1/resManager/address/lgas/" + id + "/",
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => {
        this.setState({
          selectedstate: "none",
          snackbar: true,
          snackbarresponse: response,
        });
      })
      .catch((error) => {
        if (error.response) {
          this.setState({ snackbar: true, snackbarresponse: error.response });
        }
      });
    this.getLga();
  }
  displayTable() {
    return (
      <>
        {/* <Grid container justify="space-between" align="center" spacing={4}>
          <Grid item>
            <Typography variant="h4">Local Government Areas(LGA)</Typography>
          </Grid>
        </Grid> */}

        <Grid
          container
          justify="flex-start"
          alignItems="center"
          style={{ marginTop: 20 }}
          spacing={2}
        >
          <Grid item xs={3}>
            <FormControl fullWidth variant="outlined" size="medium">
              <InputLabel id="states">State</InputLabel>
              <Select
                labelId="states"
                id="states"
                value={this.state.selectedstate}
                onChange={(event) => {
                  this.filterStates(event.target.value);
                }}
                label="states"
                fullWidth
              >
                <MenuItem selected value="none">
                  None
                </MenuItem>
                {states.map((state) => (
                  <MenuItem value={state.id}>{state.stateName}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {/* <Grid item xs={3}>
            <TextField
              label="Add LGA"
              disabled={this.state.disabled}
              variant="outlined"
              size="medium"
              fullWidth
              // helperText="Select state first"
              value={this.state.newLga}
              onChange={(event) => {
                this.setState({ newLga: event.target.value });
              }}
            />
          </Grid>
          <Grid item>
            <Fab
              size="small"
              disabled={this.state.newLga.length < 1}
              color="secondary"
              onClick={() => {
                this.addLga();
              }}
            >
              <AddIcon />
            </Fab>
          </Grid> */}
          <Grid>
            <Snackbar
              open={this.state.snackbar}
              autoHideDuration={6000}
              onClick={() => {
                this.setState({ snackbar: !this.state.snackbar });
              }}
            >
              {this.state.snackbarresponse.status === 201 ? (
                <Alert
                  onClose={() => {
                    this.setState({ snackbar: !this.state.asnackbar });
                  }}
                  severity="success"
                >
                  Lga added sucessfully
                </Alert>
              ) : this.state.snackbarresponse.status === 204 ? (
                <Alert
                  onClose={() => {
                    this.setState({ snackbar: !this.state.asnackbar });
                  }}
                  severity="success"
                >
                  Lga deleted sucessfully
                </Alert>
              ) : (
                    <Alert
                      onClose={() => {
                        this.setState({ snackbar: !this.state.snackbar });
                      }}
                      severity="error"
                    >
                      Something went wrong please try again
                    </Alert>
                  )}
            </Snackbar>
          </Grid>{" "}

          <Grid item xs={12}>
          <MaterialTable
            icons={tableIcons}
            title="Local Government Areas (LGAs)"
            columns={[
              {
                title: "State",
                field: "stateName_field",
                // lookup: this.state.categoriesdata,
              },
              { title: "Lga", field: "lgaName" },
            ]}
            data={this.state.selectedLga}
            options={{
              sorting: true,
              paging: false,
              search: true,
              grouping: true,
              actionsColumnIndex: -1,
              headerStyle: {
                backgroundColor: "#031a26",
                color: "#FFF",
                fontWeight: "bold",
              },
              // toolbar: false,
            }}
            editable={{
              // onRowAdd: (newData) =>
              //   new Promise((resolve, reject) => {
              //     setTimeout(() => {
              //       // setData([...data, newData]);
              //       // this.state.selectedLga.push(newData)
              //       this.setState({
              //         selectedLga: {
              //           ...this.state.selectedLga,
              //           newData,
              //         },
              //       });
              //       // this.setState({...this.state.selectedLga,newData})
              //       resolve();
              //     }, 1000);
              //   }),

              // onRowDelete: (oldData, newData) =>
              //   new Promise((resolve, reject) => {
              //     setTimeout(() => {
              //       // const dataDelete = [...data];

              //       this.deleteLga(oldData.id);
              //       // dataDelete.splice(index, 1);
              //       // setData([...dataDelete]);

              //       resolve();
              //     }, 1000);
              //   }),
            }}
          />
        </Grid>             

        </Grid>


        {/* <TableContainer
            component={Paper}
            style={{ marginTop: 20, marginLeft: 10, marginRight: 10 }}
            elevation={5}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow style={{ backgroundColor: "black" }}>
                  <TableCell align="left">State</TableCell>
                  <TableCell align="left">LGA</TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.selectedLga.map((row, index) => (
                  <TableRow key={row.id}>
                    <TableCell align="left">{row.state}</TableCell>
                    <TableCell align="left">{row.lgaName}</TableCell>
                    <TableCell align="right">
                      <Button
                        color="primary"
                        variant="outlined"
                        onClick={() =>
                          this.setState({
                            deleteDialogBox: true,
                            selectedIndex: index,
                            deleteid: row.id,
                          })
                        }
                      >
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer> */}

        {/* </Paper> */}


      </>
    );
  }
  async addLga() {
    let headers = {
      headers: {
        Authorization: token,
        "Content-Type": "multipart/form-data",
      },
    };
    let bodyFormData = new FormData();
    bodyFormData.append("state", this.state.selectedstate);
    bodyFormData.append("lgaName", this.state.newLga);

    await axios
      .post(
        "http://3.22.17.212:9000/api/v1/resManager/address/lgas/",
        bodyFormData,
        headers
      )
      .then((response) => {
        // console.log(response);

        this.setState({
          selectedstate: "none",
          snackbar: true,
          snackbarresponse: response,
        });
        this.setState({ disabled: true, newLga: "" });
        this.getLga();
      })
      .catch((error) => {
        if (error.response) {
          this.setState({ snackbar: true, snackbarresponse: error.response });
        }
      });
  }
  isloading() {
    return (
        <>
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justify="center"
                display="flex"
                style={{ minHeight: "0vh" }}
            >
                <CircularProgress />
            </Grid>
        </>
    );
}
  render() {
    const { classes } = this.props;

    return (
      <div style={{ marginTop: 20 }}>
        {/* <Paper style={{ padding: 20, height: '100vh' }}> */}
        {this.state.loading ? this.isloading() : this.displayTable()}
      </div>
    );
  }
}

export default index;
