import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  TextField,
  Grid,
  Typography,
  Button,
  FormControlLabel,
  Checkbox,
  FormControl,
  // Select,
  // InputLabel,
  // MenuItem,
  FormLabel,
  RadioGroup,
  Radio,
  CircularProgress,
} from "@material-ui/core/";
import { Box, CardActions, CardContent, Card } from "@material-ui/core/";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from "@material-ui/core/DialogTitle";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ViewPagesComponent from "../ViewPagesComponent";
import ViewEmployerPageComponents from "../ViewEmployerPageComponents";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import MaterialTable, { MTableToolbar } from "material-table";
import Search from "@material-ui/icons/Search";
import { forwardRef } from "react";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import ViewColumn from "@material-ui/icons/ViewColumn";
let token = "";
let id = "";
const api = "http://3.22.17.212:9000";

const styles = (theme) => ({});
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};
class index extends Component {
  state = {
    generateNewEmployementCodeDialog: false,
    actions: "",
    assignedToMeCheck: false,
    pendingApprovalRequestCheck: false,
    assignDialog: false,
    viewDialog: false,
    viewLogic: true,
    adminByRadio: "searchByEmail",
    adminIndex: "",
    isEmployerCondition: "",
    allCodes: [],
    assignedToMe: [],
    pendingApprovalRequests: [],
    bothRequests: [],
    approvalRowId: "",
    userID: "",
    viewId: "",
    assignAdminId: "",
    approvalCode: "",
    currentid: "",
    enteredUsername: "",
    selectedRequest: [],
    isLoading: true,
    adminList: [],
  };

  constructor(props) {
    super(props);
    this.allCodesTable = this.allCodesTable.bind(this);
  }

  isloading() {
    return (
      <>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          display="flex"
          style={{ minHeight: "0vh" }}
        >
          <CircularProgress />
        </Grid>
      </>
    );
  }

  async fetchAdminList() {
    let response = await fetch(api + "/api/v1/accounts/admins", {
      headers: {
        Authorization: token,
      },
    });
    response = await response.json();
    response = response.reverse();
    // console.log('adminListSuccess:', response)
    this.setState({ adminList: response });
  }

  async fetchAllCodes() {
    let response = await fetch(api + "/api/v1/codes/approval/codes", {
      headers: {
        Authorization: token,
      },
    });
    response = await response.json();
    response = response.reverse();
    // console.log('viewCodesSuccess:', response)
    this.setState({ allCodes: response });
    this.setState({ selectedRequest: response });
  }

  async fetchAssignedToMe() {
    let response = await fetch(api + "/api/v1/codes/approval/codes?mine=true", {
      headers: {
        Authorization: token,
      },
    });
    response = await response.json();
    response = response.reverse();
    // console.log('assignedToMeSuccess:', response)
    this.setState({ assignedToMe: response });
  }

  async fetchPendingApprovalRequests() {
    let response = await fetch(
      api + "/api/v1/codes/approval/codes?pending=true",
      {
        headers: {
          Authorization: token,
        },
      }
    );
    response = await response.json();
    response = response.reverse();
    // console.log('PendingApprovalSuccess:', response)
    this.setState({ pendingApprovalRequests: response });
  }

  async fetchBothRequests() {
    let response = await fetch(
      api + "/api/v1/codes/approval/codes?mine=true&pending=true",
      {
        headers: {
          Authorization: token,
        },
      }
    );
    response = await response.json();
    response = response.reverse();
    // console.log('BothCodesSuccess:', response)
    this.setState({ bothRequests: response });
  }

  async componentDidMount() {
    token = localStorage.getItem("Token");
    id = localStorage.getItem("id");

    await this.fetchAllCodes();
    await this.fetchAssignedToMe();
    await this.fetchBothRequests();
    await this.fetchPendingApprovalRequests();
    await this.fetchAdminList();

    this.setState({ isLoading: false });
  }

  render() {
    // const { classes } = this.props;

    return this.state.isLoading ? this.isloading() : this.displayTable();
  }

  displayTable() {
    return (
      <div style={{ marginTop: 20, marginRight: 20 }}>
        {/* <Paper style={{ padding: 20, height: '100vh' }}> */}
        <Grid container justify="space-between" alignItems="center" spacing={4}>
          <Grid item xs={8}>
            <Typography variant="h4">Enrollment Request</Typography>
          </Grid>

          {/* <Grid item xs={4}>
                        <Button color='secondary' variant='contained'
                            onClick={() => this.setState({ viewDialog: true })}
                            fullWidth>  Create New code </Button>
                    </Grid> */}

          <Grid container direction="row" spacing={2}>
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.assignedToMeCheck}
                    onChange={(event) => {
                      this.setState({
                        assignedToMeCheck: !this.state.assignedToMeCheck,
                      });
                      // console.log('check1:', this.state.assignedToMeCheck)
                    }}
                    name="checkedB"
                    color="primary"
                  />
                }
                label="Assigned to me"
              />
            </Grid>

            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.pendingApprovalRequestCheck}
                    onChange={(event) => {
                      this.setState({
                        pendingApprovalRequestCheck: !this.state
                          .pendingApprovalRequestCheck,
                      });
                      // console.log('check2:', this.state.pendingApprovalRequestCheck)
                    }}
                    name="checkedB"
                    color="primary"
                  />
                }
                label="All approval requests"
              />
            </Grid>
            {/* <div>{this.allTablesLogic()}</div> */}
          </Grid>
        </Grid>

        <Grid container justify="center" alignItems="center" spacing={2}>
          <Box p={2}>
            {this.state.assignDialog
              ? this.assignAdminDialog(id)
              : this.tableDisplayLogic()}
          </Box>
        </Grid>
        {this.viewDialogBox()}
      </div>
    );
  }

  // allTablesLogic() {

  //     if (this.state.assignedToMeCheck && this.state.pendingApprovalRequestCheck) {
  //         this.setState({ selectedRequest: this.state.bothRequests })
  //     }
  //     else if (this.state.pendingApprovalRequestCheck) {
  //         this.setState({ selectedRequest: this.state.pendingApprovalRequestCheck })
  //     }
  //     else if (this.state.assignedToMeCheck) {
  //         this.setState({ selectedRequest: this.state.assignedToMe })
  //     }
  //     else {
  //         this.setState({ selectedRequest: this.state.allCodes })
  //     }
  // }

  // actionsLogic() {
  //     if (this.state.selectedRequest[index].showAssignTo_field = true) {
  //         return (
  //             <div>
  //                 <MenuItem value={"assignAdmin"}>Assign Admin</MenuItem>
  //                 <MenuItem value={"view"}>View and approve</MenuItem>
  //             </div>
  //         );
  //     }

  //     else (this.state.selectedRequest[index].showAssignTo_field = false)
  //     {
  //         return (
  //             <div>
  //                 <MenuItem value={"reassignAdmin"}>Reassign Admin</MenuItem>
  //                 <MenuItem value={"view"}>View and approve</MenuItem>
  //             </div>
  //         );
  //     }
  // }

  allCodesTable() {
    // console.log("allCodes", this.state.allCodes)
    return (
      <MaterialTable
        icons={tableIcons}
        title=""
        // components={{
        //   Toolbar: (props) => (
        //     <div
        //       style={{
        //         backgroundColor: "#031a26",
        //         color: "#fff",
        //         textAlign: "center",
        //       }}
        //     >
        //       <MTableToolbar {...props} />
        //     </div>
        //   ),
        // }}
        columns={[
          {
            title: "Date",
            render: (row) => new Date(row.createdOn).toString(),
          },
          {
            title: "Last Updated",
            render: (row) => new Date(row.statusChangeDate).toString(),
          },
          {
            field: "codeString",
            title: "Code",
            // render: (rowData) => new Date(rowData.createdOn).toDateString(),
          },

          { title: "Assigned To", field: "assigned_to_name_field.name" },
          {
            title: "Is Employer",
            field: "assigned_to_name_field.name",
            render: (row) => <p>{row.is_employer_field ? "Yes" : "No"} </p>,
          },
          { title: "Code Status", field: "codeStatus" },

          {
            title: "User",
            field: "user_field.name",
            // render: (rowData) =>
            //   new Date(rowData.statusChangeDate).toDateString(),
          },
          {
            title: "Actions",
            render: (row) =>
              row.user_field.name === "User deleted" ? (
                "NA"
              ) : row.codeStatus === "Closed" ? (
                "Closed"
              ) : row.showAssignTo_field ? (
                <Button
                  variant="outlined"
                  color="secondary"
                  size="small"
                  onClick={() => {
                    this.setState({ currentid: row.id }, () => {
                      // console.log("currentId:", this.state.currentid);
                    });
                    this.assignadminTableButton(row.id);
                  }}
                >
                  Assign Admin
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  color="secondary"
                  size="small"
                  onClick={() => {
                    this.setState({ assignDialog: true });
                    this.setState({ adminIndex: row.id });
                  }}
                >
                  Reassign Admin
                </Button>
              ),
          },
          {
            title: "View",
            render: (row) =>
              row.user_field.name === "User deleted" ? (
                "NA"
              ) : row.codeStatus === "Closed" ? (
                "Closed"
              ) : (
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  onClick={() => {
                    this.setState(
                      {
                        isEmployerCondition: row.is_employer_field,
                        userID: row.user,
                        approvalCode: row.codeString,
                        viewId: row.id,
                      }
                      // () =>
                      //   console.log(
                      //     "userId:",
                      //     this.state.userID,
                      //     "isEmployer",
                      //     this.state.isEmployerCondition
                      //   )
                    );
                    this.viewAndApprove(row.id, row.codeString);
                  }}
                  // disabled={this.state.viewLogic}
                >
                  View&nbsp;&amp;&nbsp;Approve
                </Button>
              ),
          },
        ]}
        data={this.state.allCodes}
        // actions={[{}]}
        options={{
          sorting: true,
          paging: false,
          search: true,
          grouping: true,
          actionsColumnIndex: -1,
          headerStyle: {
            backgroundColor: "#031a26",
            color: "#FFF",
            fontWeight: "bold",
          },
          // toolbar: false,
        }}
      />
    );
  }

  assignedToMeTable() {
    return (
      <MaterialTable
        icons={tableIcons}
        title=""
        // components={{
        //   Toolbar: (props) => (
        //     <div
        //       style={{
        //         backgroundColor: "#031a26",
        //         color: "#fff",
        //         textAlign: "center",
        //       }}
        //     >
        //       <MTableToolbar {...props} />
        //     </div>
        //   ),
        // }}
        columns={[
          {
            title: "Date",
            render: (row) => new Date(row.createdOn).toString(),
          },
          {
            title: "Last Updated",
            render: (row) => new Date(row.statusChangeDate).toString(),
          },
          {
            field: "codeString",
            title: "Code",
            // render: (rowData) => new Date(rowData.createdOn).toDateString(),
          },

          { title: "Assigned To", field: "assigned_to_name_field.name" },
          {
            title: "Is Employer",
            field: "assigned_to_name_field.name",
            render: (row) => <p>{row.is_employer_field ? "Yes" : "No"} </p>,
          },
          { title: "Code Status", field: "codeStatus" },

          {
            title: "User",
            field: "user_field.name",
            // render: (rowData) =>
            //   new Date(rowData.statusChangeDate).toDateString(),
          },
          {
            title: "Actions",
            render: (row) =>
              row.user_field.name === "User deleted" ? (
                "NA"
              ) : row.codeStatus === "Closed" ? (
                "Closed"
              ) : row.showAssignTo_field ? (
                <Button
                  variant="outlined"
                  color="secondary"
                  size="small"
                  onClick={() => {
                    this.setState({ currentid: row.id }, () => {
                      // console.log("currentId:", this.state.currentid);
                    });
                    this.assignadminTableButton(row.id);
                  }}
                >
                  Assign Admin
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  color="secondary"
                  size="small"
                  onClick={() => {
                    this.setState({ assignDialog: true });
                    this.setState({ adminIndex: row.id });
                  }}
                >
                  Reassign Admin
                </Button>
              ),
          },
          {
            title: "View",
            render: (row) =>
              row.user_field.name === "User deleted" ? (
                "NA"
              ) : row.codeStatus === "Closed" ? (
                "Closed"
              ) : (
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  onClick={() => {
                    this.setState(
                      {
                        isEmployerCondition: row.is_employer_field,
                        userID: row.user,
                        approvalCode: row.codeString,
                        viewId: row.id,
                      }
                      // () =>
                      //   console.log(
                      //     "userId:",
                      //     this.state.userID,
                      //     "isEmployer",
                      //     this.state.isEmployerCondition
                      //   )
                    );
                    this.viewAndApprove(row.id, row.codeString);
                  }}
                  // disabled={this.state.viewLogic}
                >
                  View&nbsp;&amp;&nbsp;Approve
                </Button>
              ),
          },
        ]}
        data={this.state.assignedToMe}
        // actions={[{}]}
        options={{
          sorting: true,
          paging: false,
          search: true,
          grouping: true,
          actionsColumnIndex: -1,
          headerStyle: {
            backgroundColor: "#031a26",
            color: "#FFF",
            fontWeight: "bold",
          },
          // toolbar: false,
        }}
      />
    );
  }

  pendingApprovalTable() {
    return (
      <MaterialTable
        icons={tableIcons}
        title=""
        // components={{
        //   Toolbar: (props) => (
        //     <div
        //       style={{
        //         backgroundColor: "#031a26",
        //         color: "#fff",
        //         textAlign: "center",
        //       }}
        //     >
        //       <MTableToolbar {...props} />
        //     </div>
        //   ),
        // }}
        columns={[
          {
            title: "Date",
            render: (row) => new Date(row.createdOn).toString(),
          },
          {
            title: "Last Updated",
            render: (row) => new Date(row.statusChangeDate).toString(),
          },
          {
            field: "codeString",
            title: "Code",
            // render: (rowData) => new Date(rowData.createdOn).toDateString(),
          },

          { title: "Assigned To", field: "assigned_to_name_field.name" },
          {
            title: "Is Employer",
            field: "assigned_to_name_field.name",
            render: (row) => <p>{row.is_employer_field ? "Yes" : "No"} </p>,
          },
          { title: "Code Status", field: "codeStatus" },

          {
            title: "User",
            field: "user_field.name",
            // render: (rowData) =>
            //   new Date(rowData.statusChangeDate).toDateString(),
          },
          {
            title: "Actions",
            render: (row) =>
              row.user_field.name === "User deleted" ? (
                "NA"
              ) : row.codeStatus === "Closed" ? (
                "Closed"
              ) : row.showAssignTo_field ? (
                <Button
                  variant="outlined"
                  color="secondary"
                  size="small"
                  onClick={() => {
                    this.setState({ currentid: row.id }, () => {
                      // console.log("currentId:", this.state.currentid);
                    });
                    this.assignadminTableButton(row.id);
                  }}
                >
                  Assign Admin
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  color="secondary"
                  size="small"
                  onClick={() => {
                    this.setState({ assignDialog: true });
                    this.setState({ adminIndex: row.id });
                  }}
                >
                  Reassign Admin
                </Button>
              ),
          },
          {
            title: "View",
            render: (row) =>
              row.user_field.name === "User deleted" ? (
                "NA"
              ) : row.codeStatus === "Closed" ? (
                "Closed"
              ) : (
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  onClick={() => {
                    this.setState(
                      {
                        isEmployerCondition: row.is_employer_field,
                        userID: row.user,
                        approvalCode: row.codeString,
                        viewId: row.id,
                      }
                      // () =>
                      //   console.log(
                      //     "userId:",
                      //     this.state.userID,
                      //     "isEmployer",
                      //     this.state.isEmployerCondition
                      //   )
                    );
                    this.viewAndApprove(row.id, row.codeString);
                  }}
                  // disabled={this.state.viewLogic}
                >
                  View&nbsp;&amp;&nbsp;Approve
                </Button>
              ),
          },
        ]}
        data={this.state.pendingApprovalRequests}
        // actions={[{}]}
        options={{
          sorting: true,
          paging: false,
          search: true,
          grouping: true,
          actionsColumnIndex: -1,
          headerStyle: {
            backgroundColor: "#031a26",
            color: "#FFF",
            fontWeight: "bold",
          },
          // toolbar: false,
        }}
      />
    );
  }
  assignadminTableButton(id) {
    this.setState({ assignDialog: true, currentid: id });
  }

  viewAndApprove(id, code) {
    // console.log('viewID:', id)
    // console.log('viewCode:', code)
    this.setState({ viewDialog: true });
  }

  bothRequestsTable() {
    return (
      <MaterialTable
        icons={tableIcons}
        title=""
        // components={{
        //   Toolbar: (props) => (
        //     <div
        //       style={{
        //         backgroundColor: "#031a26",
        //         color: "#fff",
        //         textAlign: "center",
        //       }}
        //     >
        //       <MTableToolbar {...props} />
        //     </div>
        //   ),
        // }}
        columns={[
          {
            title: "Date",
            render: (row) => new Date(row.createdOn).toString(),
          },
          {
            title: "Last Updated",
            render: (row) => new Date(row.statusChangeDate).toString(),
          },
          {
            field: "codeString",
            title: "Code",
            // render: (rowData) => new Date(rowData.createdOn).toDateString(),
          },

          { title: "Assigned To", field: "assigned_to_name_field.name" },
          {
            title: "Is Employer",
            field: "assigned_to_name_field.name",
            render: (row) => <p>{row.is_employer_field ? "Yes" : "No"} </p>,
          },
          { title: "Code Status", field: "codeStatus" },

          {
            title: "User",
            field: "user_field.name",
            // render: (rowData) =>
            //   new Date(rowData.statusChangeDate).toDateString(),
          },
          {
            title: "Actions",
            render: (row) =>
              row.user_field.name === "User deleted" ? (
                "NA"
              ) : row.codeStatus === "Closed" ? (
                "Closed"
              ) : row.showAssignTo_field ? (
                <Button
                  variant="outlined"
                  color="secondary"
                  size="small"
                  onClick={() => {
                    this.setState({ currentid: row.id }, () => {
                      // console.log("currentId:", this.state.currentid);
                    });
                    this.assignadminTableButton(row.id);
                  }}
                >
                  Assign Admin
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  color="secondary"
                  size="small"
                  onClick={() => {
                    this.setState({ assignDialog: true });
                    this.setState({ adminIndex: row.id });
                  }}
                >
                  Reassign Admin
                </Button>
              ),
          },
          {
            title: "View",
            render: (row) =>
              row.user_field.name === "User deleted" ? (
                "NA"
              ) : row.codeStatus === "Closed" ? (
                "Closed"
              ) : (
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  onClick={() => {
                    this.setState(
                      {
                        isEmployerCondition: row.is_employer_field,
                        userID: row.user,
                        approvalCode: row.codeString,
                        viewId: row.id,
                      }
                      // () =>
                      //   console.log(
                      //     "userId:",
                      //     this.state.userID,
                      //     "isEmployer",
                      //     this.state.isEmployerCondition
                      //   )
                    );
                    this.viewAndApprove(row.id, row.codeString);
                  }}
                  // disabled={this.state.viewLogic}
                >
                  View&nbsp;&amp;&nbsp;Approve
                </Button>
              ),
          },
        ]}
        data={this.state.bothRequests}
        // actions={[{}]}
        options={{
          sorting: true,
          paging: false,
          search: true,
          grouping: true,
          actionsColumnIndex: -1,
          headerStyle: {
            backgroundColor: "#031a26",
            color: "#FFF",
            fontWeight: "bold",
          },
          // toolbar: false,
        }}
      />
    );
  }
  tableDisplayLogic() {
    return this.state.assignedToMeCheck
      ? this.state.assignedToMeCheck && this.state.pendingApprovalRequestCheck
        ? this.bothRequestsTable()
        : this.assignedToMeTable()
      : this.state.assignedToMeCheck || this.state.pendingApprovalRequestCheck
      ? this.allCodesTable()
      : this.pendingApprovalTable();
  }

  assignAdminDialog(id) {
    const options = this.state.adminList.map((option) => {
      const firstLetter = option.username[0].toUpperCase();
      return {
        firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
        ...option,
      };
    });

    return (
      <Card
      // fullWidth={"md"}
      // maxWidth={"md"}
      // open={this.state.assignDialog}
      // onClose={() => this.setState({ assignDialog: false })}
      // aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title" align="center">
          Assign&nbsp;Admin
        </DialogTitle>
        <CardContent>
          {/* <DialogContentText align='center'>
                        Search Admin by username:
              </DialogContentText> */}

          <Grid
            container
            justify="flex-start"
            direction="row"
            alignItems="center"
            spacing={3}
          >
            <Grid item xs={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Search admin by:</FormLabel>
                <RadioGroup
                  name="searchCategory"
                  value={this.state.adminByRadio}
                  onChange={(event) => {
                    this.setState({ adminByRadio: event.target.value });
                    // console.log('Radio:', this.state.adminByRadio);
                  }}
                >
                  <Grid container direction="row" style={{ marginTop: 10 }}>
                    <FormControlLabel
                      value="searchByEmail"
                      control={<Radio />}
                      label="Username"
                    />
                    <FormControlLabel
                      value="searchByUsername"
                      control={<Radio />}
                      label="Email"
                    />
                  </Grid>
                </RadioGroup>
              </FormControl>
            </Grid>

            {this.state.adminByRadio !== "searchByUsername" ? (
              <Grid item xs={12}>
                {/* <Autocomplete
                                    id="grouped-demo"
                                    options={options.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                                    groupBy={(option) => option.firstLetter}
                                    getOptionLabel={(option) => option.username}
                                    // onChange={this.setState({})}
                                    fullWidth
                                    renderInput={(params) => <TextField {...params} label="Username" variant="outlined" />}
                                /> */}
                <Autocomplete
                  options={options.sort(
                    (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                  )}
                  getOptionLabel={(option) => option.username}
                  groupBy={(option) => option.firstLetter}
                  id="adminUsername"
                  Username
                  fullWidth
                  value={this.state.selectedstate}
                  onChange={(event, value) => {
                    this.setState({ selectedstate: value });
                    this.setState({ assignAdminId: value["id"] });
                    // console.log("selectedstate", value);
                    // console.log("assignAdminID", this.state.assignAdminId);
                  }}
                  inputValue={this.state.enteredUsername}
                  onInputChange={(event, newInputValue) => {
                    this.setState({ enteredUsername: newInputValue });
                    // console.log(newInputValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Username"
                      margin="normal"
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
            ) : (
              <Grid item xs={12}>
                {/* <Autocomplete
                                        id="grouped-demo"
                                        options={options.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                                        groupBy={(option) => option.firstLetter}
                                        getOptionLabel={(option) => option.email}
                                        fullWidth
                                        renderInput={(params) => <TextField {...params} label="Email" variant="outlined" />}
                                    /> */}
                <Autocomplete
                  options={options.sort(
                    (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                  )}
                  getOptionLabel={(option) => option.email}
                  groupBy={(option) => option.firstLetter}
                  id="adminEmail"
                  Username
                  fullWidth
                  value={this.state.selectedstate}
                  onChange={(event, value) => {
                    this.setState({ selectedstate: value });
                    this.setState({ assignAdminId: value["id"] });
                    // console.log("selectedstate", value);
                    // console.log("assignAdminID", this.state.assignAdminId);
                  }}
                  inputValue={this.state.enteredUsername}
                  onInputChange={(event, newInputValue) => {
                    this.setState({ enteredUsername: newInputValue });
                    // console.log(newInputValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Email"
                      margin="normal"
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
            )}
          </Grid>
        </CardContent>

        <CardActions style={{ padding: 15 }}>
          <Button
            disabled={this.state.buttondisabled}
            color="primary"
            variant="contained"
            onClick={() => this.assignAdmin(id)}
          >
            Assign
          </Button>
          <Button
            color="secondary"
            variant="contained"
            onClick={() =>
              this.setState({
                assignDialog: false,
                selectedIndex: -1,
              })
            }
          >
            Cancel
          </Button>
        </CardActions>
      </Card>
    );
  }

  viewDialogBox() {
    return (
      <Dialog
        fullScreen
        fullWidth={"md"}
        maxWidth={"md"}
        open={this.state.viewDialog}
        onClose={() => this.setState({ viewDialog: false })}
        aria-labelledby="form-dialog-title"
      >
        <AppBar>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => this.setState({ viewDialog: false })}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6">Enrollment Request</Typography>
          </Toolbar>
        </AppBar>
        <DialogTitle id="form-dialog-title" align="center">
          View and Approve
        </DialogTitle>
        <DialogContent>
          {this.state.isEmployerCondition ? (
            <ViewEmployerPageComponents
              user={this.state.userID}
              approval={this.state.approvalCode}
              viewId={this.state.viewId}
              //   fetchapproval={this.fetchPendingApprovalRequests}
            />
          ) : (
            <ViewPagesComponent
              user={this.state.userID}
              approval={this.state.approvalCode}
              viewId={this.state.viewId}
              //   fetchapproval={this.fetchPendingApprovalRequests}
            />
          )}
        </DialogContent>

        <DialogActions style={{ padding: 15 }}></DialogActions>
      </Dialog>
    );
  }

  async assignAdmin() {
    let bodyData = {
      assigned_to: this.state.assignAdminId,
    };

    // console.log('Body data:', bodyData)
    // console.log('Id', this.state.approvalRowId)

    try {
      let response = await fetch(
        api + "/api/v1/codes/approval/" + this.state.currentid + "/assignto",
        {
          method: "PUT",
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(bodyData),
        }
      );
      response = await response.json();
      // console.log('assignAdminSuccess:', response);

      await this.fetchAllCodes();
      await this.fetchAssignedToMe();
      await this.fetchBothRequests();
      await this.fetchPendingApprovalRequests();
      this.setState({ selectedstate: "", assignDialog: false });
    } catch (error) {
      // console.log("[!ON_REGISTER] " + error);
    }
  }
}

export default withStyles(styles)(index);
