import React, { Component } from 'react'
import {
  Grid,
  Typography,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  CircularProgress,
} from "@material-ui/core/";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import axios from "axios";

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';

let token = "";
let id = "";

class index extends Component {
  constructor(props) {
    super(props)

    this.state = {
      result: [],
      loading: true,
    }
  }

  isloading() {
    return (
      <>
        <Grid
          container
          justify='center'
        >
          <Grid item><CircularProgress /></Grid>
        </Grid>
      </>
    );
  }
  async componentDidMount() {
    this.setState({ loading: true });

    token = localStorage.getItem("Token");
    id = localStorage.getItem("id");
    await axios
      .get(
        "http://3.22.17.212:9000/api/v1/employees/" + id + "/jobs?current=true",
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((res) => {
        // result = res.data;
        this.setState({ result: res.data })
      });
    this.setState({ loading: false });
  }
  render() {
    return (
      <div>
        {/* {this.workDescription()} */}
        {/* ONLY KEEP THE ABOVE EXPANSION PANEL AND USE MAP FUNC TO LOOP */}

        <Grid>
          {this.state.loading ? (
            this.isloading()
            // <CircularProgress />

          ) : this.state.result.length === 0 ? <Grid container
            direction="row"
            justify="center"
            alignItems="center">
            <Typography>Add your job details </Typography>
          </Grid> : (
                this.state.result.map((company, index) => (

                  <Grid style={{ marginTop: 15 }} key={index}>

                    <List >
                      <ListItem alignItems="flex-start">
                        <ListItemAvatar>
                          <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
                        </ListItemAvatar>
                        <ListItemText
                          style={{textTransform: "capitalize", fontWeight: 'bold'}}
                          primary={company.company_name_field}
                          secondary={
                            <React.Fragment>
                              <Grid container
                                direction="column"
                                justify="flex-start"
                                alignItems="flex-start">
                                <Typography
                                  component="span"
                                  variant="body2"
                                  // className={classes.inline}
                                  style={{display: 'inline', textTransform: "capitalize"}}
                                  color="textPrimary"
                                >
                                  {company.jobTitle}
                              </Typography>
                                <Typography
                                  component="span"
                                  variant="body2"
                                  // className={classes.inline}
                                  style={{display: 'inline', textTransform: "capitalize", color: "#6d6966"}}
                                  color="textPrimary"
                                >
                                  {company.jobDescription}
                                </Typography>
                              </Grid>

                              {/* {company.jobDescription} */}
                            </React.Fragment>
                          }
                        />
                      </ListItem>
                      <Divider variant="inset" component="li" />
                    </List>

                    {/* <ExpansionPanel >
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <Typography
                      variant="subtitle1"
                      display="block"
                      gutterBottom
                    >
                      {company.company_name_field}
                    </Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Grid container direction="column">
                      <Typography variant="body1" display="block" gutterBottom>
                        {company.jobTitle}
                      </Typography>
                      <Typography variant="body2" gutterBottom>
                        {company.jobDescription}
                      </Typography>
                    </Grid>
                  </ExpansionPanelDetails>
                </ExpansionPanel> */}
                  </Grid>
                ))
              )}
        </Grid>
      </div>
    );
  }


}

export default index;
