import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  TextField,
  Typography,
  Button,
  Box,
  Grid,
  Card,
  CardActions,
  Divider,
} from "@material-ui/core/";
import Avatar from "@material-ui/core/Avatar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import axios from "axios";
import MaterialTable from "material-table";
import Search from "@material-ui/icons/Search";
import { forwardRef } from "react";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import ViewColumn from "@material-ui/icons/ViewColumn";
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};
const styles = (theme) => ({
  paper: {
    // marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
});

let token = "";
let id = "";
export class index extends Component {
  state = {
    onSubmitConfirmation: false,

    emailError: false,
    error: false,
    passwordnumber: false,
    passwordlength: false,
    passwordCapitalchar: false,
    firstName: "",
    middeName: "",
    surName: "",
    email: "",
    phone: "",
    username: "",
    password: "",
    confirmpassword: "",
    confirmvalidate: false,
    middeNameerror: false,
    firstNameerror: false,
    surNameerror: false,
    userNameerror: false,
    mailerror: false,
    adddialog: false,
    users: [],
  };
  validatepassword = () => {
    const { password } = this.state;
    password.length < 6
      ? this.setState({ error: true, passwordlength: true })
      : this.setState({ passwordlength: false });
    !/\d/.test(password)
      ? this.setState({ error: true, passwordnumber: true })
      : this.setState({ passwordnumber: false });
    !/[!@#$%^&*]/.test(password)
      ? this.setState({ error: true, passwordCapitalchar: true })
      : this.setState({ passwordCapitalchar: false });
    if (
      !password.length < 6 &&
      !!/\d/.test(password) &&
      !!/[!@#$%^&*]/.test(password)
    ) {
      this.setState({ error: false });
    }
  };
  confirmpasswordvalidate = () => {
    const { confirmpassword } = this.state;
    if (this.state.password === confirmpassword) {
      this.setState({ confirmvalidate: false });
    } else {
      this.setState({ confirmvalidate: true });
    }
  };
  async getusers() {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", token);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch("http://3.22.17.212:9000/getAdminList", requestOptions)
      .then((response) => response.json())
      .then((result) => this.setState({ users: result }))
      .catch((error) => console.log("error", error));
  }
  async componentDidMount() {
    token = localStorage.getItem("Token");
    id = localStorage.getItem("id");
    await this.getusers();
  }
  async submit() {
    this.setState({ adddialog: !this.state.adddialog });
    let headers = {
      headers: {
        Authorization: token,
        "Content-Type": "multipart/form-data",
      },
    };
    let bodyFormData = new FormData();

    bodyFormData.append("firstname", this.state.firstName);
    bodyFormData.append("middlename", this.state.middeName);
    bodyFormData.append("surname", this.state.surName);
    bodyFormData.append("username", this.state.username);
    bodyFormData.append("email", this.state.email);
    bodyFormData.append("password", this.state.password);
    bodyFormData.append("phone", this.state.phone);

    // console.log(bodyFormData);
    await axios
      .post(
        "http://3.22.17.212:9000/api/v1/accounts/auth/admin/register",
        bodyFormData,
        headers
      )
      .then((response) => {
        // console.log(response);
      });
  }
  render() {
    const { classes } = this.props;

    return (
      <>
        <Box p={2}>
          <Grid
            container
            justify="space-between"
            alignItems="center"
            spacing={2}
          >
            <Grid item>
              <Typography variant="h4">Admin List</Typography>
            </Grid>
            <Grid item xs={2}>
              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  this.setState({ adddialog: !this.state.adddialog })
                }
              >
                Add Admin
              </Button>
            </Grid>
            <Grid item xs={12}>
              {" "}
              <Divider />
            </Grid>
          </Grid>
        </Box>
        {!this.state.adddialog ? (
          <Grid item xs={12}>
            <MaterialTable
              icons={tableIcons}
              title=""
              columns={[
                {
                  title: "First Name",
                  render: (rowData) => (
                    <p>
                      {rowData.firstname + "  "}
                      {rowData.surname}
                    </p>
                  ),
                },

                {
                  title: "Date joined ",
                  field: "date_joined",
                  render: (rowData) =>
                    new Date(rowData.date_joined).toDateString(),
                },
                { title: "Phone", field: "phone" },
              ]}
              data={this.state.users.results}
              options={{
                sorting: true,
                paging: false,
                search: true,
                grouping: true,
                actionsColumnIndex: -1,
                headerStyle: {
                  backgroundColor: "#031a26",
                  color: "#FFF",
                  fontWeight: "bold",
                },
                // toolbar: false,
              }}
            />
          </Grid>
        ) : (
          <Grid container justify="center" spacing={2}>
            <Grid item xs={12}>
              <Grid container justify="flex-end">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    this.setState({ adddialog: !this.state.adddialog });
                  }}
                >
                  Back
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <Container component="main" maxWidth="xs">
                  <CssBaseline />
                  <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                      <SupervisorAccountIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                      Admin Registration
                    </Typography>

                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="firstName"
                      label="First name"
                      name="text"
                      autoComplete="text"
                      autoFocus
                      error={this.state.firstNameerror}
                      size="small"
                      className={classes.textField}
                      onChange={(e) => {
                        e.target.value.length < 1
                          ? this.setState({ firstNameerror: true })
                          : this.setState({ firstNameerror: false });

                        this.setState({ firstName: e.target.value });
                      }}
                      helperText="Please enter your firstname"
                    />

                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="middleName"
                      label="Middle name"
                      name="text"
                      autoComplete="text"
                      autoFocus
                      error={this.state.middeNameerror}
                      size="small"
                      helperText="Please enter your middlename"
                      onChange={(e) => {
                        e.target.value.length < 1
                          ? this.setState({ middleNameerror: true })
                          : this.setState({ middleNameerror: false });
                        this.setState({ middeName: e.target.value });
                      }}
                    />

                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="surname"
                      label="Surname"
                      name="text"
                      autoComplete="text"
                      autoFocus
                      size="small"
                      error={this.state.surNameerror}
                      helperText="Please enter your surname"
                      onChange={(e) => {
                        e.target.value.length < 1
                          ? this.setState({ surNameerror: true })
                          : this.setState({ surNameerror: false });
                        this.setState({
                          surName: e.target.value,
                        });
                      }}
                    />
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="username"
                      label="username"
                      name="text"
                      autoComplete="text"
                      autoFocus
                      error={this.state.userNameerror}
                      size="small"
                      helperText="Please enter your username"
                      onChange={(e) => {
                        e.target.value.length < 1
                          ? this.setState({ userNameerror: true })
                          : this.setState({ userNameerror: false });
                        this.setState({
                          username: e.target.value,
                        });
                      }}
                    />

                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="email"
                      label="Email Address"
                      name="email"
                      type="email"
                      autoComplete="email"
                      autoFocus
                      size="small"
                      error={this.state.mailerror}
                      helperText="Please enter your emailid"
                      onChange={(e) => {
                        e.target.value.length < 1
                          ? this.setState({ emailerror: true })
                          : this.setState({ emailerror: false });
                        this.setState({ email: e.target.value });
                      }}
                    />
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="email"
                      label="Phone"
                      name="Phone"
                      type="phone"
                      autoComplete="email"
                      autoFocus
                      size="small"
                      // error={this.state.mailerror}
                      helperText="Please enter your Phone no"
                      onChange={(e) => {
                        e.target.value.length < 1
                          ? this.setState({ phoneerror: true })
                          : this.setState({ phoneerror: false });
                        this.setState({ phone: e.target.value });
                      }}
                    />

                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      error={this.state.error}
                      fullWidth
                      name="password"
                      label="Password"
                      type="password"
                      id="password"
                      autoComplete="current-password"
                      size="small"
                      helperText={
                        this.state.passwordlength
                          ? "Password must be at least 6 characters long"
                          : this.state.passwordCapitalchar
                          ? "Password must contain special character: !@#$%^&*"
                          : this.state.passwordnumber
                          ? "Password must contain a number"
                          : null
                      }
                      onChange={(event) =>
                        this.setState(
                          { password: event.target.value },
                          this.validatepassword
                        )
                      }
                    />

                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      error={this.state.confirmvalidate}
                      name="confirmPassword"
                      label="Confirm Password"
                      type="password"
                      id="coonFirmpassword"
                      autoComplete="confirm-password"
                      size="small"
                      helperText={
                        this.state.confirmvalidate
                          ? "password should match"
                          : "Please reenter your password"
                      }
                      onChange={(e) => {
                        this.setState(
                          { confirmpassword: e.target.value },
                          this.confirmpasswordvalidate
                        );
                      }}
                    />
                  </div>
                </Container>
                <CardActions>
                  <Grid container justify="flex-end" spacing={2}>
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={
                          this.state.firstNameerror ||
                          this.state.firstNameerror ||
                          this.state.surNameerror ||
                          this.state.userNameerror ||
                          this.state.emailerror ||
                          this.state.error ||
                          this.state.confirmvalidate
                        }
                        //  className={classes.submit}
                        onClick={() => this.submit()}
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
        )}
      </>
    );
  }
}

export default withStyles(styles)(index);
