import React from "react";
import PropTypes from "prop-types";
import { Button, ButtonGroup, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import EmploymentVerification from "../EmploymentVerification";
import Onboarding from "../Onboarding";
import Comments from "../Comments";
import UpdationRequests from "../UpdationRequests";
import AccessCodes from "../AccessCodes";
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: "100%",
    height: "100%",
  },
}));

export default function FullWidthTabs() {
  const [acessrequest, setAcessrequest] = React.useState(true);
  const [onboardingRequest, setonboardingrequest] = React.useState(false);
  const [comment, setComment] = React.useState(false);
  const [UpdateRequest, setUpdateRequest] = React.useState(false);
  const [employerVerification, setemployerVerifiaction] = React.useState(false);
  // const [value, setValue] = React.useState(0);
  const handleAcessrequest = () => {
    setAcessrequest(true);
    setonboardingrequest(false);
    setComment(false);
    setUpdateRequest(false);
    setemployerVerifiaction(false);
  };
  const handleOnboardingCodes = () => {
    setAcessrequest(false);
    setonboardingrequest(true);
    setComment(false);
    setUpdateRequest(false);
    setemployerVerifiaction(false);
  };
  const handlecomment = () => {
    setAcessrequest(false);
    setonboardingrequest(false);
    setComment(true);
    setUpdateRequest(false);
    setemployerVerifiaction(false);
  };
  const handleUpdateRequest = () => {
    setAcessrequest(false);
    setonboardingrequest(false);
    setComment(false);
    setUpdateRequest(true);
    setemployerVerifiaction(false);
  };
  const handleemployerVerification = () => {
    setAcessrequest(false);
    setonboardingrequest(false);
    setComment(false);
    setUpdateRequest(false);
    setemployerVerifiaction(true);
  };

  return (
    <>
      <Box p={2}>
        <Grid container direction="row" justify="flex-end" alignItems="center">
          <ButtonGroup
            // variant="text"
            color="primary"
            aria-label="contained primary button group"
            style={{ padding: 10 }}
          >
            <Button
              // disableElevation={false}
              // disabled={messagesdisplay}
              variant={acessrequest === true ? "contained" : null}
              onClick={() => handleAcessrequest()}
            >
              Access Codes
            </Button>
            <Button
              // disabled={acesscodesdisplay}
              variant={onboardingRequest === true ? "contained" : null}
              onClick={() => handleOnboardingCodes()}
            >
              Onboarding
            </Button>
            <Button
              variant={comment === true ? "contained" : null}
              // disabled={employmentcodesdisplay}
              onClick={() => handlecomment()}
            >
              Comments
            </Button>
            <Button
              variant={UpdateRequest === true ? "contained" : null}
              // disabled={employmentcodesdisplay}
              onClick={() => handleUpdateRequest()}
            >
              Updation Requests
            </Button>
            <Button
              variant={employerVerification === true ? "contained" : null}
              // disabled={employmentcodesdisplay}
              onClick={() => handleemployerVerification()}
            >
              Employment Verification
            </Button>
          </ButtonGroup>
        </Grid>
      </Box>
      {acessrequest ? <AccessCodes /> : null}
      {onboardingRequest ? <Onboarding /> : null}
      {comment ? <Comments /> : null}
      {UpdateRequest ? <UpdationRequests /> : null}
      {employerVerification ? <EmploymentVerification /> : null}
    </>
  );
}
