import red from '@material-ui/core/colors/red';
import blue from '@material-ui/core/colors/blue';
import green from '@material-ui/core/colors/green';
import yellow from '@material-ui/core/colors/yellow';
import teal from '@material-ui/core/colors/blueGrey';
import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary:  {
      main: blue[500],
      contrastText: "#fff",
    },
    chipred: {
      backgroundColor: red[500],
      color: "#fff",
    },
    chipgreen: {
      backgroundColor: green[500],
      color: "#fff",
    },
    chipyellow: {
      backgroundColor: yellow[500],
      color: "#fff",
    },
  },
  typography: {
    fontFamily: "Montserrat-Regular",
    fontSize: 10,
    button: {
      textTransform: "none",
      fontSize: 10,
    },
  },
});

export default theme;