import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
// import TextField from '@material-ui/core/TextField'
// import Grid from '@material-ui/core/Grid'

import Profile from '../Pages/Profile'
import Address from '../Pages/Address'
import Identity from '../Pages/Identity'
import Phone from '../Pages/Phone'
import Job from '../Pages/Job'
import Academics from '../Pages/Academics'
import ApproveAndRejectButtons from '../ApproveAndRejectButtons'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(3),
    },
}));

function getSteps() {
    return ['Profile', 'Addresses', 'Identities', 'Phones', 'Jobs', 'Academics'];
}

function getStepContent(step, user, approval) {
    switch (step) {
        case 0:
            return <Profile user={user} approval={approval} />;
        case 1:
            return <Address user={user} approval={approval} />;
        case 2:
            return <Identity user={user} approval={approval} />;
        case 3:
            return <Phone user={user} approval={approval} />;
        case 4:
            return <Job user={user} approval={approval} />;
        case 5:
            return <Academics user={user} approval={approval} />;
        default:
            return 'Unknown step';
    }
}

export default function VerticalLinearStepper(props) {
    const user = props.user;
    const approval = props.approval;
    const viewId = props.viewId;

    // console.log('user:', user, approval)
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const steps = getSteps();

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    // const handleReset = () => {
    //     setActiveStep(0);
    // };

    return (
      <div className={classes.root}>
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
              <StepContent>
                <Typography>{getStepContent(index, user, approval)}</Typography>
                <div className={classes.actionsContainer}>
                  <div>
                    <Button
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      className={classes.button}
                    >
                      Back
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                      className={classes.button}
                    >
                      {activeStep === steps.length - 1 ? "Finish" : "Next"}
                    </Button>
                  </div>
                </div>
              </StepContent>
            </Step>
          ))}
        </Stepper>
        {activeStep === steps.length && (
          <Paper square elevation={0} className={classes.resetContainer}>
            <ApproveAndRejectButtons
              approval={approval}
              viewId={viewId}
              fetchapproval={props.fetchapproval}
            />
          </Paper>
        )}
      </div>
    );
}
