import React, { Component } from "react";
import { get, post, put, update } from "../../../API";
import Grid from "@material-ui/core/Grid";
import {
    TextField,
    Paper,
    Box,
    Typography,
    Button,
    TableContainer,
    FormControl,
    Avatar,
} from "@material-ui/core/";
import { CircularProgress } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import InputLabel from "@material-ui/core/InputLabel";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Select } from "@material-ui/core";
import { MenuItem } from "@material-ui/core";import {
  List,
  ListItem,
  Divider,
  Card
} from '@material-ui/core/';

import MailOutlineTwoToneIcon from '@material-ui/icons/MailOutlineTwoTone';
import PhoneIcon from '@material-ui/icons/Phone';
import HistoryIcon from '@material-ui/icons/History';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

let token = "";
let id = "";
export class Employeedetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            result: "",
            categories: [],
            loading: true,

            phone: "",
            email: "",
            fax: "",
            logo: null,
            category: "",
            regnumber: "",
            regDate: "",


            updateDialogOpen: false,
            addDialogOpen: false,
            profileDisplayWindow: true,
            updateWindow: false
        };
    }
    postprofile() {
        let bodyFormData = new FormData();
        bodyFormData.append("phone", this.state.phone);
        bodyFormData.append("email", this.state.email);
        if (this.state.logo !== null) {
            bodyFormData.append("logo", this.state.logo);
        }
        // bodyFormData.append("fax", 0);
        bodyFormData.append("category", this.state.category);
        bodyFormData.append("regNum", this.state.regnumber);
        bodyFormData.append("regDate", this.state.regDate);

        put(
            "http://3.22.17.212:9000/api/v1/employers/postdetails",
            token,
            bodyFormData
        ).then((res) => {
            this.getprofiledata();
        });
    }

    isloading() {
        return (
            <Grid
                container
                justify="flex-end"
                alignItems="center"
                // container
                // spacing={0}
                direction="column"
            // alignItems="center"
            // justify="center"
            // // display="flex"
            // style={{ minHeight: "10vh" }}
            >
                <Grid item xs={6}>
                    <CircularProgress />
                </Grid>
            </Grid>
        );
    }

    async getprofiledata() {
        await get(
            "http://3.22.17.212:9000/api/v1/employers/" + id + "/getdetails",
            token,
            ""
        ).then((res) => {
            // console.log(res);
            // result = res.data;
            this.setState({ result: res.data });
            // console.log("Profile Data", this.state.result.length);
        });
    }
    async componentWillMount() {
        token = localStorage.getItem("Token");
        id = localStorage.getItem("id");
        await this.getprofiledata();
        await get(
            "http://3.22.17.212:9000/api/v1/resManager/employer/categories/",
            token,
            ""
        ).then((res) => this.setState({ categories: res.data, loading: false }));
    }

    addDialog() {
        return (
            <Dialog
                open={this.state.addDialogOpen}
                onClose={() => this.setState({ addDialogOpen: false })}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title" justify="center">
                    Add company details
        </DialogTitle>
                <DialogContent>
                    {/* <DialogContentText>
                      Enter the details of your profile to be added
                </DialogContentText> */}

                    <Grid
                        container
                        justify="flex-start"
                        direction="row"
                        alignItems="center"
                        spacing={3}
                    >
                        <Grid item xs={12}>
                            <TextField
                                required
                                id="firstName"
                                label="Phone"
                                value={this.state.firstname}
                                // defaultValue={result[this.state.selectedIndex].firstname}
                                onChange={(event) => {
                                    // this.capitalizefirstname(event.target.value)
                                    this.setState({ phone: event.target.value });
                                    // console.log(this.state.firstname);
                                }}
                                type="text"
                                fullWidth
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                required
                                id="middleName"
                                label="Email"
                                value={this.state.middlename}
                                // defaultValue={result[this.state.selectedIndex].middlename}
                                onChange={(event) => {
                                    // this.capitalizemiddlename(event.target.value)
                                    this.setState({ email: event.target.value });
                                    // console.log(this.state.middlename);
                                }}
                                type="text"
                                fullWidth
                            />
                        </Grid>

                        {/* <Grid item fullWidth xs={12}>
              <TextField
                id="Fax"
                label="Fax"
                // defaultValue={result[this.state.selectedIndex].surname}
                onChange={(event) => {
                  // this.capitalizelastname(event.target.value)
                  this.setState({ fax: event.target.value });
                  // console.log(this.state.lastname);
                }}
                type="text"
                fullWidth
              />
            </Grid> */}
                        <Grid item xs={12}>
                            <TextField
                                required
                                id="Registration Number"
                                label="CAC"
                                // defaultValue={result[this.state.selectedIndex].surname}
                                onChange={(event) => {
                                    // this.capitalizelastname(event.target.value)
                                    this.setState({ regnumber: event.target.value });
                                    // console.log(this.state.lastname);
                                }}
                                type="text"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                id="registration date"
                                size="small"
                                // variant="outlined"
                                label="Registration Date"
                                format={false}
                                margin="dense"
                                InputLabelProps={{ shrink: true, required: true }}
                                // defaultValue={result[this.state.selectedIndex].dob}
                                onChange={(event) => {
                                    this.setState(
                                        { regDate: event.target.value },

                                    );
                                }}
                                type="date"
                                fullWidth
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                required
                                id="chooseFile"
                                label="Logo"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <CloudUploadIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                onChange={(event) => {
                                    this.setState({ logo: event.target.files[0] });
                                }}
                                type="file"
                                fullWidth
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel id="gender" required>Cateogry</InputLabel>
                                <Select
                                    label="gender"
                                    id="gender"
                                    // value={age}
                                    onChange={(event) => {
                                        this.setState({ category: event.target.value });
                                        // console.log(this.state.gender);
                                    }}
                                >
                                    {this.state.categories.map((cat) => (
                                        <MenuItem value={cat.id}>{cat.category}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={() => {
                            this.setState(
                                {
                                    addDialogOpen: false,
                                },
                                this.postprofile
                            );
                        }}
                    >
                        Submit
          </Button>
                    <Button
                        color="secondary"
                        variant="contained"
                        onClick={() =>
                            this.setState({
                                addDialogOpen: false,
                            })
                        }
                    >
                        Cancel
          </Button>
                </DialogActions>
            </Dialog>
        );
    }

    updatedialog() {
        return (
            <Dialog
                open={this.state.updateDialogOpen}
                onClose={() => this.setState({ updateDialogOpen: false })}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title" alignItems="center">
                    Update company details
        </DialogTitle>
                <DialogContent>
                    {/* <DialogContentText>
                      Enter the details of your profile to be added
                </DialogContentText> */}

                    <Grid
                        container
                        justify="flex-start"
                        direction="row"
                        alignItems="center"
                        spacing={3}
                    >
                        <Grid item xs={12}>
                            <TextField
                                id="firstName"
                                label="Phone"
                                value={this.state.firstname}
                                defaultValue={this.state.result.phone}
                                onChange={(event) => {
                                    // this.capitalizefirstname(event.target.value)
                                    this.setState({ phone: event.target.value });
                                    // console.log(this.state.firstname);
                                }}
                                type="text"
                                fullWidth
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                id="middleName"
                                label="Email"
                                value={this.state.middlename}
                                defaultValue={this.state.result.email}
                                onChange={(event) => {
                                    // this.capitalizemiddlename(event.target.value)
                                    this.setState({ email: event.target.value });
                                    // console.log(this.state.middlename);
                                }}
                                type="text"
                                fullWidth
                            />
                        </Grid>
                        {/* 
            <Grid item fullWidth xs={12}>
              <TextField
                id="surname"
                label="Fax"
                value={this.state.lastname}
                defaultValue={this.state.result.fax}
                onChange={(event) => {
                  // this.capitalizelastname(event.target.value)
                  this.setState({ fax: event.target.value });
                  // console.log(this.state.lastname);
                }}
                type="text"
                fullWidth
              />
            </Grid> */}

                        <Grid item xs={12}>
                            <TextField
                                id="chooseFile"
                                label="Logo"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <CloudUploadIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                onChange={(event) => {
                                    this.setState({ logo: event.target.files[0] });
                                }}
                                type="file"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="Registration Number"
                                label="CAC"
                                defaultValue={this.state.regnumber}
                                onChange={(event) => {
                                    // this.capitalizelastname(event.target.value)
                                    this.setState({ regnumber: event.target.value });
                                    // console.log(this.state.lastname);
                                }}
                                type="text"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="registration date"
                                size="small"
                                // variant="outlined"
                                label="Registration Date"
                                format={false}
                                margin="dense"
                                InputLabelProps={{ shrink: true, required: true }}
                                defaultValue={this.state.regDate}
                                onChange={(event) => {
                                    this.setState({ regDate: event.target.value });
                                }}
                                type="date"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel id="gender">Cateogry</InputLabel>
                                <Select
                                    // label="gender"
                                    id="gender"
                                    // value={age}
                                    defaultValue={this.state.result.category}
                                    onChange={(event) => {
                                        this.setState({ category: event.target.value });
                                        // console.log(this.state.gender);
                                    }}
                                >
                                    {this.state.categories.map((cat, index) => (
                                        <MenuItem value={cat.id} key={index}>{cat.category}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={() =>
                            this.setState({
                                updateDialogOpen: true,
                                phone: this.state.result.phone,
                                email: this.state.result.email,
                                category: this.state.result.category,
                                regnumber: this.state.result.regNum,
                                regDate: this.state.result.regDate,

                                // add the updatedstate elements here after passing the token and adding data
                            })
                        }
                    >
                        update
          </Button>
                    <Button
                        color="secondary"
                        variant="contained"
                        onClick={() =>
                            this.setState({
                                updateDialogOpen: false,
                            })
                        }
                    >
                        Cancel
          </Button>
                </DialogActions>
            </Dialog>
        );
    }

    render() {
        return (
            <div>
                {this.state.loading ? (
                    this.isloading()
                ) : this.state.result.length === 0 ? (
                    <Grid
                        container
                        spacing={3}
                        direction="column"
                        justify="center"
                        align="center"
                    >
                        <Grid item xs={12}>
                            <Paper elevation={3} direction="column">
                                <Box
                                    p={1}
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent="center"
                                    alignItems="center"
                                    style={{ height: "50vh" }}
                                >
                                    <Typography
                                        variant="h6"
                                        gutterBottom
                                        align="center"
                                        justify="center"
                                    >
                                        Please add your company details.
                  </Typography>

                                    <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={() => this.setState({ addDialogOpen: true })}
                                    >
                                        Add company details
                  </Button>
                                </Box>
                            </Paper>
                        </Grid>
                    </Grid>
                ) : (
                            <div>
                              {this.state.profileDisplayWindow ? this.companyProfileTopCard() : this.editProfileWindow()}
                            </div>
                        )}
                {this.updatedialog()}
                {this.addDialog()}
            </div>
        );
    }

    companyProfileTopCard() {
        return (
            <React.Fragment>
                <Paper style={{ marginTop: 20, background: "#263238" }}>
                    <Grid
                        container
                        // direction="row"
                        justify="center"
                        alignItems="center"
                        style={{ padding: 20 }}
                        spacing={3}
                    >
                        <Grid item container xs={12} md={3} justify="center"
                            alignItems="center">
                            <Avatar
                                src={this.state.result.logo}
                                style={{ height: "12rem", width: "12rem" }}
                            >
                                {/* <img src="/images/sampleuserphoto.jpg" width="185" height="185" alt="" /> */}
                            </Avatar>
                        </Grid>

                        <Grid item container
                            direction="column"
                            justify="center"
                            alignItems="center"
                            spacing={1}
                            md={9}
                            xs={12}>

                            <Grid item>
                                <Typography
                                    variant="h2"
                                    style={{ textTransform: "capitalize", color: "white", fontWeight: "bold" }}
                                >
                                    {this.state.result.companyName}
                                </Typography>
                            </Grid>

                            <Grid item>
                                <Typography variant="h5" style={{ textTransform: "capitalize", color: "white" }}>
                                    {this.state.result.category_name_field}
                                </Typography>
                            </Grid>

                        </Grid>

                    </Grid>
                </Paper>
                <div>{this.companyProfileBottomCard()}</div>
            </React.Fragment>
        );
    }

    companyProfileBottomCard() {
        return (
            <Paper style={{ padding: 20 }}>
                <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="flex-start"
                    spacing={3}
                >
                    <Grid
                        item
                        container
                        direction="column"
                        justify="space-between"
                        alignItems="center"
                        md={3}
                        xs={12}
                        spacing={6}
                    >

                        <Grid item container
                            style={{ padding: 20 }}
                            container
                            direction="row"
                            justify="center"
                            alignItems="center"
                            spacing={2}
                        >

                            <Grid item xs={12}>

                                <Button
                                    fullWidth
                                    startIcon={<EditIcon />}
                                    color="primary"
                                    variant="outlined"
                                    // disabled={this.state.result[0].status === "Audit In Progress"}
                                    onClick={() =>
                                        this.setState({
                                            profileDisplayWindow: !this.state.profileDisplayWindow,
                                            phone: this.state.result.phone,
                                            email: this.state.result.email,
                                            category: this.state.result.category,
                                            regnumber: this.state.result.regNum,
                                            regDate: this.state.result.regDate,

                                            // add the updatedstate elements here after passing the token and adding data
                                        })
                                    }
                                >
                                    Edit
                    </Button>
                            </Grid>

                            <Grid item xs={12}>
                                <Typography variant="body1"
                                    style={{ textTransform: "capitalize", color: "#616161", fontWeight: "bold" }}
                                >
                                    Contact
                </Typography>
                            </Grid>

                            <Grid item container xs={12}>
                                <Grid item container spacing={2} alignItems="center">
                                    <Grid item>
                                        <MailOutlineTwoToneIcon style={{ color: "#6d6966" }} />
                                    </Grid>
                                    <Grid>
                                        <Typography component={"span"}>
                                            {this.state.result.email}
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <Grid item container spacing={2} alignItems="center">
                                    <Grid item>
                                        <PhoneIcon style={{ color: "#6d6966" }} />
                                    </Grid>
                                    <Grid>
                                        <Typography component={"span"}>
                                            {this.state.result.phone}
                                        </Typography>
                                    </Grid>
                                </Grid>

                            </Grid>

                        </Grid>
                    </Grid>


                    <Grid item container md={9} xs={12}>

                        <Grid item xs={12}>
                            <Typography variant="body1"
                                style={{ textTransform: "capitalize", color: "#616161", fontWeight: "bold" }}
                            >
                                Your Details
                </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <List component="nav" aria-label="mailbox folders">

                            <ListItem>
                                    <Grid container>
                                        <Grid item xs={3}>
                                            <Typography variant='subtitle1' color="textSecondary">
                                                Industry Type
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={9}>
                                            <Typography variant='subtitle1'>
                                                {this.state.result.category_name_field}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </ListItem>
                                <Divider />

                                <ListItem>
                                    <Grid container>
                                        <Grid item xs={3}>
                                            <Typography variant='subtitle1' color="textSecondary">
                                                Registration Number
                    </Typography>
                                        </Grid>

                                        <Grid item xs={9}>
                                            <Typography variant='subtitle1'>
                                                {this.state.result.regNum}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </ListItem>
                                <Divider />

                                <ListItem>
                                    <Grid container>
                                        <Grid item xs={3}>
                                            <Typography variant='subtitle1' color="textSecondary">
                                                Registration Date
                    </Typography>
                                        </Grid>

                                        <Grid item xs={9}>
                                            <Typography variant='subtitle1'>
                                                {new Date(this.state.result.regDate).toDateString()}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </ListItem>
                                <Divider />

                                <ListItem>
                                    <Grid container>
                                        <Grid item xs={3}>
                                            <Typography variant='subtitle1' color="textSecondary">
                                                Validity
                    </Typography>
                                        </Grid>

                                        <Grid item xs={9}>
                                            <Typography variant='subtitle1'>
                                                {this.state.result.validity}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </ListItem>
                                <Divider />

                                <ListItem>
                                    <Grid container>
                                        <Grid item xs={3}>
                                            <Typography variant='subtitle1' color="textSecondary">
                                                Ontac Id
                    </Typography>
                                        </Grid>

                                        <Grid item xs={9}>
                                            <Typography variant='subtitle1'>
                                            {this.state.result.ontrac_id}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </ListItem>
                                <Divider />

                            </List>
                        </Grid>

                    </Grid>


                </Grid>
            </Paper>
        )
    }

    editProfileWindow(index) {
      return (
          <Grid container spacing={4}>
            <Grid item container justify='flex-end'>
              <Grid>
                <Button
  
                  startIcon={<ArrowBackIcon />}
                  size="large"
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    this.setState(
                      { profileDisplayWindow: !this.state.profileDisplayWindow, 
                        updateWindow: !this.state.updateWindow }
                    )
                  }
                >
                  Back
                      </Button>
              </Grid>
  
            </Grid>

            <Grid item xs={12}>
  
            <Card>
              <Grid
                container
                justify="center"
                direction="row"
                alignItems="center"
                spacing={3}
                style={{ padding: 20 }}
              >
                <Grid item xs={12} style={{ background: "#263238" }}>
                  <Typography variant='h4' style={{ color: "white" }}>
                    Edit Profile
              </Typography>
                </Grid>
  
                <Grid item xs={12}>
                            <TextField
                                id="firstName"
                                label="Phone"
                                value={this.state.firstname}
                                defaultValue={this.state.result.phone}
                                onChange={(event) => {
                                    // this.capitalizefirstname(event.target.value)
                                    this.setState({ phone: event.target.value });
                                    // console.log(this.state.firstname);
                                }}
                                type="text"
                                fullWidth
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                id="middleName"
                                label="Email"
                                value={this.state.middlename}
                                defaultValue={this.state.result.email}
                                onChange={(event) => {
                                    // this.capitalizemiddlename(event.target.value)
                                    this.setState({ email: event.target.value });
                                    // console.log(this.state.middlename);
                                }}
                                type="text"
                                fullWidth
                            />
                        </Grid>
                        {/* 
            <Grid item fullWidth xs={12}>
              <TextField
                id="surname"
                label="Fax"
                value={this.state.lastname}
                defaultValue={this.state.result.fax}
                onChange={(event) => {
                  // this.capitalizelastname(event.target.value)
                  this.setState({ fax: event.target.value });
                  // console.log(this.state.lastname);
                }}
                type="text"
                fullWidth
              />
            </Grid> */}

                        <Grid item xs={12}>
                            <TextField
                                id="chooseFile"
                                label="Logo"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <CloudUploadIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                onChange={(event) => {
                                    this.setState({ logo: event.target.files[0] });
                                }}
                                type="file"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="Registration Number"
                                label="CAC"
                                defaultValue={this.state.regnumber}
                                onChange={(event) => {
                                    // this.capitalizelastname(event.target.value)
                                    this.setState({ regnumber: event.target.value });
                                    // console.log(this.state.lastname);
                                }}
                                type="text"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="registration date"
                                size="small"
                                // variant="outlined"
                                label="Registration Date"
                                format={false}
                                margin="dense"
                                InputLabelProps={{ shrink: true, required: true }}
                                defaultValue={this.state.regDate}
                                onChange={(event) => {
                                    this.setState({ regDate: event.target.value });
                                }}
                                type="date"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel id="gender">Cateogry</InputLabel>
                                <Select
                                    // label="gender"
                                    id="gender"
                                    // value={age}
                                    defaultValue={this.state.result.category}
                                    onChange={(event) => {
                                        this.setState({ category: event.target.value });
                                        // console.log(this.state.gender);
                                    }}
                                >
                                    {this.state.categories.map((cat, index) => (
                                        <MenuItem value={cat.id} key={index}>{cat.category}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
  
                <Grid>
                  <Button
                    color="primary"
                    variant="contained"
                    size="large"
                    disabled={this.state.buttondisabled}
                    onClick={() => {
                      this.setState(
                        {
                          updateDialogOpen: false,
                          profileDisplayWindow: !this.state.profileDisplayWindow, 
                          updateWindow: !this.state.updateWindow
                        },
                        this.postprofile()
                      );
                    }}
                    startIcon={<SaveIcon />}
                  >
                    Save Profile
                </Button>
                </Grid>
  
              </Grid>
            </Card>
          </Grid>
          </Grid>
      );
    }
}

export default Employeedetails;
