import React, { Component } from "react";
import { Button } from "@material-ui/core";
// import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
// import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import axios from "axios";
import { CircularProgress, Card } from "@material-ui/core";
// import Box from "@material-ui/core/Box";
// import CardMedia from "@material-ui/core/CardMedia";
import { Select } from "@material-ui/core";
import { MenuItem } from "@material-ui/core";
// import image from "../../../../public/images/mainImage.jpg";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import {
  InputLabel,
  ListItemText,
  Divider,
  ListItem,
  List,
  IconButton,
} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import Typography from "@material-ui/core/Typography";
import GridListTile from "@material-ui/core/GridListTile";
import GridList from "@material-ui/core/GridList";
import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import Chip from "@material-ui/core/Chip";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import PublishIcon from "@material-ui/icons/Publish";
import SaveIcon from "@material-ui/icons/Save";
import HistoryIcon from "@material-ui/icons/History";
import EditIcon from "@material-ui/icons/Edit";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import MyLocationIcon from "@material-ui/icons/MyLocation";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import InputAdornment from "@material-ui/core/InputAdornment";

let token = "";
let id = "";
// let result = [];
let history = [];
let pictures = [];
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
class Identities extends Component {
  constructor(props) {
    super(props);

    this.state = {
      updateDialogOpen: false,
      selectedIndex: -1,
      loading: true,
      viewDialogeOpen: false,
      uploadDialogOpen: false,
      pictureid: "",
      addDialogOpen: false,
      idSource: [],
      selectedidSource: "",
      fullName:
        localStorage.getItem("name") +
        " " +
        localStorage.getItem("middlename") +
        " " +
        localStorage.getItem("surname"),
      idNumber: "",
      sex: "",
      dob: "",
      updateFullName: "",
      updatedob: "",
      updatesex: "",
      updateidnumber: "",
      updateidSource: "",
      updatereason: "",
      historyloading: true,
      historyDialogeOpen: false,
      uploadpictures: "",
      pictureloading: "false",
      result: [],
      buttondisabled: true,
      addsnackbar: false,
      addresponse: "",
      updateresponse: "",
      updatesnackbar: false,
      amount: "",
      debitresponse: "",
      currentid: "",
      uploadsnackbar: false,
      uploadresponse: "",
      walletdialog: false,
      historyWindowOpen: false,
      identityDisplayWindow: true,
      walletLogic: false,
      viewIDWindow: false,
      addWindow: false,

      fullnameCheck: false,
      genderCheck: false,
      idNumberCheck: false,
      idSourceCheck: false,
      dobCheck: false,
    };
    // this.updateidentites= this.updateidentites.bind();
  }
  reasonforupdatevalidcheck = (event) => {
    if (event.target.value.length > 0) {
      this.setState({ buttondisabled: false });
    } else if (event.target.value > 250) {
      this.setState({ buttondisabled: true });
    } else {
      this.setState({ buttondisabled: true });
    }
  };
  addsnackbar() {
    return this.state.addresponse === 200 ? (
      <div>
        <Snackbar
          open={this.state.addsnackbar}
          autoHideDuration={3000}
          onClick={() => this.setState({ addsnackbar: false })}
        >
          <Alert
            onClose={() => {
              this.setState({ addsnackbar: !this.state.addasnackbar });
            }}
            severity="success"
          >
            Identity added sucessfully
          </Alert>
        </Snackbar>
      </div>
    ) : (
      <Snackbar
        open={this.state.addsnackbar}
        autoHideDuration={3000}
        onClick={() => {
          this.setState({ addsnackbar: !this.state.addsnackbar });
        }}
      >
        <Alert
          onClose={() => {
            this.setState({ addsnackbar: !this.state.addsnackbar });
          }}
          severity="error"
        >
          Something went wrong please try again
        </Alert>
      </Snackbar>
    );
  }
  updatesnackbar() {
    return this.state.updateresponse === 200 ? (
      <div>
        <Snackbar
          open={this.state.updatesnackbar}
          autoHideDuration={3000}
          onClick={() => this.setState({ updatesnackbar: false })}
        >
          <Alert
            onClose={() => {
              this.setState({ updatesnackbar: !this.state.updatesnackbar });
            }}
            severity="success"
          >
            Identites updated sucessfully
          </Alert>
        </Snackbar>
      </div>
    ) : (
      <Snackbar
        open={this.state.updatesnackbar}
        autoHideDuration={3000}
        onClick={() => {
          this.setState({ updatesnackbar: !this.state.updatesnackbar });
        }}
      >
        <Alert
          onClose={() => {
            this.setState({ updatesnackbar: !this.state.updatesnackbar });
          }}
          severity="error"
        >
          Something went wrong please try again
        </Alert>
      </Snackbar>
    );
  }
  async getidentites() {
    await axios
      .get(
        "http://3.22.17.212:9000/api/v1/employees/" +
          id +
          "/identities-by/" +
          id,
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((res) => {
        //result = res.data;
        this.setState({ result: res.data });
        console.table("identites", this.state.result);
      });
  }
  async componentDidMount() {
    console.log(this.state.fullName);
    token = localStorage.getItem("Token");
    id = localStorage.getItem("id");
    await this.getidentites();
    let idSource = await axios.get(
      "http://3.22.17.212:9000/api/v1/resManager/id/sources/?excludeSystem=true",
      {
        headers: {
          Authorization: token,
        },
      }
    );
    idSource = idSource.data;
    this.setState({ idSource: idSource });
    this.setState({ loading: false });
  }
  isloading() {
    return (
      <>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          display="flex"
          style={{ minHeight: "0vh" }}
        >
          <CircularProgress />
        </Grid>
      </>
    );
  }
  async getHistory(index) {
    this.setState({
      historyDialogeOpen: true,
      // historyWindowOpen: !this.state.historyWindowOpen
    });
    await axios
      .get(
        "http://3.22.17.212:9000/api/v1/employees/" +
          id +
          "/identities-by/" +
          id +
          "/idSources/" +
          index +
          "/history",
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((res) => {
        history = res.data;
        this.setState({ historyloading: false });
      });
  }
  async postidentities() {
    if (
      this.state.fullName.length !== 0 &&
      this.state.sex.length !== 0 &&
      this.state.idNumber.length !== 0 &&
      this.state.selectedidSource.length !== 0 &&
      this.state.dob.length !== 0
    ) {
      console.log("ConditionCheck");
      let headers = {
        headers: {
          Authorization: token,
          "Content-Type": "multipart/form-data",
        },
      };
      // await this.setState({startdate:this.state.startdate+"-01",enddate:this.state.enddate+"-01"})
      let bodyFormData = new FormData();
      bodyFormData.append("employee", id);
      bodyFormData.append("fullname", this.state.fullName);
      bodyFormData.append("idNumber", this.state.idNumber);
      bodyFormData.append("sex", this.state.sex);
      bodyFormData.append("dob", this.state.dob);
      bodyFormData.append("issueDate", this.state.startdate + "-01");
      bodyFormData.append("expiryDate", this.state.enddate + "-01");
      bodyFormData.append("idSource", this.state.selectedidSource);

      await axios
        .post(
          "http://3.22.17.212:9000/api/v1/employees/post-identity",
          bodyFormData,
          headers
        )
        .then((response) => {
          this.setState({
            addresponse: response.status,
            addsnackbar: true,
            addDialogOpen: !this.state.addDialogOpen,
            identityDisplayWindow: !this.state.identityDisplayWindow,
          });
        });
      await this.getidentites();
      this.setState({
        addDialogOpen: !this.state.addDialogOpen,
        identityDisplayWindow: !this.state.identityDisplayWindow,
      });
    } else {
      if (this.state.fullName.length === 0) {
        this.setState({ fullnameCheck: true });
      }
      if (this.state.sex.length === 0) {
        this.setState({ genderCheck: true });
      }
      if (this.state.idNumber.length === 0) {
        this.setState({ idNumberCheck: true });
      }
      if (this.state.selectedidSource.length === 0) {
        this.setState({ idSourceCheck: true });
      }
      if (this.state.dob.length === 0) {
        this.setState({ dobCheck: true });
      }
    }
  }
  async updateidentites(idsource) {
    this.setState({
      updateDialogOpen: false,
    });
    let headers = {
      headers: {
        Authorization: token,
        "Content-Type": "multipart/form-data",
      },
    };
    // await this.setState({
    //   updatestartdate: this.state.updatestartdate ,
    //   updateenddate: this.state.updateenddate + "-01",
    // });
    let bodyFormData = new FormData();
    bodyFormData.append("idSource", this.state.updateidSource);
    bodyFormData.append("idNumber", this.state.updateidnumber);
    bodyFormData.append("fullname", this.state.updateFullName);
    bodyFormData.append("sex", this.state.updatesex);
    bodyFormData.append("dob", this.state.updatedob);
    bodyFormData.append("issueDate", this.state.updatestartdate + "-01");
    bodyFormData.append("expiryDate", this.state.updateenddate + "-01");
    bodyFormData.append("update_reason", this.state.updatereason);

    await axios
      .post(
        "http://3.22.17.212:9000/api/v1/employees/update-identity",
        bodyFormData,
        headers
      )
      .then((response) => {
        this.setState({
          updateresponse: response.status,
          updatesnackbar: true,
        });
      });
    await this.getidentites();
  }
  render() {
    return (
      <div>
        {this.state.loading ? this.isloading() : this.getIdentity()}
        <Dialog
          //  fullWidth={"sm"}
          //  maxWidth={"sm"}
          open={this.state.viewDialogeOpen}
          onClose={() => this.setState({ viewDialogeOpen: false })}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            <Typography variant="subtitle1" gutterBottom align="center">
              View pictures
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Grid
              container
              direction="row"
              justify="space-evenly"
              alignItems="center"
            >
              <GridList
                cellHeight={160}
                style={{ height: 500, width: 500 }}
                cols={3}
              >
                {this.state.pictureloading
                  ? this.isloading()
                  : pictures.map((pic, index) => (
                      <GridListTile key={pic.id} cols={1}>
                        <img alt="" src={pic.picture} />
                      </GridListTile>

                      // <Grid container>
                      //     <Grid item xs={12}>
                      // <image src={pic.picture}/>
                      //       {/* {pic.picture} */}
                      //     </Grid>
                      //   </Grid>
                    ))}{" "}
              </GridList>
            </Grid>
          </DialogContent>
          <DialogActions style={{ padding: 15 }}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() =>
                this.setState({ viewDialogeOpen: false, selectedIndex: -1 })
              }
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {
          <Dialog
            // open={this.state.uploadDialogOpen}
            // onClose={() => this.setState({ uploadDialogOpen: false })}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">choose your file</DialogTitle>
            <DialogContent>
              <Grid container p={1}>
                <TextField
                  type="file"
                  onChange={(event) => {
                    this.setState({ uploadpictures: event.target.files[0] });
                  }}
                ></TextField>
              </Grid>

              <DialogActions style={{ padding: 15 }}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    if (this.state.uploadpictures === "") {
                      this.setState({ pictureSnackbarError: true });
                    } else {
                      this.setState({ pictureSnackbarOpen: true });
                      this.postpictures(this.state.pictureid);
                    }
                    this.setState({
                      uploadpictures: "",
                    });
                  }}
                >
                  upload
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() =>
                    this.setState({
                      uploadDialogOpen: false,
                      selectedIndex: -1,
                    })
                  }
                >
                  Close
                </Button>
              </DialogActions>
            </DialogContent>
          </Dialog>
        }
        {this.updatesnackbar()}
        {
          <Dialog
            open={this.state.walletdialog}
            onClose={() => this.setState({ walletdialog: false })}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title" align="center" justify="center">
              You need to pay {this.state.amount} for this service from wallet
            </DialogTitle>
            <DialogContent></DialogContent>
            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                onClick={() =>
                  this.setState(
                    {
                      walletdialog: false,
                    },
                    this.pay
                  )
                }
              >
                Pay
              </Button>
              <Button
                color="secondary"
                variant="contained"
                onClick={() =>
                  this.setState({
                    walletdialog: false,
                  })
                }
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        }

        {
          <Dialog
            // open={this.state.addDialogOpen}
            // onClose={() => this.setState({ addDialogOpen: false })}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title" align="center" justify="center">
              Add Identity
            </DialogTitle>
            <DialogContent>
              <DialogContentText align="center">
                Enter the details of your identity
              </DialogContentText>

              <Grid
                container
                justify="flex-start"
                direction="row"
                alignItems="center"
                spacing={3}
              >
                <Grid item fullWidth xs={12}>
                  <TextField
                    required
                    id="full Name"
                    value={this.state.fullName}
                    label="Full name as appears on ID"
                    onChange={(event) => {
                      this.setState({ fullName: event.target.value });
                    }}
                    type="text"
                    fullWidth
                  />
                </Grid>

                <Grid item fullWidth xs={12}>
                  <FormControl fullWidth required>
                    <InputLabel id="gender">Gender</InputLabel>
                    <Select
                      labelId="gender"
                      id="gender"
                      // value={age}
                      onChange={(event) => {
                        this.setState({ sex: event.target.value });
                      }}
                    >
                      <MenuItem value={"Male"}>Male</MenuItem>
                      <MenuItem value={"Female"}>Female</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item fullWidth xs={12}>
                  <TextField
                    required
                    id="idNumber"
                    label="Id Number"
                    // defaultValue={result[this.state.selectedIndex].surname}
                    onChange={(event) => {
                      this.setState({ idNumber: event.target.value });
                    }}
                    type="text"
                    fullWidth
                  />
                </Grid>

                <Grid item fullWidth xs={12}>
                  <FormControl fullWidth required>
                    <InputLabel id="idSource">Id Source</InputLabel>
                    <Select
                      labelId="idSource"
                      id="idSource"
                      // value={age}
                      onChange={(event) => {
                        this.setState({ selectedidSource: event.target.value });
                      }}
                    >
                      {this.state.idSource.map((source) => (
                        <MenuItem
                          id={source.id}
                          value={source.id}
                          key={source.id}
                        >
                          {source.idSource}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item fullWidth xs={12}>
                  <InputLabel id="dob">Date of Birth*</InputLabel>
                  <TextField
                    // required
                    id="dob"
                    // variant="outlined"
                    // label="Date of birth"
                    onChange={(event) => {
                      this.setState({ dob: event.target.value });
                    }}
                    type="date"
                    fullWidth
                  />
                </Grid>
                <Grid item fullWidth xs={12}>
                  {/* <InputLabel id="dob">Date of birth</InputLabel> */}
                  <InputLabel id="dob">Issue Date</InputLabel>
                  <TextField
                    id="dob"
                    onChange={(event) => {
                      this.setState({ startdate: event.target.value });
                    }}
                    type="month"
                    fullWidth
                  />
                </Grid>
                <Grid item fullWidth xs={12}>
                  {/* <InputLabel id="dob">Date of birth</InputLabel> */}
                  <InputLabel id="dob">Expiry Date</InputLabel>
                  <TextField
                    id="dob"
                    onChange={(event) => {
                      this.setState({ enddate: event.target.value });
                    }}
                    type="month"
                    fullWidth
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                onClick={() =>
                  this.setState(
                    {
                      addDialogOpen: false,
                      // selectedIndex: -1,
                    },
                    this.postidentities
                  )
                }
              >
                Submit Identity
              </Button>
              <Button
                color="secondary"
                variant="contained"
                onClick={() =>
                  this.setState({
                    addDialogOpen: false,
                    // selectedIndex: -1,
                  })
                }
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        }
        {this.addsnackbar()}
      </div>
    );
  }

  adddata() {
    return (
      <>
        {this.state.addWindow ? (
          this.addNewIdentityWindow()
        ) : (
          <Grid item xs={12}>
            <Paper style={{ padding: 20 }} elevation={3}>
              <Box
                p={8}
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                style={{ height: "50vh" }}
              >
                <Typography variant="h4" gutterBottom align="center">
                  Add Identities to improve ratings
                </Typography>

                <Grid container justify="center" style={{ marginTop: 50 }}>
                  <Grid item>
                    <Button
                      size="large"
                      startIcon={<AddCircleOutlineIcon />}
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        this.setState({ addWindow: !this.state.addWindow });
                      }}
                    >
                      Add Identity
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Grid>
        )}
      </>
    );
  }
  async getpictures(idsource) {
    // this.setState({ viewDialogeOpen: true });
    this.setState({ pictureloading: true });
    await axios
      .get(
        "http://3.22.17.212:9000/api/v1/employees/" +
          id +
          "/idSources/" +
          idsource +
          "/pics",
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((res) => {
        pictures = res.data;
        this.setState({ pictureloading: false });
      });
  }
  async postpictures(id) {
    let headers = {
      headers: {
        Authorization: token,
        "Content-Type": "multipart/form-data",
      },
    };
    let bodyFormData = new FormData();
    bodyFormData.append("empIdentity", id);
    bodyFormData.append("picture", this.state.uploadpictures);

    await axios
      .post(
        "http://3.22.17.212:9000/api/v1/employees/post-identitiy-pic",
        bodyFormData,
        headers
      )
      .then((response) => {
        this.setState({
          uploadresponse: response.status,
          uploadsnackbar: true,
        });
        response.status === 200
          ? alert("Your image has been uploaded succesfully")
          : alert("Something went wrong please try again");
      });
  }
  async getamount() {
    await axios
      .get(
        "http://3.22.17.212:9000/api/v1/resManager/serviceAPI/?serviceName=identityverification"
      )
      .then((res) => this.setState({ amount: res.data[0].serviceCharge }));
  }
  async pay() {
    let transactionid = Math.floor(
      10000000000000000 + Math.random() * 9000000000000000
    );
    let headers1 = {
      headers: {
        Authorization: token,
        "Content-Type": "multipart/form-data",
      },
    };

    await axios
      .post(
        "http://3.22.17.212:9000/wallet/transaction?type=DEBIT&amount=" +
          this.state.amount +
          "&description=" +
          transactionid,
        "",
        headers1
      )
      .then((response) => {
        if (response.status === 200) {
          this.senddetails(transactionid);
        }
      });
  }
  async senddetails(tid) {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", token);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      "http://3.22.17.212:9000/api/v1/accounts/paymentDetails?service=identityverification&trxId=" +
        tid +
        "&paymentMethod=CARD&amount=" +
        this.state.amount,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => this.verification())
      .catch((error) => console.log("error", error));
  }
  async verification() {
    let headers = {
      headers: {
        Authorization: token,
      },
    };
    let bodyFormData = {
      verType: "Identity",
      objId: this.state.currentid,
    };

    await axios
      .post(
        "http://3.22.17.212:9000/api/v1/codes/evaluation/new-code",
        bodyFormData,
        headers
      )
      .then((res) => {
        window.location.reload(false);
      });
  }

  getTableOfEmployees() {
    return (
      <>
        {this.state.result.length === 0 ? (
          this.adddata()
        ) : (
          <>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="flex-end"
            >
              <Box p={2}>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      this.setState({ addDialogOpen: true });
                    }}
                  >
                    Add Identity
                  </Button>
                </Grid>
              </Box>
            </Grid>
            <TableContainer component={Paper} elevation={16}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow style={{ backgroundColor: "black" }}>
                    {[
                      " Created Date",
                      "Full Name",
                      "Date of Birth",
                      "Sex",
                      "Identity Source",
                      "Identity Number",
                      "Source",
                      "Picture",
                      "Verified By",
                      "Update",
                      "History",
                      "Verification",
                    ].map((text, index) => (
                      <TableCell
                        key={index}
                        padding="none"
                        style={{ fontWeight: "bolder" }}
                        align="center"
                      >
                        {text}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.result.map((row, index) => (
                    <TableRow key={row.id}>
                      <TableCell align="center">
                        {new Date(row.created_on).toDateString()}
                      </TableCell>
                      <TableCell align="center">{row.fullname}</TableCell>
                      <TableCell align="center">{row.dob}</TableCell>
                      <TableCell align="center">{row.sex}</TableCell>
                      <TableCell align="center">
                        {row.idSource_name_field}
                      </TableCell>
                      <TableCell align="center">{row.idNumber}</TableCell>

                      <TableCell align="center">
                        {row.idSource_name_field}
                      </TableCell>
                      <TableCell align="center">
                        <Grid
                          container
                          display="flex"
                          direction="row"
                          alignItems="center"
                          justify="center"
                          spacing={1}
                        >
                          <Grid item>
                            <Button
                              size="small"
                              color="primary"
                              variant="outlined"
                              onClick={() => this.getpictures(row.idSource)}
                            >
                              View
                            </Button>
                          </Grid>
                          <Grid item>
                            <Button
                              size="small"
                              color="secondary"
                              variant="outlined"
                              onClick={() =>
                                this.setState({
                                  uploadDialogOpen: true,
                                  pictureid: row.id,
                                })
                              }
                            >
                              upload
                            </Button>
                          </Grid>
                        </Grid>
                      </TableCell>
                      <TableCell align="center">
                        {row.owner_name_field}
                      </TableCell>

                      <TableCell align="center">
                        <Button
                          size="small"
                          // disabled={row.status === "Audit In Progress"}
                          color="primary"
                          variant="outlined"
                          onClick={() =>
                            this.setState({
                              updateDialogOpen: true,
                              selectedIndex: index,
                              updateFullName: this.state.result[index].fullname,
                              updatedob: this.state.result[index].dob,
                              updatesex: this.state.result[index].sex,
                              updatestartdate: this.state.result[
                                index
                              ].issueDate.substring(
                                0,
                                this.state.result[index].issueDate.length - 3
                              ),
                              updateenddate: this.state.result[
                                index
                              ].expiryDate.substring(
                                0,
                                this.state.result[index].expiryDate.length - 3
                              ),
                              updateidnumber: this.state.result[index].idNumber,
                              updateidSource: this.state.result[index].idSource,
                            })
                          }
                        >
                          Update
                        </Button>
                      </TableCell>
                      <TableCell align="center">
                        <Button
                          size="small"
                          color="secondary"
                          variant="outlined"
                          onClick={() => this.getHistory(row.idSource)}
                        >
                          history
                        </Button>
                      </TableCell>
                      {row.showVerifyOnTrac_btn === true ? (
                        <TableCell align="center">
                          <Button
                            size="small"
                            variant="outlined"
                            color="default"
                            disabled={!row.showVerifyOnTrac_btn}
                            onClick={() => {
                              this.setState({
                                walletdialog: true,
                                currentid: row.id,
                              });
                              this.getamount();
                            }}
                          >
                            Request for verification
                          </Button>
                        </TableCell>
                      ) : null}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              {this.state.selectedIndex === -1 ? (
                <div />
              ) : (
                <Dialog
                  open={this.state.updateDialogOpen}
                  onClose={() => this.setState({ updateDialogOpen: false })}
                  aria-labelledby="form-dialog-title"
                >
                  <DialogTitle id="form-dialog-title" align="center">
                    Update Identity
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText align="center">
                      Type in details to update
                    </DialogContentText>

                    <Grid
                      container
                      justify="flex-start"
                      direction="row"
                      alignItems="center"
                      spacing={3}
                    >
                      <Grid item xs={12}>
                        <TextField
                          required
                          id="fullName"
                          label="Full name"
                          defaultValue={this.state.updateFullName}
                          onChange={(event) => {
                            this.setState({
                              updateFullName: event.target.value,
                            });
                          }}
                          type="text"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          required
                          id="fullName"
                          label="IdNumber"
                          defaultValue={this.state.updateidnumber}
                          onChange={(event) => {
                            this.setState({
                              updateidnumber: event.target.value,
                            });
                          }}
                          type="text"
                          fullWidth
                        />
                      </Grid>

                      <Grid item xs={12}>
                        {/* <InputLabel id="dob">Date of birth</InputLabel> */}
                        <TextField
                          required
                          id="dob"
                          label="Date of Birth"
                          defaultValue={this.state.updatedob}
                          onChange={(event) => {
                            this.setState({ updatedob: event.target.value });
                          }}
                          type="date"
                          fullWidth
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <FormControl fullWidth required>
                          <InputLabel id="gender">Gender</InputLabel>
                          <Select
                            labelId="gender"
                            id="gender"
                            // value={age}
                            defaultValue={this.state.updatesex}
                            onChange={(event) => {
                              this.setState({ updatesex: event.target.value });
                            }}
                          >
                            <MenuItem value={"Male"}>Male</MenuItem>
                            <MenuItem value={"Female"}>Female</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>

                      {/* <Grid item fullWidth xs={12}>
                      <Select
                        id="idSource"
                        label="Id Source"
                        defaultValue={this.state.updateidSource}
                        onChange={(event) => {
                          this.setState({ updateidSource: event.target.value });
                        }}
                        type="text"
                        fullWidth
                      >
                      {this.state.idSource.map((source) => (
                        <MenuItem id={source.id} value={source.id}>
                          {source.idSource}
                        </MenuItem>
                      ))}</Select>
                        </Grid> */}
                      <Grid item xs={12}>
                        {/* <InputLabel id="dob">Date of birth</InputLabel> */}
                        <InputLabel id="dob">Start Date*</InputLabel>
                        <TextField
                          id="dob"
                          defaultValue={this.state.updatestartdate}
                          onChange={(event) => {
                            this.setState({
                              updatestartdate: event.target.value,
                            });
                          }}
                          type="month"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12}>
                        {/* <InputLabel id="dob">Date of birth</InputLabel> */}
                        <InputLabel id="dob">End Date*</InputLabel>
                        <TextField
                          id="dob"
                          defaultValue={this.state.updateenddate}
                          onChange={(event) => {
                            this.setState({
                              updateenddate: event.target.value,
                            });
                          }}
                          type="month"
                          fullWidth
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          id="updatereason"
                          label="Update Reason*"
                          helperText="update reason should be less than 250 characters"
                          // defaultValue={this.state.result[this.state.selectedIndex].idSource}
                          onChange={(event) => {
                            this.setState(
                              { updatereason: event.target.value },
                              this.reasonforupdatevalidcheck(event)
                            );
                          }}
                          type="text"
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  </DialogContent>

                  <DialogActions style={{ padding: 15 }}>
                    <Button
                      disabled={this.state.buttondisabled}
                      color="primary"
                      variant="contained"
                      onClick={() => {
                        this.updateidentites(
                          this.state.result[this.state.selectedIndex].idSource
                        );
                      }}
                    >
                      Update
                    </Button>
                    <Button
                      color="secondary"
                      variant="contained"
                      onClick={() =>
                        this.setState({
                          updateDialogOpen: false,
                          selectedIndex: -1,
                        })
                      }
                    >
                      Cancel
                    </Button>
                  </DialogActions>
                </Dialog>
              )}
            </TableContainer>
          </>
        )}

        <Dialog
          // fullWidth={"md"}
          maxWidth={"md"}
          open={this.state.historyDialogeOpen}
          onClose={() => this.setState({ historyDialogeOpen: false })}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="form-dialog-title" align="center">
            Identities History
          </DialogTitle>
          {/* <DialogContent> */}
          <TableContainer p={3}>
            <Table stickyHeader>
              <TableHead>
                <TableRow style={{ backgroundColor: "black" }}>
                  {[
                    "Full Name",
                    "Date of Birth",
                    "Sex",
                    "Id Source",
                    "Id Number",
                    "Records Updated Date",
                    "Update Reason",
                  ].map((text, index) => (
                    <TableCell
                      style={{ fontWeight: "bolder" }}
                      align="center"
                      key={index}
                    >
                      {text}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              {this.state.historyloading ? (
                this.isloading()
              ) : (
                <TableBody>
                  {history.map((row, index) => (
                    <TableRow key={row.id}>
                      <TableCell align="center">{row.fullname}</TableCell>
                      <TableCell align="center">{row.dob}</TableCell>
                      <TableCell align="center">{row.sex}</TableCell>
                      <TableCell align="center">
                        {row.idSource_name_field}
                      </TableCell>
                      <TableCell align="center">{row.idNumber}</TableCell>
                      <TableCell component="th" align="center">
                        {new Date(row.created_on).toDateString()}
                      </TableCell>
                      <TableCell align="center">{row.update_reason}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              )}
            </Table>
          </TableContainer>
          {/* </DialogContent> */}
          <DialogActions style={{ padding: 15 }}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() =>
                this.setState({ historyDialogeOpen: false, selectedIndex: -1 })
              }
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }

  getIdentity() {
    return (
      <>
        {this.state.result.length === 0 ? (
          this.adddata()
        ) : (
          <>
            <Grid
              container
              direction="column"
              justify="center"
              spacing={2}
              style={{ marginTop: 10 }}
            >
              {this.state.identityDisplayWindow
                ? this.allIdentityDisplayWindow()
                : null}
              {this.state.addDialogOpen ? this.addNewIdentityWindow() : null}
              {this.state.updateDialogOpen
                ? this.editIdentityWindow(this.state.selectedIndex)
                : null}
              {this.state.historyWindowOpen
                ? this.viewHistoryWindow(this.state.selectedIndex)
                : null}
              {this.state.viewIDWindow ? this.viewIDRecord() : null}
              {/* {this.viewHistoryWindow(this.state.selectedIndex)} */}
            </Grid>

            {this.updatesnackbar()}
            {this.addsnackbar()}
          </>
        )}
      </>
    );
  }

  allIdentityDisplayWindow() {
    return (
      <Grid
        item
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
        spacing={3}
        // style={{marginTop:20}}
      >
        <Grid item container xs={12} justify="flex-end">
          <Button
            size="large"
            color="primary"
            variant="contained"
            startIcon={<AddCircleOutlineIcon />}
            onClick={() =>
              this.setState({
                addDialogOpen: !this.state.addDialogOpen,
                identityDisplayWindow: !this.state.identityDisplayWindow,
              })
            }
          >
            Add New Identity
            {/* Add New address */}
          </Button>
        </Grid>

        {this.state.result.map((row, index) => (
          <Grid
            item
            container
            direction="row"
            justify="center"
            alignItems="center"
            xs={12}
            md={4}
          >
            <Card>
              <Grid container style={{ padding: 20 }} spacing={2}>
                <Grid
                  item
                  container
                  alignItems="center"
                  justify="space-between"
                  direction="row"
                  style={{ background: "#263238" }}
                >
                  <Grid
                    item
                    container
                    alignItems="center"
                    justify="flex-end"
                    direction="row"
                    spacing={1}
                  >
                    {/* <Grid item>
                      <Typography variant='h4' style={{ color: "white" }}>
                        Identity&nbsp;{this.state.identityCardNumber++}
                      </Typography>
                    </Grid> */}

                    <Grid item>
                      <IconButton aria-label="upload picture" component="span">
                        <HistoryIcon
                          fontSize="large"
                          style={{ color: "white" }}
                          onClick={() => {
                            this.setState({
                              identityDisplayWindow: !this.state
                                .identityDisplayWindow,
                              historyWindowOpen: !this.state.historyWindowOpen,
                            });
                            this.getHistory(row.idSource);
                          }}
                        />
                      </IconButton>

                      <IconButton
                        aria-label="upload picture"
                        component="span"
                        onClick={() =>
                          this.setState({
                            identityDisplayWindow: !this.state
                              .identityDisplayWindow,
                            updateDialogOpen: !this.state.updateDialogOpen,
                            selectedIndex: index,
                            updateFullName: this.state.result[index].fullname,
                            updatedob: this.state.result[index].dob,
                            updatesex: this.state.result[index].sex,
                            updatestartdate: this.state.result[
                              index
                            ].issueDate.substring(
                              0,
                              this.state.result[index].issueDate.length - 3
                            ),
                            updateenddate: this.state.result[
                              index
                            ].expiryDate.substring(
                              0,
                              this.state.result[index].expiryDate.length - 3
                            ),
                            updateidnumber: this.state.result[index].idNumber,
                            updateidSource: this.state.result[index].idSource,
                          })
                        }
                      >
                        <EditIcon fontSize="large" style={{ color: "white" }} />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <List>
                    <ListItem>
                      <ListItemText
                        primary="Full Name"
                        secondary={row.fullname}
                      />
                    </ListItem>
                    <Divider />

                    <ListItem>
                      <ListItemText
                        primary="Date of Birth"
                        secondary={row.dob}
                      />
                    </ListItem>
                    <Divider />

                    <ListItem>
                      <ListItemText primary="Sex" secondary={row.sex} />
                    </ListItem>
                    <Divider />

                    <ListItem>
                      <ListItemText
                        primary="Identity Source"
                        secondary={row.idSource_name_field}
                      />
                    </ListItem>
                    <Divider />

                    <ListItem>
                      <ListItemText
                        primary="Identity Number"
                        secondary={row.idNumber}
                      />
                    </ListItem>
                    <Divider />

                    <ListItem>
                      <ListItemText
                        primary="Verified By"
                        secondary={row.owner_name_field}
                      />
                    </ListItem>
                    <Divider />

                    <ListItem>
                      <ListItemText
                        primary="Created Date"
                        secondary={new Date(row.created_on).toDateString()}
                      />
                    </ListItem>
                    <Divider />
                  </List>
                </Grid>

                {this.state.uploadDialogOpen &&
                this.state.selectedIndex === index ? (
                  <Paper variant="outlined">
                    <Grid
                      item
                      container
                      xs={12}
                      justify="center"
                      alignItems="center"
                      direction="row"
                      spacing={2}
                      style={{ padding: 10 }}
                    >
                      <Grid item container justify="center" xs={12}>
                        <Grid>
                          <Typography variant="subtitle1">
                            Choose your Image.
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid item container justify="center" xs={12}>
                        <TextField
                          fullWidth
                          type="file"
                          onChange={(event) => {
                            this.setState({
                              uploadpictures: event.target.files[0],
                            });
                          }}
                        ></TextField>
                      </Grid>

                      <Grid item container justify="center" xs={12} md={6}>
                        <Button
                          variant="contained"
                          color="Primary"
                          onClick={() => {
                            if (this.state.uploadpictures === "") {
                              this.setState({ pictureSnackbarError: true });
                            } else {
                              this.setState({ pictureSnackbarOpen: true });
                              this.postpictures(this.state.pictureid);
                            }
                            this.setState({
                              uploadpictures: "",
                              uploadDialogOpen: !this.state.uploadDialogOpen,
                            });
                          }}
                        >
                          Upload
                        </Button>
                      </Grid>

                      <Grid item container justify="center" xs={12} md={6}>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() =>
                            this.setState({
                              uploadDialogOpen: !this.state.uploadDialogOpen,
                            })
                          }
                        >
                          Cancel
                        </Button>
                      </Grid>
                    </Grid>
                  </Paper>
                ) : (
                  <Grid item container xs={12} justify="center">
                    <Button
                      variant="contained"
                      color="primary"
                      size="medium"
                      // disabled={!row.showVerifyOnTrac_btn}
                      onClick={() =>
                        this.setState({
                          uploadDialogOpen: !this.state.uploadDialogOpen,
                          pictureid: row.id,
                          selectedIndex: index,
                        })
                      }
                    >
                      Upload ID Image
                    </Button>
                  </Grid>
                )}

                <Grid item container xs={12} justify="center">
                  <Button
                    variant="contained"
                    color="primary"
                    size="medium"
                    // disabled={!row.showVerifyOnTrac_btn}
                    onClick={() => {
                      this.setState({
                        viewIDWindow: !this.viewIDWindow,
                        identityDisplayWindow: !this.state
                          .identityDisplayWindow,
                        selectedIndex: index,
                      });
                      this.getpictures(row.idSource);
                    }}
                  >
                    View ID Image
                  </Button>
                </Grid>

                {this.state.walletLogic &&
                this.state.selectedIndex === index ? (
                  <Paper variant="outlined">
                    <Grid
                      item
                      container
                      xs={12}
                      justify="center"
                      alignItems="center"
                      direction="row"
                      spacing={2}
                      style={{ padding: 10 }}
                    >
                      <Grid item container justify="center" xs={12}>
                        <Grid>
                          <Typography variant="subtitle1">
                            You need to pay&nbsp;{this.state.amount}&nbsp;for
                            this service from your wallet, do you want to
                            continue?
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid item container justify="center" xs={12} md={6}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() =>
                            this.setState(
                              {
                                walletLogic: false,
                                selectedIndex: index,
                              },
                              this.pay
                            )
                          }
                        >
                          Yes
                        </Button>
                      </Grid>

                      <Grid item container justify="center" xs={12} md={6}>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() =>
                            this.setState({
                              walletLogic: false,
                            })
                          }
                        >
                          No
                        </Button>
                      </Grid>
                    </Grid>
                  </Paper>
                ) : (
                  <Grid item container xs={12} justify="center">
                    <Button
                      variant="contained"
                      color="primary"
                      size="medium"
                      disabled={!row.showVerifyOnTrac_btn}
                      onClick={() => {
                        this.setState({
                          walletLogic: true,
                          currentid: row.id,
                          selectedIndex: index,
                        });
                        this.getamount();
                      }}
                    >
                      Request for Verification
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Card>
          </Grid>
        ))}
      </Grid>
    );
  }

  addNewIdentityWindow() {
    return (
      <Grid container spacing={3}>
        {this.state.result.length === 0 ? (
          <div />
        ) : (
          <Grid item container xs={12} justify="flex-end">
            <Button
              size="large"
              color="primary"
              variant="contained"
              startIcon={<ArrowBackIcon />}
              onClick={() =>
                this.setState({
                  addDialogOpen: !this.state.addDialogOpen,
                  identityDisplayWindow: !this.state.identityDisplayWindow,
                })
              }
            >
              Back
            </Button>
          </Grid>
        )}

        <Grid item xs={12}>
          <Card>
            <Grid
              container
              justify="center"
              direction="row"
              alignItems="center"
              spacing={3}
              style={{ padding: 20 }}
            >
              <Grid item xs={12} style={{ background: "#263238" }}>
                <Typography variant="h5" style={{ color: "white" }}>
                  Add New Identity
                </Typography>
              </Grid>

              <Grid item fullWidth xs={12}>
                <TextField
                  error={this.state.fullnameCheck}
                  required
                  id="full Name"
                  label="Full Name as appears on Id"
                  value={this.state.fullName}
                  onChange={(event) => {
                    this.setState({ fullName: event.target.value });
                  }}
                  type="text"
                  fullWidth
                />
              </Grid>

              <Grid item fullWidth xs={12}>
                <FormControl fullWidth required error={this.state.genderCheck}>
                  <InputLabel id="gender">Gender</InputLabel>
                  <Select
                    labelId="gender"
                    id="gender"
                    // value={age}
                    onChange={(event) => {
                      this.setState({ sex: event.target.value });
                    }}
                  >
                    <MenuItem value={"Male"}>Male</MenuItem>
                    <MenuItem value={"Female"}>Female</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item fullWidth xs={12}>
                <TextField
                  error={this.state.idNumberCheck}
                  required
                  id="idNumber"
                  label="Id Number"
                  // defaultValue={result[this.state.selectedIndex].surname}
                  onChange={(event) => {
                    this.setState({ idNumber: event.target.value });
                  }}
                  type="text"
                  fullWidth
                />
              </Grid>

              <Grid item fullWidth xs={12}>
                <FormControl
                  fullWidth
                  required
                  error={this.state.idSourceCheck}
                >
                  <InputLabel id="idSource">Id Source</InputLabel>
                  <Select
                    labelId="idSource"
                    id="idSource"
                    // value={age}
                    onChange={(event) => {
                      this.setState({ selectedidSource: event.target.value });
                    }}
                  >
                    {this.state.idSource.map((source) => (
                      <MenuItem
                        id={source.id}
                        value={source.id}
                        key={source.id}
                      >
                        {source.idSource}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item fullWidth xs={12}>
                <InputLabel id="dob">Date of Birth*</InputLabel>
                <TextField
                  error={this.state.dobCheck}
                  // required
                  id="dob"
                  // variant="outlined"
                  // label="Date of birth"
                  onChange={(event) => {
                    this.setState({ dob: event.target.value });
                  }}
                  type="date"
                  fullWidth
                />
              </Grid>
              <Grid item fullWidth xs={12}>
                {/* <InputLabel id="dob">Date of birth</InputLabel> */}
                <InputLabel id="dob">Issue Date</InputLabel>
                <TextField
                  id="dob"
                  onChange={(event) => {
                    this.setState({ startdate: event.target.value });
                  }}
                  type="month"
                  fullWidth
                />
              </Grid>
              <Grid item fullWidth xs={12}>
                {/* <InputLabel id="dob">Date of birth</InputLabel> */}
                <InputLabel id="dob">Expiry Date</InputLabel>
                <TextField
                  id="dob"
                  onChange={(event) => {
                    this.setState({ enddate: event.target.value });
                  }}
                  type="month"
                  fullWidth
                />
              </Grid>

              <Grid item>
                <Button
                  color="primary"
                  variant="contained"
                  size="large"
                  onClick={() => {
                    this.postidentities();
                  }}
                  startIcon={<PublishIcon />}
                >
                  Submit Identity
                </Button>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    );
  }

  editIdentityWindow(index) {
    return (
      <Grid container spacing={3}>
        <Grid item container xs={12} justify="flex-end">
          <Button
            size="large"
            color="primary"
            variant="contained"
            startIcon={<ArrowBackIcon />}
            onClick={() =>
              this.setState({
                updateDialogOpen: !this.state.updateDialogOpen,
                identityDisplayWindow: !this.state.identityDisplayWindow,
              })
            }
          >
            Back
          </Button>
        </Grid>
        <Card>
          <Grid
            container
            justify="center"
            direction="row"
            alignItems="center"
            spacing={3}
            style={{ padding: 20 }}
          >
            <Grid item xs={12} style={{ background: "#263238" }}>
              <Typography variant="h4" style={{ color: "white" }}>
                Edit Identity
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <TextField
                required
                id="fullName"
                label="Full name"
                defaultValue={this.state.updateFullName}
                onChange={(event) => {
                  this.setState({
                    updateFullName: event.target.value,
                  });
                }}
                type="text"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                id="fullName"
                label="IdNumber"
                defaultValue={this.state.updateidnumber}
                onChange={(event) => {
                  this.setState({
                    updateidnumber: event.target.value,
                  });
                }}
                type="text"
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              {/* <InputLabel id="dob">Date of birth</InputLabel> */}
              <TextField
                required
                id="dob"
                label="Date of Birth"
                defaultValue={this.state.updatedob}
                onChange={(event) => {
                  this.setState({ updatedob: event.target.value });
                }}
                type="date"
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth required>
                <InputLabel id="gender">Gender</InputLabel>
                <Select
                  labelId="gender"
                  id="gender"
                  // value={age}
                  defaultValue={this.state.updatesex}
                  onChange={(event) => {
                    this.setState({ updatesex: event.target.value });
                  }}
                >
                  <MenuItem value={"Male"}>Male</MenuItem>
                  <MenuItem value={"Female"}>Female</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {/* <Grid item fullWidth xs={12}>
                      <Select
                        id="idSource"
                        label="Id Source"
                        defaultValue={this.state.updateidSource}
                        onChange={(event) => {
                          this.setState({ updateidSource: event.target.value });
                        }}
                        type="text"
                        fullWidth
                      >
                      {this.state.idSource.map((source) => (
                        <MenuItem id={source.id} value={source.id}>
                          {source.idSource}
                        </MenuItem>
                      ))}</Select>
                        </Grid> */}
            <Grid item xs={12}>
              {/* <InputLabel id="dob">Date of birth</InputLabel> */}
              <InputLabel id="dob">Start Date*</InputLabel>
              <TextField
                id="dob"
                defaultValue={this.state.updatestartdate}
                onChange={(event) => {
                  this.setState({
                    updatestartdate: event.target.value,
                  });
                }}
                type="month"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              {/* <InputLabel id="dob">Date of birth</InputLabel> */}
              <InputLabel id="dob">End Date*</InputLabel>
              <TextField
                id="dob"
                defaultValue={this.state.updateenddate}
                onChange={(event) => {
                  this.setState({
                    updateenddate: event.target.value,
                  });
                }}
                type="month"
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                id="updatereason"
                label="Update Reason*"
                helperText="update reason should be less than 250 characters"
                // defaultValue={this.state.result[this.state.selectedIndex].idSource}
                onChange={(event) => {
                  this.setState(
                    { updatereason: event.target.value },
                    this.reasonforupdatevalidcheck(event)
                  );
                }}
                type="text"
                fullWidth
              />
            </Grid>

            <Grid item>
              <Button
                color="primary"
                variant="contained"
                size="large"
                disabled={this.state.buttondisabled}
                onClick={() => {
                  this.setState({
                    updateDialogOpen: !this.state.updateDialogOpen,
                    identityDisplayWindow: !this.state.identityDisplayWindow,
                  });
                  this.updateidentites(
                    this.state.result[this.state.selectedIndex].idSource
                  );
                }}
                startIcon={<SaveIcon />}
              >
                Save Identity
              </Button>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    );
  }

  viewHistoryWindow(index) {
    return (
      <>
        {this.state.isloading ? (
          this.isloading()
        ) : (
          <Grid container spacing={3}>
            <Grid item container xs={12} justify="flex-end">
              <Button
                size="large"
                color="primary"
                variant="contained"
                startIcon={<ArrowBackIcon />}
                onClick={() =>
                  this.setState({
                    viewIDWindow: !this.viewIDWindow,
                    identityDisplayWindow: !this.state.identityDisplayWindow,
                  })
                }
              >
                Back
              </Button>
            </Grid>
            <Card>
              <Grid
                container
                justify="center"
                direction="row"
                alignItems="center"
                spacing={3}
                style={{ padding: 20 }}
              >
                <Grid item xs={12} style={{ background: "#263238" }}>
                  <Typography variant="h4" style={{ color: "white" }}>
                    Edit History
                  </Typography>
                </Grid>
                {this.state.historyloading ? (
                  this.isloading()
                ) : (
                  <>
                    {history.map((row, index) => (
                      <>
                        <Grid
                          item
                          container
                          xs={12}
                          justify="space-between"
                          alignItems="center"
                        >
                          <Grid item>
                            <Typography variant="h6">
                              Record Edited on:&nbsp;&nbsp;
                              {new Date(row.created_on).toDateString()}
                            </Typography>
                          </Grid>
                        </Grid>

                        <Grid item xs={12}>
                          <TextField
                            size="small"
                            fullWidth
                            variant="outlined"
                            id="fullname"
                            label="Full Name"
                            defaultValue={row.fullname}
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            size="small"
                            variant="outlined"
                            fullWidth
                            id="dob"
                            label="Date of Birth"
                            defaultValue={row.dob}
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            size="small"
                            variant="outlined"
                            fullWidth
                            id="sex"
                            label="Sex"
                            defaultValue={row.sex}
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <TextField
                            size="small"
                            variant="outlined"
                            fullWidth
                            id="idSource"
                            label="ID Source"
                            defaultValue={row.idSource_name_field}
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <TextField
                            size="small"
                            variant="outlined"
                            fullWidth
                            id="idNumber"
                            label="ID Number"
                            defaultValue={row.idNumber}
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <TextField
                            size="small"
                            variant="outlined"
                            fullWidth
                            id="editReason"
                            label="Edit Reason"
                            defaultValue={row.update_reason}
                            InputProps={{
                              readOnly: true,
                            }}
                            multiline
                            rows={3}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <Divider />
                        </Grid>
                      </>
                    ))}
                  </>
                )}
              </Grid>
            </Card>
          </Grid>
        )}
      </>
    );
  }

  viewIDRecord() {
    return (
      <Grid container spacing={3}>
        <Grid item container xs={12} justify="flex-end">
          <Button
            size="large"
            color="primary"
            variant="contained"
            startIcon={<ArrowBackIcon />}
            onClick={() =>
              this.setState({
                viewIDWindow: !this.state.viewIDWindow,
                identityDisplayWindow: !this.state.identityDisplayWindow,
              })
            }
          >
            Back
          </Button>
        </Grid>
        <Card>
          <Grid
            container
            justify="center"
            direction="row"
            alignItems="center"
            spacing={3}
            style={{ padding: 20 }}
          >
            <Grid item xs={12} style={{ background: "#263238" }}>
              <Typography variant="h4" style={{ color: "white" }}>
                View Academic Record
              </Typography>
            </Grid>

            <Grid
              container
              direction="row"
              justify="space-evenly"
              alignItems="center"
            >
              <GridList
                cellHeight={160}
                style={{ height: 500, width: 500 }}
                cols={3}
              >
                {this.state.pictureloading
                  ? this.isloading()
                  : pictures.map((pic, index) => (
                      <GridListTile key={pic.id} cols={1}>
                        <img alt="" src={pic.picture} />
                      </GridListTile>

                      // <Grid container>
                      //     <Grid item xs={12}>
                      // <image src={pic.picture}/>
                      //       {/* {pic.picture} */}
                      //     </Grid>
                      //   </Grid>
                    ))}{" "}
              </GridList>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    );
  }
}

export default Identities;
