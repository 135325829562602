import React, { Component } from 'react'
import {
    Grid,
    Typography,
    CircularProgress
} from '@material-ui/core/';
import axios from "axios";
import Avatar from "@material-ui/core/Avatar";
import EventTwoToneIcon from '@material-ui/icons/EventTwoTone';
import MailOutlineTwoToneIcon from '@material-ui/icons/MailOutlineTwoTone';
import BrandingWatermarkIcon from '@material-ui/icons/BrandingWatermark';

let token = "";
let id = "";
// let ontracid="";
class index extends Component {
    constructor(props) {
        super(props)

        this.state = {
            result: [],
            loading: false,

        }
    }

    async componentDidMount() {
        this.setState({ loading: true });
        token = localStorage.getItem("Token");
        id = localStorage.getItem("id");
        // ontracid = localStorage.getItem("ontrac_id");
        await axios
            .get("http://3.22.17.212:9000/api/v1/employees/" + id + "/profiles-by/" + id, {
                headers: {
                    Authorization: token,
                },
            })
            .then((res) => {
                // result = res.data;
                this.setState({ result: res.data });
            });
        this.setState({ loading: false });
    }

    render() {


        return (
            <Grid style={{ marginTop: 10 }}>
                {this.state.loading ? (
                    <Grid container justify="center" alignItems="center">
                        <Grid item>
                            <CircularProgress />
                        </Grid>
                    </Grid>
                ) : this.state.result.length === 0 ? (
                    <Grid container direction="row" justify="center" alignItems="center">
                        <Typography>Add your profile data</Typography>
                    </Grid>
                ) : (
                            <>
                                <Grid
                                    container
                                    direction="column"
                                    justify="space-between"
                                    alignItems="center"
                                >

                                    <Grid container style={{ padding: 10 }} alignItems="center">

                                        <Grid item xs={1}>
                                            <EventTwoToneIcon style = {{color: "#6d6966"}}/>
                                        </Grid>
                                        <Grid item xs={11}>
                                            <Grid container justify="space-between">
                                            <Typography component={"span"} style = {{color: "#6d6966"}}>
                                                Date of Birth
                                                </Typography>
                                            <Typography component={"span"}>
                                                {new Date(this.state.result[0].dob).toDateString()}
                                                </Typography>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={1}>
                                            <MailOutlineTwoToneIcon style = {{color: "#6d6966"}}/>
                                        </Grid>
                                        <Grid item xs={11}>
                                            <Grid container justify="space-between">
                                            <Typography component={"span"} style = {{color: "#6d6966"}}>
                                                Email
                                                </Typography>
                                            <Typography component={"span"}>
                                                {this.state.result[0].employee_email_field}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <BrandingWatermarkIcon style = {{color: "#6d6966"}}/>
                                        </Grid>
                                        <Grid item xs={11}>
                                            <Grid container justify="space-between">
                                            <Typography component={"span"} style = {{color: "#6d6966"}}>
                                                Ontrac ID
                                                </Typography>
                                            <Typography component={"span"}>
                                                {this.state.result[0].ontrac_id}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </>
                        )}
            </Grid>
        );
    }
}

export default (index);
