import React from "react";
import { Paper, Grid, Typography, Avatar,Box } from "@material-ui/core/";

function Comments(props) {
  return (
    <div>
      {/* <Grid container justify='space-between' alignItems='center' spacing={4}>
                    <Grid item xs={8}>
                        <Typography variant='h4'>
                            Comments
                        </Typography>
                    </Grid>
                </Grid> */}

      {props.data.length === 0 ? (
        <h2>No Comments</h2>
      ) : (
        props.data.map((comment) => (
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item xs={12} md={12} lg={12} xl={12}>
              <Paper variant="outlined" style={{ padding: 10, marginTop: 20 }}>
                <Grid
                  container
                  direction="row"
                  justify="flex-start"
                  alignItems="center"
                >
                  <Grid item xs={1}>
                    <Avatar />
                  </Grid>
                  <Grid item xs={9}>
                    <Box pl={1}>
                      <Grid
                        container
                        direction="column"
                        justify="flex-start"
                        alignItems="flex-start"
                      >
                        <Grid item xs={12}>
                          <Typography component={"span"} variant="h6">
                            {comment.company_name_field}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography component={"span"}>
                            {comment.comment}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography component={"span"} variant="subtitle2">
                      {new Date(comment.created_on).toDateString()}
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        ))
      )}
    </div>
  );
}

export default Comments;
