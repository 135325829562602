import React from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Onboarding from "./onboarding";
import Comments from "./Comments";
import Employerlist from "./employerList";
import Acessrequest from "../AccessCodes/index";
import { ButtonGroup, Button, Typography, Grid } from "@material-ui/core";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: theme.palette.background.paper,
    // width: 500,
  },
}));

export default function TabsEmployment(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [acessrequest, setAcessrequest] = React.useState(true);
  const [employmentrequest, setEmploymentrequest] = React.useState(false);
  const [comment, setComment] = React.useState(false);
  const [employerlist, setEmployerlist] = React.useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };
  const handleAcessrequest = () => {
    setAcessrequest(true);
    setEmploymentrequest(false);
    setComment(false);
    setEmployerlist(false);
  };
  const handleEmployeeCodes = () => {
    setAcessrequest(false);
    setEmploymentrequest(true);
    setComment(false);
    setEmployerlist(false);
  };
  const handlecomment = () => {
    setAcessrequest(false);
    setEmploymentrequest(false);
    setComment(true);
    setEmployerlist(false);
  };
  const handleemployerlist = () => {
    setAcessrequest(false);
    setEmploymentrequest(false);
    setComment(false);
    setEmployerlist(true);
  };

  return (
    <div className={classes.root}>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <Box p={2}>
          <Typography variant="h4" align="center" justify="center">
            Codes Request
          </Typography>
        </Box>
        <ButtonGroup
          // variant="text"
          color="primary"
          aria-label="contained primary button group"
          style={{ padding: 10 }}
        >
          <Button
            // disableElevation={false}
            // disabled={messagesdisplay}
            variant={acessrequest === true ? "contained" : null}
            onClick={() => handleAcessrequest()}
          >
            Acess Codes
          </Button>
          <Button
            // disabled={acesscodesdisplay}
            variant={employmentrequest === true ? "contained" : null}
            onClick={() => handleEmployeeCodes()}
          >
            Employement Codes
          </Button>
          <Button
            variant={comment === true ? "contained" : null}
            // disabled={employmentcodesdisplay}
            onClick={() => handlecomment()}
          >
            Comment
          </Button>
          <Button
            variant={employerlist === true ? "contained" : null}
            // disabled={employmentcodesdisplay}
            onClick={() => handleemployerlist()}
          >
            Employer List
          </Button>
        </ButtonGroup>
      </Grid>
      {acessrequest === true ? <Acessrequest /> : null}
      {employmentrequest === true ? (
        <Onboarding data={props.Onboarding} refresh={props.refresh} />
      ) : null}
      {comment === true ? <Comments data={props.comments} /> : null}
      {employerlist === true ? (
        <Employerlist
          data={props.employerdata}
          refresh={props.employerrefresh}
        />
      ) : null}
     
    </div>
  );
}
