import React, { Component } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import FormHelperText from "@material-ui/core/FormHelperText";

import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import axios from "axios";
import {
  CircularProgress,
  Typography,
  Card,
  IconButton,
  Chip,
  List,
  ListItem,
  ListItemText,
  Divider,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import PublishIcon from "@material-ui/icons/Publish";
import SaveIcon from "@material-ui/icons/Save";
import HistoryIcon from "@material-ui/icons/History";
import EditIcon from "@material-ui/icons/Edit";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import Box from "@material-ui/core/Box";

import { Button } from "@material-ui/core";
import { Select } from "@material-ui/core";
import { MenuItem } from "@material-ui/core";
import { InputLabel } from "@material-ui/core";
import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

let token = "";
let id = "";
// let result = [];
let history = [];
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
class Phones extends Component {
  constructor(props) {
    super(props);

    this.state = {
      addDialogOpen: false,
      updateDialogOpen: false,
      selectedIndex: -1,
      phoneReason: "",
      phoneType: "",
      defaultPhone: "",
      phoneNumber: "",
      imeiNumber: "",
      startedUsingOn: "",
      updateReason: "",
      phoneReasons: "",
      PhoneTypes: "",
      phoneTypeOther: "",
      phoneReasonOther: "",
      loading: true,
      updatephoneType: "",
      updatephoneTypeother: "",
      updatephoneReason: "",
      updatephoneReasonother: "",
      updateimeiNumber: "",
      updatephoneNumber: "",
      updatestartedusingon: "",
      updatedefaultPhone: "",
      historyloading: true,
      historyDialougeOpen: false,
      result: [],
      buttondisabled: false,
      addsnackbar: false,
      addresponse: "",
      updateresponse: "",
      updatesnackbar: false,
      walletdialog: false,
      phoneDisplayWindow: true,
      historyWindowOpen: false,
      walletLogic: false,
      addWindow: false,
    };
  }
  reasonforupdatevalidcheck = (event) => {
    if (event.target.value.length > 0) {
      //  console.log(event.target.value);
      this.setState({ buttondisabled: "" });
    } else if (event.target.value > 250) {
      this.setState({ buttondisabled: "disabled" });
    } else {
      this.setState({ buttondisabled: "disabled" });
    }
  };
  async getphonedata() {
    await axios
      .get("http://3.22.17.212:9000/api/v1/employees/" + id + "/phones", {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        //  result = res.data;
        this.setState({ result: res.data });
        console.table("Phones", this.state.result);
        // console.log(result[0].phone_reason);
      });
  }
  async componentDidMount() {
    token = localStorage.getItem("Token");
    id = localStorage.getItem("id");
    await this.getphonedata();
    await axios
      .get("http://3.22.17.212:9000/api/v1/resManager/phone/reasons/", {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        this.setState({ phoneReasons: res.data });
        console.table("PhonesReason", this.state.phoneReasons);
      });
    await axios
      .get("http://3.22.17.212:9000/api/v1/resManager/phone/types/", {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        this.setState({ phoneTypes: res.data });
        console.table("PhonesTypes", this.state.phoneTypes);
      });
    this.setState({ loading: false });
  }
  addsnackbar() {
    return this.state.addresponse === 200 ? (
      <div>
        <Snackbar
          open={this.state.addsnackbar}
          autoHideDuration={3000}
          onClick={() => this.setState({ addsnackbar: false })}
        >
          <Alert
            onClose={() => {
              this.setState({ addsnackbar: !this.state.addasnackbar });
            }}
            severity="success"
          >
            Phone added sucessfully
          </Alert>
        </Snackbar>
      </div>
    ) : (
      <Snackbar
        open={this.state.addsnackbar}
        autoHideDuration={3000}
        onClick={() => {
          this.setState({ addsnackbar: !this.state.addsnackbar });
        }}
      >
        <Alert
          onClose={() => {
            this.setState({ addsnackbar: !this.state.addsnackbar });
          }}
          severity="error"
        >
          Something went wrong please try again
        </Alert>
      </Snackbar>
    );
  }
  updatesnackbar() {
    return this.state.updateresponse === 200 ? (
      <div>
        {console.log("//////////////////////////////////////")}

        <Snackbar
          open={this.state.updatesnackbar}
          autoHideDuration={3000}
          onClick={() => this.setState({ updatesnackbar: false })}
        >
          <Alert
            onClose={() => {
              this.setState({ updatesnackbar: !this.state.updatesnackbar });
            }}
            severity="success"
          >
            Phone updated sucessfully
          </Alert>
        </Snackbar>
      </div>
    ) : (
      <Snackbar
        open={this.state.updatesnackbar}
        autoHideDuration={3000}
        onClick={() => {
          this.setState({ updatesnackbar: !this.state.updatesnackbar });
        }}
      >
        <Alert
          onClose={() => {
            this.setState({ updatesnackbar: !this.state.updatesnackbar });
          }}
          severity="error"
        >
          Something went wrong please try again
        </Alert>
      </Snackbar>
    );
  }
  async postPhones() {
    // await this.setState({startedUsingOn:this.state.startedUsingOn});
    if (
      this.state.phoneReason.length !== "" &&
      this.state.phoneType.length !== 0 &&
      this.state.defaultPhone.length !== 0 &&
      this.state.phoneNumber.length !== 0 &&
      this.state.startedUsingOn.length !== 0
    ) {
      let headers = {
        headers: {
          Authorization: token,
          "Content-Type": "multipart/form-data",
        },
      };
      let bodyFormData = new FormData();
      bodyFormData.append("employee", id);
      bodyFormData.append("phone_type", this.state.phoneType);
      bodyFormData.append("phone_type_other", this.state.phoneTypeOther);
      bodyFormData.append("phone_reason", this.state.phoneReason);
      bodyFormData.append("phone_reason_other", this.state.phoneReasonOther);
      bodyFormData.append("imeiNumber", this.state.imeiNumber);
      bodyFormData.append("phoneNumber", this.state.phoneNumber);
      bodyFormData.append("dateObtained", this.state.startedUsingOn + "-01");
      bodyFormData.append("default_phone", this.state.defaultPhone);

      await axios
        .post(
          "http://3.22.17.212:9000/api/v1/employees/post-phone",
          bodyFormData,
          headers
        )
        .then((response) => {
          console.log(response);
          this.setState({ addresponse: response.status, addsnackbar: true, addDialogOpen: !this.state.addDialogOpen, phoneDisplayWindow: !this.state.phoneDisplayWindow,});
        });
        // this.setState({
        //   addDialogOpen: !this.state.addDialogOpen,
        //   phoneDisplayWindow: !this.state.phoneDisplayWindow,
        // })
      await this.getphonedata();
    } else {
      if (this.state.phoneReason.length === 0) {
        this.setState({ phonereasonvalidate: true });
      }
      if (this.state.phoneType.length === 0) {
        this.setState({ phonetypevalidate: true });
      }
      if (this.state.defaultPhone.length === 0) {
        this.setState({ defaultphonevalidate: true });
      }
      if (this.state.phoneNumber.length === 0) {
        this.setState({ phonenumbervalidate: true });
      }
      if (this.state.startedUsingOn.length === 0) {
        this.setState({ startedusingonvalidate: true });
      }
    }
  }
  async getamount() {
    await axios
      .get(
        "http://3.22.17.212:9000/api/v1/resManager/serviceAPI/?serviceName=phoneVerification"
      )
      .then((res) => this.setState({ amount: res.data[0].serviceCharge }));
  }
  async pay() {
    let transactionid = Math.floor(
      10000000000000000 + Math.random() * 9000000000000000
    );
    let headers1 = {
      headers: {
        Authorization: token,
        "Content-Type": "multipart/form-data",
      },
    };

    await axios
      .post(
        "http://3.22.17.212:9000/wallet/transaction?type=DEBIT&amount=" +
          this.state.amount +
          "&description=" +
          transactionid,
        "",
        headers1
      )
      .then((response) => {
        if (response.status === 200) {
          this.senddetails(transactionid);
        }
      });
  }
  async senddetails(tid) {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", token);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      "http://3.22.17.212:9000/api/v1/accounts/paymentDetails?service=phoneVerification&trxId=" +
        tid +
        "&paymentMethod=CARD&amount=" +
        this.state.amount,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => this.verification())
      .catch((error) => console.log("error", error));
  }
  async verification() {
    let headers = {
      headers: {
        Authorization: token,
      },
    };
    let bodyFormData = {
      verType: "Phone",
      objId: this.state.currentid,
    };
    await axios
      .post(
        "http://3.22.17.212:9000/api/v1/codes/evaluation/new-code",
        bodyFormData,
        headers
      )
      .then((res) => {
        window.location.reload(false);
      });
  }
  async updatePhones(phoneid) {
    this.setState({
      updateDialogOpen: false,
      selectedIndex: -1,
    });
    let headers = {
      headers: {
        Authorization: token,
        "Content-Type": "multipart/form-data",
      },
    };
    let bodyFormData = new FormData();
    bodyFormData.append("employee", id);
    bodyFormData.append("phone_type", this.state.updatephoneType);
    bodyFormData.append("phone_type_other", this.state.updatephoneTypeother);
    bodyFormData.append("phone_reason", this.state.updatephoneReason);
    bodyFormData.append(
      "phone_reason_other",
      this.state.updatephoneReasonother
    );
    bodyFormData.append("imeiNumber", this.state.updateimeiNumber);
    bodyFormData.append("phoneNumber", this.state.updatephoneNumber);
    bodyFormData.append(
      "dateObtained",
      this.state.updatestartedusingon + "-01"
    );
    bodyFormData.append("default_phone", this.state.updatedefaultPhone);
    bodyFormData.append("update_reason", this.state.updateReason);

    await axios
      .post(
        "http://3.22.17.212:9000/api/v1/employees/update-phone/" + phoneid,
        bodyFormData,
        headers
      )
      .then((response) => {
        console.log(response);
        this.setState({
          updateresponse: response.status,
          updatesnackbar: true,
        });
      });
    await this.getphonedata();
  }
  async getHistory(index) {
    this.setState({
      historyDialougeOpen: true,
    });
    await axios
      .get(
        "http://3.22.17.212:9000/api/v1/employees/" +
          id +
          "/phones/" +
          index +
          "/history",
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((res) => {
        history = res.data;
        console.log("history", history);
        this.setState({ historyloading: false });
      });
  }
  getPhones() {
    return (
      <>
        {this.state.result.length === 0 ? (
          <>
            {this.state.addWindow ? (
              this.addNewPhoneWindow()
            ) : (
              <Grid container spacing={3} justify="space-between">
                <Grid item xs={12}>
                  <Paper style={{ padding: 20 }} elevation={3}>
                    <Box
                      p={1}
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="center"
                      style={{ height: "50vh" }}
                    >
                      <Typography variant="h4" gutterBottom align="center">
                        Add phone record to improve ratings
                      </Typography>

                      <Grid
                        container
                        justify="center"
                        style={{ marginTop: 50 }}
                      >
                        <Button
                          size="large"
                          startIcon={<AddCircleOutlineIcon />}
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            this.setState({ addWindow: !this.state.addWindow });
                          }}
                        >
                          Add Phone Record
                        </Button>
                      </Grid>
                    </Box>
                  </Paper>
                </Grid>
              </Grid>
            )}
          </>
        ) : (
          <>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="flex-end"
            >
              <Box p={2}>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      this.setState({ addDialogOpen: true });
                    }}
                  >
                    Add Phone
                  </Button>
                </Grid>
              </Box>
            </Grid>
            <TableContainer component={Paper} elevation={16}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow
                    style={{ backgroundColor: "black", fontWeight: "bolder" }}
                  >
                    {/* Date, Source, Fullname, DOB, Sex, Picture, VerifiedBy, Actions */}
                    <TableCell align="center" style={{ fontWeight: "bolder" }}>
                      Phone
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: "bolder" }}>
                      IMEI
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: "bolder" }}>
                      Default Phone
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: "bolder" }}>
                      Phone Type
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: "bolder" }}>
                      Verified By
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: "bolder" }}>
                      Start Date
                    </TableCell>
                    {/* <TableCell align="center">IdNumber</TableCell> */}
                    {/* <TableCell align="center">picture</TableCell> */}
                    <TableCell align="center" style={{ fontWeight: "bolder" }}>
                      Update
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: "bolder" }}>
                      History
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: "bolder" }}>
                      Verification
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.result.map((row, index) => (
                    <TableRow key={row.id}>
                      <TableCell align="center">{row.phoneNumber}</TableCell>
                      <TableCell align="center">{row.imeiNumber}</TableCell>
                      <TableCell align="center">{row.default_phone}</TableCell>
                      <TableCell align="center">
                        {row.phone_type_name_field}
                      </TableCell>

                      <TableCell align="center">
                        {row.owner_name_field}
                      </TableCell>
                      <TableCell align="center">
                        {row.dateObtained.substring(
                          0,
                          row.dateObtained.length - 3
                        )}
                      </TableCell>

                      <TableCell align="center">
                        <Button
                          disabled={row.status === "Audit In Progress"}
                          color="primary"
                          variant="outlined"
                          onClick={() =>
                            this.setState({
                              updateDialogOpen: true,
                              selectedIndex: index,

                              updatephoneType: this.state.result[index]
                                .phone_type,

                              updatephoneReason: this.state.result[index]
                                .phone_reason,

                              updateimeiNumber: this.state.result[index]
                                .imeiNumber,
                              updatephoneNumber: this.state.result[index]
                                .phoneNumber,
                              updatestartedusingon: this.state.result[
                                index
                              ].dateObtained.substring(
                                0,
                                this.state.result[index].dateObtained.length - 3
                              ),
                              updatedefaultPhone: this.state.result[index]
                                .default_phone,
                            })
                          }
                        >
                          Update
                        </Button>
                      </TableCell>
                      <TableCell align="center">
                        <Button
                          color="secondary"
                          variant="outlined"
                          onClick={() => {
                            this.getHistory(row.id);
                          }}
                        >
                          history
                        </Button>
                      </TableCell>
                      {row.showVerifyOnTrac_btn &&
                      this.state.selectedIndex === index ? (
                        <TableCell align="center">
                          <Button
                            variant="outlined"
                            color="default"
                            onClick={() => {
                              this.setState({
                                currentid: row.id,
                                walletdialog: true,
                                selectedIndex: index,
                              });
                              this.getamount();
                            }}
                          >
                            Request for verification
                          </Button>
                        </TableCell>
                      ) : (
                        <></>
                      )}
                    </TableRow>
                  ))}
                  {this.addsnackbar()}
                </TableBody>
              </Table>
              {this.state.selectedIndex === -1 ? (
                <div />
              ) : (
                <Dialog
                  open={this.state.updateDialogOpen}
                  onClose={() => this.setState({ updateDialogOpen: false })}
                  //  aria-labelledby="form-dialog-title"
                  aria-labelledby="responsive-dialog-title"
                >
                  <DialogTitle id="form-dialog-title" align="center">
                    Update phone data
                  </DialogTitle>

                  <DialogContent>
                    <Grid
                      container
                      justify="flex-start"
                      direction="row"
                      alignItems="center"
                      spacing={3}
                    >
                      <Grid item xs={12}>
                        <InputLabel>Phone Reason*</InputLabel>
                        <Select
                          // className="w3-input"
                          autoFocus
                          margin="dense"
                          id="source"
                          label="phonereason"
                          type="text"
                          fullWidth
                          onChange={(event) => {
                            this.setState({
                              updatephoneReason: event.target.value,
                            });
                          }}
                          defaultValue={this.state.updatephoneReason}
                        >
                          {this.state.phoneReasons.map((phonetype) => (
                            <MenuItem
                              id={phonetype.id}
                              value={phonetype.id}
                              key={phonetype.id}
                            >
                              {phonetype.phoneReason}
                            </MenuItem>
                          ))}
                        </Select>
                      </Grid>
                      <Grid item xs={12}>
                        <InputLabel>Phone Type*</InputLabel>
                        <Select
                          autoFocus
                          margin="dense"
                          id="id"
                          label="phone type"
                          type="text"
                          fullWidth
                          onChange={(event) => {
                            this.setState({
                              updatephoneType: event.target.value,
                            });
                          }}
                          defaultValue={this.state.updatephoneType}
                        >
                          {this.state.phoneTypes.map((phonetype) => (
                            <MenuItem
                              id={phonetype.id}
                              value={phonetype.id}
                              key={phonetype.id}
                            >
                              {phonetype.phoneType}
                            </MenuItem>
                          ))}
                        </Select>
                      </Grid>
                      <Grid item xs={12}>
                        <InputLabel>Default Phone*</InputLabel>
                        <Select
                          autoFocus
                          margin="dense"
                          id="name"
                          label=""
                          type="text"
                          fullWidth
                          onChange={(event) => {
                            this.setState({
                              updatedefaultPhone: event.target.value,
                            });
                          }}
                          defaultValue={this.state.updatedefaultPhone}
                        >
                          <MenuItem id={1} value="Yes">
                            Yes
                          </MenuItem>
                          <MenuItem id={2} value="No">
                            No
                          </MenuItem>
                        </Select>
                      </Grid>
                      <Grid item xs={12}>
                        <InputLabel>Phone Number*</InputLabel>
                        <TextField
                          autoFocus
                          margin="dense"
                          id="dob"
                          label=""
                          type="text"
                          fullWidth
                          onChange={(event) => {
                            this.setState({
                              updatephoneNumber: event.target.value,
                            });
                          }}
                          defaultValue={this.state.updatephoneNumber}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <InputLabel>IMEI Number</InputLabel>
                        <TextField
                          autoFocus
                          margin="dense"
                          id="dob"
                          label=""
                          type="text"
                          fullWidth
                          onChange={(event) => {
                            this.setState({
                              updateimeiNumber: event.target.value,
                            });
                          }}
                          defaultValue={this.state.updateimeiNumber}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <InputLabel>Started Using On*</InputLabel>
                        <TextField
                          autoFocus
                          margin="dense"
                          id="dob"
                          label=""
                          type="month"
                          fullWidth
                          onChange={(event) => {
                            this.setState({
                              updatestartedusingon: event.target.value,
                            });
                          }}
                          defaultValue={this.state.updatestartedusingon}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <InputLabel>Update Reason</InputLabel>
                        <TextField
                          autoFocus
                          margin="dense"
                          id="dob"
                          label=""
                          type="text"
                          helperText="Update reason must be less than 250 characters"
                          onChange={(event) => {
                            this.setState(
                              {
                                updateReason: event.target.value,
                              },
                              this.reasonforupdatevalidcheck(event)
                            );
                          }}
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      color="primary"
                      variant="contained"
                      disabled={this.state.buttondisabled}
                      onClick={() => {
                        this.updatePhones(
                          this.state.result[this.state.selectedIndex].id
                        );
                      }}
                    >
                      Update
                    </Button>
                    <Button
                      color="secondary"
                      variant="contained"
                      onClick={() =>
                        this.setState({
                          updateDialogOpen: false,
                          selectedIndex: -1,
                        })
                      }
                    >
                      Cancel
                    </Button>
                  </DialogActions>
                </Dialog>
              )}
              {this.updatesnackbar()}
            </TableContainer>
            {
              <Dialog
                // open={this.state.walletdialog}
                // onClose={() => this.setState({ walletdialog: false })}
                aria-labelledby="form-dialog-title"
              >
                <DialogTitle
                  id="form-dialog-title"
                  align="center"
                  justify="center"
                >
                  You need to pay {this.state.amount} for this service from
                  wallet
                </DialogTitle>
                <DialogContent></DialogContent>
                <DialogActions>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() =>
                      this.setState(
                        {
                          walletdialog: false,
                        },
                        this.pay
                      )
                    }
                  >
                    Pay
                  </Button>
                  <Button
                    color="secondary"
                    variant="contained"
                    onClick={() =>
                      this.setState({
                        walletdialog: false,
                      })
                    }
                  >
                    Cancel
                  </Button>
                </DialogActions>
              </Dialog>
            }
          </>
        )}
        {/* <Dialog
          open={this.state.addDialogOpen}
          onClose={() => this.setState({ addDialogOpen: false })}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title" align="center">
            Add new phone data
          </DialogTitle>

          <DialogContent>
            <Grid container spacing={3} justify="center" alignItems="center">
              <Grid item xs={12}>
                <InputLabel>Phone Reason*</InputLabel>
                <Select
                  required
                  autoFocus
                  margin="dense"
                  id="source"
                  label="fullname"
                  type="text"
                  fullWidth
                  onChange={(event) => {
                    this.setState({ phoneReason: event.target.value });
                  }}
                  //   defaultValue={this.state.result[this.state.selectedIndex].fullname}
                >
                  {this.state.phoneReasons.map((phonetype, index) => (
                    <MenuItem
                      id={phonetype.id}
                      value={phonetype.id}
                      key={index}
                    >
                      {phonetype.phoneReason}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12}>
                <InputLabel>Phone Type*</InputLabel>
                <Select
                  required
                  fullWidth
                  onChange={(event) => {
                    this.setState({ phoneType: event.target.value });
                  }}
                  //   defaultValue={this.state.result[this.state.selectedIndex].dob}
                >
                  {this.state.phoneTypes.map((phonetype) => (
                    <MenuItem
                      id={phonetype.id}
                      value={phonetype.id}
                      key={phonetype.id}
                    >
                      {phonetype.phoneType}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>

              <Grid item xs={12}>
                <InputLabel>DefaultPhone*</InputLabel>
                <Select
                  required
                  fullWidth
                  onChange={(event) => {
                    this.setState({ defaultPhone: event.target.value });
                  }}

                  //   defaultValue={this.state.result[this.state.selectedIndex].sex}
                >
                  <MenuItem id={1} value="Yes">
                    Yes
                  </MenuItem>
                  <MenuItem id={2} value="No">
                    No
                  </MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12}>
                <InputLabel>Phone number*</InputLabel>
                <TextField
                  required
                  autoFocus
                  margin="dense"
                  id="dob"
                  label=""
                  type="text"
                  fullWidth
                  onChange={(event) => {
                    this.setState({ phoneNumber: event.target.value });
                  }}
                  //   defaultValue={this.state.result[this.state.selectedIndex].idSource}
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel>IMEI number(optional)</InputLabel>
                <TextField
                  autoFocus
                  margin="dense"
                  id="dob"
                  label=""
                  type="text"
                  fullWidth
                  onChange={(event) => {
                    this.setState({ imeiNumber: event.target.value });
                  }}
                  //   defaultValue={this.state.result[this.state.selectedIndex].idSource}
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel>Started using on(MM/YYYY)*</InputLabel>
                <TextField
                  required
                  autoFocus
                  margin="dense"
                  id="dob"
                  label=""
                  type="month"
                  fullWidth
                  value={this.state.startedUsingOn}
                  // format="yyyy-mm"
                  // formatDate={(date) => moment(date).format("MM-YYYY")}
                  onChange={(event) => {
                    this.setState({ startedUsingOn: event.target.value });
                  }}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() =>
                this.setState(
                  {
                    addDialogOpen: false,
                  },
                  this.postPhones
                )
              }
            >
              submit
            </Button>
            <Button
              color="secondary"
              variant="contained"
              onClick={() =>
                this.setState({
                  addDialogOpen: false,
                })
              }
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog> */}
        <Dialog
          // fullWidth={"md"}
          maxWidth={"md"}
          open={this.state.historyDialougeOpen}
          onClose={() => this.setState({ historyDialougeOpen: false })}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="form-dialog-title" align="center">
            Phone History
          </DialogTitle>
          <TableContainer p={3}>
            <Table stickyHeader>
              <TableHead>
                <TableRow style={{ backgroundColor: "black" }}>
                  <TableCell style={{ fontWeight: "bolder" }} align="center">
                    phonenumber
                  </TableCell>
                  <TableCell style={{ fontWeight: "bolder" }} align="center">
                    phoneReason
                  </TableCell>
                  <TableCell style={{ fontWeight: "bolder" }} align="center">
                    phoneType
                  </TableCell>
                  <TableCell style={{ fontWeight: "bolder" }} align="center">
                    DefaultPhone
                  </TableCell>

                  <TableCell style={{ fontWeight: "bolder" }} align="center">
                    imeinumber
                  </TableCell>

                  <TableCell style={{ fontWeight: "bolder" }} align="center">
                    records updated date
                  </TableCell>
                  <TableCell style={{ fontWeight: "bolder" }} align="center">
                    Update reason
                  </TableCell>
                </TableRow>
              </TableHead>

              {this.state.historyloading ? (
                this.isloading()
              ) : (
                <TableBody>
                  {history.map((row, index) => (
                    <TableRow key={row.id}>
                      <TableCell align="center">{row.phoneNumber}</TableCell>
                      <TableCell align="center">
                        {row.phone_reason_name_field}
                      </TableCell>
                      <TableCell align="center">
                        {row.phone_type_name_field}
                      </TableCell>
                      <TableCell align="center">{row.default_phone}</TableCell>
                      <TableCell align="center">
                        {row.imeiNumber}
                      </TableCell>{" "}
                      <TableCell component="th" align="center">
                        {row.created_on}
                      </TableCell>
                      <TableCell align="center">{row.update_reason}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              )}
            </Table>
          </TableContainer>
          <DialogActions style={{ padding: 15 }}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() =>
                this.setState({ historyDialougeOpen: false, selectedIndex: -1 })
              }
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }

  getPhoneRecords() {
    return (
      <>
        {this.state.result.length === 0 ? (
          this.getPhones()
        ) : (
          <>
            <Grid
              container
              direction="column"
              justify="center"
              spacing={2}
              style={{ marginTop: 10 }}
            >
              {this.state.phoneDisplayWindow
                ? this.allPhoneDisplayWindow()
                : null}
              {this.state.addDialogOpen ? this.addNewPhoneWindow() : null}
              {this.state.updateDialogOpen
                ? this.editPhoneWindow(this.state.selectedIndex)
                : null}
              {this.state.historyWindowOpen
                ? this.viewHistoryWindow(this.state.selectedIndex)
                : null}
            </Grid>

            {this.updatesnackbar()}
            {this.addsnackbar()}
          </>
        )}
      </>
    );
  }

  allPhoneDisplayWindow() {
    return (
      <Grid
        item
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
        spacing={3}
        // style={{marginTop:20}}
      >
        <Grid item container xs={12} justify="flex-end">
          <Button
            size="large"
            color="primary"
            variant="contained"
            startIcon={<AddCircleOutlineIcon />}
            onClick={() =>
              this.setState({
                addDialogOpen: !this.state.addDialogOpen,
                phoneDisplayWindow: !this.state.phoneDisplayWindow,
              })
            }
          >
            Add New Phone Record
            {/* Add New address */}
          </Button>
        </Grid>

        {this.state.result.map((row, index) => (
          <Grid
            item
            container
            direction="row"
            justify="center"
            alignItems="center"
            xs={12}
            md={4}
          >
            <Card>
              <Grid container style={{ padding: 20 }} spacing={2}>
                <Grid
                  item
                  container
                  alignItems="center"
                  justify="space-between"
                  direction="row"
                  style={{ background: "#263238" }}
                >
                  <Grid
                    item
                    container
                    alignItems="center"
                    justify="flex-end"
                    direction="row"
                    spacing={1}
                  >
                    {/* <Grid item>
                      <Typography variant='h4' style={{ color: "white" }}>
                        Phone Record&nbsp;{this.state.phoneCardNumber++}
                      </Typography>
                    </Grid> */}

                    <Grid item>
                      <IconButton
                        aria-label="upload picture"
                        component="span"
                        onClick={() => {
                          this.setState({
                            phoneDisplayWindow: !this.state.phoneDisplayWindow,
                            historyWindowOpen: !this.state.historyWindowOpen,
                          });
                          this.getHistory(row.id);
                        }}
                      >
                        <HistoryIcon
                          fontSize="large"
                          style={{ color: "white" }}
                        />
                      </IconButton>

                      <IconButton
                        aria-label="upload picture"
                        component="span"
                        onClick={() =>
                          this.setState({
                            phoneDisplayWindow: !this.state.phoneDisplayWindow,
                            updateDialogOpen: !this.state.updateDialogOpen,
                            selectedIndex: index,

                            updatephoneType: this.state.result[index]
                              .phone_type,

                            updatephoneReason: this.state.result[index]
                              .phone_reason,

                            updateimeiNumber: this.state.result[index]
                              .imeiNumber,
                            updatephoneNumber: this.state.result[index]
                              .phoneNumber,
                            updatestartedusingon: this.state.result[
                              index
                            ].dateObtained.substring(
                              0,
                              this.state.result[index].dateObtained.length - 3
                            ),
                            updatedefaultPhone: this.state.result[index]
                              .default_phone,
                          })
                        }
                      >
                        <EditIcon fontSize="large" style={{ color: "white" }} />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <List>
                    <ListItem>
                      <ListItemText
                        primary="Phone Number"
                        secondary={row.phoneNumber}
                      />
                    </ListItem>
                    <Divider />

                    <ListItem>
                      <ListItemText primary="IMEI" secondary={row.imeiNumber} />
                    </ListItem>
                    <Divider />

                    <ListItem>
                      <ListItemText
                        primary="Default Phone"
                        secondary={row.default_phone}
                      />
                    </ListItem>
                    <Divider />

                    <ListItem>
                      <ListItemText
                        primary="Phone Type"
                        secondary={row.phone_type_name_field}
                      />
                    </ListItem>
                    <Divider />

                    <ListItem>
                      <ListItemText
                        primary="Verified By"
                        secondary={row.owner_name_field}
                      />
                    </ListItem>
                    <Divider />

                    <ListItem>
                      <ListItemText
                        primary="Date Obtained"
                        secondary={row.dateObtained.substring(
                          0,
                          row.dateObtained.length - 3
                        )}
                      />
                    </ListItem>
                    <Divider />
                  </List>
                </Grid>

                {this.state.walletLogic &&
                this.state.selectedIndex === index ? (
                  <Paper variant="outlined">
                    <Grid
                      item
                      container
                      xs={12}
                      justify="center"
                      alignItems="center"
                      direction="row"
                      spacing={2}
                      style={{ padding: 10 }}
                    >
                      <Grid item container justify="center" xs={12}>
                        <Grid>
                          <Typography variant="subtitle1">
                            You need to pay&nbsp;{this.state.amount}&nbsp;for
                            this service from your wallet, do you want to
                            continue?
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid item container justify="center" xs={12} md={6}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() =>
                            this.setState(
                              {
                                walletLogic: false,
                              },
                              this.pay
                            )
                          }
                        >
                          Yes
                        </Button>
                      </Grid>

                      <Grid item container justify="center" xs={12} md={6}>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() =>
                            this.setState({
                              walletLogic: false,
                            })
                          }
                        >
                          No
                        </Button>
                      </Grid>
                    </Grid>
                  </Paper>
                ) : (
                  <Grid item container xs={12} justify="center">
                    <Button
                      variant="contained"
                      color="primary"
                      size="medium"
                      disabled={!row.showVerifyOnTrac_btn}
                      onClick={() => {
                        this.setState({
                          walletLogic: true,
                          currentid: row.id,
                          selectedIndex: index,
                        });
                        this.getamount();
                      }}
                    >
                      Request for Verification
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Card>
          </Grid>
        ))}
      </Grid>
    );
  }

  addNewPhoneWindow() {
    return (
      <Grid container spacing={3}>
        {this.state.result.length === 0 ? <div/> : (
          <Grid item container xs={12} justify="flex-end">
          <Button
            size="large"
            color="primary"
            variant="contained"
            startIcon={<ArrowBackIcon />}
            onClick={() =>
              this.setState({
                addDialogOpen: !this.state.addDialogOpen,
                phoneDisplayWindow: !this.state.phoneDisplayWindow,
              })
            }
          >
            Back
          </Button>
        </Grid>
        )}
        <Grid item xs={12}>
          <Card>
            <Grid
              container
              justify="center"
              direction="row"
              alignItems="center"
              spacing={3}
              style={{ padding: 20 }}
            >
              <Grid item xs={12} style={{ background: "#263238" }}>
                <Typography variant="h5" style={{ color: "white" }}>
                  Add New Phone Record
                </Typography>
              </Grid>

              <Grid item xs={12}>
              
                  <InputLabel>Phone Reason*</InputLabel>
                  <Select
                    required
                    autoFocus
                    margin="dense"
                    error={this.state.phonereasonvalidate}
                    id="source"
                    label="fullname"
                    type="text"
                    fullWidth
                    onChange={(event) => {
                      this.setState({
                        phoneReason: event.target.value,
                        phonereasonvalidate: false,
                      });
                    }}
                    //   defaultValue={this.state.result[this.state.selectedIndex].fullname}
                  >
                    {this.state.phoneReasons.map((phonetype, index) => (
                      <MenuItem
                        id={phonetype.id}
                        value={phonetype.id}
                        key={index}
                      >
                        {phonetype.phoneReason}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {this.state.phonereasonvalidate ? "This is a mandatory field" : ""}
                  </FormHelperText>
               
              </Grid>
              <Grid item xs={12}>
                
                  <InputLabel>Phone Type*</InputLabel>
                  <Select
                    required
                    fullWidth
                    error={this.state.phonetypevalidate}
                   
                    onChange={(event) => {
                      this.setState({
                        phoneType: event.target.value,
                        phonetypevalidate:false
                      });
                    }}
                    //   defaultValue={this.state.result[this.state.selectedIndex].dob}
                  >
                    {this.state.phoneTypes.map((phonetype) => (
                      <MenuItem
                        id={phonetype.id}
                        value={phonetype.id}
                        key={phonetype.id}
                      >
                        {phonetype.phoneType}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {this.state.phonetypevalidate ? "This is a mandatory field" : ""}
                  </FormHelperText>
                
              </Grid>

              <Grid item xs={12}>
               
                  <InputLabel>DefaultPhone*</InputLabel>
                  <Select
                    required
                    fullWidth
                    error={this.state.defaultphonevalidate}
                    onChange={(event) => {
                      this.setState({
                        defaultPhone: event.target.value,
                        defaultphonevalidate
                    :false });
                    }}

                    //   defaultValue={this.state.result[this.state.selectedIndex].sex}
                  >
                    <MenuItem id={1} value="Yes">
                      Yes
                    </MenuItem>
                    <MenuItem id={2} value="No">
                      No
                    </MenuItem>
                  </Select>
                  <FormHelperText>
                    {this.state.defaultphonevalidate
                      ? "This is a mandatory field"
                      : ""}
                  </FormHelperText>
               
              </Grid>
              <Grid item xs={12}>
                <InputLabel>Phone number*</InputLabel>
                <TextField
                  required
                  error={this.state.phonenumbervalidate}
                  helperText={
                    this.state.phonenumbervalidate
                      ? "This is a mandatory field"
                      : ""
                  }
                  autoFocus
                  margin="dense"
                  id="dob"
                  label=""
                  type="text"
                  fullWidth
                  onChange={(event) => {
                    this.setState({
                      phoneNumber: event.target.value,
                      phonenumbervalidate:false
                    });
                  }}
                  //   defaultValue={this.state.result[this.state.selectedIndex].idSource}
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel>IMEI number(optional)</InputLabel>
                <TextField
                  autoFocus
                  margin="dense"
                  id="dob"
                  label=""
                  type="text"
                  fullWidth
                  onChange={(event) => {
                    this.setState({ imeiNumber: event.target.value });
                  }}
                  //   defaultValue={this.state.result[this.state.selectedIndex].idSource}
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel>Started using on(MM/YYYY)*</InputLabel>
                <TextField
                  required
                  error={this.state.startedusingonvalidate}
                  helperText={
                    this.state.startedusingonvalidate
                      ? "This is a mandatory field"
                      : ""
                  }
                  autoFocus
                  margin="dense"
                  id="dob"
                  label=""
                  type="month"
                  fullWidth
                  value={this.state.startedUsingOn}
                  // format="yyyy-mm"
                  // formatDate={(date) => moment(date).format("MM-YYYY")}
                  onChange={(event) => {
                    this.setState({
                      startedUsingOn: event.target.value,
                      startedusingonvalidate:false
                    });
                  }}
                />
              </Grid>

              <Grid item>
                <Button
                  color="primary"
                  variant="contained"
                  size="large"
                  onClick={() => {
                    this.postPhones();
                  }}
                  startIcon={<PublishIcon />}
                >
                  Submit Phone Record
                </Button>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    );
  }

  editPhoneWindow(index) {
    return (
      <Grid container spacing={3}>
        <Grid item container xs={12} justify="flex-end">
          <Button
            size="large"
            color="primary"
            variant="contained"
            startIcon={<ArrowBackIcon />}
            onClick={() =>
              this.setState({
                updateDialogOpen: !this.state.updateDialogOpen,
                phoneDisplayWindow: !this.state.phoneDisplayWindow,
              })
            }
          >
            Back
          </Button>
        </Grid>
        <Card>
          <Grid
            container
            justify="center"
            direction="row"
            alignItems="center"
            spacing={3}
            style={{ padding: 20 }}
          >
            <Grid item xs={12} style={{ background: "#263238" }}>
              <Typography variant="h4" style={{ color: "white" }}>
                Edit Identity
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <InputLabel>Phone Reason*</InputLabel>
              <Select
                // className="w3-input"
                autoFocus
                margin="dense"
                id="source"
                label="phonereason"
                type="text"
                fullWidth
                onChange={(event) => {
                  this.setState({
                    updatephoneReason: event.target.value,
                  });
                }}
                defaultValue={this.state.updatephoneReason}
              >
                {this.state.phoneReasons.map((phonetype) => (
                  <MenuItem
                    id={phonetype.id}
                    value={phonetype.id}
                    key={phonetype.id}
                  >
                    {phonetype.phoneReason}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={12}>
              <InputLabel>Phone Type*</InputLabel>
              <Select
                autoFocus
                margin="dense"
                id="id"
                label="phone type"
                type="text"
                fullWidth
                onChange={(event) => {
                  this.setState({
                    updatephoneType: event.target.value,
                  });
                }}
                defaultValue={this.state.updatephoneType}
              >
                {this.state.phoneTypes.map((phonetype) => (
                  <MenuItem
                    id={phonetype.id}
                    value={phonetype.id}
                    key={phonetype.id}
                  >
                    {phonetype.phoneType}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={12}>
              <InputLabel>Default Phone*</InputLabel>
              <Select
                autoFocus
                margin="dense"
                id="name"
                label=""
                type="text"
                fullWidth
                onChange={(event) => {
                  this.setState({
                    updatedefaultPhone: event.target.value,
                  });
                }}
                defaultValue={this.state.updatedefaultPhone}
              >
                <MenuItem id={1} value="Yes">
                  Yes
                </MenuItem>
                <MenuItem id={2} value="No">
                  No
                </MenuItem>
              </Select>
            </Grid>
            <Grid item xs={12}>
              <InputLabel>Phone Number*</InputLabel>
              <TextField
                autoFocus
                margin="dense"
                id="dob"
                label=""
                type="text"
                fullWidth
                onChange={(event) => {
                  this.setState({
                    updatephoneNumber: event.target.value,
                  });
                }}
                defaultValue={this.state.updatephoneNumber}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel>IMEI Number</InputLabel>
              <TextField
                autoFocus
                margin="dense"
                id="dob"
                label=""
                type="text"
                fullWidth
                onChange={(event) => {
                  this.setState({
                    updateimeiNumber: event.target.value,
                  });
                }}
                defaultValue={this.state.updateimeiNumber}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel>Started Using On*</InputLabel>
              <TextField
                autoFocus
                margin="dense"
                id="dob"
                label=""
                type="month"
                fullWidth
                onChange={(event) => {
                  this.setState({
                    updatestartedusingon: event.target.value,
                  });
                }}
                defaultValue={this.state.updatestartedusingon}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel>Update Reason</InputLabel>
              <TextField
                autoFocus
                margin="dense"
                id="dob"
                label=""
                type="text"
                helperText="update reason can be less than 250 characters"
                onChange={(event) => {
                  this.setState(
                    {
                      updateReason: event.target.value,
                    },
                    this.reasonforupdatevalidcheck(event)
                  );
                }}
                fullWidth
              />
            </Grid>

            <Grid item>
              <Button
                color="primary"
                variant="contained"
                size="large"
                disabled={this.state.buttondisabled}
                onClick={() => {
                  this.setState({
                    phoneDisplayWindow: !this.state.phoneDisplayWindow,
                    updateDialogOpen: !this.state.updateDialogOpen,
                  });
                  this.updatePhones(
                    this.state.result[this.state.selectedIndex].id
                  );
                }}
                startIcon={<SaveIcon />}
              >
                Save Phone Record
              </Button>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    );
  }

  viewHistoryWindow(index) {
    return (
      <>
        {this.state.isloading ? (
          this.isloading()
        ) : (
          <Grid container spacing={3}>
            <Grid item container xs={12} justify="flex-end">
              <Button
                size="large"
                color="primary"
                variant="contained"
                startIcon={<ArrowBackIcon />}
                onClick={() =>
                  this.setState({
                    historyWindowOpen: !this.state.historyWindowOpen,
                    phoneDisplayWindow: !this.state.phoneDisplayWindow,
                  })
                }
              >
                Back
              </Button>
            </Grid>
            <Card>
              <Grid
                container
                justify="center"
                direction="row"
                alignItems="center"
                spacing={3}
                style={{ padding: 20 }}
              >
                <Grid item xs={12} style={{ background: "#263238" }}>
                  <Typography variant="h4" style={{ color: "white" }}>
                    Edit History
                  </Typography>
                </Grid>
                {this.state.historyloading ? (
                  this.isloading()
                ) : (
                  <>
                    {history.map((row, index) => (
                      <>
                        <Grid
                          item
                          container
                          xs={12}
                          justify="space-between"
                          alignItems="center"
                        >
                          <Grid item>
                            <Typography variant="h6">
                              Record Edited on:&nbsp;&nbsp;
                              {new Date(row.created_on).toDateString()}
                            </Typography>
                          </Grid>
                        </Grid>

                        <Grid item xs={12}>
                          <TextField
                            size="small"
                            fullWidth
                            variant="outlined"
                            id="phoneNumber"
                            label="Phone Number"
                            defaultValue={row.school}
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            size="small"
                            variant="outlined"
                            fullWidth
                            id="imei"
                            label="IMEI"
                            defaultValue={row.degree}
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            size="small"
                            variant="outlined"
                            fullWidth
                            id="PhoneReason"
                            label="Phone Reason"
                            defaultValue={row.startDate}
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <TextField
                            size="small"
                            variant="outlined"
                            fullWidth
                            id="phoneType"
                            label="Phone Type"
                            defaultValue={row.endDate}
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <TextField
                            size="small"
                            variant="outlined"
                            fullWidth
                            id="defaultPhone"
                            label="Default Phone"
                            defaultValue={row.status}
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <TextField
                            size="small"
                            variant="outlined"
                            fullWidth
                            id="updateReason"
                            label="Update Reason"
                            defaultValue={row.status}
                            InputProps={{
                              readOnly: true,
                            }}
                            multiline
                            rows={3}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <Divider />
                        </Grid>
                      </>
                    ))}
                  </>
                )}
              </Grid>
            </Card>
          </Grid>
        )}
      </>
    );
  }

  isloading() {
    return (
      <>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          display="flex"
          style={{ minHeight: "0vh" }}
        >
          <CircularProgress />
        </Grid>
      </>
    );
  }
  gettable() {
    return <>{this.getPhones()}</>;
  }
  render() {
    return (
      <>
        {
          this.state.loading ? this.isloading() : this.getPhoneRecords()
          // this.gettable()
        }
      </>
    );
  }
}

export default Phones;
