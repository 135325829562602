import React from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  TextField,
  Paper,
  Button,
  CircularProgress,
} from "@material-ui/core";

// let token1 = "";
let token = "";
let id = "";
const api = "http://3.22.17.212:9000";

// let user = "";

const styles = (theme) => ({});

class index extends React.Component {
  state = {
    profiles: [],
    isloading: true,
    validityDate: "",
    onTracId: "",
  };

  constructor(props) {
    super(props);
    this.fetchProfiles = this.fetchProfiles.bind(this);
  }
  async fetchProfiles() {
    const user = this.props.user;
    // const approval = this.props.approval;

    // console.log("ProfileUserId:", user);

    let response = await fetch(
      api + "/api/v1/employers/" + user + "/getdetails",
      {
        headers: {
          Authorization: token,
        },
      }
    );
    response = await response.json();
    // console.log("profileSuccess:", response);
    this.setState({ profiles: [response] });
    this.setState({ onTracId: response.ontrac_id });
    // console.log("votID:", this.state.onTracId);
  }

  async postValidityDate() {
    // const user = this.props.user;
    // const approval = this.props.approval;

    try {
      let response = await fetch(
        "http://3.22.17.212:9000/modifyValidityDate?ontracId=" +
          this.state.onTracId +
          "&date=" +
          this.state.validityDate,
        {
          method: "POST",
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(),
        }
      );
      response = await response.json();
      // console.log("postCodeSuccess:", response);

      this.setState({ validityDate: "" });
    } catch (error) {
      // console.log("[!ON_REGISTER] " + error);
    }
  }

  componentDidMount() {
    token = localStorage.getItem("Token");
    id = localStorage.getItem("id");

    // console.log("ProfileUserId:", this.props.user);

    this.fetchProfiles();

    // this.setState({isloading: false})
  }

  isloading() {
    return (
      <>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          display="flex"
          style={{ minHeight: "0vh" }}
        >
          <CircularProgress />
        </Grid>
      </>
    );
  }

  render() {
    // const { classes } = this.props;
    // console.log("profilesdata:", this.state.profiles);
    return (
      // <p>Hi</p>
      <>
        {
          // this.state.isloading ? this.isloading() :
          // (
          // this.state.profiles.length !== 0 ? (

          this.state.profiles.map((id) => (
            <Paper variant="outlined" style={{ marginTop: 20, padding: 20 }}>
              <Grid
                container
                justify="flex-end"
                direction="row"
                alignItems="center"
                spacing={1}
              >
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    type="date"
                    onChange={(event) => {
                      this.setState({
                        validityDate: event.target.value,
                      });
                      // console.log(event.target.value);
                    }}
                    helperText="Validity Date"
                  />
                </Grid>
                <Grid item fullWidth>
                  <Button
                    style={{ minWidth: 200 }}
                    variant="outlined"
                    color="secondary"
                    onClick={() => this.postValidityDate()}
                  >
                    Set Date
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          ))
          // ) : ("No data")
          // )
        }
      </>
    );
  }
}

export default withStyles(styles)(index);
