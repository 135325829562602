import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import {
  Grid, Paper
} from '@material-ui/core/';
// import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
// import ExpandLess from "@material-ui/icons/ExpandLess";
// import ExpandMore from "@material-ui/icons/ExpandMore";
// import StarBorder from "@material-ui/icons/StarBorder";
// import Collapse from "@material-ui/core/Collapse";
import { Container, Button, Box } from "@material-ui/core";
// import MenuItem from "@material-ui/core/MenuItem";
// import Menu from "@material-ui/core/Menu";
import axios from "axios";
import InfoIcon from "@material-ui/icons/Info";
// import AccountCircle from "@material-ui/icons/AccountCircle";
// import PersonIcon from "@material-ui/icons/Person";
import WorkIcon from "@material-ui/icons/Work";
import MessageIcon from "@material-ui/icons/Message";
import DashboardIcon from "@material-ui/icons/Dashboard";
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Dashboard from "./Components/DashBoardComponents/Dashboard";
// import Addresstitle from "./Components/DashBoardComponents/Addresses/title";
// import Identitiestitle from "./Components/DashBoardComponents/Identities/title";
import MyJobProfile from "./Components/DashBoardComponents/MyJobProfile";
import Index from "./Components/DashBoardComponents/Messages/Inbox";
import Indexemployment from "./Components/DashBoardComponents/MyCodes/EmployementCodes/index";
// import Outbox from "./Components/DashBoardComponents/Messages/Outbox";
import AccessCodes from "./Components/DashBoardComponents/MyCodes/AccessCodes";
// import EmployementCodes from "./Components/DashBoardComponents/MyCodes/EmployementCodes";s
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import MyInfoTabs from "./Components/DashBoardComponents/MyInfoTabs/myInfoTabs";
import Addmoney from "../src/Components/DashBoardComponents/Wallet/Addmoney";
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Fab from '@material-ui/core/Fab';

import EmployeePayments from "../src/Components/DashBoardComponents/MenuWallet/EmployeePayments"
import { FormatListNumbered } from "@material-ui/icons";

const drawerWidth = 240;
function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

 function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
  
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: theme.spacing(1),
  },
  root: {
    display: "flex",
    flexGrow: 1,
    background: '#f5f5f5'
  },
  // grow: {
  //   flexGrow: 1,
  // },

  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"],
      {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    width: "inherit",
    // background: "#031a26",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    background: "#031a26",
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
    background: "#031a26",
  },
  textColor: {
    color: "white",
  },
  drawerPaper: {
    width: "inherit",
    // background: "#031a26",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  nested: {
    paddingLeft: 72,
  },
  link: {
    textDecoration: "none",
    // color: theme.palette.text.primary
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

export default function MiniDrawer(props) {
  // const { classes } = this.props;

  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [auth, setAuth] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [Token, setToken] = React.useState("");
   const { height, width } = useWindowDimensions();


  // const [anchorEl, setAnchorEl] = React.useState(false);
  useEffect(() => {
      width > 425 ? setOpen(true) : setOpen(false);
    getBalance();
    setToken(localStorage.getItem("Token"));
  },[]);

  const [open1, setOpen1] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);
  // const [open4, setOpen4] = React.useState(false);
  const [balance, setBalance] = React.useState(false);

  // const [anchorEl, setAnchorEl] = React.useState(null);

  //  const handleClose = () => {
  //    setAnchorEl(null);
  //  };
  //  const handleClick = (event) => {
  //    setAnchorEl(event.currentTarget);
  //  };

  // const open9 = Boolean(anchorEl);

  const handleDrawerOpen = () => {
    width>425?
    setOpen(true):setOpen(false)
  };

  const handleDrawerClose = () => {
    setOpen(false);
    setOpen1(false);
    setOpen2(false);
    setOpen3(false);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getBalance = async () => {
    const Token = await localStorage.getItem("Token");

    await axios
      .get("http://3.22.17.212:9000/wallet/getBalance", {
        headers: {
          Authorization: Token,
        },
      })
      .then((response) => {
        setBalance(response);
        // console.log("messages", response);
      });
  };

  const logout = async () => {
    props.history.push("/signin");

    let headers = {
      headers: {
        Authorization: Token,
        "Content-Type": "multipart/form-data",
      },
    };
    await axios
      .post(
        "http://3.22.17.212:9000/api/v1/accounts/auth/logout",
        {},

        headers
      )
      .then((response) => {
        localStorage.clear();
        console.log(response);
      });


  };

  return (
    <Router>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open,
          })}
          style={{ background: "#78909c" }}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              // style={{background: "#f7f9fc"}}
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, {
                [classes.hide]: open,
              })}
            >
              <MenuIcon style={{color: "white"}}/>
            </IconButton>

            <Typography variant="h4" className={classes.title} style={{ color: "white" }}>
              Verify OnTrac
            </Typography>

            {/* <Button
              style={{ backgroundColor: "transparent" }}
              color="inherit"
              size="medium"
              onClick={handleClick}
            >
              <AccountBalanceWalletIcon color="white" />
            </Button>

            <Popover
              id={id}
              open={open9}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <Link to="/Addmoney" className={classes.link}>
                
              <Button>Add money to your wallet</Button>
              </Link>
            </Popover> */}
            <div>
              <Box px={2}>
                {balance.status === 200 ? (
                  <>
                  <Paper variant="outlined" style={{ marginTop: 10, marginBottom: 10,background: "#546e7a" }}>
                    <Typography style={{ color: "#ffffff", padding: 5 }}>E-Wallet Balance</Typography>
                    <Typography style={{ color: "#ffffff", fontWeight: "bold" }} align="center" justify="center">
                      $ {balance.data[0].balance}
                    </Typography>
                    </Paper>
                  </>
                ) : (
                    <>
                      {/* <Link to="/Createwallet" className={classes.link}>
                    <ListItemText
                      primary="Create wallet"
                      className={classes.textColor}
                    />
                  </Link> */}
                    </>
                  )}
              </Box>
            </div>

            {/* <Button
              onClick={logout}
              color="inherit"
              variant="outlined"
              size="medium"
              style={{color: "#6d6966"}}
            >
              Logout
            </Button> */}

            <IconButton
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={logout}
                  color="inherit"
                >
                  <ExitToAppIcon style={{ color: "white" }} fontSize="large" />
                </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
        >
          <div className={classes.toolbar}>
            <Grid item>
              <img src="/images/VERIFYlogo.png" width="175" height="30" alt="" />
            </Grid>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "rtl" ? (
                <ChevronRightIcon style={{ color: "white" }} />
              ) : (
                  <ArrowBackIcon style={{ color: "white" }} />
                )}
            </IconButton>
          </div>

          <Divider style={{background: "#73777f"}}/>

          <Link to="/dashboard" className={classes.link}>
            <ListItem
              button
            // onClick={() => setOpen(!open)}
            >
              <ListItemIcon>
                <DashboardIcon style={{ color: "white" }} />
              </ListItemIcon>
              <ListItemText
                primary={"Dashboard"}
                className={classes.textColor}
              />
            </ListItem>
          </Link>

          <Divider style={{background: "#73777f"}}/>

          <Link
            to="/myInfo"
            className={classes.link}
          // onClick={() => handleDrawerClose()}
          >
            <ListItem button>
              <ListItemIcon>
                <InfoIcon style={{ color: "white" }} />
              </ListItemIcon>
              <ListItemText primary={"My Info"} className={classes.textColor} />
            </ListItem>
          </Link>

          <Divider style={{background: "#73777f"}}/>

          <Link to="/myjobprofile" className={classes.link}>
            <ListItem
              button
            // onClick={() => setOpen(!open)}
            >
              <ListItemIcon>
                <WorkIcon style={{ color: "white" }} />
              </ListItemIcon>
              <ListItemText
                primary="My Job Profile"
                className={classes.textColor}
              />
            </ListItem>
          </Link>

          <Divider style={{background: "#73777f"}}/>


          <Link to="/employeeEmployementCodes" className={classes.link}>
            <ListItem
              button
            // className={classes.nested}
            // onClick={() => handleDrawerClose()}
            >
              <ListItemIcon>
                <FormatListNumberedIcon style={{ color: "white" }} />
              </ListItemIcon>
              <ListItemText
                primary="Code Requests"
                className={classes.textColor}
              />
            </ListItem>
          </Link>


          <Divider style={{background: "#73777f"}}/>

          <Link to="/employeeInbox" className={classes.link}>
            <ListItem
              button
            // onClick={() => setOpen(!open)}
            >
              <ListItemIcon>
                <MessageIcon style={{ color: "white" }} />
              </ListItemIcon>
              <ListItemText primary="Messages" className={classes.textColor} />
            </ListItem>
          </Link>
          <Divider style={{background: "#73777f"}}/>

          <List component="div" disablePadding>
            <Link to="/employeePayments" className={classes.link}>
              <ListItem
                button
              // className={classes.nested}
              // onClick={() => handleDrawerClose()}
              >
                <ListItemIcon>
                  <AccountBalanceWalletIcon style={{ color: "white" }} />
                </ListItemIcon>
                <ListItemText
                  primary="Payments & Cards"
                  className={classes.textColor}
                />
              </ListItem>
            </Link>
          </List>
          {/* </Collapse> */}

          <Divider style={{background: "#73777f"}}/>
        </Drawer>

        <main className={classes.content}>
          <Toolbar />
          <Switch>
            <Route exact path="/dashboard">
              <Container >
                <Dashboard />
              </Container>
            </Route>

            <Route exact path="/myInfo">
              <Container>
                <MyInfoTabs />
              </Container>
            </Route>

            <Route exact path="/myjobprofile">
              <Container>
                <MyJobProfile />
              </Container>
            </Route>

            <Route exact path="/employeeInbox">
              <Container>
                <Index />
              </Container>
            </Route>

            {/* <Route exact path="/employeeOutbox">
                <Container>
                  <Outbox />
                </Container>
              </Route> */}

            <Route exact path="/employeeAccessCodes">
              <Container>
                <AccessCodes />
              </Container>
            </Route>
            {/* <Route exact path="/Createwallet">
              <Container>
                <Createwallet />
              </Container>
            </Route> */}

            <Route exact path="/Addmoney">
              <Container>
                <Addmoney />
              </Container>
            </Route>

            <Route exact path="/employeeEmployementCodes">
              <Container>
                <Indexemployment />
              </Container>
            </Route>

            <Route exact path="/employeePayments">
              <Container>
                <EmployeePayments />
              </Container>
            </Route>
          </Switch>
        </main>
      </div>
    </Router>
  );
}
