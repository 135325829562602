import React from "react";
import {
  TextField,
  Grid,
  Typography,
  Button,
 
} from "@material-ui/core/";
import Axios from "axios";
import {
  
  CircularProgress,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  ExpansionPanelActions,
  Box,
} from "@material-ui/core/";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";


let Token = "";
const useStyles = makeStyles((theme) => ({
  column: {
    flexBasis: "100%",
  },
}));
export default function Datatable(props) {
  
  const [viewDetailsButton, setviewDetailsButton] = React.useState(false);
  const [addNewMessageDialog, setaddNewMessageDialog] = React.useState(false);
  const [Message, setMessage] = React.useState([]);
  const [addMessage, addSetMessage] = React.useState("");
  const [expanded, setExpanded] = React.useState(false);
  // const [newmessage, setNewmessage] = React.useState(false);

  const Closeviewbutton = () => {
    setviewDetailsButton(false);
    setMessage("");
  };
  const ViewMessage = async (id) => {
    Token = await localStorage.getItem("Token");
    setviewDetailsButton(true);
    // setCurrentID(id);
    await Axios.get("http://3.22.17.212:9000/api/v1/messages/" + id, {
      headers: {
        Authorization: Token,
      },
    }).then((response) => {
      setMessage(response.data);
    });
  };
  const addMessages = async (id) => {
    setaddNewMessageDialog(false);
    let bodyFormData = new FormData();
    bodyFormData.append("message", addMessage);
    await Axios.put(
      "http://3.22.17.212:9000/api/v1/messages/" + id + "/add",
      bodyFormData,
      {
        headers: {
          Authorization: Token,
        },
      }
    ).then((response) => {
      ViewMessage(response.data.message.id);
      props.data.refresh();
    });
  };
  const handleChange = (panel, row) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    ViewMessage(row);
  };
  return (
    <div>
      <Grid container direction="row" justify="center" alignItems="center">
        {props.data.data.map((row, index) => (
          <Grid item sm={12} md={12} lg={12} xl={12} key={index}>
            <Box p={1}>
              <ExpansionPanel
                expanded={expanded === "panel" + row.id}
                onChange={handleChange("panel" + row.id, row.id)}
              >
                <ExpansionPanelDetails>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1c-content"
                    id="panel1c-header"
                  >
                    <Grid
                      container
                      direction="column"
                      justify="center"
                      alignItems="center"
                    >
                      <Grid item>
                        <Typography
                          variant="h4"
                          component="h2"
                          style={{ textTransform: "capitalize" }}
                        >
                          {row.discuss_with_employee_field.name}
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          Last Updated:
                          {new Date(row.messageDate).toDateString()}
                        </Typography>

                        {/* <Typography variant="body2" component="p">
                          Initial Date:
                          {new Date(row.initialDate_field).toDateString()}
                        </Typography> */}
                        <Typography variant="body1" component="p">
                          Message: {row.message}
                        </Typography>
                      </Grid>
                    </Grid>
                  </ExpansionPanelSummary>
                </ExpansionPanelDetails>
                <ExpansionPanelActions>
                  {Message.length === 0 ? (
                    <Grid
                      container
                      spacing={0}
                      direction="column"
                      // alignItems="center"
                      justify="center"
                      display="flex"
                      style={{ minHeight: "100vh" }}
                    >
                      <CircularProgress />
                    </Grid>
                  ) : (
                    <>
                      <Grid
                        container
                        justify="space-between"
                        alignItems="center"
                        direction="row"
                        // alignItems="center"
                        spacing={2}
                      >
                        {Message.map((row, index) => (
                          <Grid item xs={12} md={12} lg={12} key={index}>
                            <Card elevation={4} variant="outlined">
                              <CardContent>
                                <Typography
                                  variant="h6"
                                  component="h2"
                                  style={{ textTransform: "capitalize" }}
                                >
                                  {row.initiated_by_field}
                                </Typography>
                                <Typography
                                  variant="caption"
                                  color="textSecondary"
                                >
                                  {new Date(row.messageDate).toDateString()}
                                </Typography>

                                <Typography color="textSecondary">
                                  {row.msgCategory_field}
                                </Typography>
                                <Typography variant="body2" component="p">
                                  {row.message}
                                </Typography>
                              </CardContent>
                            </Card>
                          </Grid>
                        ))}
                        <Grid item xs={12} md={12} lg={12}>
                          <Box p={2}>
                            <Grid container direction="column">
                              <Grid item>
                                <TextField
                                  autoFocus
                                  margin="dense"
                                  id="newMessage"
                                  label="Type message"
                                  type="text"
                                  fullWidth="true"
                                  multiline
                                  variant="outlined"
                                  rows={4}
                                  onChange={(event) => {
                                    addSetMessage(event.target.value);
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </Box>
                        </Grid>

                        <Grid item xs={12} md={12} lg={12}>
                          <Grid
                            container
                            direction="row"
                            justify="flex-end"
                            alignItems="center"
                          >
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => addMessages(Message[0].id)}
                            >
                              Submit
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </>
                  )}
                </ExpansionPanelActions>
              </ExpansionPanel>
            </Box>
          </Grid>
        ))}
      </Grid>
      {/* <div>
        <Dialog
          fullWidth={true}
          maxWidth={"sm"}
          open={viewDetailsButton}
          onClose={() => {
            Closeviewbutton();
          }}
        >
          <DialogTitle id="codegenerator" align="center">
            {"Message Details"}
          </DialogTitle>
        </Dialog>
      </div> */}
      <div>
        <Dialog
          open={addNewMessageDialog}
          onClose={() => setaddNewMessageDialog(false)}
        >
          <DialogTitle id="form-dialog-title">Add new message</DialogTitle>
          <DialogContent style={{ minWidth: 500 }}></DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setaddNewMessageDialog(false)}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}
