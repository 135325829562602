import React, {  useEffect } from "react";
import { 
  Grid,
  Typography,
} from "@material-ui/core/";
import {
  CircularProgress,
} from "@material-ui/core/";
import Tabs from "./tabs";
import Axios from "axios";
let Token = "";
export default function Index() {
  const [Response, setResponse] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const fetchInboxMessages = async () => {
    Token = await localStorage.getItem("Token");
 
    await Axios.get("http://3.22.17.212:9000/api/v1/messages/", {
      headers: {
        Authorization: Token,
      },
    }).then((response) => {

      setResponse(response.data);
      setLoading(false);
    });
  };

  useEffect(() => {
    fetchInboxMessages();
  }, []);
  // let tempArr = [];
  // response.forEach(element => {
  //     tempArr.push(element["companyName"]);
  // });
  // this.setState({ companies: tempArr });

  return (
    <div style={{ marginTop: 20 }}>
      {/* <Paper style={{ padding: 20, height: '100vh' }}> */}
      <Grid container justify="space-between" alignItems="center" spacing={4}>
        <Grid item xs={12}>
          <Typography variant="h4" align="center">
            Messages
          </Typography>
        </Grid>
      </Grid>
      {loading ? (
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          display="flex"
          style={{ minHeight: "100vh" }}
        >
          <CircularProgress />
        </Grid>
      ) : Response.length === 0 ? (
        <Grid container align="center" justify="center">
          <h1>No Messages</h1>
        </Grid>
      ) : (
        <Tabs data={Response} refresh={fetchInboxMessages} />
      )}
    </div>
  );
}
