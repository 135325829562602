import React, { useEffect } from "react";
import {
  TextField,
  Paper,
  Grid,
  Typography,
  Button,
  TableContainer,
  FormControlLabel,
  Chip,
  Avatar,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  CircularProgress,
  Radio,
  RadioGroup,
  FormLabel,
  Box,
} from "@material-ui/core/";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core/";
import Rating from "@material-ui/lab/Rating";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { get, post, update, put } from "../../../../API";
import MaterialTable, { MTableToolbar } from "material-table";
import Search from "@material-ui/icons/Search";
import { forwardRef } from "react";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import SearchBar from "material-ui-search-bar";
import ViewColumn from "@material-ui/icons/ViewColumn";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import WorkOutlineIcon from "@material-ui/icons/WorkOutline";
import PinDropIcon from "@material-ui/icons/PinDrop";
import AccountBalanceWalletOutlinedIcon from "@material-ui/icons/AccountBalanceWalletOutlined";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import NotInterestedOutlinedIcon from "@material-ui/icons/NotInterestedOutlined";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import Tooltip from "@material-ui/core/Tooltip";
import CheckCircleOutlineOutlinedIcon from "@material-ui/icons/CheckCircleOutlineOutlined";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import PriorityHighOutlinedIcon from "@material-ui/icons/PriorityHighOutlined";
import ClearOutlinedIcon from "@material-ui/icons/ClearOutlined";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import theme from "../../../../theme";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};
function EmployerList(props) {
  const [data] = React.useState(props.data);
  const [Token] = React.useState(localStorage.getItem("Token"));
  const [id] = React.useState(localStorage.getItem("id"));
  const [Termination, setTermination] = React.useState(false);
  const [offboardingTypes, setOffboardTypes] = React.useState([]);
  const [leavingReason, setLeavingReasons] = React.useState([]);
  const [ratingdata, setRatingdata] = React.useState([]);
  const [Choice, setChoicedata] = React.useState([]);
  const [Loading, setLoading] = React.useState(true);
  const [viewrating, setViewrating] = React.useState(false);
  const [viewsurvey, setViewsurvey] = React.useState(false);
  const [ratingSurvey, setRatingentry] = React.useState([]);
  const [choiceSurvey, setChoiceentry] = React.useState([]);
  // const[ratingsurvey,setRatingsurvey]=React.useState({})
  // const[choiceSurvey,setChoicesurvey]=React.useState({})
  const [updationdialouge, setUpdationdialog] = React.useState(false);
  const [Jobcategory, setJobcategory] = React.useState([]);
  const [commentdialog, setcommentdialog] = React.useState(false);
  const [updateid, setupdateid] = React.useState();
  const [commentbyemployer, setcommentbyemployer] = React.useState([]);
  const [getcommentemployerid, setemployerid] = React.useState();
  const [comentdata, setCommentdata] = React.useState({
    employer: "",
    comment: "",
  });
  const [offboardid, setoffboardid] = React.useState();
  const [updatedialog, setupdatedialog] = React.useState(false);
  const [employmentupdate, setEmploymentupdate] = React.useState({
    jobProfile: "",
    jobCategory: "",
    jobTitle: "",
    jobDescription: "",
  });
  const [currentupdateid, setcurrentupdateid] = React.useState(0);
  const [offboard, setOffboard] = React.useState({
    // offboard: {
    //   jobProfile: "",
    //   offboardType: "",
    //   leavingReason: "",
    //   description: "",
    //   endDate: "",
    // },
  });
  const [confirmterminationdailog, setconfirmTerminationdialg] = React.useState(
    false
  );
  // const getcommentbyemployer=async(employerid)=>{
  //   await get("http://3.22.17.212:9000/api/v1/employees/"+employerid+"/comments/mycomments",Token,"").then((res)=>{setcommentbyemployer(res.data)})
  //     }
  const confirmterminationapi = async () => {
    await put(
      "http://3.22.17.212:9000/api/v1/employers/confirmTermination/" +
        offboardid,
      Token,
      { ratingSurvey, choiceSurvey }
    ).then(() => {
      props.refresh();
      setconfirmTerminationdialg(false);
    });
  };
  const updatedilaogconfirmation = () => {
    return (
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={updatedialog}
        onClose={() => {
          setupdatedialog(false);
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle align="center">
          <Typography variant="h5" component="h5" gutterBottom>
            Updated Job Details
          </Typography>
        </DialogTitle>
        <DialogContent>
          {data[currentupdateid].empUpdate.length > 0 ? (
            <>
              <Grid
                container
                justify="center"
                direction="row"
                alignItems="center"
                spacing={1}
                // style={{ padding: 20 }}
              >
                <Grid item xs={12}>
                  <FormLabel component="legend">Employee Details:</FormLabel>
                </Grid>

                <>
                  <>
                    <Grid item xs={12}>
                      <TextField
                        id="fullName"
                        label="Company Name"
                        defaultValue={data[currentupdateid].company.companyName}
                        type="text"
                        InputProps={{
                          readOnly: true,
                        }}
                        fullWidth
                        size="small"
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        id="jobTitle"
                        label="Job Title"
                        defaultValue={
                          data[currentupdateid].empUpdate[0].statusReason
                        }
                        type="text"
                        InputProps={{
                          readOnly: true,
                        }}
                        fullWidth
                        size="small"
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        id="jobCategory"
                        label="Job Category"
                        defaultValue={
                          data[currentupdateid].empUpdate[0].updateStatus
                        }
                        type="text"
                        InputProps={{
                          readOnly: true,
                        }}
                        fullWidth
                        size="small"
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        id="endDate2"
                        label="End Date"
                        defaultValue={
                          data[currentupdateid].jobDetails.endDate === null
                            ? "NA"
                            : data[currentupdateid].jobDetails.endDate
                        }
                        type="text"
                        InputProps={{
                          readOnly: true,
                        }}
                        fullWidth
                        size="small"
                      />
                    </Grid>
                  </>
                </>
              </Grid>

              <>
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item xs={6}>
                    <Paper variant="outlined" style={{ padding: 15 }}>
                      <Grid
                        container
                        justify="flex-start"
                        direction="row"
                        alignItems="center"
                        spacing={2}
                        // style={{ padding: 20 }}
                      >
                        <Grid item xs={12}>
                          <FormLabel component="legend">
                            Original details:
                          </FormLabel>
                        </Grid>

                        <Grid item xs={12}>
                          <TextField
                            id="originaljobTitle"
                            label="Job Title"
                            defaultValue={
                              data[currentupdateid].jobDetails.jobTitle
                            }
                            type="text"
                            InputProps={{
                              readOnly: true,
                            }}
                            fullWidth
                            size="small"
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <TextField
                            id="originaljobCategory"
                            label="Job Category"
                            defaultValue={
                              data[currentupdateid].jobDetails
                                .job_category_field
                            }
                            type="text"
                            InputProps={{
                              readOnly: true,
                            }}
                            fullWidth
                            size="small"
                          />
                        </Grid>

                        <Grid item xs={12} style={{ marginTop: 5 }}>
                          <TextField
                            id="originaljobDescription"
                            label="Job Description"
                            defaultValue={
                              data[currentupdateid].jobDetails.jobDescription
                            }
                            type="text"
                            InputProps={{
                              readOnly: true,
                            }}
                            fullWidth
                            size="small"
                            variant="outlined"
                            multiline
                            rows={3}
                          />
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>

                  <Grid item xs={6}>
                    <Paper variant="outlined" style={{ padding: 15 }}>
                      <Grid
                        container
                        justify="flex-start"
                        direction="row"
                        alignItems="center"
                        spacing={2}
                        // style={{ padding: 20 }}
                      >
                        <Grid item xs={12}>
                          <FormLabel component="legend">
                            Updated details:
                          </FormLabel>
                        </Grid>

                        <Grid item xs={12}>
                          <TextField
                            id="updatedjobTitle"
                            label="Job Title"
                            defaultValue={
                              data[currentupdateid].empUpdate[0]["jobTitle"]
                            }
                            type="text"
                            InputProps={{
                              readOnly: true,
                            }}
                            fullWidth
                            size="small"
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <TextField
                            id="updatedjobCategory"
                            label="Job Category"
                            defaultValue={
                              data[currentupdateid].empUpdate[0][
                                "jobCategory_name_field"
                              ]
                            }
                            type="text"
                            InputProps={{
                              readOnly: true,
                            }}
                            fullWidth
                            size="small"
                          />
                        </Grid>

                        <Grid item xs={12} style={{ marginTop: 5 }}>
                          <TextField
                            id="updatedjobDescription"
                            label="Job Description"
                            defaultValue={
                              data[currentupdateid].empUpdate[0][
                                "jobDescription"
                              ]
                            }
                            type="text"
                            InputProps={{
                              readOnly: true,
                            }}
                            fullWidth
                            size="small"
                            variant="outlined"
                            multiline
                            rows={3}
                          />
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                </Grid>
              </>
            </>
          ) : (
            console.log(
              "data[currentupdateid].empUpdate.length",
              data[currentupdateid].empUpdate.length
            )
          )}
        </DialogContent>
        <DialogActions>
          <Grid container justify="space-evenly">
            <Button
              size="small"
              color="primary"
              variant="outlined"
              onClick={() => {
                confirmupdates(updateid);
                setupdatedialog(false);
              }}
            >
              Confirm updates
            </Button>
            <Button
              size="small"
              color="secondary"
              variant="outlined"
              onClick={() => {
                rejectupdates(updateid);
                setupdatedialog(false);
              }}
            >
              Reject updates
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
    );
  };
  const confirmtermination = () => {
    return (
      <div>
        <Dialog
          fullWidth={true}
          maxWidth={"sm"}
          open={confirmterminationdailog}
          onClose={() => {
            setconfirmTerminationdialg(false);
          }}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title" align="center">
            Confirm Termination
          </DialogTitle>
          <DialogContent>
            <Grid
              container
              align="flex-start"
              justify="center"
              direction="row"
              spacing={2}
            >
              <Grid item xs={12}>
                Do you want to rate your employer?
                <Button
                  size="small"
                  onClick={() => {
                    setViewrating(true);
                  }}
                >
                  Yes
                </Button>
                <Button
                  size="small"
                  onClick={() => {
                    setViewrating(false);
                    setRatingentry([]);
                  }}
                >
                  No
                </Button>
                {viewrating ? <Ratingdialog /> : null}
              </Grid>{" "}
              {/* <DialogContentText align="center"> */}
              <Grid item xs={12}>
                Would you like to fill this survey about your employer?
                <Button
                  size="small"
                  onClick={() => {
                    setViewsurvey(true);
                  }}
                >
                  Yes
                </Button>
                <Button
                  size="small"
                  onClick={() => {
                    setViewsurvey(false);
                    setChoiceentry([]);
                  }}
                >
                  No
                </Button>
                {/* </DialogContentText> */}
                {/* </DialogContentText> */}
                {viewsurvey ? <Survey /> : null}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                confirmterminationapi();
              }}
              color="primary"
            >
              Confirm termination
            </Button>
            <Button
              onClick={() => {
                setconfirmTerminationdialg(false);
              }}
              color="primary"
            >
              Back
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };
  useEffect(() => {
    getTerminationapi();
  }, []);

  const isloading = () => {
    return (
      <Grid
        container
        justify="flex-end"
        alignItems="center"
        // container
        // spacing={0}
        direction="column"
        // alignItems="center"
        // justify="center"
        // // display="flex"
        // style={{ minHeight: "10vh" }}
      >
        <Grid item xs={6} style={{ marginTop: 100 }}>
          <CircularProgress />
        </Grid>
      </Grid>
    );
  };
  const terminateemployee = async () => {
    // await setRatingsurvey({"ratingSurvey":ratingentry})
    // await setChoicesurvey({"choiceSurvey":choiceentry})
    setTermination(false);
    await post("http://3.22.17.212:9000/api/v1/employers/newoffboard", Token, {
      offboard,
      ratingSurvey,
      choiceSurvey,
    }).then((response) => {
      props.refresh();
    });
  };
  const jobupdation = async () => {
    setUpdationdialog(false);
    await update(
      "http://3.22.17.212:9000/api/v1/employers/newEmpUpdate",
      Token,
      employmentupdate
    ).then((response) => {
      props.refresh();
      setEmploymentupdate({
        jobProfile: "",
        jobCategory: "",
        jobTitle: "",
        jobDescription: "",
      });
    });
  };
  const sendComment = async () => {
    await post(
      "http://3.22.17.212:9000/api/v1/employers/post-comments",
      Token,
      comentdata
    ).then((response) => {});
  };
  const confirmupdates = async (updateid) => {
    await put(
      "http://3.22.17.212:9000/api/v1/employers/confirmEmpUpdate/" + updateid,
      Token,
      ""
    ).then((response) => {
      props.refresh();
    });
  };
  const rejectupdates = async (updateid) => {
    await put(
      "http://3.22.17.212:9000/api/v1/employers/rejectEmpUpdate/" + updateid,
      Token,
      ""
    ).then((response) => {
      props.refresh();
    });
  };
  const getTerminationapi = async () => {
    setLoading(true);

    await get(
      "http://3.22.17.212:9000/api/v1/resManager/job/offboardTypes/",
      Token
    ).then((response) => {
      setOffboardTypes(response.data);
    });
    await get(
      "http://3.22.17.212:9000/api/v1/resManager/job/leaving-reasons/",
      Token
    ).then((response) => {
      setLeavingReasons(response.data);
    });
    await get(
      "http://3.22.17.212:9000/api/v1/resManager/job/surveyq/employer/rating/",
      Token
    ).then((response) => {
      setRatingdata(response.data);
      response.data.map((index) => {
        // let newdata={"question":index.id,"answerRating":index.question}
        setRatingentry((prevvalue) => {
          return { ...prevvalue, [index.id.toString()]: "" };
        });
        // setRatingentry({"question":index.id,"answerRating":index.question})

        // setRatingentry(ratingentry.push( {question:index.id}))
      });
    });
    await get(
      "http://3.22.17.212:9000/api/v1/resManager/job/surveyq/employer/choice/",
      Token
    ).then((response) => {
      setChoicedata(response.data);
      response.data.map((index) => {
        setChoiceentry((prevvalue) => {
          let n = index.id.toString();

          return { ...prevvalue, [index.id.toString()]: "" };
        });
      });
    });
    await get(
      "http://3.22.17.212:9000/api/v1/resManager/job/categories/",
      Token
    ).then((response) => {
      setJobcategory(response.data);
    });
    setLoading(false);
  };

  const Ratingdialog = () => {
    return (
      <div>
        {ratingdata.map((question1, index) => (
          <>
            <div>
              <p>
                {question1.question} {question1.id}
              </p>
              <Rating
                // id={}
                name={question1.id}
                value={ratingSurvey[question1.id]}
                onChange={(event, newValue) => {
                  setRatingentry({
                    ...ratingSurvey,
                    [event.currentTarget.name]: newValue,
                  });
                }}
              />
            </div>
          </>
        ))}
      </div>
    );
  };
  const Updationdialouge = () => {
    return (
      <Grid
        container
        align="center"
        justify="center"
        direction="row"
        spacing={2}
      >
        <Grid item xs={12}>
          <Card>
            <CardHeader title=" Job Updation" align="center"></CardHeader>
            <CardContent>
              <Grid item xs={12}>
                <FormControl fullWidth required>
                  <InputLabel id="demo-simple-select-required-label">
                    Jobcategory
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-required-label"
                    id="demo-simple-select-required"
                    value={employmentupdate.jobCategory}
                    onChange={(event) => {
                      setEmploymentupdate({
                        ...employmentupdate,
                        jobCategory: event.target.value,
                      });
                    }}
                    // className={classes.selectEmpty}
                  >
                    {Jobcategory.map((type, index) => (
                      <MenuItem value={type.id} key={index}>
                        {type.positionCategory}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="standard-basic"
                  value={employmentupdate.jobTitle}
                  label="Jobtitle"
                  fullWidth
                  autoFocus
                  margin="dense"
                  type="text"
                  onChange={(event) => {
                    setEmploymentupdate({
                      ...employmentupdate,
                      jobTitle: event.target.value,
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  autoFocus
                  margin="dense"
                  id="newMessage"
                  label="Description"
                  type="text"
                  fullWidth
                  multiline
                  // variant="outlined"
                  rows={4}
                  value={employmentupdate.jobDescription}
                  onChange={(event) => {
                    setEmploymentupdate({
                      ...employmentupdate,
                      jobDescription: event.target.value,
                    });
                  }}
                />
              </Grid>
            </CardContent>
            <CardActions>
              <Grid container justify="flex-end" direction="row">
                <Button
                  onClick={() => {
                    jobupdation();
                  }}
                  color="primary"
                >
                  Update
                </Button>
                <Button
                  onClick={() => {
                    setUpdationdialog(false);
                  }}
                  color="primary"
                >
                  Back
                </Button>
              </Grid>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    );
  };
  const Survey = () => {
    return (
      <div>
        {Choice.map((choice) => (
          <div>
            <p>{choice.question}</p>
            <RadioGroup
              row
              name={choice.id.toString()}
              value={choiceSurvey[choice.id]}
              onChange={(event, newValue) => {
                setChoiceentry({
                  ...choiceSurvey,
                  [event.currentTarget.name.toString()]: event.target.value,
                });
              }}
              aria-label="position"
              defaultValue="top"
            >
              <FormControlLabel
                value="Yes"
                control={<Radio color="primary" size="small" />}
                label="Yes"
              />
              <FormControlLabel
                value="No"
                control={<Radio color="primary" size="small" />}
                label="No"
              />
              <FormControlLabel
                value="Maybe"
                control={<Radio color="primary" size="small" />}
                label="Maybe"
              />
              <FormControlLabel
                value="NotApplicable"
                control={<Radio color="primary" size="small" />}
                label="NotApplicable"
              />
            </RadioGroup>
          </div>
        ))}
      </div>
    );
  };
  const AddComment = () => {
    return (
      <Grid
        container
        align="center"
        justify="center"
        direction="row"
        spacing={2}
      >
        <Grid item xs={12} md={12} lg={12} xl={12}>
          <Card>
            <CardHeader title="Comment" align="center"></CardHeader>

            <CardContent>
              <TextField
                autoFocus
                margin="dense"
                id="newMessage"
                label="Please add your comment"
                type="text"
                fullWidth
                multiline
                // variant="outlined"
                rows={4}
                value={comentdata.comment}
                onChange={(event) => {
                  setCommentdata({
                    ...comentdata,
                    comment: event.target.value,
                  });
                }}
              />
            </CardContent>
            <CardActions>
              <Grid container justify="flex-end" direction="row">
                <Button
                  onClick={() => {
                    setcommentdialog(false);
                    sendComment();
                  }}
                  color="primary"
                >
                  Add comment
                </Button>
                <Button
                  onClick={() => {
                    setcommentdialog(false);
                  }}
                  color="primary"
                >
                  Back
                </Button>
              </Grid>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    );
  };
  const Terminationdialouge = () => {
    return (
      <Grid
        container
        align="center"
        justify="center"
        direction="row"
        spacing={2}
      >
        <Grid item xs={12}>
          <Card>
            <CardHeader title="Termination" align="center"></CardHeader>
            {Loading ? (
              isloading()
            ) : (
              <Grid>
                <CardContent>
                  <Grid
                    container
                    align="flex-start"
                    justify="center"
                    direction="row"
                    spacing={2}
                  >
                    <Grid item xs={12}>
                      <FormControl fullWidth required>
                        <InputLabel id="demo-simple-select-required-label">
                          Offboarddata Type
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-required-label"
                          id="demo-simple-select-required"
                          value={offboard.offboardType}
                          onChange={(event) => {
                            setOffboard({
                              ...offboard,
                              offboardType: event.target.value,
                            });
                          }}
                          // className={classes.selectEmpty}
                        >
                          {offboardingTypes.map((type, index) => (
                            <MenuItem value={type.id} key={index}>
                              {type.offboardType}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth required>
                        <InputLabel id="demo-simple-select-required-label">
                          Leaving Reason
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-required-label"
                          id="demo-simple-select-required"
                          value={offboard.leavingReason}
                          onChange={(event) => {
                            setOffboard({
                              ...offboard,
                              leavingReason: event.target.value,
                            });
                          }}
                          // className={classes.selectEmpty}
                        >
                          {leavingReason.map((reason, index) => (
                            <MenuItem value={reason.id} key={index}>
                              {reason.reason}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        autoFocus
                        margin="dense"
                        id="newMessage"
                        label="Description"
                        type="text"
                        fullWidth
                        multiline
                        // variant="outlined"
                        rows={4}
                        value={offboard.description}
                        onChange={(event) => {
                          setOffboard({
                            ...offboard,
                            description: event.target.value,
                          });
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        name="EndDate"
                        label="EndDate"
                        InputLabelProps={{ shrink: true, required: true }}
                        type="date"
                        value={offboard.endDate}
                        onChange={(event) => {
                          setOffboard({
                            ...offboard,
                            endDate: event.target.value,
                          });
                        }}
                      />
                    </Grid>
                    {/* <DialogContentText align="center"> */}
                    <Grid item xs={12}>
                      Do you want to rate your employer?
                      <Button
                        size="small"
                        onClick={() => {
                          setViewrating(true);
                        }}
                      >
                        Yes
                      </Button>
                      <Button
                        size="small"
                        onClick={() => {
                          setViewrating(false);
                          setRatingentry([]);
                        }}
                      >
                        No
                      </Button>
                      {viewrating ? <Ratingdialog /> : null}
                    </Grid>{" "}
                    {/* <DialogContentText align="center"> */}
                    <Grid item xs={12}>
                      Would you like to fill this survey about your employer?
                      <Button
                        size="small"
                        onClick={() => {
                          setViewsurvey(true);
                        }}
                      >
                        Yes
                      </Button>
                      <Button
                        size="small"
                        onClick={() => {
                          setViewsurvey(false);
                          setChoiceentry([]);
                        }}
                      >
                        No
                      </Button>
                      {viewsurvey ? <Survey /> : null}
                    </Grid>
                  </Grid>
                </CardContent>
                <CardActions>
                  <Grid container justify="flex-end">
                    <Button
                      onClick={() => {
                        setTermination(false);
                      }}
                      color="primary"
                    >
                      Back
                    </Button>
                    <Button
                      onClick={() => {
                        terminateemployee();
                      }}
                      color="primary"
                    >
                      Terminate
                    </Button>
                  </Grid>
                </CardActions>
              </Grid>
            )}
          </Card>
        </Grid>
      </Grid>
    );
  };
  return (
    <div>
      <Box p={3}>
        {data.length === 0 ? (
          <h1>You have not yet onboarded any job</h1>
        ) : (
          <Grid container justify="flex-start" alignItems="center" spacing={2}>
            {commentdialog === true ? (
              AddComment()
            ) : Termination === true ? (
              Terminationdialouge()
            ) : updationdialouge === true ? (
              Updationdialouge()
            ) : (
              <MaterialTable
                icons={tableIcons}
                title=""
                columns={[
                  {
                    title: "Avatar",
                    render: () => <Avatar />,
                  },
                  {
                    field: "company.companyName",
                    title: "Company Name",
                    // render: (rowData) => new Date(rowData.createdOn).toDateString(),
                  },
                  {
                    title: "Start Date",
                    field: "jobDetails.startDate",
                  },
                  { title: "End Date", field: "jobDetails.endDate" },
                  { title: "Job Title", field: "jobDetails.jobTitle" },
                  {
                    title: "Job category",
                    field: "jobDetails.job_category_field",
                  },
                  {
                    title: "Termination",
                    // field: "codeStatus",
                    render: (rowData) => (
                      <>
                        {rowData.showTerminate ? (
                          <Button
                            size="small"
                            color="primary"
                            variant="outlined"
                            onClick={() => {
                              setTermination(true);
                              setOffboard({
                                ...offboard,
                                jobProfile: rowData.jobDetails.id,
                              });
                            }}
                          >
                            Terminate
                          </Button>
                        ) : null}
                        {rowData.showConfirmTermination ? (
                          <Button
                            size="small"
                            color="primary"
                            variant="outlined"
                            onClick={() => {
                              setconfirmTerminationdialg(true);
                              setoffboardid(rowData.offboard.id);
                            }}
                          >
                            Confirm Termination
                          </Button>
                        ) : null}
                      </>
                    ),
                  },
                  {
                    title: "Updation",
                    render: (rowData) => (
                      <>
                        {rowData.showUpdate ? (
                          <Button
                            size="small"
                            color="primary"
                            variant="outlined"
                            onClick={() => {
                              setUpdationdialog(true);
                              setEmploymentupdate({
                                ...employmentupdate,
                                jobProfile: rowData.jobDetails.id,
                              });
                            }}
                          >
                            update
                          </Button>
                        ) : null}
                        {rowData.showConfirmRejectUpdates ? (
                          <Grid container justify="space-evenly">
                            <Button
                              size="small"
                              color="primary"
                              variant="outlined"
                              onClick={() => {
                                setcurrentupdateid(rowData.id);
                                setupdateid(rowData.empUpdate[0].id);
                                setupdatedialog(true);
                              }}
                            >
                              View updates
                            </Button>
                          </Grid>
                        ) : null}
                      </>
                    ),
                  },
                  {
                    title: "Comment",
                    field: "approvedFlag",
                    render: (rowData) => (
                      <>
                        <Button
                          size="small"
                          color="primary"
                          variant="outlined"
                          onClick={() => {
                            // getcommentbyemployer(row.company.employer)
                            setcommentdialog(true);
                            setCommentdata({
                              ...comentdata,
                              employer: rowData.company.employer,
                            });
                          }}
                        >
                          Comment
                        </Button>
                      </>
                    ),
                  },
                ]}
                data={props.data}
                // actions={[{}]}
                options={{
                  sorting: true,
                  paging: false,
                  search: true,
                  grouping: true,
                  actionsColumnIndex: -1,
                  headerStyle: {
                    backgroundColor: "#031a26",
                    color: "#FFF",
                    fontWeight: "bold",
                  },
                  // toolbar: false,
                }}
              />
            )}

            {confirmtermination()}
            {updatedilaogconfirmation()}
          </Grid>
        )}
      </Box>
    </div>
  );
}

export default EmployerList;
