import React, { useEffect, useState } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Tabs,
  Tab,
  Box,
  Button,
  ButtonGroup,
  Divider
} from "@material-ui/core/";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import Employeelocation from '../Employeelocation/Employeelocation'
import Employeedetails from '../Employeedetails/Employeedetails'


import AppBar from "@material-ui/core/AppBar";
import { TabRounded } from "@material-ui/icons";

const styles = (theme) => ({});
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 500,
  },
  profileCardHeight: {
    minHeight: 300,
    // minWidth: window.innerWidth/3
  },
  tabs: {
    flexGrow: 1,
    flexBasis: 'auto'
  },
  expansionWidth: {
    width: "100%",
  },
  marginTop: {
    marginTop: 20,
  },
  demo2: {
    backgroundColor: "#fff",
  },
}));
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Grid
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </Grid>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}
const StyledTabs = withStyles({
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    "& > span": {
      maxWidth: 40,
      width: "100%",
      backgroundColor: '#f5f5f5',
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    color: "#000000",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    "&:focus": {
      opacity: 1,
    },
  },
}))((props) => <Tab disableRipple {...props} />);
export default function Index(props) {

  useEffect(() => {

  }, []);
  const [value, setValue] = React.useState(0);
  const theme = useTheme();

  const [employerProfile, setemployerProfile] = useState(true);
  const [employerLocation, setemployerLocation] = useState(false);

  const handleemployerProfile = () => {
    setemployerProfile(true);
    setemployerLocation(false);
  };

  const handleemployerLocation = () => {
    setemployerProfile(false);
    setemployerLocation(true);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  // const { classes } = this.props;
  const classes = useStyles();

  return (

      <Grid
        container
        spacing={2}
        justify="flex-start"
      >
            <Grid
              item
              container
              direction="row"
              justify="space-between"
              alignItems="center"
              spacing={3}
            >

              <Grid item xs={6} md={6}>
              <Typography
                component={"span"}
                variant="h4"
                display="block"
                align="left"
                // style={{ padding: 20}}
              >
                My Info
              </Typography>
              </Grid>

              <Grid item>
              <ButtonGroup
                    color="primary"
                    aria-label="contained primary button group"
                    // style={{padding: 10}}
                  >
                    <Button
                      variant={employerProfile === true ? "contained" : null}
                      onClick={() => handleemployerProfile()}
                      style={{minWidth: 100}}
                    >
                    Company Profile
                    </Button>
                    <Button
                      // disabled={addressDisplay}
                      variant={employerLocation === true ? "contained" : null}
                      onClick={() => handleemployerLocation()}
                      style={{minWidth: 100}}
                    >
                      Company Location
                    </Button>
                  </ButtonGroup>
              </Grid>

              <Grid item xs={12}>
                <Divider/>
              </Grid>
              {/* <Grid item xs={12} md={12}> */}
              {/* </Grid> */}
              <Grid item xs={12} md={12} 
              // style={{marginLeft:20, marginRight: 20}}
              >
                {employerProfile ? <Employeedetails /> : null}
                {employerLocation ? <Employeelocation /> : null}
              </Grid>

            </Grid>
        <Grid>
        </Grid>
      </Grid>
  );
}
