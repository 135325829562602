import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  CircularProgress,
  Grid,
  Typography,
  Paper,
  Avatar,
  Box,
} from "@material-ui/core/";
let token = "";
let id = "";
const api = "http://3.22.17.212:9000";
const styles = (theme) => ({});
class index extends Component {
  state = {
    allComments: [],

    isLoading: true,
  };

  isloading() {
    return (
      <>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="flex-start"
          display="flex"
          style={{ minHeight: "0vh" }}
        >
          <CircularProgress />
        </Grid>
      </>
    );
  }

  async fetchAllComments() {
    let response = await fetch(api + "/api/v1/employers/" + id + "/comments", {
      headers: {
        Authorization: token,
      },
    });
    response = await response.json();
    // console.log('allcomments:', response)
    this.setState({ allComments: response });
  }

  componentDidMount() {
    token = localStorage.getItem("Token");
    id = localStorage.getItem("id");

    this.fetchAllComments();

    this.setState({ isLoading: false });
  }

  render() {
    return (
      <>
        {this.state.isLoading ? (
          this.isloading()
        ) : (
          <>
            <Grid
              container
              justify="flex-start"
              alignItems="center"
              spacing={1}
            >
              <Grid item xs={12}>
                <Typography component={"span"} variant="h4">
                  Comments
                </Typography>
              </Grid>
            </Grid>

            {this.state.allComments.map((comment) => (
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
              >
                <Grid item xs={12} md={12} lg={12} xl={12}>
                  <Paper
                    variant="outlined"
                    style={{ padding: 10, marginTop: 20 }}
                  >
                    <Grid
                      container
                      direction="row"
                      justify="flex-start"
                      alignItems="center"
                    >
                      <Grid item xs={1}>
                        <Avatar />
                      </Grid>
                      <Grid item xs={9}>
                        <Box pl={1}>
                          <Grid
                            container
                            direction="column"
                            justify="flex-start"
                            alignItems="flex-start"
                          >
                            <Grid item xs={12}>
                              <Typography component={"span"} variant="h6">
                                {comment.employee_name_field}
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography
                                component={"span"}
                                variant="subtitle1"
                                color="textSecondary"
                              >
                                {comment.company_name_field}
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography component={"span"}>
                                {comment.comment}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography component={"span"} variant="subtitle2">
                          {new Date(comment.created_on).toDateString()}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            ))}
          </>
        )}
      </>
    );
  }
}

export default withStyles(styles)(index);
