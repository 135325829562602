import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, ButtonGroup, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import AdminEvaluationCodes from "../AdminEvaluationCodes";
import ApprovalCodes from "../ApprovalCodes";
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: "100%",
    height: "100%",
  },
}));

export default function FullWidthTabs() {
  const classes = useStyles();
  // const theme = useTheme();
  const [myProfileDisplay, setMyProfile] = useState(true);
  const [addressDisplay, setAddresses] = useState(false);
  const handleMyProfile = () => {
    setMyProfile(true);
    setAddresses(false);
  };
  const handleAddress = () => {
    setMyProfile(false);
    setAddresses(true);
  };

  return (
    <div className={classes.root}>
      <ButtonGroup
        color="primary"
        aria-label="contained primary button group"
        style={{ padding: 10 }}
      >
        <Button
          variant={myProfileDisplay === true ? "contained" : null}
          onClick={() => handleMyProfile()}
          style={{ minWidth: 100 }}
        >
          Enrollment Request
        </Button>
        <Button
          // disabled={addressDisplay}
          variant={addressDisplay === true ? "contained" : null}
          onClick={() => handleAddress()}
          style={{ minWidth: 100 }}
        >
          Verification Request
        </Button>
      </ButtonGroup>

      <Grid item xs={12} md={12} style={{ marginLeft: 20, marginRight: 20 }}>
        {myProfileDisplay ? <ApprovalCodes /> : null}
        {addressDisplay ? <AdminEvaluationCodes /> : null}
      </Grid>
    </div>
  );
}
