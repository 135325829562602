import React, { Component } from "react";
import {
  Grid,
  Box,
  CircularProgress,
  Table,
  TableContainer,
  DialogActions,
  Dialog,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
  Paper,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  Typography,
  InputLabel,
  MenuItem,
  Select,
  GridList,
  GridListTile,
  Card,
  IconButton,
  Chip,
  List,
  ListItem,
  ListItemText,
  Divider

} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { get, update, post } from "../../../API";
import axios from "axios";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import PublishIcon from '@material-ui/icons/Publish';
import SaveIcon from '@material-ui/icons/Save';
import HistoryIcon from '@material-ui/icons/History';
import EditIcon from '@material-ui/icons/Edit';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import InputAdornment from '@material-ui/core/InputAdornment';


let id = "";
let token = "";
let pictures = [];

export class index extends Component {
  state = {
    result: [
      {
        startDate: "",
        endDate: "",
        school: "",
        degree: "",
        status: "",
        update_reason: "",
      },
    ],
    updatedresult: {
      id: "",
      employee: "",
      startDate: "",
      endDate: "",
      school: "",
      degree: "",
      status: "",
      academicType: "",
      update_reason: "",
      created_on: "",
    },
    loading: false,
    history: [
      {
        startDate: "",
        endDate: "",
        school: "",
        degree: "",
        status: "",
        update_reason: "",
      },
    ],
    newAcademics: {
      degree: "",
      startDate: "",
      endDate: "",
      school: "",
      academicType: "",
    },
    historyloading: false,
    historyDialogeOpen: false,
    addnewdialog: false,
    types: [],
    pictureloading: true,
    uploadpictures: "",
    updateDialogOpen: false,
    viewDialogeOpen: false,
    uploadDialogOpen: false,
    walletdialog: false,
    academicDisplayWindow: true,
    historyWindowOpen: false,
    viewRecordWindow: false,
    addWindow: false

  };
  async componentDidMount() {
    this.setState({ loading: true });
    token = localStorage.getItem("Token");
    id = localStorage.getItem("id");
    await this.getAcademics();
    await get(
      "http://3.22.17.212:9000/api/v1/resManager/academic/types/",
      token,
      ""
    ).then((res) => this.setState({ types: res.data }));
    this.setState({ loading: false });
  }

  isloading() {
    return (
      <>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          display="flex"
          style={{ minHeight: "0vh" }}
        >
          <CircularProgress />
        </Grid>
      </>
    );
  }

  async getAcademics() {
    await get(
      "http://3.22.17.212:9000/api/v1/employees/" + id + "/academics",
      token,
      ""
    ).then((response) => {
      console.log("response from api page", response);
      this.setState({ result: response.data });
    });
  }
  loading() {
    return (
      <Grid
        container
        spacing={0}
        direction="row"
        alignItems="center"
        justify="center"
        display="flex"
        style={{ minHeight: "0vh" }}
      >
        <CircularProgress />
      </Grid>
    );
  }
  async getpictures(idsource) {
    this.setState({ viewDialogeOpen: true });
    this.setState({ pictureloading: true });
    await get(
      "http://3.22.17.212:9000/api/v1/employees/" +
      id +
      "/academics/" +
      idsource +
      "/pics",
      token,
      ""
    ).then((res) => {
      pictures = res.data;
      console.log("pictures", pictures);
      this.setState({ pictureloading: false });
    });
  }
  async postpictures(id) {
    this.setState({ uploadDialogOpen: false });

    let bodyFormData = new FormData();
    bodyFormData.append("empEdu", id);
    bodyFormData.append("picture", this.state.uploadpictures);

    await post(
      "http://3.22.17.212:9000/api/v1/employees/post-academic-pic",
      token,
      bodyFormData
    ).then((response) => {
      console.log(response);
    });
  }
  async fetchhistory(index) {
    this.setState({ historyDialogeOpen: true, historyloading: true });
    await get(
      "http://3.22.17.212:9000/api/v1/employees/" + id + "/academics/" + index,
      token,
      ""
    ).then((response) => {
      this.setState({ history: response.data, historyloading: false });
    });
  }
  async updatedetails(id) {
    this.setState({
      updateDialogOpen: false,
    });

    await update(
      "http://3.22.17.212:9000/api/v1/employees/update-academics/" + id,
      token,
      this.state.updatedresult
    ).then((response) => {
      console.log("update response", response);
    });
    await this.getAcademics();
  }
  async addacademics() {
    if(
     this.state.newAcademics.degree.length>0&&
      this.state.newAcademics.startDate.length>0&&
      this.state.newAcademics.endDate.length>0&&
      this.state.newAcademics.school.length>0
     )
      {
    await post(
      "http://3.22.17.212:9000/api/v1/employees/post-academics",
      token,
      this.state.newAcademics
    ).then((response) => {
      console.log(response);
      this.setState({newAcademics:""})
    });
    this.getAcademics();
    this.setState({
      addDialogOpen: !this.state.addDialogOpen,
      academicDisplayWindow: !this.state.acdemicDisplayWindow,
    })
  }
  else{
    if (this.state.newAcademics.startDate.length === 0) {
      this.setState({ admissionstartdatevalidate: true });
    }
    if (this.state.newAcademics.endDate.length === 0) {
      this.setState({ admissionenddatevalidate: true });
    }
    if (this.state.newAcademics.school.length === 0) {
      this.setState({ admissionschoolvalidate: true });
    }
    if (this.state.newAcademics.degree.length === 0) {
      this.setState({ admissiondegreevalidate: true });
    }
  }
  }
  async getamount() {
    await axios
      .get(
        "http://3.22.17.212:9000/api/v1/resManager/serviceAPI/?serviceName=AcademicVerification"
      )
      .then((res) => this.setState({ amount: res.data[0].serviceCharge }));
  }
  async pay() {
    let transactionid = Math.floor(
      10000000000000000 + Math.random() * 9000000000000000
    );
    let headers1 = {
      headers: {
        Authorization: token,
        "Content-Type": "multipart/form-data",
      },
    };

    await axios
      .post(
        "http://3.22.17.212:9000/wallet/transaction?type=DEBIT&amount=" +
        this.state.amount +
        "&description=" +
        transactionid,
        "",
        headers1
      )
      .then((response) => {
        if (response.status === 200) {
          this.senddetails(transactionid);
        }
      });
  }
  async senddetails(tid) {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", token);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      "http://3.22.17.212:9000/api/v1/accounts/paymentDetails?service=AcademicVerification&trxId=" +
      tid +
      "&paymentMethod=CARD&amount=" +
      this.state.amount,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => this.verification())
      .catch((error) => console.log("error", error));
  }
  async verification() {
    let bodyFormData = {
      verType: "Academic",
      objId: this.state.currentid,
    };
    await post(
      "http://3.22.17.212:9000/api/v1/codes/evaluation/new-code",
      token,
      bodyFormData
    ).then((res) => {
      window.location.reload(false);
    });
  }
  gettable() {
    return (
      <div>
        {this.state.result.length === 0 ? (
          <>
            {this.state.addWindow? this.addNewAcademicWindow() : (
              <Grid container spacing={3} justify="space-between">
              <Grid item xs={12}>
                <Paper style={{ padding: 20 }} elevation={3}>
                  <Box
                    p={1}
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    style={{ height: "50vh" }}
                  >
                    <Typography variant="h4" gutterBottom align="center">
                      Add academics to improve ratings
                    </Typography>
  
                    <Grid container justify="center" style={{ marginTop: 50 }}>
                      <Button
                      size='large'
                      startIcon={<AddCircleOutlineIcon/>}
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          this.setState({ addWindow: !this.state.addWindow });
                        }}
                      >
                        Add Academics
                      </Button>
                    </Grid>
                  </Box>
                </Paper>
              </Grid>
            </Grid>
            )}
          </>
          
        ) : (
            <Grid>
              <Box p={2}>
                <Grid
                  container
                  direction="column"
                  justify="center"
                  alignItems="flex-end"
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      this.setState({ addnewdialog: true });
                    }}
                  >
                    Add Academics
                </Button>
                </Grid>
              </Box>
              <TableContainer component={Paper} elevation={16} p={3}>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow style={{ backgroundColor: "black" }}>
                      {[
                        "StartDate",
                        "EndDate",
                        "School",
                        "Degree",
                        "Academic Type",
                        "Status",
                        "Createdon",
                        "Pictures",
                        "Update",
                        "History",
                        "Verification",
                      ].map((text, index) => (
                        <TableCell
                          key={index}
                          style={{ fontWeight: "bolder" }}
                          align="center"
                          padding="none"
                        >
                          {text}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {this.state.result.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell padding="default" align="center">
                          {row.startDate}
                        </TableCell>
                        <TableCell padding="default" align="center">
                          {row.endDate}
                        </TableCell>
                        <TableCell padding="default" align="center">
                          {row.school}
                        </TableCell>
                        <TableCell padding="default" align="center">
                          {row.degree}
                        </TableCell>
                        <TableCell padding="default" align="center">
                          {row.academicType_name_field}
                        </TableCell>

                        <TableCell padding="default" align="center">
                          {row.status}
                        </TableCell>
                        <TableCell
                          padding="default"
                          component="th"
                          align="center"
                        >
                          {new Date(row.created_on).toDateString()}
                        </TableCell>
                        <TableCell padding="default" align="center">
                          <Grid
                            container
                            display="flex"
                            direction="row"
                            alignItems="center"
                            justify="center"
                            spacing={1}
                          >
                            <Grid item>
                              <Button
                                size="small"
                                color="primary"
                                variant="outlined"
                                onClick={() => this.getpictures(row.id)}
                              >
                                View
                            </Button>
                            </Grid>
                            <Grid item>
                              <Button
                                size="small"
                                color="secondary"
                                variant="outlined"
                                onClick={() =>
                                  this.setState(
                                    {
                                      uploadDialogOpen: true,
                                      pictureid: row.id,
                                    },
                                    console.log(
                                      "picturedid",
                                      this.state.pictureid
                                    )
                                  )
                                }
                              >
                                upload
                            </Button>
                            </Grid>
                          </Grid>
                        </TableCell>
                        <TableCell padding="default" align="center">
                          <Button
                            size="small"
                            disabled={row.status === "Audit In Progress"}
                            color="primary"
                            variant="outlined"
                            onClick={() =>
                              this.setState({
                                updateDialogOpen: true,
                                selectedIndex: index,
                                updatedresult: this.state.result[index],
                              })
                            }
                          >
                            Update
                        </Button>
                        </TableCell>
                        <TableCell padding="default" align="center">
                          <Button
                            size="small"
                            variant="outlined"
                            color="secondary"
                            onClick={() => this.fetchhistory(row.id)}
                          >
                            History
                        </Button>
                        </TableCell>
                        {row.showVerifyOnTrac_btn === true ? (
                          <TableCell padding="default" align="center">
                            <Button
                              size="small"
                              variant="outlined"
                              color="default"
                              onClick={() => {
                                this.setState({
                                  currentid: row.id,
                                  walletdialog: true,
                                });
                                this.getamount();
                              }}
                            >
                              Request for verification
                          </Button>
                          </TableCell>
                        ) : null}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          )}
        <Dialog
          open={this.state.updateDialogOpen}
          onClose={() =>
            this.setState({
              updateDialogOpen: false,
              buttondisabled: "disabled",
            })
          }
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title" align="center">
            Update Academics
          </DialogTitle>
          <DialogContent>
            <DialogContentText align="center">
              Enter the details of your academics to be updated
            </DialogContentText>

            <Grid
              container
              justify="flex-start"
              direction="row"
              alignItems="center"
              spacing={3}
            >
              <Grid item xs={12}>
                <TextField
                  required
                  name="Start date"
                  label="Admission Date"
                  defaultValue={this.state.updatedresult.startDate}
                  onChange={(event) => {
                    this.setState({
                      updatedresult: {
                        ...this.state.updatedresult,
                        startDate: event.target.value,
                      },
                    });
                    console.log(event.target.value);
                  }}
                  type="month"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  id="dob"
                  label="Finish Date"
                  defaultValue={this.state.updatedresult.endDate}
                  onChange={(event) => {
                    this.setState({
                      updatedresult: {
                        ...this.state.updatedresult,
                        endDate: event.target.value,
                      },
                    });
                    console.log(event.target.value);
                  }}
                  type="month"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  id="middleName"
                  label="School"
                  defaultValue={this.state.updatedresult.school}
                  onChange={(event) =>
                    this.setState({
                      updatedresult: {
                        ...this.state.updatedresult,
                        school: event.target.value,
                      },
                    })
                  }
                  type="text"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  required
                  id="surname"
                  label="Degree"
                  defaultValue={this.state.updatedresult.degree}
                  onChange={(event) => {
                    this.setState({
                      updatedresult: {
                        ...this.state.updatedresult,
                        degree: event.target.value,
                      },
                    });
                  }}
                  type="text"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel id="state">Academic type*</InputLabel>
                <Select
                  id="Academic type"
                  onChange={(event) => {
                    this.setState({
                      updatedresult: {
                        ...this.state.updatedresult,
                        academicType: event.target.value,
                      },
                    });
                  }}
                  defaultValue={this.state.updatedresult.academicType}
                  fullWidth
                >
                  {this.state.types.map((type, index) => (
                    <MenuItem id={type.id} value={type.id} key={index}>
                      {type.academicType}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  id="reasonForUpdating"
                  label="Reason for updating:"
                  helperText="update reason can be less than 250 characters"
                  onChange={(event) =>
                    this.setState({
                      updatedresult: {
                        ...this.state.updatedresult,
                        update_reason: event.target.value,
                      },
                    })
                  }
                  type="text"
                  fullWidth
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions style={{ padding: 10 }}>
            <Button
              color="primary"
              // disabled={this.state.updatedresult.update_reason.length === 0}
              variant="contained"
              onClick={() => {
                this.updatedetails(this.state.updatedresult.id);
              }}
            >
              Update
            </Button>
            <Button
              color="secondary"
              variant="contained"
              onClick={() =>
                this.setState({
                  updateDialogOpen: false,
                  selectedIndex: -1,
                  buttondisabled: "disabled",
                })
              }
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={this.state.addnewdialog}
          onClose={() =>
            this.setState({
              updateDialogOpen: false,
              buttondisabled: "disabled",
            })
          }
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title" align="center">
            Add new academics
          </DialogTitle>
          <DialogContent>
            <DialogContentText align="center">
              Enter the details of your academics to be added
            </DialogContentText>

            <Grid
              container
              justify="flex-start"
              direction="row"
              alignItems="center"
              spacing={3}
            >
              <Grid item xs={12}>
                <TextField
                  required
                  name="Start date"
                  InputLabelProps={{ shrink: true, required: true }}
                  label="Admission Date"
                  onChange={(event) => {
                    this.setState({
                      newAcademics: {
                        ...this.state.newAcademics,
                        startDate: event.target.value,
                      },
                    });
                    console.log(event.target.value);
                  }}
                  type="month"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  name="End date"
                  label="Finish Date"
                  InputLabelProps={{ shrink: true, required: true }}
                  onChange={(event) => {
                    this.setState({
                      newAcademics: {
                        ...this.state.newAcademics,
                        endDate: event.target.value,
                      },
                    });
                    console.log(event.target.value);
                  }}
                  type="month"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  id="School"
                  label="School"
                  onChange={(event) =>
                    this.setState({
                      newAcademics: {
                        ...this.state.newAcademics,
                        school: event.target.value,
                      },
                    })
                  }
                  type="text"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel id="state">Academic type</InputLabel>
                <Select
                  required
                  id="Academic type"
                  onChange={(event) => {
                    this.setState({
                      newAcademics: {
                        ...this.state.newAcademics,
                        academicType: event.target.value,
                      },
                    });
                  }}
                  fullWidth
                >
                  {this.state.types.map((type) => (
                    <MenuItem id={type.id} value={type.id} key={type.id}>
                      {type.academicType}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  required
                  id="Degree"
                  label="Degree"
                  onChange={(event) => {
                    this.setState({
                      newAcademics: {
                        ...this.state.newAcademics,
                        degree: event.target.value,
                      },
                    });
                  }}
                  type="text"
                  fullWidth
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions style={{ padding: 10 }}>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                this.addacademics();
              }}
            >
              Add
            </Button>
            <Button
              color="secondary"
              variant="contained"
              onClick={() =>
                this.setState({
                  addnewdialog: false,
                  selectedIndex: -1,
                  buttondisabled: "disabled",
                })
              }
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          // fullWidth={"md"}
          maxWidth={"md"}
          open={this.state.historyDialogeOpen}
          onClose={() => this.setState({ historyDialogeOpen: false })}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="form-dialog-title" align="center">
            Academics History
          </DialogTitle>
          {/* <DialogContent> */}
          <TableContainer p={3}>
            <Table stickyHeader>
              <TableHead>
                <TableRow style={{ backgroundColor: "black" }}>
                  {[
                    "Admission Date",
                    "Finish Date",
                    "School",
                    "Degree",
                    "Status",
                    "Created On",
                  ].map((text, index) => (
                    <TableCell
                      key={index}
                      style={{ fontWeight: "bolder" }}
                      align="center"
                      key={index}
                    >
                      {text}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              {this.state.historyloading ? (
                this.loading()
              ) : (
                  <TableBody>
                    {this.state.history.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell align="center"> {row.startDate} </TableCell>
                        <TableCell align="center">{row.endDate}</TableCell>
                        <TableCell align="center">{row.school}</TableCell>
                        <TableCell align="center">{row.degree}</TableCell>
                        <TableCell align="center">{row.status}</TableCell>
                        <TableCell component="th" align="center">
                          {new Date(row.created_on).toDateString()}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                )}
            </Table>
          </TableContainer>
          {/* </DialogContent> */}
          <DialogActions style={{ padding: 15 }}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() =>
                this.setState({
                  historyDialogeOpen: false,
                  selectedIndex: -1,
                })
              }
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          //  fullWidth={"sm"}
          //  maxWidth={"sm"}
          open={this.state.viewDialogeOpen}
          onClose={() => this.setState({ viewDialogeOpen: false })}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            <Typography variant="subtitle1" gutterBottom align="center">
              View pictures
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Grid
              container
              direction="row"
              justify="space-evenly"
              alignItems="center"
            >
              <GridList
                cellHeight={160}
                style={{ height: 500, width: 500 }}
                cols={3}
              >
                {this.state.pictureloading
                  ? this.loading()
                  : pictures.map((pic, index) => (
                    <GridListTile key={pic.id} cols={1}>
                      <img src={pic.picture} />
                    </GridListTile>

                    // <Grid container>
                    //     <Grid item xs={12}>
                    // <image src={pic.picture}/>
                    //       {/* {pic.picture} */}
                    //     </Grid>
                    //   </Grid>
                  ))}{" "}
              </GridList>
            </Grid>
          </DialogContent>
          <DialogActions style={{ padding: 15 }}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() =>
                this.setState({
                  viewDialogeOpen: false,
                  selectedIndex: -1,
                })
              }
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          // open={this.state.uploadDialogOpen}
          // onClose={() => this.setState({ uploadDialogOpen: false })}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">choose your file</DialogTitle>
          <DialogContent>
            <Grid container p={1}>
              <TextField
                type="file"
                onChange={(event) => {
                  this.setState({
                    uploadpictures: event.target.files[0],
                  });
                }}
              ></TextField>
            </Grid>
            <Box p={1}>
              <Grid container direction="column-reverse" alignItems="flex-end">
                <Button
                  size="small"
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    this.postpictures(this.state.pictureid);
                  }}
                >
                  upload
                </Button>
              </Grid>
            </Box>
          </DialogContent>
        </Dialog>
        {
          <Dialog
            open={this.state.walletdialog}
            onClose={() => this.setState({ walletdialog: false })}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title" align="center" justify="center">
              You need to pay {this.state.amount} for this service from wallet
            </DialogTitle>
            <DialogContent></DialogContent>
            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                onClick={() =>
                  this.setState(
                    {
                      walletdialog: false,
                    },
                    this.pay
                  )
                }
              >
                Pay
              </Button>
              <Button
                color="secondary"
                variant="contained"
                onClick={() =>
                  this.setState({
                    walletdialog: false,
                  })
                }
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        }
      </div>
    );
  }

  getAcademicRecords() {
    return (
      <>
        {this.state.result.length === 0 ? (

          this.gettable()
        ) : (
            <>
              <Grid container direction='column' justify='center' spacing={2} style={{ marginTop: 10 }}>

                {this.state.academicDisplayWindow ? this.allAcademicDisplayWindow() : null}
                {this.state.addDialogOpen ? this.addNewAcademicWindow() : null}
                {this.state.updateDialogOpen ? this.editAcademicWindow(this.state.selectedIndex) : null}
                {this.state.historyWindowOpen ? this.viewHistoryWindow(this.state.selectedIndex) : null}
                {this.state.viewRecordWindow ? this.viewAcademicRecord() : null}

              </Grid>

              {/* {this.updatesnackbar()} */}
              {/* {this.addsnackbar()} */}
            </>
          )}
      </>
    );
  }

  allAcademicDisplayWindow() {
    return (
      <Grid
        item
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
        spacing={3}
      // style={{marginTop:20}}
      >

        <Grid item container xs={12} justify='flex-end'>
          <Button
            size="large"
            color="primary"
            variant="contained"
            startIcon={<AddCircleOutlineIcon />}
            onClick={() => this.setState({
              addDialogOpen: !this.state.addDialogOpen,
              academicDisplayWindow: !this.state.academicDisplayWindow
            })}
          >
            Add New Academic Record
                       {/* Add New address */}
          </Button>
        </Grid>

        {this.state.result.map((row, index) => (
          <Grid
            item
            container
            direction="row"
            justify="center"
            alignItems="center"
            xs={12}
            md={4}
          >
            <Card>

              <Grid container style={{ padding: 20 }} spacing={2}>

                <Grid item
                  container
                  alignItems="center"
                  justify='space-between'
                  direction='row'
                  style={{ background: "#263238" }}
                >
                  <Grid item container alignItems="center"
                    justify='flex-end'
                    direction='row'
                    spacing={1}>

                    {/* <Grid item>
                      <Typography variant='h4' style={{ color: "white" }}>
                        Academic Record&nbsp;{this.state.academicCardNumber++}
                      </Typography>
                    </Grid> */}

                    <Grid item>
                      <IconButton
                        aria-label="histor"
                        component="span"
                        onClick={() => {
                          this.setState({
                            academicDisplayWindow: !this.state.academicDisplayWindow,
                            historyWindowOpen: !this.state.historyWindowOpen,
                          });
                          this.fetchhistory(row.id);
                        }}
                      >
                        <HistoryIcon fontSize='large' style={{ color: "white" }} />
                      </IconButton>

                      <IconButton
                        aria-label="edit"
                        component="span"
                        onClick={() =>
                          this.setState({
                            academicDisplayWindow: !this.state.academicDisplayWindow,
                            updateDialogOpen: !this.state.updateDialogOpen,
                            selectedIndex: index,
                            updatedresult: this.state.result[index],
                          })
                        }
                      >
                        <EditIcon fontSize='large' style={{ color: "white" }} />
                      </IconButton>

                    </Grid>


                  </Grid>

                </Grid>

                {/* <Grid item container xs={12} justify='center'>
                  <Chip
                    icon={<AddAPhotoIcon />}
                    label={row.status}
                    clickable
                    color="primary"
                    href={`http://www.google.com/maps/place/${row.google_coordinate1}+,+${row.google_coordinate2}`}
                  />
                </Grid> */}

                <Grid item xs={12}>
                  <List>
                    <ListItem>
                      <ListItemText primary="School" secondary={row.school} />
                    </ListItem>
                    <Divider />

                    <ListItem>
                      <ListItemText primary="Degree" secondary={row.degree} />
                    </ListItem>
                    <Divider />

                    <ListItem>
                      <ListItemText primary="Admission Date" secondary={row.startDate} />
                    </ListItem>
                    <Divider />

                    <ListItem>
                      <ListItemText primary="Exit Date" secondary={row.endDate} />
                    </ListItem>
                    <Divider />

                    <ListItem>
                      <ListItemText primary="Academic Type" secondary={row.academicType_name_field} />
                    </ListItem>
                    <Divider />

                    <ListItem>
                      <ListItemText primary="Record Created On" secondary={new Date(row.created_on).toDateString()} />
                    </ListItem>
                    <Divider />

                  </List>
                </Grid>

                {this.state.uploadDialogOpen && this.state.selectedIndex === index ? (
                  <Paper variant='outlined'>
                    <Grid item container xs={12} justify="center" alignItems="center" direction="row" spacing={2} style={{ padding: 10 }}>
                      <Grid item container justify="center" xs={12}>
                        <Grid>
                          <Typography variant='subtitle1'>
                            Choose your Image.
                              </Typography>
                        </Grid>
                      </Grid>

                      <Grid item container justify="center" xs={12}>
                        <TextField
                          type="file"
                          onChange={(event) => {
                            this.setState({
                              uploadpictures: event.target.files[0],
                            });
                          }}
                        ></TextField>
                      </Grid>

                      <Grid item container justify="center" xs={12} md={6}>
                        <Button
                          variant="contained"
                          color='Primary'
                          onClick={() => {
                            this.postpictures(this.state.pictureid);
                          }}
                        >
                          Upload
                  </Button>
                      </Grid>

                      <Grid item container justify="center" xs={12} md={6}>
                        <Button
                          variant="contained"
                          color='secondary'
                          onClick={() =>
                            this.setState({
                              uploadDialogOpen: !this.state.uploadDialogOpen,
                            })
                          }
                        >
                          Cancel
                  </Button>
                      </Grid>

                    </Grid>
                  </Paper>
                ) : (
                    <Grid item container xs={12} justify='center'>
                      <Button
                        variant='contained'
                        color='primary'
                        size='medium'
                        // disabled={!row.showVerifyOnTrac_btn}
                        onClick={() =>
                          this.setState(
                            {
                              selectedIndex: index,
                              uploadDialogOpen: true,
                              pictureid: row.id,
                            },
                            console.log(
                              "picturedid",
                              this.state.pictureid
                            )
                          )
                        }
                      >
                        Upload Record
                        </Button>
                    </Grid>

                  )}

                <Grid item container xs={12} justify='center'>
                  <Button
                    variant='contained'
                    color='primary'
                    size='medium'
                    disabled={!row.showVerifyOnTrac_btn}
                    onClick={() => {
                      this.setState({
                        viewRecordWindow: !this.viewRecordWindow,
                        academicDisplayWindow:!this.state.academicDisplayWindow,
                        selectedIndex: index
                      });
                      this.getpictures(row.id);
                    }}
                  >
                    View Record
                        </Button>
                </Grid>

                {this.state.walletLogic && this.state.selectedIndex === index ? (
                  <Paper variant='outlined'>
                    <Grid item container xs={12} justify="center" alignItems="center" direction="row" spacing={2} style={{ padding: 10 }}>
                      <Grid item container justify="center" xs={12}>
                        <Grid>
                          <Typography variant='subtitle1'>
                            You need to pay&nbsp;{this.state.amount}&nbsp;for this service from your wallet, do you want to continue?
                              </Typography>
                        </Grid>
                      </Grid>

                      <Grid item container justify="center" xs={12} md={6}>
                        <Button
                          variant="contained"
                          color='primary'
                          onClick={() =>
                            this.setState(
                              {
                                walletLogic: false,
                              },
                              this.pay
                            )
                          }
                        >
                          Yes
                  </Button>
                      </Grid>

                      <Grid item container justify="center" xs={12} md={6}>
                        <Button
                          variant="contained"
                          color='secondary'
                          onClick={() =>
                            this.setState({
                              walletLogic: false,
                            })
                          }
                        >
                          No
                  </Button>
                      </Grid>

                    </Grid>
                  </Paper>
                ) : (
                    <Grid item container xs={12} justify='center'>
                      <Button
                        variant='contained'
                        color='primary'
                        size='medium'
                        disabled={!row.showVerifyOnTrac_btn}
                        onClick={() => {
                          this.setState({
                            walletLogic: true,
                            currentid: row.id,
                            selectedIndex: index
                          });
                          this.getamount();
                        }}
                      >
                        Request for Verification
                        </Button>
                    </Grid>

                  )}

              </Grid>
            </Card>
          </Grid>
        ))}
      </Grid>
    );
  }

  addNewAcademicWindow() {
    return (
      <Grid container spacing={3}>
        {this.state.result.length === 0 ? <div/>: (
          <Grid item container xs={12} justify="flex-end">
          <Button
            size="large"
            color="primary"
            variant="contained"
            startIcon={<ArrowBackIcon />}
            onClick={() =>
              this.setState({
                addDialogOpen: !this.state.addDialogOpen,
                academicDisplayWindow: !this.state.acdemicDisplayWindow,
              })
            }
          >
            Back
          </Button>
        </Grid>
        )}
        
        <Grid xs={12}>
          <Card>
            <Grid
              container
              justify="center"
              direction="row"
              alignItems="center"
              spacing={3}
              style={{ padding: 20 }}
            >
              <Grid item xs={12} style={{ background: "#263238" }}>
                <Typography variant="h5" style={{ color: "white" }}>
                  Add New Academic Record
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  required
                  name="Start date"
                  InputLabelProps={{ shrink: true, required: true }}
                  label="Admission Date"
                  error={this.state.admissionstartdatevalidate}
                  helperText={
                    this.state.admissionstartdatevalidate ? "Choose a date" : ""
                  }
                  onChange={(event) => {
                    this.setState({
                      newAcademics: {
                        ...this.state.newAcademics,
                        startDate: event.target.value,
                      },
                      admissionstartdatevalidate: false,
                    });
                  }}
                  type="month"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  name="End date"
                  label="Finish Date"
                  InputLabelProps={{ shrink: true, required: true }}
                  error={this.state.admissionenddatevalidate}
                  helperText={
                    this.state.admissionenddatevalidate ? "Choose a date" : ""
                  }
                  onChange={(event) => {
                    this.setState({
                      newAcademics: {
                        ...this.state.newAcademics,
                        endDate: event.target.value,
                      },
                      admissionenddatevalidate: false,
                    });
                    console.log(event.target.value);
                  }}
                  type="month"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  id="School"
                  label="School"
                  error={this.state.admissionschoolvalidate}
                  helperText={
                    this.state.admissionschoolvalidate
                      ? "Please enter your School Name"
                      : ""
                  }
                  onChange={(event) =>
                    this.setState({
                      newAcademics: {
                        ...this.state.newAcademics,
                        school: event.target.value,
                      },
                      admissionschoolvalidate: false,
                    })
                  }
                  type="text"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel id="state">Academic type</InputLabel>
                <Select
                  required
                  id="Academic type"
                  error={this.state.admissiontypevalidate}
                  helperText={
                    this.state.admissiontypevalidate
                      ? "Please choose an option"
                      : ""
                  }
                  onChange={(event) => {
                    this.setState({
                      newAcademics: {
                        ...this.state.newAcademics,
                        academicType: event.target.value,
                      },
                      admissiontypevalidate: false,
                    });
                  }}
                  fullWidth
                >
                  {this.state.types.map((type) => (
                    <MenuItem id={type.id} value={type.id} key={type.id}>
                      {type.academicType}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  required
                  id="Degree"
                  label="Degree"
                  error={this.state.admissiondegreevalidate}
                  helperText={
                    this.state.admissiondegreevalidate
                      ? "Please enter the name of your degree"
                      : ""
                  }
                  onChange={(event) => {
                    this.setState({
                      newAcademics: {
                        ...this.state.newAcademics,
                        degree: event.target.value,
                      },
                      admissiondegreevalidate:false
                    });
                  }}
                  type="text"
                  fullWidth
                />
              </Grid>

              <Grid item>
                <Button
                  color="primary"
                  variant="contained"
                  size="large"
                  onClick={() => {
                    this.addacademics();
                  }}
                  startIcon={<PublishIcon />}
                >
                  Submit Academic Record
                </Button>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    );
  }

  editAcademicWindow(index) {
    return (
      <Grid container spacing={3}>
        <Grid item container xs={12} justify='flex-end'>
          <Button
            size="large"
            color="primary"
            variant="contained"
            startIcon={<ArrowBackIcon />}
            onClick={() => this.setState({
              updateDialogOpen: !this.state.updateDialogOpen,
              academicDisplayWindow: !this.state.academicDisplayWindow
            })}
          >
            Back
                     </Button>
        </Grid>
        <Card>
          <Grid
            container
            justify="center"
            direction="row"
            alignItems="center"
            spacing={3}
            style={{ padding: 20 }}
          >
            <Grid item xs={12} style={{ background: "#263238" }}>
              <Typography variant='h4' style={{ color: "white" }}>
                Edit Identity
            </Typography>
            </Grid>

            <Grid item xs={12}>
              <TextField
                required
                name="Start date"
                label="Admission Date"
                defaultValue={this.state.updatedresult.startDate}
                onChange={(event) => {
                  this.setState({
                    updatedresult: {
                      ...this.state.updatedresult,
                      startDate: event.target.value,
                    },
                  });
                  console.log(event.target.value);
                }}
                type="month"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                id="dob"
                label="Finish Date"
                defaultValue={this.state.updatedresult.endDate}
                onChange={(event) => {
                  this.setState({
                    updatedresult: {
                      ...this.state.updatedresult,
                      endDate: event.target.value,
                    },
                  });
                  console.log(event.target.value);
                }}
                type="month"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                id="middleName"
                label="School"
                defaultValue={this.state.updatedresult.school}
                onChange={(event) =>
                  this.setState({
                    updatedresult: {
                      ...this.state.updatedresult,
                      school: event.target.value,
                    },
                  })
                }
                type="text"
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                required
                id="surname"
                label="Degree"
                defaultValue={this.state.updatedresult.degree}
                onChange={(event) => {
                  this.setState({
                    updatedresult: {
                      ...this.state.updatedresult,
                      degree: event.target.value,
                    },
                  });
                }}
                type="text"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel id="state">Academic type*</InputLabel>
              <Select
                id="Academic type"
                onChange={(event) => {
                  this.setState({
                    updatedresult: {
                      ...this.state.updatedresult,
                      academicType: event.target.value,
                    },
                  });
                }}
                defaultValue={this.state.updatedresult.academicType}
                fullWidth
              >
                {this.state.types.map((type, index) => (
                  <MenuItem id={type.id} value={type.id} key={index}>
                    {type.academicType}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                id="reasonForUpdating"
                label="Reason for updating:"
                helperText="update reason can be less than 250 characters"
                onChange={(event) =>
                  this.setState({
                    updatedresult: {
                      ...this.state.updatedresult,
                      update_reason: event.target.value,
                    },
                  })
                }
                type="text"
                fullWidth
              />
            </Grid>

            <Grid item>
              <Button
                color="primary"
                variant="contained"
                size="large"
                disabled={this.state.buttondisabled}
                onClick={() => {
                  this.setState({
                    updateDialogOpen: !this.state.updateDialogOpen,
                    academicDisplayWindow: !this.state.academicDisplayWindow
                  });
                  this.updatedetails(this.state.updatedresult.id);
                }}
                startIcon={<SaveIcon />}
              >
                Save Address
              </Button>
            </Grid>

          </Grid>
        </Card>
      </Grid>
    );
  }

  viewHistoryWindow(index) {
    return (
      <>
        {this.state.isloading ? (
          this.isloading()
        ) : (
            <Grid container spacing={3}>
              <Grid item container xs={12} justify='flex-end'>
                <Button
                  size="large"
                  color="primary"
                  variant="contained"
                  startIcon={<ArrowBackIcon />}
                  onClick={() => this.setState({ historyWindowOpen: !this.state.historyWindowOpen, academicDisplayWindow: !this.state.academicDisplayWindow })}
                >
                  Back
                     </Button>
              </Grid>
              <Card>
                <Grid
                  container
                  justify="center"
                  direction="row"
                  alignItems="center"
                  spacing={3}
                  style={{ padding: 20 }}
                >
                  <Grid item xs={12} style={{ background: "#263238" }}>
                    <Typography variant='h4' style={{ color: "white" }}>
                      Edit History
        </Typography>
                  </Grid>
                  {this.state.historyloading ? this.isloading() : (
                    <>
                      {this.state.history.map((row, index) => (
                        <>

                          <Grid item container xs={12} justify='space-between' alignItems='center'>
                            <Grid item>
                              <Typography variant='h6'>Record Edited on:&nbsp;&nbsp;{new Date(row.created_on).toDateString()}
                              </Typography>
                            </Grid>
                          </Grid>

                          <Grid item xs={12}>
                            <TextField
                              size='small'
                              fullWidth
                              variant='outlined'
                              id="school"
                              label="School"
                              defaultValue={row.school}
                              InputProps={{
                                readOnly: true,
                              }}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              size='small'
                              variant='outlined'
                              fullWidth
                              id="degree"
                              label="Degree"
                              defaultValue={row.degree}
                              InputProps={{
                                readOnly: true,
                              }}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              size='small'
                              variant='outlined'
                              fullWidth
                              id="admissionDate"
                              label="Admission Date"
                              defaultValue={row.startDate}
                              InputProps={{
                                readOnly: true,
                              }}
                            />
                          </Grid>

                          <Grid item xs={12}>
                            <TextField
                              size='small'
                              variant='outlined'
                              fullWidth
                              id="finishDate"
                              label="Finish Date"
                              defaultValue={row.endDate}
                              InputProps={{
                                readOnly: true,
                              }}
                            />
                          </Grid>

                          <Grid item xs={12}>
                            <TextField
                              size='small'
                              variant='outlined'
                              fullWidth
                              id="status"
                              label="Status"
                              defaultValue={row.status}
                              InputProps={{
                                readOnly: true,
                              }}
                            />
                          </Grid>

                          <Grid item xs={12}>
                            <Divider />
                          </Grid>
                        </>
                      ))}
                    </>
                  )}

                </Grid>
              </Card>
            </Grid>
          )}
      </>
    );
  }

  viewAcademicRecord() {
    return (
      <Grid container spacing={3}>
        <Grid item container xs={12} justify='flex-end'>
          <Button
            size="large"
            color="primary"
            variant="contained"
            startIcon={<ArrowBackIcon />}
            onClick={() => this.setState({
              viewRecordWindow: !this.state.viewRecordWindow,
              academicDisplayWindow: !this.state.academicDisplayWindow
            })}
          >
            Back
                     </Button>
        </Grid>
        <Card>
          <Grid
            container
            justify="center"
            direction="row"
            alignItems="center"
            spacing={3}
            style={{ padding: 20 }}
          >
            <Grid item xs={12} style={{ background: "#263238" }}>
              <Typography variant='h4' style={{ color: "white" }}>
                View Academic Record
            </Typography>
            </Grid>

            <GridList
              cellHeight={160}
              style={{ height: 500, width: 500 }}
              cols={3}
            >
              {this.state.pictureloading
                ? this.loading()
                : pictures.map((pic, index) => (
                  <GridListTile key={pic.id} cols={1}>
                    <img src={pic.picture} />
                  </GridListTile>

                  // <Grid container>
                  //     <Grid item xs={12}>
                  // <image src={pic.picture}/>
                  //       {/* {pic.picture} */}
                  //     </Grid>
                  //   </Grid>
                ))}{" "}
            </GridList>

          </Grid>
        </Card>
      </Grid>
    );
  }

  render() {
    return this.state.loading ? this.loading() : this.getAcademicRecords();
  }
}

export default index;
