import React, { useEffect } from "react";
import {
  TextField,
  Paper,
  Grid,
  Typography,
  Button,
  FormControlLabel,
  Checkbox,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  CircularProgress,
  Chip,
  Box,
  Avatar,
} from "@material-ui/core/";
import { Fab } from "@material-ui/core/";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Axios from "axios";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import MaterialTable from "material-table";
import Search from "@material-ui/icons/Search";
import { forwardRef } from "react";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import ViewColumn from "@material-ui/icons/ViewColumn";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import CheckCircleOutlineOutlinedIcon from "@material-ui/icons/CheckCircleOutlineOutlined";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import PriorityHighOutlinedIcon from "@material-ui/icons/PriorityHighOutlined";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};
function Onboarding(props) {
  const [Token] = React.useState(localStorage.getItem("Token"));
  const [modifyOfferButton, setModifyofferbutton] = React.useState(false);
  const [modifyOfferButtonshow, setModifyofferbuttonshow] = React.useState(
    false
  );
  const [viewOfferButton, setviewOfferButton] = React.useState(false);
  const [oboffer, setOboffer] = React.useState([]);
  const [modifyoboffer, setModifyOboffer] = React.useState({
    jobCategory: "",
    startSalary: "",
    startDate: "",
    jobDescription: "",
    conditions: "",
    jobTitle: "",
    jobCategory_name_field: "",
  });
  const [acceptbutton, setAcceptbutton] = React.useState(false);
  const [rejectbutton, setRejectbutton] = React.useState(false);
  const [modifyofferform, setModifyofferform] = React.useState(false);
  const [Loading, setLoading] = React.useState(true);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [JobCateogry, setJobCateogry] = React.useState([]);
  const [
    generateNewEmployementCodeDialog,
    setGenerateNewEmployementCodeDialog,
  ] = React.useState(false);
  const Modifyofferformdetails = (offer) => {
    setModifyOboffer({
      ...modifyoboffer,
      jobCategory: oboffer[0].jobCategory,
      startSalary: offer[0].startSalary,
      startDate: offer[0].startDate,
      jobDescription: offer[0].jobDescription,
      conditions: offer[0].conditions,
      jobTitle: offer[0].jobTitle,
    });
    setModifyofferbutton(!modifyOfferButton);
  };
  const Modifyofferformpost = async (id) => {
    setviewOfferButton(false);
    console.log("modifyoboffer", modifyoboffer);
    await Axios.put(
      "http://3.22.17.212:9000/api/v1/employers/oboffers/" + id + "/modify",
      modifyoboffer,
      {
        headers: {
          Authorization: Token,
        },
      }
    ).then((response) => {
      console.log("acceptofferresponse", response);
      setOboffer([]);
      props.refresh();
    });
  };
  const confirmoffer = async (id) => {
    setviewOfferButton(false);
    console.log("id", id);
    await Axios.put(
      "http://3.22.17.212:9000/api/v1/employers/oboffers/" + id + "/accept",
      "",
      {
        headers: {
          Authorization: Token,
        },
      }
    ).then((response) => {
      console.log("acceptofferresponse", response);
      setOboffer([]);
      props.refresh();
    });
  };
  const canceloffer = async (id) => {
    setviewOfferButton(false);
    await Axios.put(
      "http://3.22.17.212:9000/api/v1/employers/oboffers/" + id + "/reject",
      "",
      {
        headers: {
          Authorization: Token,
        },
      }
    ).then((response) => {
      console.log("acceptofferresponse", response);
      setOboffer([]);
      props.refresh();
    });
  };
  const handleopen = async (id) => {
    setviewOfferButton(true);
    setLoading(true);
    await Axios.get("http://3.22.17.212:9000/api/v1/employers/oboffers/" + id, {
      headers: {
        Authorization: Token,
      },
    }).then((response) => {
      setOboffer(response.data);
      setModifyofferform(response.data[0].showModify_field);
      setAcceptbutton(response.data[0].showAccept_field);
      setRejectbutton(response.data[0].showReject_field);
      setModifyofferbuttonshow(response.data[0].showModify_field);
      setLoading(false);
    });
    await Axios.get(
      "http://3.22.17.212:9000/api/v1/resManager/job/categories/",
      {
        headers: {
          Authorization: Token,
        },
      }
    ).then((response) => {
      setJobCateogry(response.data);
    });
  };
  useEffect(() => {
    console.log("props from employment requests", props);
  });
  return (
    <div>
      {props.data.length === 0 ? (
        <h2>No Offers</h2>
      ) : (
        <Grid container justify="center" alignItems="center" spacing={2}>
          {viewOfferButton ? (
            <Box p={2}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid container direction="row" justify="flex-end">
                    <Button
                      color="secondary"
                      variant="contained"
                      onClick={() => {
                        setviewOfferButton(false);
                      }}
                    >
                      Back
                    </Button>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Card fullWidth="true">
                    <CardContent>
                      {Loading ? (
                        <Grid
                          container
                          justify="flex-end"
                          alignItems="center"
                          direction="column"
                        >
                          <Grid item xs={6} style={{ marginTop: 100 }}>
                            <CircularProgress />
                          </Grid>
                        </Grid>
                      ) : oboffer.length === 0 ? null : (
                        <>
                          <Typography
                            gutterBottom
                            variant="h5"
                            component="h2"
                            align="center"
                          >
                            Job Details
                          </Typography>
                          <Grid container justify="space-between" spacing={2}>
                            {modifyofferform ? (
                              <Grid item xs={12}>
                                <Grid container direction="row-reverse">
                                  {modifyOfferButton ? (
                                    <>
                                      <Fab
                                        size="small"
                                        color="default"
                                        onClick={() =>
                                          setModifyofferbutton(
                                            !modifyOfferButton
                                          )
                                        }
                                      >
                                        <ArrowBackIcon />
                                      </Fab>
                                    </>
                                  ) : (
                                    <>
                                      <Button
                                        color="primary"
                                        variant="outlined"
                                        size="large"
                                        onClick={() =>
                                          Modifyofferformdetails(oboffer)
                                        }
                                      >
                                        Modify offer
                                      </Button>
                                    </>
                                  )}
                                </Grid>
                              </Grid>
                            ) : null}

                            {modifyOfferButton ? (
                              <>
                                <Grid item xs={12}>
                                  <Paper
                                    variant="outlined"
                                    style={{ padding: 15 }}
                                  >
                                    <Grid
                                      container
                                      justify="flex-start"
                                      direction="row"
                                      alignItems="center"
                                      spacing={2}
                                    >
                                      <Grid item xs={12}>
                                        <FormLabel component="legend">
                                          Enter new offer details:
                                        </FormLabel>
                                      </Grid>
                                      <Grid item fullWidth xs={12}>
                                        <InputLabel id="demo-simple-select-label">
                                          JobCategory
                                        </InputLabel>
                                        <Select
                                          fullWidth
                                          labelId="demo-simple-select-label"
                                          id="demo-simple-select"
                                          value={modifyoboffer.jobCategory}
                                          onChange={(e) => {
                                            setModifyOboffer({
                                              ...modifyoboffer,
                                              jobCategory: e.target.value,
                                            });
                                            console.log(
                                              "modifyoboffer.jobCategory",
                                              modifyoboffer.jobCategory
                                            );
                                          }}
                                        >
                                          {JobCateogry.map((job) => (
                                            <MenuItem
                                              id={job.id}
                                              value={job.id}
                                            >
                                              {job.positionCategory}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                      </Grid>
                                      <Grid item fullWidth xs={12}>
                                        <TextField
                                          id="verifyOntracId"
                                          label="JobTitle"
                                          value={modifyoboffer.jobTitle}
                                          onChange={(e) => {
                                            setModifyOboffer({
                                              ...modifyoboffer,
                                              jobTitle: e.target.value,
                                            });
                                            console.log(
                                              "modifyoboffer.jobCategory",
                                              modifyoboffer.jobTitle
                                            );
                                          }}
                                          type="text"
                                          fullWidth
                                          size="small"
                                        />
                                      </Grid>
                                      <Grid item xs={12}>
                                        <TextField
                                          id="startingSalary"
                                          label="Starting Salary"
                                          value={modifyoboffer.startSalary}
                                          onChange={(e) => {
                                            setModifyOboffer({
                                              ...modifyoboffer,
                                              startSalary: e.target.value,
                                            });
                                            console.log(
                                              "modifyoboffer.jobCategory",
                                              modifyoboffer.startSalary
                                            );
                                          }}
                                          type="number"
                                          fullWidth
                                          size="small"
                                        />
                                      </Grid>
                                      <Grid item xs={12}>
                                        <TextField
                                          id="startingDate"
                                          value={modifyoboffer.startDate}
                                          onChange={(e) => {
                                            setModifyOboffer({
                                              ...modifyoboffer,
                                              startDate: e.target.value,
                                            });
                                            console.log(
                                              "modifyoboffer.jobCategory",
                                              modifyoboffer.startDate
                                            );
                                          }}
                                          type="date"
                                          helperText="Starting date"
                                          fullWidth
                                          size="small"
                                        />
                                      </Grid>

                                      <Grid item xs={12}>
                                        <TextField
                                          id="jobDescription"
                                          label="Job Description"
                                          // variant="outlined"
                                          value={modifyoboffer.jobDescription}
                                          onChange={(e) => {
                                            setModifyOboffer({
                                              ...modifyoboffer,
                                              jobDescription: e.target.value,
                                            });
                                            console.log(
                                              "modifyoboffer.jobCategory",
                                              modifyoboffer.jobDescription
                                            );
                                          }}
                                          type="date"
                                          fullWidth
                                          multiline
                                          rows={3}
                                          size="small"
                                        />
                                      </Grid>

                                      <Grid item xs={12}>
                                        <TextField
                                          id="otherConditions"
                                          label="Other Conditions"
                                          // variant="outlined"
                                          value={modifyoboffer.conditions}
                                          onChange={(e) => {
                                            setModifyOboffer({
                                              ...modifyoboffer,
                                              conditions: e.target.value,
                                            });
                                            console.log(
                                              "modifyoboffer.jobCategory",
                                              modifyoboffer.conditions
                                            );
                                          }}
                                          type="date"
                                          fullWidth
                                          multiline
                                          rows={3}
                                          size="small"
                                        />
                                      </Grid>
                                    </Grid>
                                  </Paper>
                                </Grid>
                              </>
                            ) : (
                              <>
                                {oboffer.length === 1 ? (
                                  <Grid
                                    container
                                    direction="row"
                                    justify="center"
                                    alignItems="center"
                                  >
                                    <Grid item xs={12}>
                                      <Paper
                                        variant="outlined"
                                        style={{ padding: 15 }}
                                      >
                                        <Grid
                                          container
                                          justify="flex-start"
                                          direction="row"
                                          alignItems="center"
                                          spacing={2}
                                          // style={{ padding: 20 }}
                                        >
                                          <Grid item xs={12}>
                                            <Typography
                                              variant="subtitle1"
                                              align="center"
                                              justify="center"
                                              gutterBottom
                                            >
                                              Original offer:
                                            </Typography>
                                          </Grid>

                                          <Grid item xs={12}>
                                            <TextField
                                              id="verifyOntracId"
                                              label="JobCategory"
                                              defaultValue={
                                                oboffer[0]
                                                  .jobCategory_name_field
                                              }
                                              type="text"
                                              InputProps={{
                                                readOnly: true,
                                              }}
                                              fullWidth
                                              size="small"
                                            />
                                          </Grid>
                                          <Grid item xs={12}>
                                            <TextField
                                              id="verifyOntracId"
                                              label="JobTitle"
                                              defaultValue={oboffer[0].jobTitle}
                                              type="text"
                                              InputProps={{
                                                readOnly: true,
                                              }}
                                              fullWidth
                                              size="small"
                                            />
                                          </Grid>

                                          <Grid item xs={12}>
                                            <TextField
                                              id="startingSalary"
                                              label="Starting Salary"
                                              // variant="outlined"
                                              value={oboffer[0].startSalary}
                                              // onChange={}
                                              type="number"
                                              fullWidth
                                              size="small"
                                            />
                                          </Grid>

                                          <Grid item xs={12}>
                                            <TextField
                                              id="startingDate"
                                              // label="Starting Salary"
                                              // variant="outlined"
                                              value={oboffer[0].startDate}
                                              // onChange={}
                                              type="date"
                                              helperText="Starting date"
                                              fullWidth
                                              size="small"
                                            />
                                          </Grid>

                                          <Grid item xs={12}>
                                            <TextField
                                              id="jobDescription"
                                              label="Job Description"
                                              // variant="outlined"
                                              value={oboffer[0].jobDescription}
                                              // onChange={}
                                              type="date"
                                              fullWidth
                                              multiline
                                              rows={3}
                                              size="small"
                                            />
                                          </Grid>

                                          <Grid item xs={12}>
                                            <TextField
                                              id="otherConditions"
                                              label="Other Conditions"
                                              // variant="outlined"
                                              value={oboffer[0].conditions}
                                              // onChange={}
                                              type="date"
                                              fullWidth
                                              multiline
                                              rows={3}
                                              size="small"
                                            />
                                          </Grid>
                                        </Grid>
                                      </Paper>
                                    </Grid>
                                  </Grid>
                                ) : (
                                  <>
                                    <Grid item xs={6}>
                                      <Paper
                                        variant="outlined"
                                        style={{ padding: 15 }}
                                      >
                                        <Grid
                                          container
                                          justify="flex-start"
                                          direction="row"
                                          alignItems="center"
                                          spacing={2}
                                          // style={{ padding: 20 }}
                                        >
                                          <Grid item xs={12}>
                                            <Typography
                                              variant="subtitle1"
                                              align="center"
                                              justify="center"
                                              gutterBottom
                                            >
                                              Original offer:
                                            </Typography>
                                          </Grid>

                                          <Grid item xs={12}>
                                            <TextField
                                              id="verifyOntracId"
                                              label="JobCategory"
                                              defaultValue={
                                                oboffer[1]
                                                  .jobCategory_name_field
                                              }
                                              type="text"
                                              InputProps={{
                                                readOnly: true,
                                              }}
                                              fullWidth
                                              size="small"
                                            />
                                          </Grid>
                                          <Grid item xs={12}>
                                            <TextField
                                              id="verifyOntracId"
                                              label="JobTitle"
                                              defaultValue={oboffer[1].jobTitle}
                                              type="text"
                                              InputProps={{
                                                readOnly: true,
                                              }}
                                              fullWidth
                                              size="small"
                                            />
                                          </Grid>
                                          <Grid item xs={12}>
                                            <TextField
                                              id="startingSalary"
                                              label="Starting Salary"
                                              // variant="outlined"
                                              value={oboffer[1].startSalary}
                                              // onChange={}
                                              type="number"
                                              fullWidth
                                              size="small"
                                            />
                                          </Grid>

                                          <Grid item xs={12}>
                                            <TextField
                                              id="startingDate"
                                              // label="Starting Salary"
                                              // variant="outlined"
                                              value={oboffer[1].startDate}
                                              // onChange={}
                                              type="date"
                                              helperText="Starting date"
                                              fullWidth
                                              size="small"
                                            />
                                          </Grid>

                                          <Grid item xs={12}>
                                            <TextField
                                              id="jobDescription"
                                              label="Job Description"
                                              // variant="outlined"
                                              value={oboffer[1].jobDescription}
                                              // onChange={}
                                              type="date"
                                              fullWidth
                                              multiline
                                              rows={3}
                                              size="small"
                                            />
                                          </Grid>

                                          <Grid item xs={12}>
                                            <TextField
                                              id="otherConditions"
                                              label="Other Conditions"
                                              // variant="outlined"
                                              value={oboffer[1].conditions}
                                              // onChange={}
                                              type="date"
                                              fullWidth
                                              multiline
                                              rows={3}
                                              size="small"
                                            />
                                          </Grid>
                                        </Grid>
                                      </Paper>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <Paper
                                        variant="outlined"
                                        style={{ padding: 15 }}
                                      >
                                        <Grid
                                          container
                                          justify="flex-start"
                                          direction="row"
                                          alignItems="center"
                                          spacing={2}
                                          // style={{ padding: 20 }}
                                        >
                                          <Grid item xs={12}>
                                            <Typography
                                              variant="subtitle1"
                                              align="center"
                                              justify="center"
                                              gutterBottom
                                            >
                                              Modified offer:
                                            </Typography>
                                          </Grid>

                                          <Grid item xs={12}>
                                            <TextField
                                              id="verifyOntracId"
                                              label="JobCategory"
                                              value={
                                                oboffer[0]
                                                  .jobCategory_name_field
                                              }
                                              type="text"
                                              InputProps={{
                                                readOnly: true,
                                              }}
                                              fullWidth
                                              size="small"
                                            />
                                          </Grid>

                                          <Grid item xs={12}>
                                            <TextField
                                              id="verifyOntracId"
                                              label="JobTitle"
                                              defaultValue={oboffer[0].jobTitle}
                                              type="text"
                                              InputProps={{
                                                readOnly: true,
                                              }}
                                              fullWidth
                                              size="small"
                                            />
                                          </Grid>

                                          <Grid item xs={12}>
                                            <TextField
                                              id="startingSalary"
                                              label="Starting Salary"
                                              // variant="outlined"
                                              value={oboffer[0].startSalary}
                                              // onChange={}
                                              type="number"
                                              fullWidth
                                              size="small"
                                            />
                                          </Grid>

                                          <Grid item xs={12}>
                                            <TextField
                                              id="startingDate"
                                              // label="Starting Salary"
                                              // variant="outlined"
                                              value={oboffer[0].startDate}
                                              // onChange={}
                                              type="date"
                                              helperText="Starting date"
                                              fullWidth
                                              size="small"
                                            />
                                          </Grid>

                                          <Grid item xs={12}>
                                            <TextField
                                              id="jobDescription"
                                              label="Job Description"
                                              // variant="outlined"
                                              value={oboffer[0].jobDescription}
                                              // onChange={}
                                              type="date"
                                              fullWidth
                                              multiline
                                              rows={3}
                                              size="small"
                                            />
                                          </Grid>

                                          <Grid item xs={12}>
                                            <TextField
                                              id="otherConditions"
                                              label="Other Conditions"
                                              // variant="outlined"
                                              value={oboffer[0].conditions}
                                              // onChange={}
                                              type="date"
                                              fullWidth
                                              multiline
                                              rows={3}
                                              size="small"
                                            />
                                          </Grid>
                                        </Grid>
                                      </Paper>
                                    </Grid>
                                  </>
                                )}
                              </>
                            )}
                          </Grid>

                          <CardActions style={{ padding: 15 }}>
                            <Grid
                              container
                              direction="row"
                              justify="flex-end"
                              alignItems="center"
                              spacing={3}
                            >
                              <Grid item>
                                {modifyOfferButton ? (
                                  <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={() =>
                                      Modifyofferformpost(oboffer[0].id)
                                    }
                                    style={{ minWidth: 200 }}
                                  >
                                    Send New Offer
                                  </Button>
                                ) : acceptbutton ? (
                                  <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={() => confirmoffer(oboffer[0].id)}
                                    style={{ minWidth: 200 }}
                                  >
                                    Accept Offer
                                  </Button>
                                ) : null}
                              </Grid>
                              <Grid item>
                                {rejectbutton ? (
                                  <Button
                                    color="secondary"
                                    variant="contained"
                                    onClick={() => canceloffer(oboffer[0].id)}
                                    style={{ minWidth: 200 }}
                                  >
                                    Cancel Offer
                                  </Button>
                                ) : null}
                              </Grid>
                            </Grid>
                          </CardActions>
                        </>
                      )}
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Box>
          ) : (
            <Box p={2}>
              <MaterialTable
                title=""
                icons={tableIcons}
                columns={[
                  {
                    title: "Avatar",
                    render: () => <Avatar />,
                  },
                  {
                    field: "Created On",
                    title: "Created On",
                    render: (rowData) =>
                      new Date(rowData.created_on).toDateString(),
                  },

                  { title: "Employer Ontracid", field: "employer_ontracid" },
                  { title: "Job Categories", field: "jobCategory_name_field" },
                  { title: "Job Title", field: "jobTitle" },
                  { title: "Start Date", field: "startDate" },

                  {
                    title: "Onboarding Status",
                    field: "obStatus",
                    render: (rowData) =>
                      rowData.obStatus === "Onboarded" ? (
                        <Chip
                          icon={
                            <CheckCircleOutlineOutlinedIcon
                              style={{ color: "white" }}
                            />
                          }
                          label={rowData.obStatus}
                          style={theme.palette.chipgreen}
                        />
                      ) : rowData.obStatus === "Accepted" ? (
                        <Chip
                          icon={
                            <PriorityHighOutlinedIcon
                              style={{ color: "white" }}
                            />
                          }
                          label={rowData.obStatus}
                          style={theme.palette.chipyellow}
                        />
                      ) : rowData.obStatus === "Offered" ? (
                        <Chip
                          icon={
                            <CancelOutlinedIcon style={{ color: "white" }} />
                          }
                          label={rowData.obStatus}
                          style={theme.palette.chipred}
                        />
                      ) : null,
                  },
                  {
                    title: "Actions",
                    render: (rowData) => (
                      <Button
                        size="small"
                        color="primary"
                        // variant="outlined"
                        onClick={() => {
                          handleopen(rowData.id);
                        }}
                      >
                        <VisibilityOutlinedIcon />
                      </Button>
                    ),
                  },
                ]}
                data={props.data.reverse()}
                options={{
                  sorting: true,
                  paging: false,
                  search: true,
                  grouping: true,
                  actionsColumnIndex: -1,
                  headerStyle: {
                    backgroundColor: "#031a26",
                    color: "#FFF",
                    fontWeight: "bold",
                  },
                  // toolbar: false,
                }}
              />
            </Box>
          )}
        </Grid>
      )}
      {/* </Paper> */}

      {
        <div>
          <Dialog
            open={generateNewEmployementCodeDialog}
            onClose={() => setGenerateNewEmployementCodeDialog(false)}
          >
            <DialogTitle id="alert-dialog-title">
              {"Code Generator"}
            </DialogTitle>
            <DialogContent>
              <Grid
                container
                justify="flex-start"
                direction="row"
                alignItems="center"
                spacing={2}
              >
                <Grid item xs={12}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="searchEmployeeBy">
                      Search Employee by
                    </InputLabel>
                    <Select
                      labelId="statusOptionsEmployeeField"
                      id="statusOptionsEmployeeField"
                      fullWidth
                      // value={age}
                      // onChange={handleChange}
                    >
                      <MenuItem value={10}>User Name</MenuItem>
                      <MenuItem value={20}>Email</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    id="userName"
                    label="User Name"
                    // value={}
                    // onChange={event => this.setState({ addJobDialog: { jobTitle: event.target.value } })}
                    type="text"
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    id="email"
                    label="Email"
                    // value={}
                    // onChange={event => this.setState({ addJobDialog: { jobTitle: event.target.value } })}
                    type="email"
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">
                      Employement code category
                    </FormLabel>
                    <RadioGroup
                      aria-label="gender"
                      name="codeCategory"
                      // value={value}
                      // onChange={handleChange}
                    >
                      <FormControlLabel
                        value="employementEngagement"
                        control={<Radio />}
                        label="employementEngagement"
                      />
                      <FormControlLabel
                        value="employementTermination"
                        control={<Radio />}
                        label="employementTermination"
                      />
                      <FormControlLabel
                        value="employementUpdation"
                        control={<Radio />}
                        label="employementUpdation"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>

                {/* FOR Employment Engagement Condition */}

                <Grid item xs={12}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Request for:</FormLabel>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            // checked={employeeProfile}
                            // onChange={handleChange}
                            name="employeeProfile"
                          />
                        }
                        label="Employee-Profile"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            // checked={jobProfile}
                            // onChange={handleChange}
                            name="jobProfile"
                          />
                        }
                        label="Job-Profile"
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>

                {/* For Employement Termination */}

                <Grid item xs={12}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="selectJobProfileRecord">
                      Select Job-Profile Record
                    </InputLabel>
                    <Select
                      labelId="selectJobProfileRecord"
                      id="selectJobProfileRecord"
                      fullWidth
                      // value={age}
                      // onChange={handleChange}
                    >
                      <MenuItem value={10}>Data1</MenuItem>
                      <MenuItem value={20}>Data2</MenuItem>
                      <MenuItem value={30}>Data3</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                {/* For Employement Updation  */}

                <Grid item xs={12}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="selectJobProfileRecord">
                      Select Job-Profile Record
                    </InputLabel>
                    <Select
                      labelId="selectJobProfileRecord"
                      id="selectJobProfileRecord"
                      fullWidth
                      // value={age}
                      // onChange={handleChange}
                    >
                      <MenuItem value={10}>Data1</MenuItem>
                      <MenuItem value={20}>Data2</MenuItem>
                      <MenuItem value={30}>Data3</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">
                      Request updates for:
                    </FormLabel>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            // checked={employeeProfile}
                            // onChange={handleChange}
                            name="employeeProfile"
                          />
                        }
                        label="Employee-Profile"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            // checked={jobProfile}
                            // onChange={handleChange}
                            name="jobProfile"
                          />
                        }
                        label="Job-Profile"
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    id="updateReason"
                    label="Update reason"
                    // value={}
                    // onChange={event => this.setState({ addJobDialog: { jobTitle: event.target.value } })}
                    type="text"
                    fullWidth
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions style={{ padding: 15 }}>
              <Button
                color="secondary"
                variant="contained"
                onClick={() => setGenerateNewEmployementCodeDialog(false)}
              >
                Generate One-time Code
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={() => setGenerateNewEmployementCodeDialog(false)}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      }
    </div>
  );
}

export default Onboarding;
