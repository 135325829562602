import React, { Component } from "react";
import { Grid, Typography, Paper, CircularProgress } from "@material-ui/core/";
import { withStyles } from "@material-ui/core/styles";
import axios from "axios";
import MessageIcon from "@material-ui/icons/Message";

import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';


const styles = (theme) => ({});

let token = "";
// let id = "";
class index extends Component {
  state = {
    result: [],
    inboxButtonDisable: true,
    outboxButtonDisable: false,
    loading: false,
  };
  async componentDidMount() {
    this.setState({ loading: true });

    token = localStorage.getItem("Token");
    // id = localStorage.getItem("id");
    await axios
      .get("http://3.22.17.212:9000/api/v1/messages/", {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        // result = res.data;
        this.setState({ result: res.data });
        // console.table("messages data", this.state.result);
      });
    this.setState({ loading: false });
  }
  render() {
    return (
      <Grid style={{ marginTop: 10 }}>
        {this.state.inboxButtonDisable
          ? this.messageInbox()
          : this.messageOutbox()}
      </Grid>
    );
  }

  messageInbox() {
    return (
      <React.Fragment>
        {this.state.loading ? (
          <Grid container justify="center" alignItems="center">
            <Grid item>
              <CircularProgress />
            </Grid>
          </Grid>
        ) : this.state.result.length === 0 ? (
          <Grid container direction="row" justify="center" alignItems="center">
            No Messages
          </Grid>
        ) : this.state.result.length === 1 ? (
          <React.Fragment>
            <Paper variant="outlined" style={{ marginTop: 10 }}>
              <Grid container style={{ padding: 10 }} alignItems="center">
                <Grid item xs={1}>
                  {/* <MessageIcon style={{color: "#6d6966"}}/> */}
                  <ListItemAvatar>
                          <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
                        </ListItemAvatar>
                </Grid>
                <Grid item xs={11} justify="space-between">
                  <Typography component={"span"} style={{textTransform: 'capitalize', fontWeight: "bold", color: "#6d6966"}}>
                    {this.state.result[0].initiated_by_field}
                  </Typography>
                  <Typography component={"span"} variant="caption">
                    {new Date(
                      this.state.result[0].initialDate_field
                    ).toDateString()}
                  </Typography>
                </Grid>
                <Typography component={"span"} variant="body2" display="block">
                  {this.state.result[0].message}
                </Typography>
              </Grid>
            </Paper>
          </React.Fragment>
        ) : (
          <Grid>
            <Paper variant="outlined" style={{ marginTop: 20 }}>
              <Grid container style={{ padding: 10 }} alignItems="center">
                <Grid item xs={1}>
                  {/* <MessageIcon style={{color: "#607d8b"}}/> */}
                  <ListItemAvatar>
                          <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
                        </ListItemAvatar>
                </Grid>
                <Grid item xs={11}>
                  <Grid container justify="space-between">
                    <Typography component={"span"} style={{textTransform: 'capitalize', fontWeight: "bold", color: "#6d6966"}}>
                      {this.state.result[0].initiated_by_field}
                    </Typography>
                    <Typography component={"span"} variant="caption">
                      {new Date(
                        this.state.result[0].initialDate_field
                      ).toDateString()}
                    </Typography>
                  </Grid>
                  <Typography
                    component={"span"}
                    variant="body2"
                    display="block"
                  >
                    {this.state.result[0].message}
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
            <Paper variant="outlined" style={{ marginTop: 10 }}>
              <Grid container style={{ padding: 10 }} alignItems="center">
                
                <Grid item xs={1}>
                  {/* <MessageIcon style={{color: "#607d8b"}}/> */}
                  <ListItemAvatar>
                          <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
                        </ListItemAvatar>
                </Grid>
                <Grid item xs={11}>
                  <Grid container justify="space-between">
                    <Typography component={"span"} style={{textTransform: 'capitalize', fontWeight: "bold", color: "#6d6966"}}>
                      {this.state.result[1].initiated_by_field}
                    </Typography>
                    <Typography component={"span"} variant="caption">
                      {new Date(
                        this.state.result[1].initialDate_field
                      ).toDateString()}
                    </Typography>
                  </Grid>
                  <Typography
                    component={"span"}
                    variant="body2"
                    display="block"
                  >
                    {this.state.result[1].message}
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        )}
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(index);
