import React, { Component } from "react";
import { Grid, Typography, CircularProgress } from "@material-ui/core/";
import { withStyles } from "@material-ui/core/styles";
import Chart from "react-apexcharts";
import axios from "axios";
const styles = (theme) => ({});
let token = "";
let id = "";
class index extends Component {
  async componentDidMount() {
    this.setState({ loading: true });
    token = localStorage.getItem("Token");
    id = localStorage.getItem("id");
    await axios
      .get("http://3.22.17.212:9000/api/v1/employees/" + id + "/ratings", {
        headers: {
          Authorization: token,
        },
      })
      .then(async (res) => {
        this.setState({ result: res.data });
        let result = [];
        result.push(res.data.addressRating);
        result.push(res.data.idRating);
        result.push(res.data.otherJobRating);
        result.push(res.data.overallRating);
        result.push(res.data.phoneRating);
        result.push(res.data.profileRating);
        this.setState({ series: result });
      });
    this.setState({ loading: false });
  }
  constructor(props) {
    super(props);
    this.state = {
      overallProfileValue: 0,
      profileValue: 0,
      idValue: 0,
      addressValue: 0,
      phoneValue: 0,
      votValue: 0,
      otherJobsValue: 0,
      result: [],

      series: [],
      
      options: {
        series: [76, 67, 61, 90],
        chart: {
        height: 390,
        type: 'radialBar',
      },
      plotOptions: {
        radialBar: {
          offsetX: -60,
          offsetY: 0,
          startAngle: 0,
          endAngle: 270,
          hollow: {
            margin: 5,
            size: '5%',
            background: 'transparent',
            image: undefined,

          },
          dataLabels: {
            name: {
              show: false,
            },
            value: {
              show: false,
            }
          }
        }
      },
      // colors: ['#651fff', '#33bfff', '#00e676', '#ffca28', '#ff3d00', '#f5f5f5' ],
      colors: ['#1ab7ea', '#0084ff', '#39539E', '#0077B5', '#455a64', '#78909c' ],
      labels: [ "Profile","Address","Identites","Academics","Phones","Other Jobs",],
      legend: {
        show: true,
        floating: true,
        fontSize: '10px',
        position: 'left',
        offsetX: 30,
        offsetY: 0,
        labels: {
          useSeriesColors: true,
        },
        markers: {
          size: 0
        },
        formatter: function(seriesName, opts) {
          return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex] + "%"
        },
        itemMargin: {
          vertical: 3
        }
      },
      responsive: [{
        breakpoint: 480,
        options: {
          legend: {
              show: false
          }
        }
      }]
      }
    };
  }
  render() {
    return (
      <div>
        <Grid
  container
  direction="row"
  justify="flex-start"
  alignItems="center"
>

          {this.state.loading ? (
            <>
              <Grid container justify="center">
                <Grid item>
                  <CircularProgress />
                </Grid>
              </Grid>
            </>
          ) : (

            <Chart
              options={this.state.options}
              series={this.state.series}
              type="radialBar"
              width={"454"}
              height={"303"}
              
            />
          )}
        </Grid>
      </div>
    );
  }
}
export default withStyles(styles)(index);