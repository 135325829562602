import React, { useEffect, useState } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Tabs,
  Tab,
  Box,
  Button,
  ButtonGroup,
} from "@material-ui/core/";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import WorksAt from "../DashBoardPageComponents/WorksAt";
import Messages from "../DashBoardPageComponents/Messages/messages";
import Ratings from "../DashBoardPageComponents/Ratings";
import EmployementCodes from "../DashBoardPageComponents/Codes/EmployementCodes";
import AccessCodes from "../DashBoardPageComponents/Codes/AccessCodes";
import Profile from "../DashBoardPageComponents/Profile";
import RatingBars from "../DashBoardPageComponents/RatingBars";
import Divider from "@material-ui/core/Divider";
import About from "../DashBoardPageComponents/About"
import AppBar from "@material-ui/core/AppBar";
const styles = (theme) => ({});
const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: theme.palette.background.paper,
    // background: '#f5f5f5',
    width: 500,
  },
  profileCardHeight: {
    minHeight: 300,
    // minWidth: window.innerWidth/3
  },
  tabs: {
    flexGrow: 1,
    flexBasis: 'auto'
  },
  expansionWidth: {
    width: "100%",
  },
  marginTop: {
    marginTop: 20,
  },
  demo2: {
    backgroundColor: "#fff",
  },
}));
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Grid
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </Grid>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}
const StyledTabs = withStyles({
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    "& > span": {
      maxWidth: 40,
      width: "100%",
      backgroundColor: '#f5f5f5',
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    color: "#000000",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    "&:focus": {
      opacity: 1,
    },
  },
}))((props) => <Tab disableRipple {...props} />);
export default function Index(props) {

  useEffect(() => {

  }, []);
  const [value, setValue] = React.useState(0);
  const theme = useTheme();

  const [messagesdisplay, setMessages] = useState(true);
  const [acesscodesdisplay, setacessCodes] = useState(false);
  const [employmentcodesdisplay, setEmploymentCodes] = useState(false);
  const handleMessages = () => {
    setMessages(true);
    setacessCodes(false);
    setEmploymentCodes(false);
  };
  const handleCodes = () => {
    setMessages(false);
    setacessCodes(true);
    setEmploymentCodes(false);
  };
  const handleEmployeeCodes = () => {
    setMessages(false);
    setacessCodes(false);
    setEmploymentCodes(true);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  // const { classes } = this.props;
  const classes = useStyles();

  return (
    <Box py={2} style={{ background: '#f5f5f5' }}>
      <Grid
        container
        direction="row"
        spacing={2}
        justify="flex-start"
        style={{ background: '#f5f5f5' }}
      >
        <Grid item xs={12} >
          <Typography
            component={"span"}
            variant="h3"
            display="block"
            align="left"
            style={{ marginBottom: 20, color: "#6d6966" }}
          >
            Welcome back, Arjun!
              </Typography>

          <Divider style={{ marginBottom: 10 }} />
        </Grid>



        <Grid item xs={12} md={4}>
          <Card className={classes.profileCardHeight} >
            <CardContent>
              <Typography
                component={"span"}
                variant="h5"
                display="block"
                align="left"
                style={{ marginLeft: 12 }}
              >
                Profile
              </Typography>

              <Profile />
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={8}>
          <Card className={classes.profileCardHeight}>


            <Grid
              item
              container
              direction="column"
              justify="space-between"
              alignItems="center"
              direction="row"
            >

              <Grid item xs={6} md={6}>
              <Typography
                component={"span"}
                variant="h5"
                display="block"
                align="left"
                style={{ padding: 20}}
              >
                Utilities
              </Typography>
              </Grid>

              <Grid item xs={6} md={6}>
              <ButtonGroup
                    // variant="text"
                    color="primary"
                    aria-label="contained primary button group"
                    style={{padding: 10}}
                  >
                    <Button
                      // disableElevation={false}
                      // disabled={messagesdisplay}
                      variant={messagesdisplay === true ? "contained" : null}
                      onClick={() => handleMessages()}
                    >
                      Messages
                    </Button>
                    <Button
                      // disabled={acesscodesdisplay}
                      variant={acesscodesdisplay === true ? "contained" : null}
                      onClick={() => handleCodes()}
                    >
                      Acess Codes
                    </Button>
                    <Button
                      variant={
                        employmentcodesdisplay === true ? "contained" : null
                      }
                      // disabled={employmentcodesdisplay}
                      onClick={() => handleEmployeeCodes()}
                    >
                      Employement Codes
                    </Button>
                  </ButtonGroup>
              </Grid>
              {/* <Grid item xs={12} md={12}> */}
              {/* </Grid> */}
              <Grid item xs={12} md={12} style={{marginLeft:20, marginRight: 20}}>
                {messagesdisplay ? <Messages /> : null}
                {acesscodesdisplay ? <AccessCodes /> : null}
                {employmentcodesdisplay ? <EmployementCodes /> : null}
              </Grid>

            </Grid>

          </Card>{" "}
        </Grid>

        <Grid item xs={12} md={4}>
          <Card  >
            <CardContent>
              <Typography
                component={"span"}
                variant="h5"
                display="block"
                align="left"
                style={{ marginLeft: 12 }}
              >
                About
              </Typography>

              <About />

            </CardContent>
          </Card>

          <Card style={{ marginTop: 15, minHeight: 210 }}>
            <CardContent>
              <Typography
                component={"span"}
                variant="h5"
                align="left"
                style={{ marginLeft: 12 }}
              >
                Ratings
              </Typography>
              <RatingBars />
            </CardContent>
          </Card>
        </Grid>

        {/* <Grid item xs={12} md={4}>
          
        </Grid> */}

        <Grid item xs={12} md={8}>
          <Card style={{minHeight: 523, maxHeight: 522, overflow: 'auto'}} >
            <CardContent>
              <Typography
                component={"span"}
                variant="h5"
                display="block"
                align="left"
                style={{ marginLeft: 12 }}

              >
                Works at
              </Typography>
              <WorksAt />
            </CardContent>
          </Card>
        </Grid>

        <Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
