import React, { Component } from "react";
import {
  TextField,
  Grid,
  Button,
  TableContainer,
  FormControlLabel,
  Checkbox,
  CircularProgress,
  Avatar,
  Box,
  Card,
  CardActions,
  CardContent,
} from "@material-ui/core/";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core/";
import DialogTitle from "@material-ui/core/DialogTitle";
import { get, put } from "../../../../API";
import MaterialTable from "material-table";
import Search from "@material-ui/icons/Search";
import { forwardRef } from "react";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
// import SearchBar from "material-ui-search-bar";
import ViewColumn from "@material-ui/icons/ViewColumn";
let token = "";
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};
class index extends Component {
  state = {
    generateNewEmployementCodeDialog: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      codes: [],
      pendingcodes: [],
      pendingcode: false,
      selectedtype: "",
      selectedid: "",
      selectedCloseBtn: "",
      selectedcode: "",
      selecteduserid: "",
      viewdetailsdata: "",
      loading: true,
      viewdetailsdilog: false,
      historydata: [],
      historydetailsdilog: false,
      selectedmarkid: "",
    };
  }

  async getcodes() {
    await get(
      "http://3.22.17.212:9000/api/v1/codes/evaluation/codes",
      token,
      ""
    ).then((res) => this.setState({ codes: res.data }));
    await get(
      "http://3.22.17.212:9000/api/v1/codes/evaluation/pending-codes",
      token,
      ""
    ).then((res) => this.setState({ pendingcodes: res.data }));
    this.setState({ loading: false });
  }
  async componentDidMount() {
    token = localStorage.getItem("Token");
    this.setState({ loading: true });
    await this.getcodes();
  }
  async viewdetailsprofile(selecteduserid, selectedcode, type) {
    this.setState({ viewdetailsdilog: true });
    // console.log(selecteduserid, selectedcode, type);
    await get(
      "http://3.22.17.212:9000/api/v1/employees/" +
        selecteduserid +
        "/" +
        type +
        "/" +
        selecteduserid +
        "?votcode=" +
        selectedcode,
      token,
      ""
    ).then((res) => {
      this.setState({
        viewdetailsdata: res.data,
      });
      // console.log(this.state.viewdetailsdata[0].firstname);
    });
  }
  async viewdetails(selecteduserid, selectedcode, type, objId) {
    this.setState({ viewdetailsdilog: true });
    // console.log(selecteduserid, selectedcode, type, objId);
    await get(
      "http://3.22.17.212:9000/api/v1/employees/" +
        selecteduserid +
        "/" +
        type +
        "/" +
        objId +
        "?votcode=" +
        selectedcode,
      token,
      ""
    ).then((res) => {
      this.setState({
        viewdetailsdata: res.data,
      });
    });
  }
  async gethistory(selecteduserid, selectedcode, type, objId) {
    this.setState({ historydetailsdilog: true });
    // console.log(selecteduserid, selectedcode, type, objId);
    await get(
      "http://3.22.17.212:9000/api/v1/employees/" +
        selecteduserid +
        "/" +
        type +
        "/" +
        objId +
        "/history?votcode=" +
        selectedcode,
      token,
      ""
    ).then((res) => this.setState({ historydata: res.data }));
  }
  async gethistoryacademics(selecteduserid, selectedcode, type, objId) {
    this.setState({ historydetailsdilog: true });
    // console.log(selecteduserid, selectedcode, type, objId);
    await get(
      "http://3.22.17.212:9000/api/v1/employees/" +
        selecteduserid +
        "/" +
        type +
        "?votcode=" +
        selectedcode,
      token,
      ""
    ).then((res) => this.setState({ historydata: res.data }));
  }
  async gethistoryprofile(selecteduserid, selectedcode, type, objId) {
    this.setState({ historydetailsdilog: true });
    // console.log(selecteduserid, selectedcode, type, objId);
    await get(
      "http://3.22.17.212:9000/api/v1/employees/" +
        selecteduserid +
        "/" +
        type +
        "/" +
        selecteduserid +
        "/history?votcode=" +
        selectedcode,
      token,
      ""
    ).then((res) => this.setState({ historydata: res.data }));
  }
  async gethistoryidentites(selecteduserid, selectedcode, objId) {
    this.setState({ historydetailsdilog: true, viewdetailsdilog: false });
    // console.log(objId);
    await get(
      "http://3.22.17.212:9000/api/v1/employees/" +
        selecteduserid +
        "/" +
        "identities-by/" +
        selecteduserid +
        "/" +
        "idSources/" +
        objId +
        "/history?votcode=" +
        selectedcode,
      token,
      ""
    ).then((res) => this.setState({ historydata: res.data }));
  }
  isloading() {
    return (
      <Grid
        container
        justify="flex-end"
        alignItems="center"
        // container
        // spacing={0}
        direction="column"
        // alignItems="center"
        // justify="center"
        // // display="flex"
        // style={{ minHeight: "10vh" }}
      >
        <Grid item xs={6} style={{ marginTop: 100 }}>
          <CircularProgress />
        </Grid>
      </Grid>
    );
  }
  render() {
    return (
      <div style={{ marginTop: 20, marginRight: 20 }}>
        {/* <Paper style={{ padding: 20, height: '100vh' }}> */}
        <Grid container justify="space-between" alignItems="center" spacing={4}>
          <Grid container direction="row" spacing={2}>
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.pendingcode}
                    onChange={() =>
                      this.setState({ pendingcode: !this.state.pendingcode })
                    }
                    name="checkedB"
                    color="primary"
                  />
                }
                label="Show pending codes"
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Box p={2}>
              {this.state.loading ? (
                this.isloading()
              ) : this.state.viewdetailsdilog ? (
                <>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Grid container justify="flex-end">
                        <Button
                          color="secondary"
                          variant="contained"
                          onClick={() =>
                            this.setState({
                              viewdetailsdilog: false,
                              viewdetailsdata: [],
                            })
                          }
                        >
                          Back
                        </Button>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Card
                        open={this.state.viewdetailsdilog}
                        onClose={() =>
                          this.setState({
                            viewdetailsdilog: false,
                            viewdetailsdata: [],
                          })
                        }
                      >
                        <DialogTitle id="codegenerator" align="center">
                          View Details
                        </DialogTitle>

                        <CardContent>
                          {this.state.selectedtype === "Profile" ? (
                            this.state.viewdetailsdata.length === 0 ? (
                              this.isloading()
                            ) : (
                              <Grid
                                container
                                justify="flex-start"
                                direction="row"
                                alignItems="center"
                                spacing={2}
                              >
                                <Grid item xs={12} align="center">
                                  <Avatar
                                    style={{ height: "8rem", width: "8rem" }}
                                  >
                                    <img
                                      src={
                                        this.state.viewdetailsdata[0].picture
                                      }
                                      width="130"
                                      height="130"
                                      alt=""
                                    />
                                  </Avatar>
                                </Grid>

                                <Grid item xs={12}>
                                  <Grid
                                    container
                                    justify="flex-start"
                                    direction="column"
                                    alignItems="center"
                                    spacing={2}
                                  >
                                    <TextField
                                      id="fullName"
                                      label="FirstName"
                                      defaultValue={
                                        this.state.viewdetailsdata[0].firstname
                                      }
                                      type="text"
                                      InputProps={{
                                        readOnly: true,
                                      }}
                                      fullWidth
                                      size="small"
                                    />
                                    <TextField
                                      id="fullName"
                                      label="MiddleName"
                                      defaultValue={
                                        this.state.viewdetailsdata[0].middlename
                                      }
                                      type="text"
                                      InputProps={{
                                        readOnly: true,
                                      }}
                                      fullWidth
                                      size="small"
                                    />
                                    <TextField
                                      id="fullName"
                                      label="LastName"
                                      defaultValue={
                                        this.state.viewdetailsdata[0].surname
                                      }
                                      type="text"
                                      InputProps={{
                                        readOnly: true,
                                      }}
                                      fullWidth
                                      size="small"
                                    />
                                  </Grid>
                                </Grid>

                                <Grid item xs={12}>
                                  <TextField
                                    id="fullName"
                                    label="Gender"
                                    defaultValue={
                                      this.state.viewdetailsdata[0].sex
                                    }
                                    type="text"
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    fullWidth
                                    size="small"
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <TextField
                                    id="fullName"
                                    label="Date of birth"
                                    defaultValue={
                                      this.state.viewdetailsdata[0].dob
                                    }
                                    type="text"
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    fullWidth
                                    size="small"
                                  />
                                </Grid>
                              </Grid>
                            )
                          ) : this.state.selectedtype === "Address" ? (
                            this.state.viewdetailsdata.length === 0 ? (
                              this.isloading()
                            ) : (
                              <Grid
                                container
                                justify="flex-start"
                                direction="row"
                                alignItems="center"
                                spacing={2}
                              >
                                <Grid item xs={12} align="center">
                                  <h3>Address</h3>
                                </Grid>
                                <Grid item xs={12}>
                                  <TextField
                                    id="fullName"
                                    label="State Name"
                                    defaultValue={
                                      this.state.viewdetailsdata
                                        .state_name_field
                                    }
                                    type="text"
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    fullWidth
                                    size="small"
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <TextField
                                    id="fullName"
                                    label="Lga Name"
                                    defaultValue={
                                      this.state.viewdetailsdata.lga_name_field
                                    }
                                    type="text"
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    fullWidth
                                    size="small"
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <TextField
                                    id="fullName"
                                    label="City Name"
                                    defaultValue={
                                      this.state.viewdetailsdata.city_name_field
                                    }
                                    type="text"
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    fullWidth
                                    size="small"
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <TextField
                                    id="fullName"
                                    label="Address Hint1"
                                    defaultValue={
                                      this.state.viewdetailsdata.address_hint1
                                    }
                                    type="text"
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    fullWidth
                                    size="small"
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <TextField
                                    id="fullName"
                                    label="Address Hint1"
                                    defaultValue={
                                      this.state.viewdetailsdata.address_hint1
                                    }
                                    type="text"
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    fullWidth
                                    size="small"
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <h6>Google Coordinates</h6>
                                  <a
                                    href={`http://www.google.com/maps/place/${this.state.viewdetailsdata.google_coordinate1},${this.state.viewdetailsdata.google_coordinate2}`}
                                    target="_blank"
                                  >
                                    Location
                                  </a>
                                </Grid>
                                <Grid item xs={12}>
                                  <TextField
                                    id="fullName"
                                    label="Adress Type"
                                    defaultValue={
                                      this.state.viewdetailsdata
                                        .address_type_name_field
                                    }
                                    type="text"
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    fullWidth
                                    size="small"
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <TextField
                                    id="fullName"
                                    label="Created On"
                                    defaultValue={
                                      this.state.viewdetailsdata.created_on
                                    }
                                    type="text"
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    fullWidth
                                    size="small"
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <TextField
                                    id="fullName"
                                    label="House Numer"
                                    defaultValue={
                                      this.state.viewdetailsdata.house_number
                                    }
                                    type="text"
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    fullWidth
                                    size="small"
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <TextField
                                    id="fullName"
                                    label="Street Name"
                                    defaultValue={
                                      this.state.viewdetailsdata.street_name
                                    }
                                    type="text"
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    fullWidth
                                    size="small"
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <TextField
                                    id="fullName"
                                    label="Update Reason"
                                    defaultValue={
                                      this.state.viewdetailsdata.update_reason
                                    }
                                    type="text"
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    fullWidth
                                    size="small"
                                  />
                                </Grid>
                              </Grid>
                            )
                          ) : this.state.selectedtype === "Identity" ? (
                            this.state.viewdetailsdata.length === 0 ? (
                              this.isloading()
                            ) : (
                              <Grid
                                container
                                justify="flex-start"
                                direction="row"
                                alignItems="center"
                                spacing={2}
                              >
                                <Grid item xs={12} align="center">
                                  <Grid container justify="space-between">
                                    <h3>Identity</h3>
                                    <Button
                                      color="primary"
                                      size="small"
                                      variant="outlined"
                                      onClick={() =>
                                        this.gethistoryidentites(
                                          this.state.selecteduserid,
                                          this.state.selectedcode,
                                          this.state.viewdetailsdata.idSource
                                        )
                                      }
                                    >
                                      History
                                    </Button>
                                  </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                  <TextField
                                    id="fullName"
                                    label="Created On"
                                    defaultValue={
                                      this.state.viewdetailsdata.created_on
                                    }
                                    type="text"
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    fullWidth
                                    size="small"
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <TextField
                                    id="fullName"
                                    label="Full Name"
                                    defaultValue={
                                      this.state.viewdetailsdata.fullname
                                    }
                                    type="text"
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    fullWidth
                                    size="small"
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <TextField
                                    id="fullName"
                                    label="Date of Birth"
                                    defaultValue={
                                      this.state.viewdetailsdata.dob
                                    }
                                    type="text"
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    fullWidth
                                    size="small"
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <TextField
                                    id="fullName"
                                    label="IdNumber"
                                    defaultValue={
                                      this.state.viewdetailsdata.idNumber
                                    }
                                    type="text"
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    fullWidth
                                    size="small"
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <TextField
                                    id="fullName"
                                    label="IdSource"
                                    defaultValue={
                                      this.state.viewdetailsdata
                                        .idSource_name_field
                                    }
                                    type="text"
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    fullWidth
                                    size="small"
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <TextField
                                    id="fullName"
                                    label="Update Reason"
                                    defaultValue={
                                      this.state.viewdetailsdata.update_reason
                                    }
                                    type="text"
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    fullWidth
                                    size="small"
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <TextField
                                    id="fullName"
                                    label="Gender"
                                    defaultValue={
                                      this.state.viewdetailsdata.sex
                                    }
                                    type="text"
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    fullWidth
                                    size="small"
                                  />
                                </Grid>
                              </Grid>
                            )
                          ) : this.state.selectedtype === "Academic" ? (
                            this.state.viewdetailsdata.length === 0 ? (
                              this.isloading()
                            ) : (
                              <Grid
                                container
                                justify="flex-start"
                                direction="row"
                                alignItems="center"
                                spacing={2}
                              >
                                <Grid item xs={12} align="center">
                                  <h3>Academics</h3>
                                </Grid>
                                <Grid item xs={12}>
                                  <TextField
                                    id="fullName"
                                    label="Academic Types"
                                    defaultValue={
                                      this.state.viewdetailsdata[0]
                                        .academicType_name_field
                                    }
                                    type="text"
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    fullWidth
                                    size="small"
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <TextField
                                    id="fullName"
                                    label="Degree"
                                    defaultValue={
                                      this.state.viewdetailsdata[0].degree
                                    }
                                    type="text"
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    fullWidth
                                    size="small"
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <TextField
                                    id="fullName"
                                    label="School"
                                    defaultValue={
                                      this.state.viewdetailsdata[0].school
                                    }
                                    type="text"
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    fullWidth
                                    size="small"
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <TextField
                                    id="fullName"
                                    label="Start Date"
                                    defaultValue={
                                      this.state.viewdetailsdata[0].startDate
                                    }
                                    type="text"
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    fullWidth
                                    size="small"
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <TextField
                                    id="fullName"
                                    label="End Date"
                                    defaultValue={
                                      this.state.viewdetailsdata[0].endDate
                                    }
                                    type="text"
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    fullWidth
                                    size="small"
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <TextField
                                    id="fullName"
                                    label="Created On"
                                    defaultValue={
                                      this.state.viewdetailsdata[0].created_on
                                    }
                                    type="text"
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    fullWidth
                                    size="small"
                                  />
                                </Grid>
                              </Grid>
                            )
                          ) : this.state.selectedtype === "Phone" ? (
                            this.state.viewdetailsdata.length === 0 ? (
                              this.isloading()
                            ) : (
                              <Grid
                                container
                                justify="flex-start"
                                direction="row"
                                alignItems="center"
                                spacing={2}
                              >
                                <Grid item xs={12} align="center">
                                  <h3>Phone</h3>
                                </Grid>
                                <Grid item xs={12}>
                                  <TextField
                                    id="fullName"
                                    label="Created On"
                                    defaultValue={
                                      this.state.viewdetailsdata.created_on
                                    }
                                    type="text"
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    fullWidth
                                    size="small"
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <TextField
                                    id="fullName"
                                    label="Date Obtained"
                                    defaultValue={
                                      this.state.viewdetailsdata.dateObtained
                                    }
                                    type="text"
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    fullWidth
                                    size="small"
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <TextField
                                    id="fullName"
                                    label="Default Phone"
                                    defaultValue={
                                      this.state.viewdetailsdata.default_phone
                                    }
                                    type="text"
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    fullWidth
                                    size="small"
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <TextField
                                    id="fullName"
                                    label="ImeI Number"
                                    defaultValue={
                                      this.state.viewdetailsdata.imeiNumber
                                    }
                                    type="text"
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    fullWidth
                                    size="small"
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <TextField
                                    id="fullName"
                                    label="Phone Number"
                                    defaultValue={
                                      this.state.viewdetailsdata.phoneNumber
                                    }
                                    type="text"
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    fullWidth
                                    size="small"
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <TextField
                                    id="fullName"
                                    label="Phone Reason"
                                    defaultValue={
                                      this.state.viewdetailsdata
                                        .phone_reason_name_field
                                    }
                                    type="text"
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    fullWidth
                                    size="small"
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <TextField
                                    id="fullName"
                                    label="Phone Type"
                                    defaultValue={
                                      this.state.viewdetailsdata
                                        .phone_type_name_field
                                    }
                                    type="text"
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    fullWidth
                                    size="small"
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <TextField
                                    id="fullName"
                                    label="Update Reason"
                                    defaultValue={
                                      this.state.viewdetailsdata.update_reason
                                    }
                                    type="text"
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    fullWidth
                                    size="small"
                                  />
                                </Grid>
                              </Grid>
                            )
                          ) : this.state.selectedtype === "Job" ? (
                            this.state.viewdetailsdata.length === 0 ? (
                              this.isloading()
                            ) : (
                              <Grid
                                container
                                justify="flex-start"
                                direction="row"
                                alignItems="center"
                                spacing={2}
                              >
                                <Grid item xs={12} align="center">
                                  <h3>Job</h3>
                                </Grid>
                                <Grid item xs={12}>
                                  <TextField
                                    id="fullName"
                                    label="Company Name"
                                    defaultValue={
                                      this.state.viewdetailsdata
                                        .company_name_field
                                    }
                                    type="text"
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    fullWidth
                                    size="small"
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <TextField
                                    id="fullName"
                                    label="Created On"
                                    defaultValue={
                                      this.state.viewdetailsdata.created_on
                                    }
                                    type="text"
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    fullWidth
                                    size="small"
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <TextField
                                    id="fullName"
                                    label="End Date"
                                    defaultValue={
                                      this.state.viewdetailsdata.endDate
                                    }
                                    type="text"
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    fullWidth
                                    size="small"
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <TextField
                                    id="fullName"
                                    label="Job Description"
                                    defaultValue={
                                      this.state.viewdetailsdata.jobDescription
                                    }
                                    type="text"
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    fullWidth
                                    size="small"
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <TextField
                                    id="fullName"
                                    label="Job Title"
                                    defaultValue={
                                      this.state.viewdetailsdata.jobTitle
                                    }
                                    type="text"
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    fullWidth
                                    size="small"
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <TextField
                                    id="fullName"
                                    label="Job Category"
                                    defaultValue={
                                      this.state.viewdetailsdata
                                        .job_category_field
                                    }
                                    type="text"
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    fullWidth
                                    size="small"
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <TextField
                                    id="fullName"
                                    label="Leaving Reason"
                                    defaultValue={
                                      this.state.viewdetailsdata
                                        .leaving_reason_field
                                    }
                                    type="text"
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    fullWidth
                                    size="small"
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <TextField
                                    id="fullName"
                                    label="Start Date"
                                    defaultValue={
                                      this.state.viewdetailsdata.startDate
                                    }
                                    type="text"
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    fullWidth
                                    size="small"
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <TextField
                                    id="fullName"
                                    label="Update Reason"
                                    defaultValue={
                                      this.state.viewdetailsdata.update_reason
                                    }
                                    type="text"
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    fullWidth
                                    size="small"
                                  />
                                </Grid>
                              </Grid>
                            )
                          ) : null}
                        </CardContent>
                        <CardActions>
                          <Grid container justify="flex-end" spacing={2}>
                            <Grid item>
                              <Button
                                disabled={this.state.selectedCloseBtn}
                                color="primary"
                                variant="contained"
                                onClick={() => {
                                  this.setState({
                                    viewdetailsdilog: false,
                                    viewdetailsdata: [],
                                    selectedIndex: -1,
                                  });
                                  this.markverifed();
                                }}
                              >
                                Mark Verified
                              </Button>
                            </Grid>
                            <Grid item>
                              <Button
                                disabled={this.state.selectedCloseBtn}
                                color="secondary"
                                variant="contained"
                                onClick={() => {
                                  this.setState({
                                    viewdetailsdilog: false,
                                    viewdetailsdata: [],
                                  });
                                  this.markverifiedfailed();
                                }}
                              >
                                Mark Verified Failed
                              </Button>
                            </Grid>
                            <Grid item>
                              <Button
                                disabled={!this.state.selectedCloseBtn}
                                color="secondary"
                                variant="contained"
                                onClick={() => {
                                  this.setState({
                                    viewdetailsdilog: false,
                                    viewdetailsdata: [],
                                  });
                                  this.closecode();
                                }}
                              >
                                Mark Closed
                              </Button>
                            </Grid>
                          </Grid>
                        </CardActions>
                      </Card>
                    </Grid>
                  </Grid>
                </>
              ) : this.state.historydetailsdilog ? (
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Grid container justify="flex-end">
                      <Button
                        color="secondary"
                        variant="contained"
                        onClick={() => {
                          this.setState({
                            historydetailsdilog: false,
                            historydata: [],
                          });
                        }}
                      >
                        Back
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Card
                      fullWidth={"md"}
                      maxWidth={"md"}
                      open={this.state.historydetailsdilog}
                      onClose={() =>
                        this.setState({
                          historydetailsdilog: false,
                          historydata: [],
                        })
                      }
                    >
                      <DialogTitle id="codegenerator" align="center">
                        History
                      </DialogTitle>
                      <CardContent>
                        {this.state.selectedtype === "Profile" ? (
                          this.state.historydata.length === 0 ? (
                            this.isloading()
                          ) : (
                            <Grid
                              container
                              justify="flex-start"
                              direction="row"
                              alignItems="center"
                              spacing={2}
                            >
                              <DialogTitle
                                id="form-dialog-title"
                                align="center"
                              >
                                Profile History
                              </DialogTitle>
                              <TableContainer p={3}>
                                <Table stickyHeader>
                                  <TableHead>
                                    <TableRow
                                      style={{ backgroundColor: "black" }}
                                    >
                                      {[
                                        "Picture",
                                        "Fullname",
                                        "Middlename",
                                        "Surname",
                                        "Date of birth",
                                        "Sex",
                                        "Records updated date",
                                        "Updated reason",
                                      ].map((text, index) => (
                                        <TableCell
                                          style={{ fontWeight: "bolder" }}
                                          align="left"
                                        >
                                          {text}
                                        </TableCell>
                                      ))}
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {this.state.historydata.map(
                                      (row, index) => (
                                        <TableRow key={row.id}>
                                          <TableCell align="left">
                                            <Avatar src={row.picture}>
                                              Picture
                                            </Avatar>
                                          </TableCell>
                                          <TableCell align="left">
                                            {row.firstname}
                                          </TableCell>
                                          <TableCell align="left">
                                            {row.middlename}
                                          </TableCell>
                                          <TableCell align="left">
                                            {row.surname}
                                          </TableCell>
                                          <TableCell align="left">
                                            {new Date(row.dob).toDateString()}
                                          </TableCell>
                                          {/* <TableCell align="center">{row.source_name_field}</TableCell> */}
                                          <TableCell align="left">
                                            {row.sex}
                                          </TableCell>{" "}
                                          <TableCell
                                            component="th"
                                            align="left"
                                          >
                                            {new Date(
                                              row.created_on
                                            ).toDateString()}
                                          </TableCell>
                                          <TableCell align="left">
                                            {row.update_reason}
                                          </TableCell>
                                        </TableRow>
                                      )
                                    )}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </Grid>
                          )
                        ) : this.state.selectedtype === "Address" ? (
                          this.state.historydata.length === 0 ? (
                            this.isloading()
                          ) : (
                            <Grid
                              container
                              justify="flex-start"
                              direction="row"
                              alignItems="center"
                              spacing={2}
                            >
                              <TableContainer p={3}>
                                <Table stickyHeader>
                                  <TableHead>
                                    <TableRow
                                      style={{ backgroundColor: "black" }}
                                    >
                                      {[
                                        "State/Lga/City",
                                        "House Number,Street Name,Address Hint 1",
                                        "Google Link",
                                        "Address Reason",
                                        "Address Type",
                                        "Records Updated Date",
                                        "Update Reason",
                                      ].map((text, index) => (
                                        <TableCell
                                          align="center"
                                          style={{ fontWeight: "bolder" }}
                                        >
                                          {text}
                                        </TableCell>
                                      ))}
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {this.state.historydata.map(
                                      (row, index) => (
                                        <TableRow key={row.id}>
                                          <TableCell align="center">
                                            {row.state_name_field},
                                            {row.lga_name_field},
                                            {row.city_name_field}
                                          </TableCell>
                                          <TableCell align="center">
                                            {row.house_number},{row.street_name}
                                            ,{row.address_hint1}
                                          </TableCell>
                                          <TableCell align="center">
                                            <a
                                              href={`http://www.google.com/maps/place/${row.google_coordinate1}+,+${row.google_coordinate2}`}
                                              target="_blank"
                                            >
                                              Location
                                            </a>
                                          </TableCell>
                                          <TableCell align="center">
                                            {row.address_reason_name_field}
                                          </TableCell>{" "}
                                          <TableCell align="center">
                                            {row.address_type_name_field}
                                          </TableCell>{" "}
                                          <TableCell
                                            component="th"
                                            align="center"
                                          >
                                            {new Date(
                                              row.created_on
                                            ).toDateString()}
                                          </TableCell>
                                          <TableCell align="center">
                                            {row.update_reason}
                                          </TableCell>
                                        </TableRow>
                                      )
                                    )}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </Grid>
                          )
                        ) : this.state.selectedtype === "Identity" ? (
                          this.state.historydata.length === 0 ? (
                            this.isloading()
                          ) : (
                            <Grid
                              container
                              justify="flex-start"
                              direction="row"
                              alignItems="center"
                              spacing={2}
                            >
                              <TableContainer p={3}>
                                <Table stickyHeader>
                                  <TableHead>
                                    <TableRow
                                      style={{ backgroundColor: "black" }}
                                    >
                                      {[
                                        "Full Name",
                                        "Date of birth",
                                        "Sex",
                                        "Id Source",
                                        "Id Number",
                                        "Records Updated Date",
                                        "Update Reason",
                                      ].map((text, index) => (
                                        <TableCell
                                          style={{ fontWeight: "bolder" }}
                                          align="center"
                                        >
                                          {text}
                                        </TableCell>
                                      ))}
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {this.state.historydata.map(
                                      (row, index) => (
                                        <TableRow key={row.id}>
                                          <TableCell align="center">
                                            {row.fullname}
                                          </TableCell>
                                          <TableCell align="center">
                                            {row.dob}
                                          </TableCell>
                                          <TableCell align="center">
                                            {row.sex}
                                          </TableCell>
                                          <TableCell align="center">
                                            {row.idSource_name_field}
                                          </TableCell>
                                          <TableCell align="center">
                                            {row.idNumber}
                                          </TableCell>{" "}
                                          <TableCell
                                            component="th"
                                            align="center"
                                          >
                                            {new Date(
                                              row.created_on
                                            ).toDateString()}
                                          </TableCell>
                                          <TableCell align="center">
                                            {row.update_reason}
                                          </TableCell>
                                        </TableRow>
                                      )
                                    )}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </Grid>
                          )
                        ) : this.state.selectedtype === "Academic" ? (
                          this.state.historydata.length === 0 ? (
                            this.isloading()
                          ) : (
                            <Grid
                              container
                              justify="flex-start"
                              direction="row"
                              alignItems="center"
                              spacing={2}
                            >
                              <TableContainer p={3}>
                                <Table stickyHeader>
                                  <TableHead>
                                    <TableRow
                                      style={{ backgroundColor: "black" }}
                                    >
                                      {[
                                        "Start Date",
                                        "End Date",
                                        "School",
                                        "Degree",
                                        "Update Reason",
                                        "Created On",
                                      ].map((text, index) => (
                                        <TableCell
                                          style={{ fontWeight: "bolder" }}
                                          align="center"
                                        >
                                          {text}
                                        </TableCell>
                                      ))}
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {this.state.historydata.map(
                                      (row, index) => (
                                        <TableRow key={row.id}>
                                          <TableCell align="center">
                                            {row.startDate}
                                          </TableCell>
                                          <TableCell align="center">
                                            {row.endDate}
                                          </TableCell>
                                          <TableCell align="center">
                                            {row.school}
                                          </TableCell>
                                          <TableCell align="center">
                                            {row.degree}
                                          </TableCell>
                                          <TableCell align="center">
                                            {row.update_reason}
                                          </TableCell>
                                          <TableCell
                                            component="th"
                                            align="center"
                                          >
                                            {new Date(
                                              row.created_on
                                            ).toDateString()}
                                          </TableCell>
                                        </TableRow>
                                      )
                                    )}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </Grid>
                          )
                        ) : this.state.selectedtype === "Phone" ? (
                          this.state.historydata.length === 0 ? (
                            this.isloading()
                          ) : (
                            <Grid
                              container
                              justify="flex-start"
                              direction="row"
                              alignItems="center"
                              spacing={2}
                            >
                              <TableContainer p={3}>
                                <Table stickyHeader>
                                  <TableHead>
                                    <TableRow
                                      style={{ backgroundColor: "black" }}
                                    >
                                      <TableCell
                                        style={{ fontWeight: "bolder" }}
                                        align="center"
                                      >
                                        phonenumber
                                      </TableCell>
                                      <TableCell
                                        style={{ fontWeight: "bolder" }}
                                        align="center"
                                      >
                                        phoneReason
                                      </TableCell>
                                      <TableCell
                                        style={{ fontWeight: "bolder" }}
                                        align="center"
                                      >
                                        phoneType
                                      </TableCell>
                                      <TableCell
                                        style={{ fontWeight: "bolder" }}
                                        align="center"
                                      >
                                        DefaultPhone
                                      </TableCell>

                                      <TableCell
                                        style={{ fontWeight: "bolder" }}
                                        align="center"
                                      >
                                        imeinumber
                                      </TableCell>

                                      <TableCell
                                        style={{ fontWeight: "bolder" }}
                                        align="center"
                                      >
                                        records updated date
                                      </TableCell>
                                      <TableCell
                                        style={{ fontWeight: "bolder" }}
                                        align="center"
                                      >
                                        Update reason
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>

                                  <TableBody>
                                    {this.state.historydata.map(
                                      (row, index) => (
                                        <TableRow key={row.id}>
                                          <TableCell align="center">
                                            {row.phoneNumber}
                                          </TableCell>
                                          <TableCell align="center">
                                            {row.phone_reason_name_field}
                                          </TableCell>
                                          <TableCell align="center">
                                            {row.phone_type_name_field}
                                          </TableCell>
                                          <TableCell align="center">
                                            {row.default_phone}
                                          </TableCell>
                                          <TableCell align="center">
                                            {row.imeiNumber}
                                          </TableCell>
                                          <TableCell
                                            component="th"
                                            align="center"
                                          >
                                            {row.created_on}
                                          </TableCell>
                                          <TableCell align="center">
                                            {row.update_reason}
                                          </TableCell>
                                        </TableRow>
                                      )
                                    )}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </Grid>
                          )
                        ) : this.state.selectedtype === "Job" ? (
                          this.state.historydata.length === 0 ? (
                            this.isloading()
                          ) : (
                            <Grid
                              container
                              justify="flex-start"
                              direction="row"
                              alignItems="center"
                              spacing={2}
                            >
                              <Table stickyHeader>
                                <TableHead>
                                  <TableRow>
                                    <TableCell align="left">
                                      Created on
                                    </TableCell>
                                    <TableCell align="left">
                                      Update Reason
                                    </TableCell>
                                    <TableCell align="left">
                                      Job Description
                                    </TableCell>
                                    <TableCell align="left">
                                      Job Category
                                    </TableCell>
                                  </TableRow>
                                </TableHead>

                                <TableBody>
                                  {this.state.historydata.map((row, index) => (
                                    <TableRow key={row.id}>
                                      <TableCell align="left">
                                        {new Date(
                                          row.created_on
                                        ).toDateString()}
                                      </TableCell>
                                      <TableCell align="left">
                                        {row.update_reason}
                                      </TableCell>
                                      <TableCell align="left">
                                        {row.jobDescription}
                                      </TableCell>
                                      <TableCell align="left">
                                        {row.job_category_field}
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </Grid>
                          )
                        ) : null}
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              ) : (
                <Grid
                  container
                  justify="flex-start"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item xs={12}>
                    <MaterialTable
                      icons={tableIcons}
                      title="Verification requests"
                      columns={[
                        {
                          title: "Date",
                          field: "createdOn",
                        },
                        {
                          field: "codeString",
                          title: "Code String",
                        },
                        {
                          title: "Name",
                          render: (row) =>
                            row.employee_name_field[0].firstname +
                            " " +
                            row.employee_name_field[0].middlename +
                            " " +
                            row.employee_name_field[0].surname,
                        },

                        {
                          title: "Verification Type",
                          field: "verType",
                        },

                        {
                          title: "Code Status",
                          field: "codeStatus",
                          // render: (rowData) =>
                          //   new Date(rowData.statusChangeDate).toDateString(),
                        },
                        {
                          title: "Last Updated",
                          field: "statusChangeDate",
                          // render: (rowData) =>
                          //   new Date(rowData.statusChangeDate).toDateString(),
                        },
                        {
                          title: "Details",
                          field: "approvedFlag",
                          render: (row) => (
                            <>
                              <Button
                                disabled={row.codeStatus === "Closed"}
                                size="small"
                                color="primary"
                                variant="outlined"
                                onClick={() => {
                                  this.setState({
                                    selectedtype: row.verType,
                                    selectedid: row.objId,
                                    selectedCloseBtn: row.showMarkCloseBtn,
                                    selectedcode: row.codeString,
                                    selecteduserid: row.vot_employee,
                                    selectedmarkid: row.id,
                                  });
                                  row.verType === "Profile"
                                    ? this.viewdetailsprofile(
                                        row.vot_employee,
                                        row.codeString,
                                        "profiles-by"
                                      )
                                    : row.verType === "Address"
                                    ? this.viewdetails(
                                        row.vot_employee,
                                        row.codeString,
                                        "addresses",
                                        row.objId
                                      )
                                    : row.verType === "Identity"
                                    ? this.viewdetails(
                                        row.vot_employee,
                                        row.codeString,
                                        "identities",
                                        row.objId
                                      )
                                    : row.verType === "Academic"
                                    ? this.viewdetails(
                                        row.vot_employee,
                                        row.codeString,
                                        "academics",
                                        row.objId
                                      )
                                    : row.verType === "Phone"
                                    ? this.viewdetails(
                                        row.vot_employee,
                                        row.codeString,
                                        "phones",
                                        row.objId
                                      )
                                    : row.verType === "Job"
                                    ? this.viewdetails(
                                        row.vot_employee,
                                        row.codeString,
                                        "jobs",
                                        row.objId
                                      )
                                    : this.setState({});
                                }}
                              >
                                View Details
                              </Button>
                            </>
                          ),
                        },
                        {
                          title: "History",
                          render: (row) =>
                            row.verType !== "Identity" ? (
                              <Button
                                disabled={row.codeStatus === "Closed"}
                                size="small"
                                color="primary"
                                variant="outlined"
                                onClick={() => {
                                  this.setState({
                                    selectedtype: row.verType,
                                  });
                                  row.verType === "Profile"
                                    ? this.gethistoryprofile(
                                        row.vot_employee,
                                        row.codeString,
                                        "profiles-by",
                                        row.objId
                                      )
                                    : row.verType === "Address"
                                    ? this.gethistory(
                                        row.vot_employee,
                                        row.codeString,
                                        "addresses",
                                        row.objId
                                      )
                                    : row.verType === "Academic"
                                    ? this.gethistoryacademics(
                                        row.vot_employee,
                                        row.codeString,
                                        "academics",
                                        row.objId
                                      )
                                    : row.verType === "Phone"
                                    ? this.gethistory(
                                        row.vot_employee,
                                        row.codeString,
                                        "phones",
                                        row.objId
                                      )
                                    : row.verType === "Job"
                                    ? this.gethistory(
                                        row.vot_employee,
                                        row.codeString,
                                        "jobs",
                                        row.objId
                                      )
                                    : this.setState({});
                                }}
                              >
                                History
                              </Button>
                            ) : null,
                        },
                      ]}
                      data={
                        this.state.pendingcode
                          ? this.state.pendingcodes
                          : this.state.codes
                      }
                      options={{
                        sorting: true,
                        paging: false,
                        search: true,
                        grouping: true,
                        actionsColumnIndex: -1,
                        headerStyle: {
                          backgroundColor: "#031a26",
                          color: "#FFF",
                          fontWeight: "bold",
                        },
                        // toolbar: false,
                      }}
                    />
                  </Grid>
                </Grid>
              )}
            </Box>
          </Grid>
        </Grid>
      </div>
    );
  }
  async markverifed() {
    await put(
      "http://3.22.17.212:9000/api/v1/codes/evaluation/codes/" +
        this.state.selectedmarkid +
        "/verify?pass=true",
      token,
      ""
    ).then((res) => {
      this.getcodes();
    });
  }
  async markverifiedfailed() {
    await put(
      "http://3.22.17.212:9000/api/v1/codes/evaluation/codes/" +
        this.state.selectedmarkid +
        "/verify?pass=false",
      token,
      ""
    ).then((res) => {
      this.getcodes();
    });
  }
  async closecode() {
    await put(
      "http://3.22.17.212:9000/api/v1/codes/evaluation/codes/" +
        this.state.selectedmarkid +
        "/close",
      token,
      ""
    ).then((res) => {
      this.getcodes();
    });
  }
}

export default index;
