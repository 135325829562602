import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  TextField,
  Paper,
  Grid,
  Button,
  TableContainer,
  FormControlLabel,
  Checkbox,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  CircularProgress,
  Snackbar,
  Avatar,
  Box,
  Typography,
} from "@material-ui/core/";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Chip,
} from "@material-ui/core/";
import MuiAlert from "@material-ui/lab/Alert";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Autocomplete from "@material-ui/lab/Autocomplete";
import axios from "axios";
import MaterialTable, { MTableToolbar } from "material-table";
import Search from "@material-ui/icons/Search";
import { forwardRef } from "react";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import SearchBar from "material-ui-search-bar";
import ViewColumn from "@material-ui/icons/ViewColumn";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import WorkOutlineIcon from "@material-ui/icons/WorkOutline";
import PinDropIcon from "@material-ui/icons/PinDrop";
import AccountBalanceWalletOutlinedIcon from "@material-ui/icons/AccountBalanceWalletOutlined";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import NotInterestedOutlinedIcon from "@material-ui/icons/NotInterestedOutlined";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import Tooltip from "@material-ui/core/Tooltip";
import CheckCircleOutlineOutlinedIcon from "@material-ui/icons/CheckCircleOutlineOutlined";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import PriorityHighOutlinedIcon from "@material-ui/icons/PriorityHighOutlined";
import ClearOutlinedIcon from "@material-ui/icons/ClearOutlined";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import theme from "../../../../theme";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
let token = "";
let id = "";
// let result = [];
let codes = [];
let pendingcodes = [];

let usernames = [];
let emails = [];
let companys = [];
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};
const styles = (theme) => ({});
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
class index extends Component {
  state = {
    opencodes: false,
    generateNewEmployementCodeDialog: false,
    loading: true,
    choiceemail: false,
    choiceusername: false,
    choicecompany: false,
    employerby: "",
    employersearch: false,
    isadmin: true,
    employerid: "",
    companyvalue: "",
    dummyvalue: "",
    dummyvalue1: "",
    usernamevalue: "",
    emailsvalue: "",
    rating: false,
    address: false,
    profile: false,
    identites: false,
    phone: false,
    jobHistory: false,
    viewDialog: false,
    codedetails: "",
    codes: [],
    pendingcodes: [],
    status: "",
    updatesnackbar: false,
    updateresponse: "",
    addDialogOpen: false,
    open: false,
  };

  isloading() {
    return (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
        display="flex"
        style={{ minHeight: "100vh" }}
      >
        <CircularProgress />
      </Grid>
    );
  }
  // searchemail(emailvalue) {
  //   emails.filter(function (e) {
  //     if (emailvalue === e.email) {
  //     }
  //   });
  // }
  updatesnackbar() {
    return this.state.updateresponse === 200 ? (
      <div>
        <Snackbar
          open={this.state.updatesnackbar}
          autoHideDuration={3000}
          onClick={() => this.setState({ updatesnackbar: false })}
        >
          <Alert
            onClose={() => {
              this.setState({ updatesnackbar: !this.state.updatesnackbar });
            }}
            severity="success"
          >
            status updated sucessfully
          </Alert>
        </Snackbar>
      </div>
    ) : (
      <Snackbar
        open={this.state.updatesnackbar}
        autoHideDuration={3000}
        onClick={() => {
          this.setState({ updatesnackbar: !this.state.updatesnackbar });
        }}
      >
        <Alert
          onClose={() => {
            this.setState({ updatesnackbar: !this.state.updatesnackbar });
          }}
          severity="error"
        >
          Something went wrong please try again
        </Alert>
      </Snackbar>
    );
  }
  async searchusername(username) {
    await axios
      .get(
        `http://3.22.17.212:90008000/api/v1/accounts/employer?username=` +
          username,

        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((res) => {
        usernames = res.data;
      });
  }
  async searchcompany(companyName) {
    await axios
      .get(
        `http://3.22.17.212:9000/api/v1/employers/?company=` + companyName,

        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((res) => {
        companys = res.data;
      });
  }

  employersearchchoice() {
    return (
      <>
        <Grid item xs={12}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Search employer by:</FormLabel>
            <RadioGroup
              name="searchCategory"
              // value={value}
              onChange={(event) => {
                this.setState({ employerby: event.target.value });
              }}
            >
              <Grid container direction="row" style={{ marginTop: 10 }}>
                <FormControlLabel
                  value="searchByEmail"
                  control={<Radio />}
                  label="Email"
                  //   onChange={this.setState({
                  //     choicecompany: false,
                  //     choiceusername: false,
                  //     choiceemail: true,
                  //   })}
                />
                <FormControlLabel
                  value="searchByUsername"
                  control={<Radio />}
                  label="Username"
                  //   onChange={this.setState({
                  //     choicecompany: false,
                  //     choiceusername: true,
                  //     choiceemail: false,
                  //   })}
                />
                <FormControlLabel
                  value="searchByCompany"
                  control={<Radio />}
                  label="Company"
                  //   onChange={this.setState({
                  //     choicecompany: true,
                  //     choiceusername: false,
                  //     choiceemail: false,
                  //   })}
                />
              </Grid>
            </RadioGroup>
          </FormControl>
        </Grid>

        {this.state.employerby === "searchByEmail" ? (
          <Grid item xs={12}>
            <Autocomplete
              options={emails}
              getOptionLabel={(option) => option.email}
              size="small"
              id="email"
              value={this.state.emailsvalue}
              onChange={(event, value) => {
                this.setState({ emailsvalue: value });
                // this.setState({ employerid: value.id });
              }}
              inputValue={this.state.dummyvalue}
              onInputChange={(event, newInputValue) => {
                this.setState({ dummyvalue: newInputValue });
              }}
              Email
              renderInput={(params) => (
                <TextField {...params} label="Email" margin="normal" />
              )}
            />
          </Grid>
        ) : this.state.employerby === "searchByUsername" ? (
          <Grid item xs={12}>
            <Autocomplete
              size="small"
              options={usernames}
              getOptionLabel={(option) => option.username}
              id="username"
              Username
              value={this.state.usernamevalue}
              onChange={(event, value) => {
                this.setState({ usernamevalue: value });
                // this.setState({ employerid: value.id });
              }}
              inputValue={this.state.dummyvalue}
              onInputChange={(event, newInputValue) => {
                this.setState({ dummyvalue: newInputValue });
              }}
              renderInput={(params) => (
                <TextField {...params} label="Username" margin="normal" />
              )}
            />
          </Grid>
        ) : (
          <Grid item xs={12}>
            <Autocomplete
              options={companys}
              getOptionLabel={(option) => option.companyName}
              size="small"
              id="comapny"
              Company
              value={this.state.companyvalue}
              onChange={(event, value) => {
                this.setState({ companyvalue: value });
                // this.setState({ employerid: value.id });
              }}
              inputValue={this.state.dummyvalue}
              onInputChange={(event, newInputValue) => {
                this.setState({ dummyvalue: newInputValue });
              }}
              renderInput={(params) => (
                <TextField {...params} label="Company" margin="normal" />
              )}
            />
          </Grid>
        )}
      </>
    );
  }
  async getcodes() {
    await axios
      .get("http://3.22.17.212:9000/api/v1/codes/access/codes", {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        codes = res.data;
        this.setState({ codes: res.data });
      });
    await axios
      .get("http://3.22.17.212:9000/api/v1/codes/access/pending-codes", {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        pendingcodes = res.data;
        this.setState({ pendingcodes: res.data });
      });
  }
  async componentDidMount() {
    token = localStorage.getItem("Token");
    id = localStorage.getItem("id");
    await this.getcodes();

    await axios
      .get(
        `http://3.22.17.212:9000/api/v1/accounts/employer?email=`,

        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((res) => {
        emails = res.data;
      });
    await axios
      .get(
        `http://3.22.17.212:9000/api/v1/accounts/employer?username=`,

        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((res) => {
        usernames = res.data;
      });

    await axios
      .get(
        `http://3.22.17.212:9000/api/v1/employers/`,

        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((res) => {
        companys = res.data;
      });
    this.setState({ loading: false });
  }
  async getcode(codeid) {
    this.setState({ loading: true });
    await axios
      .get(
        "http://3.22.17.212:9000/api/v1/codes/access/codes/" + codeid,

        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((res) => {
        this.setState({ codedetails: res.data });
        this.setState({ loading: false, viewDialog: true });
      });
  }
  async postcode() {
    this.setState({
      generateNewEmployementCodeDialog: false,
      selectedIndex: -1,
    });
    let headers = {
      headers: {
        Authorization: token,
        "Content-Type": "multipart/form-data",
      },
    };

    let bodyFormData = new FormData();
    this.state.employersearch
      ? this.state.employerby === "searchByEmail"
        ? bodyFormData.append("employer", this.state.emailsvalue.id)
        : this.state.employerby === "searchByUsername"
        ? bodyFormData.append("employer", this.state.usernamevalue.id)
        : bodyFormData.append("employer", this.state.companyvalue.id)
      : bodyFormData.append("forAdmin", this.state.isadmin);

    bodyFormData.append("employee", id);

    bodyFormData.append("canAccessProfile", this.state.profile);
    bodyFormData.append("canAccessAddresses", this.state.address);
    bodyFormData.append("canAccessJobHistory", this.state.jobHistory);
    bodyFormData.append("canAccessPhones", this.state.phone);
    bodyFormData.append("canAccessIdentities", this.state.identites);
    bodyFormData.append("canAccessRatings", this.state.rating);

    await axios
      .post(
        "http://3.22.17.212:9000/api/v1/codes/access/new-code",
        bodyFormData,
        headers
      )
      .then((response) => {});
  }
  async updatestatus() {
    let headers = {
      headers: {
        Authorization: token,
        "Content-Type": "multipart/form-data",
      },
    };
    let bodyFormData = new FormData();
    bodyFormData.append("codeStatus", this.state.status);

    await axios
      .put(
        "http://3.22.17.212:9000/api/v1/codes/access/update-code/" +
          this.state.currentid,
        bodyFormData,
        headers
      )
      .then((response) => {
        this.setState({
          updateresponse: response.status,
          updatesnackbar: true,
        });
      });
    await this.getcodes();
  }

  gettable() {
    return (
      <>
        <Grid
          container
          justify="flex-start"
          alignItems="flex-start"
          spacing={2}
        >
          {/* <Typography variant="h4">Access Request</Typography> */}

          <Grid item>
            <Box p={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.opencodes}
                    onChange={() => {
                      this.setState({
                        opencodes: !this.state.opencodes,
                      });
                    }}
                    name="checkedB"
                    color="primary"
                  />
                }
                label="Show open codes"
              />
            </Box>
          </Grid>
        </Grid>

        <Grid container justify="center" alignItems="center" spacing={2}>
          {this.state.viewDialog === true ? (
            <>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid container justify="flex-end">
                    <Button
                      color="secondary"
                      variant="contained"
                      onClick={() =>
                        this.setState({
                          viewDialog: false,
                        })
                      }
                    >
                      Back
                    </Button>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Card>
                    <DialogTitle id="codeDetails">{"Code Details"}</DialogTitle>
                    <CardContent>
                      <Paper variant="outlined" style={{ padding: 20 }}>
                        <Grid
                          container
                          justify="flex-start"
                          direction="row"
                          alignItems="center"
                          spacing={2}
                        >
                          <Grid item xs={6}>
                            <TextField
                              id="=viewCreatedOn"
                              label="Created on"
                              defaultValue={new Date(
                                this.state.codedetails.createdOn
                              ).toDateString()}
                              type="text"
                              InputProps={{
                                readOnly: true,
                              }}
                              fullWidth
                              size="small"
                            />
                          </Grid>

                          <Grid item xs={6}>
                            <TextField
                              id="=viewLastUpdated"
                              label="Last updated"
                              defaultValue={new Date(
                                this.state.codedetails.statusChangeDate
                              ).toDateString()}
                              type="text"
                              InputProps={{
                                readOnly: true,
                              }}
                              fullWidth
                              size="small"
                            />
                          </Grid>

                          <Grid item xs={12}>
                            <TextField
                              id="=viewCodeString"
                              label="Code String"
                              defaultValue={this.state.codedetails.codeString}
                              type="text"
                              InputProps={{
                                readOnly: true,
                              }}
                              fullWidth
                              size="small"
                            />
                          </Grid>

                          <Grid item xs={12}>
                            <TextField
                              id="=viewEmployeeName"
                              label="Company Name"
                              defaultValue={
                                this.state.codedetails.employer_company_field
                              }
                              type="text"
                              InputProps={{
                                readOnly: true,
                              }}
                              fullWidth
                              size="small"
                            />
                          </Grid>

                          <Grid item xs={12}>
                            <TextField
                              id="=viewCurentStatus"
                              label="Current Status"
                              defaultValue={this.state.codedetails.codeStatus}
                              type="text"
                              InputProps={{
                                readOnly: true,
                              }}
                              fullWidth
                              size="small"
                            />
                          </Grid>

                          <Grid item xs={12}>
                            <FormControl component="fieldset">
                              <FormLabel component="legend">
                                Access requested for:
                              </FormLabel>
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={
                                        this.state.codedetails[
                                          "canAccessRatings"
                                        ]
                                      }
                                      name="ratings"
                                    />
                                  }
                                  label="Ratings"
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={
                                        this.state.codedetails[
                                          "canAccessAddresses"
                                        ]
                                      }
                                      name="address"
                                    />
                                  }
                                  label="Address"
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={
                                        this.state.codedetails[
                                          "canAccessProfile"
                                        ]
                                      }
                                      name="profile"
                                    />
                                  }
                                  label="Profile"
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={
                                        this.state.codedetails[
                                          "canAccessIdentities"
                                        ]
                                      }
                                      name="identites"
                                    />
                                  }
                                  label="Identities"
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={
                                        this.state.codedetails[
                                          "canAccessPhones"
                                        ]
                                      }
                                      name="phones"
                                    />
                                  }
                                  label="Phones"
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={
                                        this.state.codedetails[
                                          "canAccessJobHistory"
                                        ]
                                      }
                                      name="jobHistory"
                                    />
                                  }
                                  label="Job History"
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={
                                        this.state.codedetails[
                                          "canAccessAcademics"
                                        ]
                                      }
                                      name="academics"
                                    />
                                  }
                                  label="Academics"
                                />
                              </FormGroup>
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Paper>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </>
          ) : (
            <TableContainer
              component={Paper}
              // style={{ marginTop: 20, marginLeft: 10, marginRight: 10 }}
              elevation={5}
            >
              <Table stickyHeader>
                <TableBody>
                  {!this.state.opencodes ? (
                    <MaterialTable
                      icons={tableIcons}
                      title=""
                      // components={{
                      //   Toolbar: (props) => (
                      //     <div
                      //       style={{
                      //         backgroundColor: "#031a26",
                      //         color: "#fff",
                      //         textAlign: "center",
                      //       }}
                      //     >
                      //       <MTableToolbar {...props} />
                      //     </div>
                      //   ),
                      // }}
                      columns={[
                        {
                          title: "Avatar",
                          render: () => <Avatar />,
                        },
                        {
                          field: "createdOn",
                          title: "Created On",
                          render: (rowData) =>
                            new Date(rowData.createdOn).toDateString(),
                        },

                        { title: "Employer", field: "employer_company_field" },
                        {
                          title: "Code Status",
                          field: "codeStatus",
                          render: (rowData) =>
                            rowData.codeStatus === "AccessGranted" ? (
                              <Chip
                                icon={
                                  <CheckCircleOutlineOutlinedIcon
                                    style={{ color: "white" }}
                                  />
                                }
                                label={rowData.codeStatus}
                                style={theme.palette.chipgreen}
                              />
                            ) : rowData.codeStatus === "RequestViewed" ? (
                              <Chip
                                icon={
                                  <PriorityHighOutlinedIcon
                                    style={{ color: "white" }}
                                  />
                                }
                                label={rowData.codeStatus}
                                style={theme.palette.chipyellow}
                              />
                            ) : rowData.codeStatus === "RequestExpired" ? (
                              <Chip
                                icon={
                                  <CancelOutlinedIcon
                                    style={{ color: "white" }}
                                  />
                                }
                                label={rowData.codeStatus}
                                style={theme.palette.chipred}
                              />
                            ) : null,
                        },
                        {
                          title: "Last Updated",
                          render: (rowData) =>
                            new Date(rowData.statusChangeDate).toDateString(),
                        },
                        {
                          title: "Details",
                          field: "approvedFlag",
                          render: (rowData) => (
                            <>
                              <Button
                                size="small"
                                color="primary"
                                // variant="outlined"
                                onClick={() => this.getcode(rowData.id)}
                              >
                                <VisibilityOutlinedIcon />
                              </Button>
                            </>
                          ),
                        },
                        {
                          title: "Actions",
                          render: (rowData) => (
                            <Grid container direction="row" spacing={2}>
                              <FormControl
                                variant="outlined"
                                size="small"
                                style={{ minWidth: 85 }}
                                fullWidth
                              >
                                <InputLabel id="demo-simple-select-outlined-label">
                                  Status
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-outlined-label"
                                  id={rowData.id}
                                  // value={this.state.status}
                                  onChange={(event) => {
                                    this.setState({
                                      status: event.target.value,
                                      addDialogOpen: true,
                                      currentid: rowData.id,
                                    });
                                  }}
                                  label="Status"
                                >
                                  {rowData.status_options_employee_field.map(
                                    (val) => (
                                      <MenuItem value={val.status}>
                                        {val.action}
                                      </MenuItem>
                                    )
                                  )}
                                </Select>
                              </FormControl>
                              {this.state.addDialogOpen === true &&
                              this.state.currentid === rowData.id ? (
                                <>
                                  <Button
                                    color="primary"
                                    size="large"
                                    // variant="contained"
                                    onClick={() => {
                                      this.setState(
                                        {
                                          addDialogOpen: false,
                                        },
                                        this.updatestatus
                                      );
                                    }}
                                  >
                                    <CheckOutlinedIcon size="large" />
                                  </Button>
                                  <Button
                                    color="secondary"
                                    size="large"
                                    // variant="contained"
                                    onClick={() =>
                                      this.setState({
                                        addDialogOpen: false,
                                        status: "none",
                                        currentid: "",
                                      })
                                    }
                                  >
                                    <ClearOutlinedIcon size="large" />
                                  </Button>
                                </>
                              ) : null}
                            </Grid>
                          ),
                        },
                      ]}
                      data={this.state.codes}
                      // actions={[{}]}
                      options={{
                        sorting: true,
                        paging: false,
                        search: true,
                        grouping: true,
                        actionsColumnIndex: -1,
                        headerStyle: {
                          backgroundColor: "#031a26",
                          color: "#FFF",
                          fontWeight: "bold",
                        },
                        // toolbar: false,
                      }}
                    />
                  ) : (
                    // this.state.codes.map((row, index) => (
                    //     <TableRow key={row.id}>
                    //       <TableCell align="center">
                    //         {" "}
                    //         {new Date(row.createdOn).toDateString()}
                    //       </TableCell>
                    //       <TableCell align="center">{row.codeString}</TableCell>
                    //       <TableCell align="center">
                    //         {row.employer_company_field}
                    //       </TableCell>
                    //       <TableCell align="center">{row.codeStatus}</TableCell>
                    //       <TableCell align="center">
                    //         {new Date(row.statusChangeDate).toDateString()}
                    //       </TableCell>
                    //       <TableCell align="center">
                    //         <Button
                    //           size="small"
                    //           color="primary"
                    //           variant="outlined"
                    //           onClick={() => this.getcode(row.id)}
                    //         >
                    //           View Details
                    //         </Button>
                    //       </TableCell>
                    //       <TableCell align="center">
                    //         <FormControl
                    //           variant="outlined"
                    //           size="small"
                    //           style={{ minWidth: 85 }}
                    //           fullWidth
                    //         >
                    //           <InputLabel id="demo-simple-select-outlined-label">
                    //             Status
                    //           </InputLabel>
                    //           <Select
                    //             labelId="demo-simple-select-outlined-label"
                    //             // id={row.id}
                    //             //  value={this.state.status}
                    //             onChange={(event) => {
                    //               this.setState({
                    //                 status: event.target.value,
                    //                 addDialogOpen: true,
                    //                 currentid: row.id,
                    //               });
                    //             }}
                    //             label="Status"
                    //           >
                    //             {row.status_options_employee_field.map((val,index) => (
                    //               <MenuItem value={val.status} key={index}>
                    //                 {val.action}
                    //               </MenuItem>
                    //             ))}
                    //           </Select>
                    //         </FormControl>
                    //       </TableCell>

                    //       {/* <TableCell align="right">
                    //       <Button
                    //         size="small"
                    //         color="secondary"
                    //         variant="outlined"
                    //         onClick={()=>{this.updatestatus(row.id)}}

                    //       >
                    //         Update status
                    //         </Button>
                    //     </TableCell> */}
                    //     </TableRow>
                    //   ))
                    <MaterialTable
                      icons={tableIcons}
                      title=""
                      columns={[
                        {
                          title: "Avatar",
                          render: () => <Avatar />,
                        },
                        {
                          field: "createdOn",
                          title: "Created On",
                          render: (rowData) =>
                            new Date(rowData.createdOn).toDateString(),
                        },
                        {
                          title: "Code",
                          field: "codeString",
                        },
                        { title: "Employer", field: "employer_company_field" },
                        {
                          title: "Code Status",
                          field: "codeStatus",
                          render: (rowData) =>
                            rowData.codeStatus === "AccessGranted" ? (
                              <Chip
                                icon={
                                  <CheckCircleOutlineOutlinedIcon
                                    style={{ color: "white" }}
                                  />
                                }
                                label={rowData.codeStatus}
                                style={theme.palette.chipgreen}
                              />
                            ) : rowData.codeStatus === "RequestViewed" ? (
                              <Chip
                                icon={
                                  <PriorityHighOutlinedIcon
                                    style={{ color: "white" }}
                                  />
                                }
                                label={rowData.codeStatus}
                                style={theme.palette.chipyellow}
                              />
                            ) : rowData.codeStatus === "RequestExpired" ? (
                              <Chip
                                icon={
                                  <CancelOutlinedIcon
                                    style={{ color: "white" }}
                                  />
                                }
                                label={rowData.codeStatus}
                                style={theme.palette.chipred}
                              />
                            ) : null,
                        },
                        {
                          title: "Last Updated",
                          render: (rowData) =>
                            new Date(rowData.statusChangeDate).toDateString(),
                        },
                        {
                          title: "Details",
                          field: "approvedFlag",
                          render: (rowData) => (
                            <>
                              <Button
                                // size="small"
                                color="primary"
                                // variant="text"
                                onClick={() => this.getcode(rowData.id)}
                              >
                                <VisibilityOutlinedIcon />
                              </Button>
                            </>
                          ),
                        },
                        {
                          title: "Actions",
                          render: (rowData) => (
                            <Grid container direction="row" spacing={2}>
                              <FormControl
                                variant="outlined"
                                size="small"
                                style={{ minWidth: 85 }}
                                fullWidth
                              >
                                <InputLabel id="demo-simple-select-outlined-label">
                                  Status
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-outlined-label"
                                  id={rowData.id}
                                  value={this.state.status}
                                  open={this.state.open}
                                  onClose={() => this.setState({ open: false })}
                                  onOpen={() => this.setState({ open: true })}
                                  onChange={(event) => {
                                    this.setState({
                                      status: event.target.value,
                                      addDialogOpen: true,
                                      currentid: rowData.id,
                                    });
                                  }}
                                  label="Status"
                                >
                                  {rowData.status_options_employee_field.map(
                                    (val) => (
                                      <MenuItem value={val.status}>
                                        {val.action}
                                      </MenuItem>
                                    )
                                  )}
                                </Select>
                              </FormControl>
                              {this.state.addDialogOpen === true &&
                              this.state.currentid === rowData.id ? (
                                <>
                                  <Button
                                    color="primary"
                                    size="large"
                                    // variant="contained"
                                    onClick={() => {
                                      this.setState(
                                        {
                                          addDialogOpen: false,
                                        },
                                        this.updatestatus
                                      );
                                    }}
                                  >
                                    <CheckOutlinedIcon size="large" />
                                  </Button>
                                  <Button
                                    color="secondary"
                                    // variant="contained"
                                    onClick={() =>
                                      this.setState({
                                        addDialogOpen: false,
                                        status: null,
                                        currentid: "",
                                      })
                                    }
                                  >
                                    <ClearOutlinedIcon size="large" />
                                  </Button>
                                </>
                              ) : null}
                            </Grid>
                          ),
                        },
                      ]}
                      data={pendingcodes}
                      // actions={[{}]}
                      options={{
                        sorting: true,
                        paging: false,
                        search: true,
                        grouping: true,
                        actionsColumnIndex: -1,
                        headerStyle: {
                          backgroundColor: "#031a26",
                          color: "#FFF",
                          fontWeight: "bold",
                        },
                      }}
                    />
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Grid>
        {/* 
        {
          <div>
            <Dialog
              open={this.state.addDialogOpen}
              onClose={() => this.setState({ addDialogOpen: true })}
              aria-labelledby="form-dialog-title"
            >
              <DialogTitle id="form-dialog-title" justify="center">
                Update status
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Are you sure you want to do this?
                </DialogContentText>
              </DialogContent>
              <DialogActions></DialogActions>
            </Dialog>
          </div>
        } */}
        {/* </Paper> */}
        {this.updatesnackbar()}
        {/* GENERATE NEW CODE DIALOG DATA */}

        <div>
          <Dialog
            open={this.state.generateNewEmployementCodeDialog}
            onClose={() =>
              this.setState({ generateNewEmployementCodeDialog: false })
            }
          >
            <DialogTitle id="codegenerator">Code Generator</DialogTitle>
            <DialogContent>
              <Grid
                container
                justify="flex-start"
                direction="row"
                alignItems="center"
                spacing={2}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(event) => {
                        this.setState({
                          employersearch: !this.state.employersearch,
                          isadmin: !this.state.isadmin,
                        });
                      }}
                      name="employer"
                    />
                  }
                  label="Employer search"
                />
                {this.state.employersearch ? this.employersearchchoice() : null}

                <Grid item xs={12}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Provide access to:</FormLabel>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            // checked={employeeProfile}
                            onChange={() =>
                              this.setState({ rating: !this.state.rating })
                            }
                            name="ratings"
                          />
                        }
                        label="Ratings"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            // checked={jobProfile}
                            onChange={() =>
                              this.setState({ address: !this.state.address })
                            }
                            name="address"
                          />
                        }
                        label="Address"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            // checked={jobProfile}
                            onChange={() =>
                              this.setState({ profile: !this.state.profile })
                            }
                            name="profile"
                          />
                        }
                        label="Profile"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            // checked={jobProfile}
                            onChange={() =>
                              this.setState({
                                identites: !this.state.identites,
                              })
                            }
                            name="identites"
                          />
                        }
                        label="Identities"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            // checked={jobProfile}
                            onChange={() =>
                              this.setState({ phone: !this.state.phone })
                            }
                            name="phones"
                          />
                        }
                        label="Phones"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            // checked={jobProfile}
                            onChange={() =>
                              this.setState({
                                jobHistory: !this.state.jobHistory,
                              })
                            }
                            name="jobHistory"
                          />
                        }
                        label="Job History"
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions style={{ padding: 15 }}>
              <Button
                color="secondary"
                variant="contained"
                onClick={() => this.postcode()}
              >
                Generate One-time Code
              </Button>
            </DialogActions>
          </Dialog>
          {/* <Dialog
            // fullWidth={"lg"}
            maxWidth={"lg"}
            open={this.state.viewDialog}
            onClose={() => this.setState({ viewDialog: false })}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle id="codegenerator" align="center">
              Acess is requested
            </DialogTitle>
            <DialogContent>
              
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => this.setState({ viewDialog: false })}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog> */}
        </div>
      </>
    );
  }
  render() {
    // const [inputValue, setInputValue] = React.useState("");
    return (
      <div style={{ marginTop: 10 }}>
        {this.state.loading ? this.isloading() : this.gettable()}
        {/* <Paper style={{ padding: 20, height: '100vh' }}> */}
      </div>
    );
  }
}

export default withStyles(styles)(index);
