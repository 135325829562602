import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  TextField,
  IconButton,
  Avatar,
  CircularProgress,
  Grid,
  Typography,
  Button,
  FormControlLabel,
  Checkbox,
  Card,
  CardActions,
  CardContent,
  Box,
  Divider,
} from "@material-ui/core/";
import DialogTitle from "@material-ui/core/DialogTitle";
import CancelIcon from "@material-ui/icons/Cancel";
import FormLabel from "@material-ui/core/FormLabel";
import MaterialTable from "material-table";
import Search from "@material-ui/icons/Search";
import { forwardRef } from "react";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import ViewColumn from "@material-ui/icons/ViewColumn";

let token = "";
let id = "";
const api = "http://3.22.17.212:9000";
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const styles = (theme) => ({});

class index extends Component {
  state = {
    generateNewEmployementCodeDialog: false,
    pendingCodesCheck: false,
    codeDetailsDialog: false,

    allVerifications: [],
    pendingVerifications: [],
    codeMapLogic: "",
    viewDetails: [],

    employeeJobId: "",
    employeePicture: "",
    employeeFirstName: "",
    employeeMiddleName: "",
    employeeLastName: "",
    employeeEmail: "",
    employeeVotId: "",
    jobTitle: "",
    employeeJobCategory: "",
    employeeEndDate: "",
    employeeJobDescription: "",

    detailsStatus: "",
  };

  // constructor(props) {
  //     super(props);
  //     this.generateNewEmployementCodeButton = this.generateNewEmployementCodeButton.bind(this);
  //   }

  async fetchVerifications() {
    let response = await fetch(api + "/api/v1/employers/empVerifications", {
      headers: {
        Authorization: token,
      },
    });
    response = await response.json();
    // console.log('verSuccess:', response)
    // console.log("currentId:", id)
    this.setState({ allVerifications: response });
  }

  async fetchPendingVerifications() {
    let response = await fetch(
      api + "/api/v1/employers/empVerifications?pending=true",
      {
        headers: {
          Authorization: token,
        },
      }
    );
    response = await response.json();
    // console.log('pendingVerSuccess:', response)
    this.setState({ pendingVerifications: response });
  }

  async componentDidMount() {
    token = localStorage.getItem("Token");
    id = localStorage.getItem("id");

    this.fetchVerifications();
    this.fetchPendingVerifications();
  }

  isloading() {
    return (
      <>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          display="flex"
          style={{ minHeight: "0vh" }}
        >
          <CircularProgress />
        </Grid>
      </>
    );
  }

  render() {
    // const { classes } = this.props;

    return (
      <>
        {this.state.isLoading ? (
          this.isloading()
        ) : (
          <Box px={5}>
            <Grid
              container
              justify="space-between"
              alignItems="center"
              spacing={2}
            >
              <Grid item xs={12}>
                <Typography component={"span"} variant="h4">
                  Employement Verification
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>

              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.pendingCodesCheck}
                      onChange={(event) => {
                        this.setState({
                          pendingCodesCheck: !this.state.pendingCodesCheck,
                        });
                        // console.log('check1:', this.state.pendingCodesCheck)
                      }}
                      name="checkedB"
                      color="primary"
                    />
                  }
                  label="Pending verifications"
                />
              </Grid>
            </Grid>

            <Grid
              container
              justify="flex-start"
              alignItems="center"
              spacing={2}
            >
              {this.allCodesTable()}
            </Grid>
          </Box>
        )}
      </>
    );
  }

  allCodesTable() {
    return this.state.codeDetailsDialog ? (
      this.viewCodeDetails()
    ) : (
      <Grid container>
        {" "}
        <Grid item xs={12}>
          <MaterialTable
            icons={tableIcons}
            title=""
            columns={[
              {
                title: "Picture",
                // field: "employeeDetails.picture_url",
                render: (rowData) => (
                  <Avatar
                    src={rowData.employeeDetails.picture_url}
                    style={{ height: "4rem", width: "4rem" }}
                  >
                    {/* <img
                      src={rowData.employeeDetails.picture_url}
                      width="65"
                      height="65"
                      alt=""
                    /> */}
                  </Avatar>
                ),
              },
              {
                title: "Name",
                field: "employeeDetails.firstname",
                render: (rowData) => (
                  <>
                    <p>{rowData.employeeDetails.firstname}</p>
                  </>
                ),
              },

              { title: "Job Title", field: "jobDetails.jobTitle" },
              { title: "Verify Ontrac ID", field: "employeeDetails.ontrac_id" },
              { title: "Code String", field: "empVerDetails.codeString" },
              { title: "Status", field: "empVerDetails.updateStatus" },
              {
                title: "Action",
                render: (row) => (
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() =>
                      this.setState(
                        {
                          viewDetails: [row],

                          employeeJobId: row.empVerDetails.id,
                          employeePicture: row.employeeDetails.picture_url,
                          employeeFirstName: row.employeeDetails.firstname,
                          employeeMiddleName: row.employeeDetails.middlename,
                          employeeLastName: row.employeeDetails.surname,
                          employeeEmail: row.employeeDetails.email,
                          employeeVotId: row.employeeDetails.ontrac_id,
                          jobTitle: row.jobDetails.jobTitle,
                          employeeJobCategory:
                            row.jobDetails.job_category_field,
                          employeeEndDate: row.jobDetails.endDate,
                          employeeJobDescription: row.jobDetails.jobDescription,

                          detailsStatus: row.empVerDetails.updateStatus,

                          codeDetailsDialog: true,
                        }
                        // () => console.log('viewDetails:', this.state.viewDetails)
                      )
                    }
                  >
                    Details
                  </Button>
                ),
              },
            ]}
            data={
              this.state.pendingCodesCheck
                ? this.state.pendingVerifications
                : this.state.allVerifications
            }
            options={{
              sorting: true,
              paging: false,
              search: true,
              grouping: true,
              actionsColumnIndex: -1,
              headerStyle: {
                backgroundColor: "#031a26",
                color: "#FFF",
                fontWeight: "bold",
              },
            }}
          />
        </Grid>
      </Grid>
    );
  }

  tableDisplayLogic() {
    return this.allCodesTable();
  }

  viewCodeDetails() {
    return (
      <React.Fragment>
        <Card>
          <DialogTitle id="codeDetails">
            <Grid container justify="space-between" alignItems="center">
              <Grid item>Job Details</Grid>
              <Grid item>
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={() => {
                    this.setState({ codeDetailsDialog: false });
                  }}
                >
                  Back
                </Button>
              </Grid>
            </Grid>
          </DialogTitle>
          <CardContent>
            {/* <Paper variant='outlined' style={{ padding: 20 }}> */}

            <Grid
              container
              justify="center"
              direction="row"
              alignItems="center"
              spacing={1}
              // style={{ padding: 20 }}
            >
              <Grid item xs={12}>
                <FormLabel component="legend">Employee Details:</FormLabel>
              </Grid>

              <Grid item xs={12}>
                <Avatar
                  src={this.state.employeePicture}
                  style={{ height: "8rem", width: "8rem" }}
                >
                  <img src={id.picture} width="130" height="130" alt="" />
                </Avatar>
              </Grid>

              <Grid item xs={4}>
                <TextField
                  id="fullName"
                  label="Fisrt Name"
                  defaultValue={this.state.employeeFirstName}
                  type="text"
                  InputProps={{
                    readOnly: true,
                  }}
                  fullWidth
                  size="small"
                />
              </Grid>

              <Grid item xs={4}>
                <TextField
                  id="middleName"
                  label="Middle Name"
                  defaultValue={this.state.employeeMiddleName}
                  type="text"
                  InputProps={{
                    readOnly: true,
                  }}
                  fullWidth
                  size="small"
                />
              </Grid>

              <Grid item xs={4}>
                <TextField
                  id="surname"
                  label="Surname"
                  defaultValue={this.state.employeeLastName}
                  type="text"
                  InputProps={{
                    readOnly: true,
                  }}
                  fullWidth
                  size="small"
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  id="email"
                  label="Email Id"
                  defaultValue={this.state.employeeEmail}
                  type="text"
                  InputProps={{
                    readOnly: true,
                  }}
                  fullWidth
                  size="small"
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  id="emailId"
                  label="Verify Ontrac Id"
                  defaultValue={this.state.employeeVotId}
                  type="text"
                  InputProps={{
                    readOnly: true,
                  }}
                  fullWidth
                  size="small"
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  id="jobTitle"
                  label="Job Title"
                  defaultValue={this.state.jobTitle}
                  type="text"
                  InputProps={{
                    readOnly: true,
                  }}
                  fullWidth
                  size="small"
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  id="jobCategory"
                  label="Job Category"
                  defaultValue={this.state.employeeJobCategory}
                  type="text"
                  InputProps={{
                    readOnly: true,
                  }}
                  fullWidth
                  size="small"
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  id="endDate"
                  label="End Date"
                  defaultValue={
                    this.state.employeeEndDate === null
                      ? "NA"
                      : this.state.employeeEndDate
                  }
                  type="text"
                  InputProps={{
                    readOnly: true,
                  }}
                  fullWidth
                  size="small"
                />
              </Grid>

              <Grid item xs={12} style={{ marginTop: 5 }}>
                <TextField
                  id="jobDescription"
                  label="Job Description"
                  defaultValue={this.state.employeeJobDescription}
                  type="text"
                  InputProps={{
                    readOnly: true,
                  }}
                  fullWidth
                  size="small"
                  variant="outlined"
                  multiline
                  rows={3}
                />
              </Grid>
            </Grid>

            {/* </Paper> */}
          </CardContent>
          <CardActions style={{ padding: 15 }}>
            <Grid container justify="flex-end" spacing={2}>
              {this.state.detailsStatus !==
                "Employment Verification Confirmed" &&
              this.state.detailsStatus !==
                "Employment Verification Rejected" ? (
                <>
                  <Grid item>
                    <Button
                      color="primary"
                      variant="contained"
                      style={{ minWidth: 100 }}
                      onClick={() =>
                        this.approveVerification(this.employeeJobId)
                      }
                    >
                      Approve
                    </Button>
                  </Grid>

                  <Grid item>
                    <Button
                      color="secondary"
                      variant="contained"
                      style={{ minWidth: 100 }}
                      onClick={() =>
                        this.rejectVerification(this.employeeJobId)
                      }
                    >
                      Reject
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => {
                        this.setState({ codeDetailsDialog: false });
                      }}
                    >
                      Close
                    </Button>
                  </Grid>
                </>
              ) : (
                <div />
              )}
            </Grid>
          </CardActions>
        </Card>
      </React.Fragment>
    );
  }

  async approveVerification(JobId) {
    // console.log('jobId:', this.state.employeeJobId)

    try {
      let response = await fetch(
        api +
          "/api/v1/employers/confirmEmpVerification/" +
          this.state.employeeJobId,
        {
          method: "PUT",
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(""),
        }
      );
      response = await response.json();
      // console.log('approveJob:', response);

      this.setState({ codeDetailsDialog: false });
      this.fetchVerifications();
      this.fetchPendingVerifications();
    } catch (error) {
      // console.log("[!ON_REGISTER] " + error);
    }
  }

  async rejectVerification(JobId) {
    // console.log('jobId:', this.state.employeeJobId)

    try {
      let response = await fetch(
        api +
          "/api/v1/employers/rejectEmpVerification/" +
          this.state.employeeJobId,
        {
          method: "PUT",
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(""),
        }
      );
      response = await response.json();
      // console.log('rejectJob:', response);

      this.setState({ codeDetailsDialog: false });
      this.fetchVerifications();
      this.fetchPendingVerifications();
    } catch (error) {
      // console.log("[!ON_REGISTER] " + error);
    }
  }
}

export default withStyles(styles)(index);
