import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  TextField,
  CircularProgress,
  Paper,
  Grid,
  Typography,
  Button,
  TableContainer,
  FormControlLabel,
  Checkbox,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  CardActions,
  Card,
  CardHeader,
  CardContent,
  Chip,
  Divider
} from "@material-ui/core/";

import {
 
  Box,
} from "@material-ui/core/";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormLabel from "@material-ui/core/FormLabel";
import MaterialTable from "material-table";
import Search from "@material-ui/icons/Search";
import { forwardRef } from "react";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import SearchBar from "material-ui-search-bar";
import ViewColumn from "@material-ui/icons/ViewColumn";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import WorkOutlineIcon from "@material-ui/icons/WorkOutline";
import PinDropIcon from "@material-ui/icons/PinDrop";
import Tooltip from "@material-ui/core/Tooltip";
import ClearOutlinedIcon from "@material-ui/icons/ClearOutlined";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
// import ClearOutlinedIcon from "@material-ui/icons/ClearOutlined";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import PriorityHighOutlinedIcon from "@material-ui/icons/PriorityHighOutlined";
import theme from "../../../../../theme";
import CheckCircleOutlineOutlinedIcon from "@material-ui/icons/CheckCircleOutlineOutlined";
let token = "";
let id = "";
const api = "http://3.22.17.212:9000";
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const styles = (theme) => ({});

class index extends Component {
  state = {
    generateNewEmployementCodeDialog: false,
    viewOfferButton: false,
    modifyOfferButton: false,
    newOfferButton: false,
    cancelOfferButton: false,
    confirmOfferButton: false,
    confirmOpen: false,
    employeeByRadio: "searchByPhone",

    cancelButton: "",
    modifyButton: "",
    acceptButton: "",

    categoryId: "",
    newOfferId: "",

    selectedVotId: "",
    selectedJobType: "",
    selectedJobTypeNumeric: "",
    jobSalary: "",
    startDate: "",
    jobDescription: "",
    otherConditions: "",
    jobTitle: "",
    showAccept_field: "",
    showCancelOffer_field: "",
    showEmpJoined_field: "",
    showModify_field: "",
    showNewOffer_field: "",
    showReject_field: "",

    selectedVotId1: "",
    selectedJobType1: "",
    jobSalary1: "",
    startDate1: "",
    jobDescription1: "",
    otherConditions1: "",
    jobTitle1: "",
    showAccept_field1: "",
    showCancelOffer_field1: "",
    showEmpJoined_field1: "",
    showModify_field1: "",
    showNewOffer_field1: "",
    showReject_field1: "",

    employeeId: "",

    onboardOffers: [],
    getEmployeeByOntracId: [],
    getEmployeePhone: [],
    getAllJobTypes: [],
    viewDetailsData: [],
    isLoading: true,
    joinId: "",

    votIdMatchesLength: "",
    votIdAllMatches: [],

    matchedVotId: "",
    generateButton: true,

    phoneMatchesLength: "",
    phoneAllMatches: [],
    votmatchError: "",
    matchedPhone: "",
    generateButton: true,
    phonematchError: "",
  };

  // constructor(props) {
  //     super(props);
  //     this.generateNewEmployementCodeButton = this.generateNewEmployementCodeButton.bind(this);
  //   }

  isloading() {
    return (
      <>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          display="flex"
          style={{ minHeight: "0vh" }}
        >
          <CircularProgress />
        </Grid>
      </>
    );
  }

  // async fetchAllEmployeesOntracId() {

  //     let response = await fetch(api + "/api/v1/accounts/employee?ontrac_id=",
  //         {
  //             headers: {
  //                 'Authorization': token
  //             }
  //         });
  //     response = await response.json();
  //     console.log('empAllOntracIds:', response)
  //     this.setState({ getEmployeeByOntracId: response });
  // }

  async selectedEmployeeOntracId(votId) {
    let response = await fetch(
      api + "/api/v1/accounts/employee?ontrac_id=" + votId,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    response = await response.json();
    // console.log('OTIDSuccess:', response)
    this.setState({ votIdAllMatches: response });
    // console.log('votIdAllMatches:', this.state.votIdAllMatches)
    this.setState({ votIdMatchesLength: this.state.votIdAllMatches.length });
    // console.log('votIdMatchesLength:', this.state.votIdMatchesLength)

    this.state.votIdMatchesLength === 1
      ? this.setState({
          matchedVotId: this.state.votIdAllMatches,
          matchedVotId: this.state.votIdAllMatches[0].id,
          generateButton: false,
          votmatchError: false,
        })
      : this.setState(
          { votmatchError: true, generateButton: true }
          // console.log('matchedEmployeeId:', this.state.matchedVotId)
        );
  }

  // async fetchAllEmployeesPhones(phone) {

  //     let response = await fetch(api + "/api/v1/accounts/employee?phone=" + phone,
  //         {
  //             headers: {
  //                 'Authorization': token
  //             }
  //         });
  //     response = await response.json();
  //     console.log('empAllPhones:', response)
  //     this.setState({ getEmployeePhone: response });
  // }

  async fetchEmployeePhones(phone) {
    let response = await fetch(
      api + "/api/v1/accounts/employee?phone=" + phone,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    response = await response.json();
    // console.log('phoneSuccess:', response)
    this.setState({ phoneAllMatches: response });
    // console.log('phoneAllMatches:', this.state.phoneAllMatches)
    this.setState({ phoneMatchesLength: this.state.phoneAllMatches.length });
    // console.log('phoneMatchesLength:', this.state.phoneMatchesLength)

    this.state.phoneMatchesLength === 1
      ? this.setState({
          matchedVotId: this.state.phoneAllMatches,
          matchedVotId: this.state.phoneAllMatches[0].id,
          generateButton: false,
          phonematchError: false,
        })
      : this.setState(
          { phonematchError: true, generateButton: true }
          // console.log('matchedEmployeeId:', this.state.matchedPhone)
        );
  }

  async fetchAllJobTypes() {
    let response = await fetch(api + "/api/v1/resManager/job/categories/", {
      headers: {
        Authorization: token,
      },
    });
    response = await response.json();
    // console.log('getAllJobTypes:', response)
    this.setState({ getAllJobTypes: response });
  }

  async fetchOnboardOffers() {
    let response = await fetch(api + "/api/v1/employers/oboffers", {
      headers: {
        Authorization: token,
      },
    });
    response = await response.json();
    // console.log('obSuccess:', response)
    // console.log("currentId:", id)
    this.setState({ onboardOffers: response });
  }

  async fetchOnboardOfferDetails(rowId) {
    let response = await fetch(api + "/api/v1/employers/oboffers/" + rowId, {
      headers: {
        Authorization: token,
      },
    });
    response = await response.json();
    this.setState({ viewDetailsData: response });
    // console.log('viewDetailsData:', response)
    // console.log('Length:', this.state.viewDetailsData.length)
    // console.log('firstStatus:', this.state.viewDetailsData[0].showNewOffer_field)

    this.setState({ employeeId: this.state.viewDetailsData[0].employee });

    this.state.viewDetailsData.length !== 1
      ? this.setState(
          {
            selectedVotId: this.state.viewDetailsData[0].employee_ontracid,
            selectedJobType: this.state.viewDetailsData[0]
              .jobCategory_name_field,
            selectedJobTypeNumeric: this.state.viewDetailsData[0].jobCategory,
            jobSalary: this.state.viewDetailsData[0].startSalary,
            startDate: this.state.viewDetailsData[0].startDate,
            jobDescription: this.state.viewDetailsData[0].jobDescription,
            otherConditions: this.state.viewDetailsData[0].conditions,
            jobTitle: this.state.viewDetailsData[0].jobTitle,
            selectedVotId1: this.state.viewDetailsData[1].employee_ontracid,
            selectedJobType1: this.state.viewDetailsData[1]
              .jobCategory_name_field,
            jobSalary1: this.state.viewDetailsData[1].startSalary,
            startDate1: this.state.viewDetailsData[1].startDate,
            jobDescription1: this.state.viewDetailsData[1].jobDescription,
            otherConditions1: this.state.viewDetailsData[1].conditions,
            jobTitle1: this.state.viewDetailsData[1].jobTitle,

            viewOfferButton: true,
          }
          // console.log('statusOffer:', this.state.showNewOffer_field, 'not 1')
        )
      : this.setState(
          {
            selectedVotId: this.state.viewDetailsData[0].employee_ontracid,
            selectedJobType: this.state.viewDetailsData[0]
              .jobCategory_name_field,
            jobSalary: this.state.viewDetailsData[0].startSalary,
            startDate: this.state.viewDetailsData[0].startDate,
            jobDescription: this.state.viewDetailsData[0].jobDescription,
            otherConditions: this.state.viewDetailsData[0].conditions,
            jobTitle: this.state.viewDetailsData[0].jobTitle,

            viewOfferButton: true,
          }
          // console.log('statusOffer2:', this.state.showNewOffer_field, 'this is 1')
        );
  }

  async componentDidMount() {
    token = localStorage.getItem("Token");
    id = localStorage.getItem("id");

    this.fetchOnboardOffers();
    // this.fetchAllEmployeesOntracId();
    // this.fetchAllEmployeesPhones();
    this.fetchAllJobTypes();

    this.setState({ isLoading: false });
  }

  render() {
    const { classes } = this.props;

    return this.state.isLoading ? this.isloading() : this.displayTable();
  }

  displayTable() {
    return (
      <>
        <Grid container justify="space-between" alignItems="center" spacing={2}>
          <Grid item xs={8}>
            <Typography component={"span"} variant="h4">
              Onboarding
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Button
              color="secondary"
              variant="contained"
              onClick={() =>
                this.setState({
                  generateNewEmployementCodeDialog: !this.state
                    .generateNewEmployementCodeDialog,
                })
              }
              fullWidth
            >
              Generate New Onboarding Request
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item>
            {this.state.generateNewEmployementCodeDialog ? (
              this.generateNewEmploymentDialog()
            ) : this.state.viewOfferButton ? (
              this.viewOfferDetailsDialog()
            ) : (
              <Grid
                container
                justify="flex-start"
                alignItems="center"
                spacing={2}
              >
                <Grid item xs={12}>
                  <MaterialTable
                    icons={tableIcons}
                    title=""
                    columns={[
                      { title: "Created on", field: "created_on" },
                      {
                        title: "OnTrac Id",
                        field: "employee_ontracid",
                      },
                      {
                        title: "Job Category",
                        field: "jobCategory_name_field",
                      },
                      { title: "Job title", field: "jobTitle" },
                      { title: "Start date", field: "startDate" },
                      {
                        title: "Onboard Status",
                        field: "obStatus",
                        render: (rowData) =>
                          rowData.obStatus === "Onboarded" ? (
                            <Chip
                              icon={
                                <CheckCircleOutlineOutlinedIcon
                                  style={{ color: "white" }}
                                />
                              }
                              label={rowData.obStatus}
                              style={theme.palette.chipgreen}
                            />
                          ) : rowData.obStatus === "Accepted" ? (
                            <Chip
                              icon={
                                <PriorityHighOutlinedIcon
                                  style={{ color: "white" }}
                                />
                              }
                              label={rowData.obStatus}
                              style={theme.palette.chipyellow}
                            />
                          ) : rowData.obStatus === "Offered" ? (
                            <Chip
                              icon={
                                <CancelOutlinedIcon
                                  style={{ color: "white" }}
                                />
                              }
                              label={rowData.obStatus}
                              style={theme.palette.chipgreen}
                            />
                          ) : null,
                      },
                      {
                        title: "Action",
                        render: (rowData) => (
                          <>
                            <Button
                              style={{ minWidth: 125 }}
                              size="small"
                              color="primary"
                              variant="outlined"
                              onClick={() =>
                                this.setState(
                                  {
                                    cancelButton: rowData.showCancelOffer_field,
                                    modifyButton: rowData.showNewOffer_field,
                                    acceptButton: rowData.showAccept_field,
                                    newOfferId: rowData.id,
                                  },
                                  () =>
                                    this.fetchOnboardOfferDetails(rowData.id)
                                )
                              }
                            >
                              View Details
                            </Button>
                            {rowData.showEmpJoined_field ? (
                              <>
                                <Button
                                  style={{ minWidth: 125, marginTop: 10 }}
                                  size="small"
                                  color="secondary"
                                  variant="outlined"
                                  onClick={() =>
                                    this.setState({
                                      joinId: rowData.id,
                                      confirmOpen: true,
                                    })
                                  }
                                >
                                  Join Employee
                                </Button>
                                {this.state.joinId === rowData.id &&
                                this.state.confirmOpen ? (
                                  <>
                                    <Grid container direction="row">
                                      <Grid item xs={6}>
                                        <Button
                                          color="primary"
                                          // variant="contained"
                                          style={{ minWidth: 100 }}
                                          onClick={() =>
                                            this.joinEmployee(this.state.joinId)
                                          }
                                        >
                                          <CheckOutlinedIcon size="large" />
                                        </Button>
                                      </Grid>
                                      <Grid item xs={6}>
                                        <Button
                                          color="secondary"
                                          // variant="contained"
                                          style={{ minWidth: 100 }}
                                          onClick={() =>
                                            this.setState({
                                              confirmOpen: false,
                                            })
                                          }
                                        >
                                          <ClearOutlinedIcon size="large" />
                                        </Button>
                                      </Grid>
                                    </Grid>
                                  </>
                                ) : null}
                              </>
                            ) : (
                              <div />
                            )}
                          </>
                        ),
                      },
                    ]}
                    data={this.state.onboardOffers}
                    options={{
                      sorting: true,
                      paging: false,
                      search: true,
                      grouping: true,
                      actionsColumnIndex: -1,
                      headerStyle: {
                        backgroundColor: "#031a26",
                        color: "#FFF",
                        fontWeight: "bold",
                      },
                    }}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
        {this.confirmOfferDialog()}
        {this.cancelOfferDialog()}
        {this.newOfferDialog()}
      </>
    );
  }
  generateNewEmploymentDialog() {
    return (
      <Card

      // onClose={() =>
      //   this.setState({
      //     generateNewEmployementCodeDialog: false,
      //     selectedVotId: "",
      //     selectedJobType: "",
      //     jobTitle: "",
      //     startDate: "",
      //     jobDescription: "",
      //     otherConditions: "",
      //     jobSalary: "",
      //     votmatchError: "",
      //     phonematchError: "",
      //     votIdMatchesLength: "",
      //     generateButton: true,
      //   })
      // }
      >
        <CardHeader id="alert-dialog-title" title="New Request"></CardHeader>
        <CardContent>
          <Grid
            container
            justify="flex-start"
            direction="row"
            alignItems="center"
            spacing={2}
            // style={{ padding: 20 }}
          >
            <Grid item xs={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend" required>
                  Search employee by:
                </FormLabel>
                <RadioGroup
                  name="searchCategory"
                  value={this.state.employeeByRadio}
                  onChange={(event) => {
                    this.setState({
                      employeeByRadio: event.target.value,
                    });
                    // console.log('Radio:', this.state.employeeByRadio);
                  }}
                >
                  <Grid container direction="row" style={{ marginTop: 10 }}>
                    <FormControlLabel
                      value="searchByPhone"
                      control={<Radio />}
                      label="OnTrac Id"
                    />
                    <FormControlLabel
                      value="searchByOntracId"
                      control={<Radio />}
                      label="Phone"
                    />
                  </Grid>
                </RadioGroup>
              </FormControl>
            </Grid>

            {this.state.employeeByRadio !== "searchByOntracId" ? (
              <Grid item xs={12}>
                <TextField
                  required
                  id="searchByOntracId"
                  label="Verify OnTrac Id"
                  variant="outlined"
                  type="text"
                  fullWidth
                  error={this.state.votmatchError}
                  helperText={
                    this.state.votmatchError
                      ? "Not an existing OnTrac Id!"
                      : this.state.votIdMatchesLength === 1
                      ? ("Match found:",
                        this.state.votIdAllMatches[0].firstname +
                          " " +
                          this.state.votIdAllMatches[0].middlename +
                          "" +
                          this.state.votIdAllMatches[0].surname)
                      : ""
                  }
                  onChange={(event) =>
                    this.selectedEmployeeOntracId(event.target.value)
                  }
                />
              </Grid>
            ) : (
              <Grid item xs={12}>
                <TextField
                  required
                  id="searchByPhone"
                  label="Phone"
                  variant="outlined"
                  type="number"
                  fullWidth
                  error={this.state.phonematchError}
                  helperText={
                    this.state.phonematchError
                      ? "Not an existing phone number!"
                      : this.state.phoneMatchesLength === 1
                      ? "Match found:" +
                        this.state.phoneAllMatches[0].firstname +
                        "" +
                        this.state.phoneAllMatches[0].middlename +
                        "" +
                        this.state.phoneAllMatches[0].surname +
                        ""
                      : ""
                  }
                  onChange={(event) =>
                    this.fetchEmployeePhones(event.target.value)
                  }
                />
              </Grid>
            )}

            <Grid item xs={12}>
              <FormLabel component="legend" required>
                Enter Job Details:
              </FormLabel>
            </Grid>

            <Grid item xs={12}>
              <FormControl variant="outlined" fullWidth size="small">
                <InputLabel id="demo-simple-select-outlined-label" required>
                  Job Type
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  // value={age}
                  onChange={(event) =>
                    this.setState(
                      { selectedJobType: event.target.value }
                      // (event) =>console.log("jobId:", this.state.selectedJobType)
                    )
                  }
                  label="Job Type"
                >
                  {this.state.getAllJobTypes.map((row, index) => (
                    <MenuItem value={row.id} key={index}>
                      {row.positionCategory}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <TextField
                required
                id="jobTitle"
                label="Job Title"
                variant="outlined"
                value={this.state.jobTitle}
                onChange={(event) => {
                  this.setState({ jobTitle: event.target.value });
                }}
                type="text"
                fullWidth
                size="small"
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                required
                id="startingSalary"
                label="Starting Salary"
                variant="outlined"
                value={this.state.jobSalary}
                onChange={(event) => {
                  this.setState({ jobSalary: event.target.value });
                }}
                type="number"
                fullWidth
                size="small"
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                required
                id="startingDate"
                variant="outlined"
                value={this.state.startDate}
                onChange={(event) => {
                  this.setState({ startDate: event.target.value });
                }}
                type="date"
                helperText="Starting date"
                fullWidth
                size="small"
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                required
                id="jobDescription"
                label="Job Description"
                variant="outlined"
                value={this.state.jobDescription}
                onChange={(event) => {
                  this.setState({ jobDescription: event.target.value });
                }}
                type="text"
                fullWidth
                multiline
                rows={3}
                size="small"
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                required
                id="otherConditions"
                label="Other Conditions"
                variant="outlined"
                value={this.state.otherConditions}
                onChange={(event) => {
                  this.setState({ otherConditions: event.target.value });
                }}
                type="text"
                fullWidth
                multiline
                rows={3}
                size="small"
              />
            </Grid>
          </Grid>
        </CardContent>
        <Grid container justify="flex-end">
          <CardActions style={{ padding: 15 }}>
            <Button
              color="primary"
              variant="contained"
              disabled={this.state.generateButton}
              onClick={() => this.sendJobOffer()}
            >
              Send Job Request
            </Button>
            <Button
              color="secondary"
              variant="contained"
              onClick={() =>
                this.setState({
                  generateNewEmployementCodeDialog: false,
                  selectedVotId: "",
                  selectedJobType: "",
                  jobTitle: "",
                  startDate: "",
                  jobDescription: "",
                  otherConditions: "",
                  jobSalary: "",
                })
              }
            >
              Cancel
            </Button>
          </CardActions>
        </Grid>
      </Card>
    );
  }
  viewOfferDetailsDialog() {
    return (
      <div>
        <Card>
          <DialogTitle id="alert-dialog-title">
            <Grid container justify="space-between" alignItems="center">
              <Grid item>Job Details</Grid>
              <Grid item>
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={() =>
                    this.setState({
                      viewOfferButton: false,
                      modifyOfferButton: false,
                    })
                  }
                >
                  Back
                </Button>
                {/* <IconButton>
                  <CancelIcon />
                </IconButton> */}
              </Grid>
            </Grid>
          </DialogTitle>
          <CardContent>
            <Grid container justify="space-between" spacing={2}>
              <Grid item xs={6}>
                <TextField
                  id="verifyOntracId"
                  label="Verify Ontrac Id"
                  defaultValue={this.state.selectedVotId}
                  type="text"
                  InputProps={{
                    readOnly: true,
                  }}
                  fullWidth
                  size="small"
                />
              </Grid>
              {
                // true
                this.state.modifyButton ? (
                  <>
                    <Grid item>
                      <Button
                        style={{ minWidth: 125 }}
                        color="primary"
                        variant="outlined"
                        size="large"
                        onClick={() =>
                          this.setState({
                            modifyOfferButton: !this.state.modifyOfferButton,
                          })
                        }
                      >
                        {this.state.modifyOfferButton ? "Back" : "Modify Offer"}
                      </Button>
                    </Grid>
                  </>
                ) : (
                  <div />
                )
              }

              {
                // true
                this.state.modifyButton ? (
                  this.state.modifyOfferButton ? (
                    <>
                      <Grid item xs={12}>
                        <Paper variant="outlined" style={{ padding: 15 }}>
                          <Grid
                            container
                            justify="flex-start"
                            direction="row"
                            alignItems="center"
                            spacing={2}
                            // style={{ padding: 20 }}
                          >
                            <Grid item xs={12}>
                              <FormLabel component="legend">
                                Enter new offer details:
                              </FormLabel>
                            </Grid>

                            <Grid item xs={12}>
                              <FormControl
                                variant="outlined"
                                fullWidth
                                size="small"
                              >
                                <InputLabel id="demo-simple-select-outlined-label">
                                  Job Type
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-outlined-label"
                                  id="demo-simple-select-outlined"
                                  defaultValue={
                                    this.state.selectedJobTypeNumeric
                                  }
                                  onChange={(event) =>
                                    this.setState(
                                      { categoryId: event.target.value }
                                      // (event) => console.log('jobId!!!!:', this.state.selectedJobType)
                                    )
                                  }
                                  label="Job Type"
                                >
                                  {this.state.getAllJobTypes.map((row) => (
                                    <MenuItem value={row.id}>
                                      {row.positionCategory}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                              <TextField
                                id="jobTitle"
                                label="Job Title"
                                variant="outlined"
                                defaultValue={this.state.jobTitle}
                                onChange={(event) => {
                                  this.setState({
                                    jobTitle: event.target.value,
                                  });
                                }}
                                type="text"
                                fullWidth
                                size="small"
                              />
                            </Grid>

                            <Grid item xs={12}>
                              <TextField
                                id="startingSalary"
                                label="Starting Salary"
                                variant="outlined"
                                value={this.state.jobSalary}
                                onChange={(event) => {
                                  this.setState({
                                    jobSalary: event.target.value,
                                  });
                                }}
                                type="number"
                                fullWidth
                                size="small"
                              />
                            </Grid>

                            <Grid item xs={12}>
                              <TextField
                                id="startingDate"
                                variant="outlined"
                                defaultValue={this.state.startDate}
                                onChange={(event) => {
                                  this.setState({
                                    startDate: event.target.value,
                                  });
                                }}
                                type="date"
                                helperText="Starting date"
                                fullWidth
                                size="small"
                              />
                            </Grid>

                            <Grid item xs={12}>
                              <TextField
                                id="jobDescription"
                                label="Job Description"
                                variant="outlined"
                                defaultValue={this.state.jobDescription}
                                onChange={(event) => {
                                  this.setState({
                                    jobDescription: event.target.value,
                                  });
                                }}
                                type="text"
                                fullWidth
                                multiline
                                rows={3}
                                size="small"
                              />
                            </Grid>

                            <Grid item xs={12}>
                              <TextField
                                id="otherConditions"
                                label="Other Conditions"
                                variant="outlined"
                                defaultValue={this.state.otherConditions}
                                onChange={(event) => {
                                  this.setState({
                                    otherConditions: event.target.value,
                                  });
                                }}
                                type="text"
                                fullWidth
                                multiline
                                rows={3}
                                size="small"
                              />
                            </Grid>
                          </Grid>
                        </Paper>
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        spacing={2}
                      >
                        <Grid item xs={6}>
                          <Paper variant="outlined" style={{ padding: 15 }}>
                            <Grid
                              container
                              justify="flex-start"
                              direction="row"
                              alignItems="center"
                              spacing={2}
                              // style={{ padding: 20 }}
                            >
                              <Grid item xs={12}>
                                <FormLabel component="legend">
                                  Original offer:
                                </FormLabel>
                              </Grid>

                              <Grid item xs={12}>
                                <TextField
                                  id="ModifiedjobType"
                                  label="Job type"
                                  variant="outlined"
                                  defaultValue={this.state.selectedJobType1}
                                  // onChange={}
                                  type="text"
                                  fullWidth
                                  size="small"
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Grid>

                              <Grid item xs={12}>
                                <TextField
                                  id="ModifiedjobTitle"
                                  label="Job title"
                                  variant="outlined"
                                  defaultValue={this.state.jobTitle1}
                                  // onChange={}
                                  type="text"
                                  fullWidth
                                  size="small"
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Grid>

                              <Grid item xs={12}>
                                <TextField
                                  id="ModifiedstartingSalary"
                                  label="Starting Salary"
                                  variant="outlined"
                                  defaultValue={this.state.jobSalary1}
                                  // onChange={}
                                  type="number"
                                  fullWidth
                                  size="small"
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Grid>

                              <Grid item xs={12}>
                                <TextField
                                  id="ModifiedstartingDate"
                                  // label="Starting Salary"
                                  variant="outlined"
                                  defaultValue={this.state.startDate1}
                                  // onChange={}
                                  type="date"
                                  helperText="Starting date"
                                  fullWidth
                                  size="small"
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Grid>

                              <Grid item xs={12}>
                                <TextField
                                  id="ModifiedjobDescription"
                                  label="Job Description"
                                  variant="outlined"
                                  defaultValue={this.state.jobDescription1}
                                  // onChange={}
                                  type="date"
                                  fullWidth
                                  multiline
                                  rows={3}
                                  size="small"
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Grid>

                              <Grid item xs={12}>
                                <TextField
                                  id="ModifiedotherConditions"
                                  label="Other Conditions"
                                  variant="outlined"
                                  defaultValue={this.state.otherConditions1}
                                  // onChange={}
                                  type="date"
                                  fullWidth
                                  multiline
                                  rows={3}
                                  size="small"
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </Paper>
                        </Grid>

                        <Grid item xs={6}>
                          <Paper variant="outlined" style={{ padding: 15 }}>
                            <Grid
                              container
                              justify="flex-start"
                              direction="row"
                              alignItems="center"
                              spacing={2}
                              // style={{ padding: 20 }}
                            >
                              <Grid item xs={12}>
                                <FormLabel component="legend">
                                  Modified offer:
                                </FormLabel>
                              </Grid>

                              <Grid item xs={12}>
                                <TextField
                                  id="OriginaljobType"
                                  label="Job type"
                                  variant="outlined"
                                  defaultValue={this.state.selectedJobType}
                                  // onChange={}
                                  type="text"
                                  fullWidth
                                  size="small"
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Grid>

                              <Grid item xs={12}>
                                <TextField
                                  id="OriginaljobTitle"
                                  label="Job title"
                                  variant="outlined"
                                  defaultValue={this.state.jobTitle}
                                  // onChange={}
                                  type="text"
                                  fullWidth
                                  size="small"
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Grid>

                              <Grid item xs={12}>
                                <TextField
                                  id="OriginalstartingSalary"
                                  label="Starting Salary"
                                  variant="outlined"
                                  defaultValue={this.state.jobSalary}
                                  // onChange={}
                                  type="number"
                                  fullWidth
                                  size="small"
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Grid>

                              <Grid item xs={12}>
                                <TextField
                                  id="OriginalstartingDate"
                                  // label="Starting Salary"
                                  variant="outlined"
                                  defaultValue={this.state.startDate}
                                  // onChange={}
                                  type="date"
                                  helperText="Starting date"
                                  fullWidth
                                  size="small"
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Grid>

                              <Grid item xs={12}>
                                <TextField
                                  id="OriginaljobDescription"
                                  label="Job Description"
                                  variant="outlined"
                                  defaultValue={this.state.jobDescription}
                                  // onChange={}
                                  type="date"
                                  fullWidth
                                  multiline
                                  rows={3}
                                  size="small"
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Grid>

                              <Grid item xs={12}>
                                <TextField
                                  id="OriginalotherConditions"
                                  label="Other Conditions"
                                  variant="outlined"
                                  defaultValue={this.state.otherConditions}
                                  // onChange={}
                                  type="date"
                                  fullWidth
                                  multiline
                                  rows={3}
                                  size="small"
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </Paper>
                        </Grid>
                      </Grid>
                    </>
                  )
                ) : (
                  <>
                    <Grid
                      container
                      direction="row"
                      justify="center"
                      alignItems="center"
                      spacing={2}
                    >
                      <Grid item xs={12}>
                        <Paper variant="outlined" style={{ padding: 15 }}>
                          <Grid
                            container
                            justify="flex-start"
                            direction="row"
                            alignItems="center"
                            spacing={2}
                            // style={{ padding: 20 }}
                          >
                            <Grid item xs={12}>
                              <FormLabel component="legend">
                                Original offer:
                              </FormLabel>
                            </Grid>

                            <Grid item xs={12}>
                              <TextField
                                id="OriginaljobType"
                                label="Job type"
                                variant="outlined"
                                defaultValue={this.state.selectedJobType}
                                // onChange={}
                                type="text"
                                fullWidth
                                size="small"
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Grid>

                            <Grid item xs={12}>
                              <TextField
                                id="OriginaljobTitle"
                                label="Job title"
                                variant="outlined"
                                defaultValue={this.state.jobTitle}
                                // onChange={}
                                type="text"
                                fullWidth
                                size="small"
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Grid>

                            <Grid item xs={12}>
                              <TextField
                                id="OriginalstartingSalary"
                                label="Starting Salary"
                                variant="outlined"
                                defaultValue={this.state.jobSalary}
                                // onChange={}
                                type="number"
                                fullWidth
                                size="small"
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Grid>

                            <Grid item xs={12}>
                              <TextField
                                id="OriginalstartingDate"
                                // label="Starting Salary"
                                variant="outlined"
                                defaultValue={this.state.startDate}
                                // onChange={}
                                type="date"
                                helperText="Starting date"
                                fullWidth
                                size="small"
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Grid>

                            <Grid item xs={12}>
                              <TextField
                                id="OriginaljobDescription"
                                label="Job Description"
                                variant="outlined"
                                defaultValue={this.state.jobDescription}
                                // onChange={}
                                type="date"
                                fullWidth
                                multiline
                                rows={3}
                                size="small"
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Grid>

                            <Grid item xs={12}>
                              <TextField
                                id="OriginalotherConditions"
                                label="Other Conditions"
                                variant="outlined"
                                defaultValue={this.state.otherConditions}
                                // onChange={}
                                type="date"
                                fullWidth
                                multiline
                                rows={3}
                                size="small"
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Paper>
                      </Grid>
                    </Grid>
                  </>
                )
              }
            </Grid>
          </CardContent>
          <CardActions style={{ padding: 15 }}>
            <Grid container justify="flex-end">
              {this.state.modifyButton && this.state.modifyOfferButton ? (
                <>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => this.setState({ newOfferButton: true })}
                    style={{ minWidth: 200 }}
                  >
                    Send New Offer
                  </Button>
                </>
              ) : this.state.acceptButton ? (
                <>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => this.setState({ confirmOfferButton: true })}
                    style={{ minWidth: 200 }}
                  >
                    Confirm Employee Onboard
                  </Button>
                </>
              ) : (
                <div />
              )}
              {this.state.cancelButton ? (
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={() => this.setState({ cancelOfferButton: true })}
                  style={{ minWidth: 200 }}
                >
                  Cancel Offer
                </Button>
              ) : (
                <div />
              )}{" "}
            </Grid>
          </CardActions>
        </Card>
        {this.confirmOfferDialog()}
        {this.cancelOfferDialog()}
        {this.newOfferDialog()}
      </div>
    );
  }

  confirmOfferDialog() {
    return (
      <Dialog
        open={this.state.confirmOfferButton}
        onClose={() => this.setState({ confirmOfferButton: false })}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure do you want to confirm this offer?"}
        </DialogTitle>
        <DialogActions>
          <Button
            onClick={() => this.confirmJobOffer(this.state.employeeId)}
            variant="contained"
            color="primary"
          >
            Yes
          </Button>
          <Button
            onClick={() => this.setState({ confirmOfferButton: false })}
            color="secondary"
            variant="contained"
            autoFocus
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  cancelOfferDialog() {
    return (
      <Dialog
        open={this.state.cancelOfferButton}
        onClose={() => this.setState({ cancelOfferButton: false })}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure do you want to cancel this offer?"}
        </DialogTitle>

        <DialogActions>
          <Button
            onClick={() =>
              this.cancelJobOffer(this.state.viewDetailsData["employee"])
            }
            variant="contained"
            color="primary"
          >
            Yes
          </Button>
          <Button
            onClick={() =>
              this.setState({
                cancelOfferButton: false,
                viewOfferButton: false,
              })
            }
            color="secondary"
            variant="contained"
            autoFocus
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  newOfferDialog() {
    return (
      <Dialog
        open={this.state.newOfferButton}
        onClose={() => this.setState({ newOfferButton: false })}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure do you want to send this new offer?"}
        </DialogTitle>
        <DialogActions>
          <Button
            onClick={() => this.newJobOffer(this.state.employeeId)}
            variant="contained"
            color="primary"
          >
            Yes
          </Button>
          <Button
            onClick={() => this.setState({ newOfferButton: false })}
            color="secondary"
            variant="contained"
            autoFocus
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  async sendJobOffer() {
    let bodyData = {
      employee: this.state.matchedVotId,
      jobCategory: this.state.selectedJobType,
      jobTitle: this.state.jobTitle,
      startSalary: this.state.jobSalary,
      startDate: this.state.startDate,
      jobDescription: this.state.jobDescription,
      conditions: this.state.otherConditions,
    };

    // console.log('Body data:', bodyData)

    try {
      let response = await fetch(api + "/api/v1/employers/newoboffer", {
        method: "POST",
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(bodyData),
      });
      response = await response.json();
      // console.log('jobOfferSuccess:', response);

      this.fetchOnboardOffers();

      this.setState({
        generateNewEmployementCodeDialog: false,
        selectedVotId: "",
        selectedJobType: "",
        jobTitle: "",
        startDate: "",
        jobDescription: "",
        otherConditions: "",
        jobSalary: "",
        generateButton: true,
      });
    } catch (error) {
      // console.log("[!ON_REGISTER] " + error);
    }
  }

  async confirmJobOffer(empId) {
    let bodyData = {};

    try {
      let response = await fetch(
        api + "/api/v1/employers/oboffers/" + empId + "/confirmJoined",
        {
          method: "PUT",
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(""),
        }
      );
      response = await response.json();
      // console.log('confirmJob:', response);

      this.setState({ confirmOfferButton: false });
      this.fetchOnboardOffers();
    } catch (error) {
      // console.log("[!ON_REGISTER] " + error);
    }
  }

  async cancelJobOffer(empId) {
    let bodyData = {};

    try {
      let response = await fetch(
        api + "/api/v1/employers/oboffers/" + this.state.newOfferId + "/cancel",
        {
          method: "PUT",
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(""),
        }
      );
      response = await response.json();
      // console.log('cancelJob:', response);

      this.setState({
        cancelOfferButton: false,
        cancelOfferButton: false,
        viewOfferButton: false,
        modifyOfferButton: false,
      });
      this.fetchOnboardOffers();
    } catch (error) {
      // console.log("[!ON_REGISTER] " + error);
    }
  }

  async newJobOffer() {
    let bodyData = {
      employee: this.state.selectedVotId,
      jobCategory: this.state.categoryId,
      jobTitle: this.state.jobTitle,
      startSalary: this.state.jobSalary,
      startDate: this.state.startDate,
      jobDescription: this.state.jobDescription,
      conditions: this.state.otherConditions,
    };

    // console.log('Body data:', bodyData)

    try {
      let response = await fetch(
        api + "/api/v1/employers/oboffers/" + this.state.newOfferId + "/modify",
        {
          method: "PUT",
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(bodyData),
        }
      );
      response = await response.json();
      // console.log('jobOfferSuccess:', response);

      this.fetchOnboardOffers();

      this.setState({
        selectedVotId: "",
        selectedJobType: "",
        jobTitle: "",
        startDate: "",
        jobDescription: "",
        otherConditions: "",
        jobSalary: "",
        viewOfferButton: false,
        modifyOfferButton: false,
        newOfferButton: false,
      });
    } catch (error) {
      // console.log("[!ON_REGISTER] " + error);
    }
  }

  async joinEmployee(empId) {
    let bodyData = {};

    try {
      let response = await fetch(
        api + "/api/v1/employers/oboffers/" + empId + "/confirmJoined",
        {
          method: "PUT",
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(""),
        }
      );
      response = await response.json();
      // console.log('cancelJob:', response);

      this.setState({ confirmOpen: false });
      this.fetchOnboardOffers();
    } catch (error) {
      // console.log("[!ON_REGISTER] " + error);
    }
  }
}

export default withStyles(styles)(index);
