import React, { PureComponent } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { Button } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Box from "@material-ui/core/Box";
import axios from "axios";
import { CircularProgress } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import {
  Map,
  // InfoWindow,
  Marker,
  GoogleApiWrapper,
} from "google-maps-react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Select } from "@material-ui/core";
import { MenuItem } from "@material-ui/core";
import { InputLabel, Card, Divider, IconButton } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import HistoryIcon from "@material-ui/icons/History";
import EditIcon from "@material-ui/icons/Edit";
import MyLocationIcon from "@material-ui/icons/MyLocation";
import Chip from "@material-ui/core/Chip";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import PublishIcon from "@material-ui/icons/Publish";
import SaveIcon from "@material-ui/icons/Save";
import FormControl from "@material-ui/core/FormControl";

import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
// let result = [];
// let state = [];
// let lga = [];
// let city = [];
let history = [];
let token = "";
let id = "";
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
class Addresses extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      location: {
        latitude: null,
        longtitude: null,
        updatedlatitude: null,
        updatedlongititude: null,
      },
      result: [],
      selectedaddressType: "",
      selectedaddressReason: "",
      defaultaddress: "",
      Streetname: "",
      housenumber: "",
      addDialogOpen: false,
      updateDialogOpen: false,
      stateName: [],
      selectedState: -1,
      selectedLga: -1,
      SelectedCity: null,
      addressimage: "",
      isloading: true,
      lgaStates: [],
      cityStates: [],
      addressReasons: [],
      addressTypes: [],
      startedLivingHere: "",
      addresshint1: "",
      addresshint2: "",
      addresshint3: "",
      historyloading: true,
      historyDialogeOpen: false,
      selectedIndex: -1,
      updatedaddressreason: "",
      updateddefaultaddresses: "",
      updatedstate: "",
      updatedlga: "",
      updatedcity: "",
      updatedstreet: "",
      updatehousenumber: "",
      updatedaddresshint1: "",
      updatedimage: "",
      updatestartedlivinghere: "",
      updatedaddressestype: "",
      updatedlgastates: [],
      updatedcityStates: [],
      buttondisabled: true,
      updateresponse: "",
      addresponse: "",
      addsnackbar: false,
      updatesnackbar: false,
      currentid: "",
      walletdialog: false,
      city_other: "",
      cityothercheck: false,
      updatecityothercheck: false,
      addressCardNumber: 1,
      addressDisplayWindow: true,
      historyWindowOpen: false,
      addWindow: false,

      addressTypeCheck: false,
      addressReasonCheck: false,
      defaultAddressCheck: false,
      houseNumberCheck: false,
      streetCheck: false,
      statesCheck: false,
      LGACheck: false,
      cityCheck: false,
      addressHint1Check: false,
      addressHint2Check: false,
      startedLivingHereCheck: false,
      latitudeCheck: false,
      longitudeCheck: false

    };

    this.onMarkerClick = this.onMarkerClick.bind(this);
  }
  onMarkerClick(props, marker, e) {
    this.setState({
      location: {
        latitude: e.latLng.lat(),
        longtitude: e.latLng.lng(),
      },
    });
    
    // this.setState({location:{latitude:this.state.location.latitude.slice(0,8),longtitude:this.state.location.longtitude.slice(0,8)}})
    let lat = this.state.location.latitude.toString();
    let long = this.state.location.longtitude.toString();

    lat = lat.slice(0, 11);
    long = long.slice(0, 11);
    lat = parseFloat(lat);
    long = parseFloat(long);
    this.setState({
      location: {
        latitude: lat,
        longtitude: long,
      },
    });
    console.log(
      this.state.location.latitude,
      this.state.location.longtitude
    );
  }

  reasonforupdatevalidcheck = (event) => {
    if (event.target.value.length > 0) {
      this.setState({ buttondisabled: false });
    } else if (event.target.value.length > 250) {
      this.setState({ buttondisabled: true });
    } else {
      this.setState({ buttondisabled: true });
    }
  };
  async getaddressdata() {
    await axios
      .get("http://3.22.17.212:9000/api/v1/employees/" + id + "/addresses", {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        // result = res.data;
        this.setState({ result: res.data });
      });
  }
  async getamount() {
    await axios
      .get(
        "http://3.22.17.212:9000/api/v1/resManager/serviceAPI/?serviceName=addressVerification"
      )
      .then((res) => this.setState({ amount: res.data[0].serviceCharge }));
  }
  async pay() {
    let transactionid = Math.floor(
      10000000000000000 + Math.random() * 9000000000000000
    );
    let headers1 = {
      headers: {
        Authorization: token,
        "Content-Type": "multipart/form-data",
      },
    };

    await axios
      .post(
        "http://3.22.17.212:9000/wallet/transaction?type=DEBIT&amount=" +
          this.state.amount +
          "&description=" +
          transactionid,
        "",
        headers1
      )
      .then((response) => {
        if (response.status === 200) {
          this.senddetails(transactionid);
        }
      });
  }
  async senddetails(tid) {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", token);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      "http://3.22.17.212:9000/api/v1/accounts/paymentDetails?service=addressVerification&trxId=" +
        tid +
        "&paymentMethod=CARD&amount=" +
        this.state.amount,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => this.verification())
      .catch((error) => console.log("error", error));
  }
  async verification() {
    let headers = {
      headers: {
        Authorization: token,
      },
    };
    let bodyFormData = {
      verType: "Address",
      objId: this.state.currentid,
    };
    await axios
      .post(
        "http://3.22.17.212:9000/api/v1/codes/evaluation/new-code",
        bodyFormData,
        headers
      )
      .then((res) => {
        window.location.reload(false);
      });
  }

  async componentDidMount() {
    token = localStorage.getItem("Token");
    id = localStorage.getItem("id");
    await this.getaddressdata();

    await axios
      .get("http://3.22.17.212:9000/api/v1/resManager/address/states/", {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        this.setState({ stateName: res.data });
      });

    await axios
      .get("http://3.22.17.212:9000/api/v1/resManager/address/types/", {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        this.setState({ addressTypes: res.data });
      });
    await axios
      .get("http://3.22.17.212:9000/api/v1/resManager/address/reasons/", {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        this.setState({ addressReasons: res.data });
      });

    this.setState({ isloading: false });
  }
  isloading() {
    return (
      <>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          display="flex"
          style={{ minHeight: "0vh" }}
        >
          <CircularProgress />
        </Grid>
      </>
    );
  }
  async getHistory(index) {
    this.setState({
      historyDialogeOpen: true,
    });
    await axios
      .get(
        "http://3.22.17.212:9000/api/v1/employees/" +
          id +
          "/addresses/" +
          index +
          "/history",
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((res) => {
        history = res.data;
        this.setState({ historyloading: false });
      });
  }

  async updatedetails(addressid) {
    this.setState({
      updateDialogOpen: false,
      selectedIndex: -1,
    });
    let headers = {
      headers: {
        Authorization: token,
        "Content-Type": "multipart/form-data",
      },
    };
    let bodyFormData = new FormData();
    bodyFormData.append("employee", id);
    bodyFormData.append("address_reason", this.state.updatedaddressreason);
    bodyFormData.append("default_address", this.state.updateddefaultaddresses);
    bodyFormData.append("state", this.state.updatedstate);
    bodyFormData.append("lga", this.state.updatedlga);
    if (this.state.updatecityothercheck) {
      bodyFormData.append("city_other", this.state.updatecity_other);
      await this.setState({ city: "" });
    }
    bodyFormData.append("city", this.state.updatedcity);
    bodyFormData.append("street_name", this.state.updatedstreet);
    bodyFormData.append("house_number", this.state.updatehousenumber);
    bodyFormData.append("address_hint1", this.state.updatedaddresshint1);
    bodyFormData.append("address_hint2", this.state.updatedaddresshint2);
    bodyFormData.append("address_hint3", this.state.updatedaddresshint3);
    bodyFormData.append(
      "google_coordinate1",

      this.state.location.latitude
    );
    bodyFormData.append(
      "google_coordinate2",

      this.state.location.longtitude
    );
    // bodyFormData.append("address_image", this.state.updatedimage);
    bodyFormData.append("since", this.state.updatestartedlivinghere);
    bodyFormData.append("address_type", this.state.updatedaddressestype);
    bodyFormData.append("update_reason", this.state.updatedreason);

    await axios
      .post(
        "http://3.22.17.212:9000/api/v1/employees/update-address/" + addressid,
        bodyFormData,
        headers
      )
      .then((response) => {
        this.setState({
          updateresponse: response.status,
          updatesnackbar: true,
        });
      });
    await this.getaddressdata();
  }
  addsnackbar() {
    return this.state.addresponse === 200 ? (
      <div>
        <Snackbar
          open={this.state.addsnackbar}
          autoHideDuration={3000}
          onClick={() => this.setState({ addsnackbar: false })}
        >
          <Alert
            onClose={() => {
              this.setState({ addsnackbar: !this.state.addasnackbar });
            }}
            severity="success"
          >
            Address added sucessfully
          </Alert>
        </Snackbar>
      </div>
    ) : (
      <Snackbar
        open={this.state.addsnackbar}
        autoHideDuration={3000}
        onClick={() => {
          this.setState({ addsnackbar: !this.state.addsnackbar });
        }}
      >
        <Alert
          onClose={() => {
            this.setState({ addsnackbar: !this.state.addsnackbar });
          }}
          severity="error"
        >
          Something went wrong please try again
        </Alert>
      </Snackbar>
    );
  }
  updatesnackbar() {
    return this.state.updateresponse === 200 ? (
      <div>
        <Snackbar
          open={this.state.updatesnackbar}
          autoHideDuration={3000}
          onClick={() => this.setState({ updatesnackbar: false })}
        >
          <Alert
            onClose={() => {
              this.setState({ updatesnackbar: !this.state.updatesnackbar });
            }}
            severity="success"
          >
            Address updated sucessfully
          </Alert>
        </Snackbar>
      </div>
    ) : (
      <Snackbar
        open={this.state.updatesnackbar}
        autoHideDuration={3000}
        onClick={() => {
          this.setState({ updatesnackbar: !this.state.updatesnackbar });
        }}
      >
        <Alert
          onClose={() => {
            this.setState({ updatesnackbar: !this.state.updatesnackbar });
          }}
          severity="error"
        >
          Something went wrong please try again
        </Alert>
      </Snackbar>
    );
  }

  getaddress() {
    return (
      <>
        {this.state.result.length === 0 ? (
          this.addaddress()
        ) : (
          <>
            <Grid
              container
              direction="column"
              justify="center"
              spacing={3}
              style={{ marginTop: 10 }}
            >
              {this.state.addressDisplayWindow
                ? this.allAddressDisplayWindow()
                : null}
              {this.state.addDialogOpen ? this.addNewAddressWindow() : null}
              {this.state.updateDialogOpen
                ? this.editAddressWindow(this.state.selectedIndex)
                : null}
              {this.state.historyWindowOpen
                ? this.viewHistoryWindow(this.state.selectedIndex)
                : null}
            </Grid>

            {/* <TableContainer component={Paper} elevation={16} p={0}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow style={{ backgroundColor: "black" }}>
                    {[
                      "Living Since",
                      "Address",
                      "State/LGA/City",
                      "Google Link",
                      "Address Type",
                      "Default Address",
                      "Verifier",
                      "Update",
                      "History",
                      "Verification",
                    ].map((text, index) => (
                      <TableCell
                        key={index}
                        style={{ fontWeight: "bolder" }}
                        align="center"
                      >
                        {text}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.result.map((row, index) => (
                    <TableRow key={row.id}>
                      <TableCell align="center" size="small">
                        {new Date(row.since).toDateString()}
                      </TableCell>
                      <TableCell align="center" size="small" padding="none">
                        {row.house_number},{row.street_name},{row.address_hint1}
                        ,{row.address_hint2} {row.address_hint3}
                      </TableCell>
                      <TableCell align="center" size="small" padding="none">
                        {row.state_name_field}/{row.lga_name_field}/
                        {row.city_name_field}
                      </TableCell>
                      <TableCell align="center" size="small">
                        <a
                          href={`http://www.google.com/maps/place/${row.google_coordinate1}+,+${row.google_coordinate2}`}
                          target="_blank"
                        >
                          Location
                        </a>
                      </TableCell>

                      <TableCell align="center" size="small" padding="none">
                        {row.address_type_name_field}
                      </TableCell>
                      <TableCell align="center" size="small" padding="none">
                        {row.default_address}
                      </TableCell>

                      <TableCell align="center" size="small" padding="none">
                        {row.owner_name_field}
                      </TableCell>
                      <TableCell align="center" size="small" padding="none">
                        <Button
                          disabled={row.status === "Audit In Progress"}
                          size="small"
                          color="primary"
                          variant="outlined"
                          onClick={() => {
                            this.setState({
                              updateDialogOpen: true,
                              selectedIndex: index,
                              updatedaddressreason: this.state.result[index]
                                .address_reason,
                              updateddefaultaddresses: this.state.result[index]
                                .default_address,
                              updatedstate: this.state.result[index].state,
                              updatedlga: this.state.result[index].lga,
                              updatedcity: this.state.result[index].city,
                              updatecity_other: this.state.result[index]
                                .city_name_field,
                              updatedstreet: this.state.result[index]
                                .street_name,
                              updatehousenumber: this.state.result[index]
                                .house_number,
                              updatedaddresshint1: this.state.result[index]
                                .address_hint1,
                              updatedaddresshint2: this.state.result[index]
                                .address_hint2,
                              updatedaddresshint3: this.state.result[index]
                                .address_hint3,

                              updatedimage: this.state.result[index]
                                .address_image,
                              updatestartedlivinghere: this.state.result[index]
                                .since,
                              updatedaddressestype: this.state.result[index]
                                .address_type,
                              // this.state.result[index].city===null?:null
                              // updatedlatitude: this.state.result[index].google_coordinate1,
                              // updatedlongititude:
                              // this.state.result[index].google_coordinate2,
                              location: {
                                latitude: this.state.result[index]
                                  .google_coordinate1,
                                longtitude: this.state.result[index]
                                  .google_coordinate2,
                              },
                              // add the updatedstate elements here after passing the token and adding data
                            });
                            this.lganames(
                              this.state.result[index].state,
                              "update"
                            );
                            this.citynames(
                              this.state.result[index].lga,
                              "update"
                            );

                            if (
                              this.state.result[index].city_other.length > 0
                            ) {
                              this.setState({ updatecityothercheck: true });
                            } else {
                              this.setState({ updatecityothercheck: false });
                            }
                          }}
                        >
                          Update
                        </Button>
                      </TableCell>
                      <TableCell align="center">
                        <Button
                          size="small"
                          variant="outlined"
                          color="secondary"
                          onClick={() => {
                            this.getHistory(row.id);
                          }}
                        >
                          History
                        </Button>
                      </TableCell>
                      {row.showVerifyOnTrac_btn === true ? (
                        <TableCell align="center">
                          <Button
                            size="small"
                            variant="outlined"                           
                            color="default"
                            disabled={!row.showVerifyOnTrac_btn}
                            onClick={() => {
                              this.setState({
                                currentid: row.id,
                                walletdialog: true,
                              });
                              this.getamount();
                            }}
                          >
                            Request for verification
                          </Button>
                        </TableCell>
                      ) : null}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              </TableContainer> */}

            {/* Wallet Funtion here */}
            {/* Update Function here */}
            {/* Add new address dialog */}
            {/* {this.addNewAddressWindow()} */}

            {this.updatesnackbar()}
            {this.addsnackbar()}
          </>
        )}
        {/* Address History */}
      </>
    );
  }

  allAddressDisplayWindow() {
    return (
      <Grid
        item
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
        spacing={3}
        // style={{marginTop:20}}
      >
        <Grid item container xs={12} justify="flex-end">
          <Button
            size="large"
            color="primary"
            variant="contained"
            startIcon={<AddCircleOutlineIcon />}
            onClick={() =>
              this.setState({
                addDialogOpen: !this.state.addDialogOpen,
                addressDisplayWindow: !this.state.addressDisplayWindow,
              })
            }
          >
            Add New Address
            {/* Add New address */}
          </Button>
        </Grid>
        {this.state.result.map((row, index) => (
          <Grid
            item
            container
            direction="row"
            justify="center"
            alignItems="center"
            xs={12}
            md={4}
          >
            <Card>
              <Grid container style={{ padding: 20 }} spacing={2}>
                <Grid
                  item
                  container
                  alignItems="center"
                  justify="space-between"
                  direction="row"
                  style={{ background: "#263238" }}
                >
                  <Grid
                    item
                    container
                    alignItems="center"
                    justify="flex-end"
                    direction="row"
                    spacing={1}
                  >
                    {/* <Grid item>
                      <Typography variant='h4' style={{ color: "white" }}>
                        Address&nbsp;{this.state.addressCardNumber++}
                      </Typography>
                    </Grid>   */}

                    <Grid item>
                      <IconButton
                        aria-label="upload picture"
                        component="span"
                        onClick={() => {
                          this.setState({
                            addressDisplayWindow: !this.state
                              .addressDisplayWindow,
                            historyWindowOpen: !this.state.historyWindowOpen,
                          });
                          this.getHistory(row.id);
                        }}
                      >
                        <HistoryIcon
                          fontSize="large"
                          style={{ color: "white" }}
                        />
                      </IconButton>

                      <IconButton
                        aria-label="upload picture"
                        component="span"
                        onClick={() => {
                          this.setState({
                            addressDisplayWindow: !this.state
                              .addressDisplayWindow,
                            updateDialogOpen: !this.state.updateDialogOpen,
                            selectedIndex: index,
                            updatedaddressreason: this.state.result[index]
                              .address_reason,
                            updateddefaultaddresses: this.state.result[index]
                              .default_address,
                            updatedstate: this.state.result[index].state,
                            updatedlga: this.state.result[index].lga,
                            updatedcity: this.state.result[index].city,
                            updatecity_other: this.state.result[index]
                              .city_name_field,
                            updatedstreet: this.state.result[index].street_name,
                            updatehousenumber: this.state.result[index]
                              .house_number,
                            updatedaddresshint1: this.state.result[index]
                              .address_hint1,
                            updatedaddresshint2: this.state.result[index]
                              .address_hint2,
                            updatedaddresshint3: this.state.result[index]
                              .address_hint3,

                            updatedimage: this.state.result[index]
                              .address_image,
                            updatestartedlivinghere: this.state.result[index]
                              .since,
                            updatedaddressestype: this.state.result[index]
                              .address_type,
                            // this.state.result[index].city===null?:null
                            // updatedlatitude: this.state.result[index].google_coordinate1,
                            // updatedlongititude:
                            // this.state.result[index].google_coordinate2,
                            location: {
                              latitude: this.state.result[index]
                                .google_coordinate1,
                              longtitude: this.state.result[index]
                                .google_coordinate2,
                            },
                            // add the updatedstate elements here after passing the token and adding data
                          });
                          this.lganames(
                            this.state.result[index].state,
                            "update"
                          );
                          this.citynames(
                            this.state.result[index].lga,
                            "update"
                          );

                          if (this.state.result[index].city_other.length > 0) {
                            this.setState({ updatecityothercheck: true });
                          } else {
                            this.setState({ updatecityothercheck: false });
                          }
                        }}
                      >
                        <EditIcon fontSize="large" style={{ color: "white" }} />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <List>
                    <ListItem>
                      <ListItemText
                        primary="Address"
                        secondary={
                          row.house_number +
                          ", " +
                          row.street_name +
                          ", " +
                          row.address_hint1 +
                          ", " +
                          row.address_hint2 +
                          ", " +
                          row.address_hint3
                        }
                      />
                    </ListItem>

                    <Divider />

                    <ListItem>
                      <ListItemText
                        primary="State"
                        secondary={row.state_name_field}
                      />
                    </ListItem>
                    <Divider />

                    <ListItem>
                      <ListItemText
                        primary="Local Government Area"
                        secondary={row.lga_name_field}
                      />
                    </ListItem>
                    <Divider />

                    <ListItem>
                      <ListItemText
                        primary="City"
                        secondary={row.city_name_field}
                      />
                    </ListItem>
                    <Divider />

                    <ListItem>
                      <ListItemText
                        primary="Address Type"
                        secondary={row.address_type_name_field}
                      />
                    </ListItem>
                    <Divider />

                    <ListItem>
                      <ListItemText
                        primary="Living Since"
                        secondary={new Date(row.since).toDateString()}
                      />
                    </ListItem>
                    <Divider />

                    <ListItem>
                      <ListItemText
                        primary="Default Address"
                        secondary={row.default_address}
                      />
                    </ListItem>
                    <Divider />

                    <ListItem>
                      <ListItemText
                        primary="Verifier"
                        secondary={row.owner_name_field}
                      />
                    </ListItem>
                    <Divider />
                  </List>
                </Grid>

                <Grid item container xs={12} justify="center">
                  <Chip
                    icon={<MyLocationIcon />}
                    label={
                      <a
                        href={`http://www.google.com/maps/place/${row.google_coordinate1}+,+${row.google_coordinate2}`}
                        target="_blank"
                        style={{ color: "white" }}
                      >
                        Location
                      </a>
                    }
                    clickable
                    color="primary"
                    // href={<a
                    //   href={`http://www.google.com/maps/place/${row.google_coordinate1}+,+${row.google_coordinate2}`}
                    //   target="_blank"
                    // >
                    //   Location
                    // </a>}
                  />
                </Grid>

                {this.state.walletLogic &&
                this.state.selectedIndex === index ? (
                  <Paper variant="outlined">
                    <Grid
                      item
                      container
                      xs={12}
                      justify="center"
                      alignItems="center"
                      direction="row"
                      spacing={2}
                      style={{ padding: 10 }}
                    >
                      <Grid item container justify="center" xs={12}>
                        <Grid>
                          <Typography variant="subtitle1">
                            You need to pay&nbsp;{this.state.amount}&nbsp;for
                            this service from your wallet, do you want to
                            continue?
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid item container justify="center" xs={12} md={6}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() =>
                            this.setState(
                              {
                                walletLogic: false,
                              },
                              this.pay
                            )
                          }
                        >
                          Yes
                        </Button>
                      </Grid>

                      <Grid item container justify="center" xs={12} md={6}>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() =>
                            this.setState({
                              walletLogic: false,
                            })
                          }
                        >
                          No
                        </Button>
                      </Grid>
                    </Grid>
                  </Paper>
                ) : (
                  <Grid item container xs={12} justify="center">
                    <Button
                      variant="contained"
                      color="primary"
                      size="medium"
                      disabled={!row.showVerifyOnTrac_btn}
                      onClick={() => {
                        this.setState({
                          walletLogic: true,
                          currentid: row.id,
                          selectedIndex: index,
                        });
                        this.getamount();
                      }}
                    >
                      Request for Verification
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Card>
          </Grid>
        ))}
      </Grid>
    );
  }

  addNewAddressWindow() {
    return (
      <Grid container spacing={3}>
        {this.state.result.length === 0 ? <div/> : (
          <Grid item container xs={12} justify="flex-end">
          <Button
            size="large"
            color="primary"
            variant="contained"
            startIcon={<ArrowBackIcon />}
            onClick={() =>
              this.setState({
                addDialogOpen: !this.state.addDialogOpen,
                addressDisplayWindow: !this.state.addressDisplayWindow,
                addWindow: !this.state.addWindow,
                addressCardNumber: 1,
              })
            }
          >
            Back
          </Button>
        </Grid>
        )}
        
        <Card>
          <Grid
            container
            justify="center"
            direction="row"
            alignItems="center"
            spacing={3}
            style={{ padding: 20 }}
          >
            <Grid item xs={12} style={{ background: "#263238" }}>
              <Typography variant="h5" style={{ color: "white" }}>
                Add New Address
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth required error={this.state.addressTypeCheck}>
                <InputLabel id="addressType">Address types</InputLabel>
                <Select
                  required
                  labelId="addressType"
                  id="addresType"
                  onChange={(event) => {
                    this.setState({
                      selectedaddressType: event.target.value,
                    });
                  }}
                >
                  {this.state.addressTypes.map((address) => (
                    <MenuItem
                      id={address.id}
                      value={address.id}
                      key={address.id}
                    >
                      {address.addressType}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth required error={this.state.addressReasonCheck}>
                <InputLabel id="addressReason">Address Reason</InputLabel>
                <Select
                  required
                  labelId="addressReason"
                  id="addresReason"
                  onChange={(event) => {
                    this.setState({
                      selectedaddressReason: event.target.value,
                    });
                  }}
                >
                  {this.state.addressReasons.map((address) => (
                    <MenuItem
                      id={address.id}
                      value={address.id}
                      key={address.id}
                    >
                      {address.addressReason}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth required error={this.state.defaultAddressCheck}>
                <InputLabel id="defaultAddress">Default Address</InputLabel>
                <Select
                  required
                  labelId="defaultAddress"
                  id="defaultAddress"
                  onChange={(event) => {
                    this.setState({ defaultaddress: event.target.value });
                  }}
                >
                  <MenuItem id={1} value="Yes">
                    Yes
                  </MenuItem>
                  <MenuItem id={2} value="No">
                    No
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <TextField
                error={this.state.houseNumberCheck}
                required
                id="houseNumber"
                label="House number"
                onChange={(event) => {
                  this.setState({ housenumber: event.target.value });
                }}
                type="text"
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                error={this.state.streetCheck}
                required
                id="street"
                label="Street"
                onChange={(event) =>
                  this.setState(
                    {
                      Streetname: event.target.value,
                    }
                    // this.reasonforupdatevalidcheck(event)
                  )
                }
                type="text"
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <FormControl required fullWidth error={this.state.statesCheck}>
                <InputLabel id="states">States</InputLabel>
                <Select
                  required
                  labelId="states"
                  id="states"
                  onChange={(event) => {
                    this.setState({
                      selectedState: event.target.value,
                    });

                    this.lganames(event.target.value, "add");
                  }}
                >
                  {this.state.stateName.map((states) => (
                    <MenuItem id={states.id} value={states.id} key={states.id}>
                      {states.stateName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl required fullWidth error={this.state.LGACheck}>
                <InputLabel id="lga">LGA</InputLabel>
                <Select
                  labelId="lga"
                  id="lga"
                  onChange={(e) => {
                    this.setState({ selectedLga: e.target.value });
                    this.citynames(e.target.value, "add");
                  }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>

                  {this.state.lgaStates.map((lgas) => (
                    <MenuItem value={lgas.id}>{lgas.lgaName} </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={6} md={10}>
              <FormControl fullWidth error={this.state.cityCheck}>
                <InputLabel id="city">City</InputLabel>
                <Select
                  disabled={this.state.cityothercheck}
                  labelId="city"
                  id="city"
                  onChange={(event) => {
                    this.setState({
                      selectedCity: event.target.value,
                    });
                  }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>

                  {this.state.cityStates.map((city) => (
                    <MenuItem value={city.id}>{city.cityName}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={6} md={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.cityothercheck}
                    name="Others"
                    onChange={() =>
                      this.setState({
                        cityothercheck: !this.state.cityothercheck,
                      })
                    }
                    color="primary"
                    inputProps={{
                      "aria-label": "secondary checkbox",
                    }}
                  />
                }
                label="Others"
              />
            </Grid>
            {this.state.cityothercheck ? (
              <Grid item fullWidth xs={12}>
                <TextField
                  id="addressHint1"
                  label="Other city"
                  onChange={(event) => {
                    this.setState({ city_other: event.target.value });
                  }}
                  type="text"
                  fullWidth
                />
              </Grid>
            ) : null}
            <Grid item xs={12}>
              <TextField
              error={this.state.addressHint1Check}
                required
                id="addressHint1"
                label="Address hint 1"
                onChange={(event) => {
                  this.setState({ addresshint1: event.target.value });
                }}
                type="text"
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
              error={this.state.addressHint2Check}
                id="addressHint2"
                label="Address hint 2"
                onChange={(event) => {
                  this.setState({ addresshint2: event.target.value });
                }}
                type="text"
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
              error={this.state.addressHint3Check}
                id="addressHint3"
                label="Address hint 3"
                onChange={(event) => {
                  this.setState({ addresshint3: event.target.value });
                }}
                type="text"
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
              error={this.state.startedLivingHereCheck}
                required
                // label="Started living here since"
                id="startedLivingHereSince"
                helperText="Started living here since*"
                onChange={(event) => {
                  this.setState({
                    startedLivingHere: event.target.value,
                  });
                }}
                type="date"
                fullWidth
              />
            </Grid>

            <Grid item xs={8} style={{ position: "relative", height: "500px" }}>
              <Box p={2} width={1 / 2} style={{ minHeight: "10vh" }}>
                <Map
                  google={this.props.google}
                  zoom={6}
                  initialCenter={{
                    lat: 9.0765,
                    lng: 7.3986,
                  }}
                  onClick={this.onMarkerClick}
                  style={{
                    width: "80%",
                    height: "80%",
                  }}
                  fullscreenControl={true}
                >
                  <Marker
                    position={{
                      lat: this.state.location.latitude,
                      lng: this.state.location.longtitude,
                    }}
                  />
                  {/* <InfoWindow onClose={this.onInfoWindowClose}></InfoWindow> */}
                </Map>
              </Box>
            </Grid>

            {this.state.longitudeCheck ? (
              <Grid item xs={8}>
              <Typography variant="subtitle2" style={{color: "#f44336"}}>Please point the marker on the map.</Typography>
            </Grid>
            ) : <div/>}

            <Grid item xs={4} style={{ position: "relative" }}>
              <Button
                color="primary"
                variant="contained"
                size="large"
                onClick={() => {
                  this.postAddress();
                }}
                startIcon={<PublishIcon />}
              >
                Submit Address
              </Button>
            </Grid>

            {/* <Grid container justify="flex-start" direction="row">
              <Grid class="w3-container">
                <p>
                  <label>Google coordinates</label>
                  <input
                    class="w3-input"
                    type="text"
                    defaultValue={this.state.location.latitude}
                  />
                  <input
                    class="w3-input"
                    type="text"
                    defaultValue={this.state.location.longtitude}
                  />
                </p>
              </Grid>
            </Grid> */}
          </Grid>
        </Card>
      </Grid>
    );
  }

  editAddressWindow(index) {
    return (
      <>
        {this.state.selectedIndex === -1 ? (
          <div />
        ) : (
          // <Dialog
          //   // fullWidth={"md"}
          //   maxWidth={"md"}
          //   open={this.state.updateDialogOpen}
          //   onClose={() => this.setState({ updateDialogOpen: false })}
          //   aria-labelledby="responsive-dialog-title"
          // >
          //   <DialogTitle id="addNewAddress" align="center">
          //     Update address
          //     </DialogTitle>
          //   <DialogContent>
          //     <Box display="flex" flexDirection="row" width={1}>
          //       {/* <Box p={2} width={1 / 2} xs={12}> */}
          <Grid container spacing={3}>
            <Grid
              container
              xs={12}
              justify="flex-end"
              style={{ position: "relative" }}
            >
              <Button
                size="large"
                color="primary"
                variant="contained"
                startIcon={<ArrowBackIcon />}
                onClick={() =>
                  this.setState({
                    updateDialogOpen: !this.state.updateDialogOpen,
                    addressDisplayWindow: !this.state.addressDisplayWindow,
                    addressCardNumber: 1,
                  })
                }
              >
                Back
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <Grid
                  container
                  justify="center"
                  direction="row"
                  alignItems="center"
                  spacing={3}
                  style={{ padding: 20 }}
                >
                  <Grid item xs={12} style={{ background: "#263238" }}>
                    <Typography variant="h4" style={{ color: "white" }}>
                      Edit Address
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <InputLabel id="addressType">Address types*</InputLabel>
                    <Select
                      required
                      id="addressType"
                      label="Address type"
                      defaultValue={this.state.updatedaddressestype}
                      onChange={(event) =>
                        this.setState({
                          updatedaddressestype: event.target.value,
                        })
                      }
                      fullWidth
                    >
                      {this.state.addressTypes.map((address) => (
                        <MenuItem
                          id={address.id}
                          value={address.id}
                          key={index}
                        >
                          {address.addressType}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>

                  <Grid item xs={12}>
                    <InputLabel id="addressReason">Address Reason*</InputLabel>
                    <Select
                      required
                      id="addressReason"
                      label="Address reason"
                      defaultValue={this.state.updatedaddressreason}
                      onChange={(event) =>
                        this.setState({
                          updatedaddressreason: event.target.value,
                        })
                      }
                      type="text"
                      fullWidth
                    >
                      {this.state.addressReasons.map((address) => (
                        <MenuItem
                          id={address.id}
                          value={address.id}
                          key={address.id}
                        >
                          {address.addressReason}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>

                  <Grid item xs={12}>
                    <InputLabel id="addressType">Default Address*</InputLabel>
                    <Select
                      required
                      id="defaultAddress"
                      label="Default address"
                      defaultValue={this.state.updateddefaultaddresses}
                      onChange={(event) => {
                        this.setState({
                          updateddefaultaddresses: event.target.value,
                        });
                      }}
                      type="text"
                      fullWidth
                    >
                      <MenuItem id={1} value="Yes">
                        Yes
                      </MenuItem>
                      <MenuItem id={2} value="No">
                        No
                      </MenuItem>
                    </Select>
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      required
                      id="houseNumber"
                      label="House number"
                      onChange={(event) => {
                        this.setState({
                          updatehousenumber: event.target.value,
                        });
                      }}
                      defaultValue={this.state.updatehousenumber}
                      type="text"
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <InputLabel id="state">State</InputLabel>
                    <Select
                      required
                      id="states"
                      label="States"
                      onChange={(event) => {
                        this.setState({
                          updatedstate: event.target.value,
                        });
                        this.lganames(event.target.value, "update");
                      }}
                      defaultValue={this.state.updatedstate}
                      fullWidth
                    >
                      {this.state.stateName.map((states) => (
                        <MenuItem
                          id={states.id}
                          value={states.id}
                          key={states.id}
                        >
                          {states.stateName}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>

                  <Grid item xs={12}>
                    <InputLabel id="Lga">Lga*</InputLabel>
                    <Select
                      required
                      id="lga"
                      label="LGA"
                      onChange={(event) => {
                        this.setState({
                          updatedlga: event.target.value,
                        });
                        this.citynames(event.target.value, "update");
                      }}
                      defaultValue={this.state.updatedlga}
                      fullWidth
                    >
                      {this.state.updatedlgastates.map((lgas) => (
                        <MenuItem value={lgas.id} key={lgas.id}>
                          {lgas.lgaName}{" "}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>

                  <Grid item md={10} xs={6}>
                    <InputLabel id="City"> City</InputLabel>
                    <Select
                      required
                      id="city"
                      label="City"
                      disabled={this.state.updatecityothercheck}
                      onChange={(event) => {
                        this.setState({
                          updatedcity: event.target.value,
                        });
                      }}
                      defaultValue={this.state.updatedcity}
                      type="text"
                      fullWidth
                    >
                      {this.state.updatedcityStates.map((city) => (
                        <MenuItem value={city.id} key={city.id}>
                          {city.cityName}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid item xs={6} md={2}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.updatecityothercheck}
                          name="Others"
                          onChange={() =>
                            this.setState({
                              updatecityothercheck: !this.state
                                .updatecityothercheck,
                            })
                          }
                          color="primary"
                          inputProps={{
                            "aria-label": "secondary checkbox",
                          }}
                        />
                      }
                      label="Others"
                    />
                  </Grid>
                  {this.state.updatecityothercheck ? (
                    <Grid item xs={12}>
                      <TextField
                        id="addressHint1"
                        label="Other city"
                        value={this.state.updatecity_other}
                        onChange={(event) => {
                          this.setState({
                            updatecity_other: event.target.value,
                          });
                        }}
                        type="text"
                        fullWidth
                      />
                    </Grid>
                  ) : null}

                  <Grid item xs={12}>
                    <TextField
                      required
                      id="addressHint1"
                      label="Address hint 1"
                      onChange={(event) => {
                        this.setState({
                          updatedaddresshint1: event.target.value,
                        });
                      }}
                      defaultValue={this.state.updatedaddresshint1}
                      type="text"
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      id="addressHint2"
                      label="Address hint 2"
                      onChange={(event) => {
                        this.setState({
                          updatedaddresshint2: event.target.value,
                        });
                      }}
                      defaultValue={this.state.updatedaddresshint2}
                      type="text"
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      id="addressHint3"
                      label="Address hint 3"
                      onChange={(event) => {
                        this.setState({
                          updatedaddresshint3: event.target.value,
                        });
                      }}
                      defaultValue={this.state.updatedaddresshint3}
                      type="text"
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      required
                      id="startedLivingHereSince"
                      helperText="Started living here since*"
                      onChange={(event) => {
                        this.setState({
                          updatestartedlivinghere: event.target.value,
                        });
                      }}
                      defaultValue={this.state.updatestartedlivinghere}
                      type="date"
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      id="street"
                      label="Street"
                      onChange={(event) =>
                        this.setState({
                          updatedstreet: event.target.value,
                        })
                      }
                      defaultValue={this.state.updatedstreet}
                      type="text"
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      required
                      id="updateReason"
                      label="Reason for updating"
                      helperText="update reason field can not exceed 250 characters"
                      onChange={(event) => {
                        this.setState(
                          {
                            updatedreason: event.target.value,
                          },
                          this.reasonforupdatevalidcheck(event)
                        );
                      }}
                      type="text"
                      fullWidth
                    />
                  </Grid>

                  <Grid
                    item
                    xs={8}
                    style={{ position: "relative", height: "500px" }}
                  >
                    <Map
                      google={this.props.google}
                      zoom={6}
                      onClick={this.onMarkerClick}
                      initialCenter={{
                        lat: this.state.location.latitude,
                        lng: this.state.location.longtitude,
                      }}
                      style={{
                        height: "80%",
                        width: "80%",
                      }}
                      fullscreenControl={true}
                    >
                      <Marker
                        position={{
                          lat: this.state.location.latitude,
                          lng: this.state.location.longtitude,
                        }}
                      />
                    </Map>
                  </Grid>
                  {/* <Grid item xs={12} sm={6}>
                  <Box display={{ xs: "block", sm: "none" }}>
                    <Map
                      google={this.props.google}
                      zoom={6}
                      onClick={this.onMarkerClick}
                      initialCenter={{
                        lat: this.state.location.latitude,
                        lng: this.state.location.longtitude,
                      }}
                      style={{
                        height: "55%",
                        width: "90%",
                        position: "absolute",
                      }}
                      fullscreenControl={true}
                    >
                      <Marker
                        // initial={{
                        //   lat: this.state.updatedlatitude,
                        //   lng: this.state.updatedlongititude,
                        // }}

                        position={{
                          lat: this.state.location.latitude,
                          lng: this.state.location.longtitude,
                        }}
                      />
                     
                    </Map>
                  </Box>
                </Grid> */}
                  <Grid
                    item
                    xs={4}
                    style={{ position: "relative" }}
                    alignItems="flex-start"
                  >
                    <Grid
                      container
                      direction="column"
                      justify="flex-start"
                      alignItems="flex-start"
                    >
                      <Button
                        color="primary"
                        variant="contained"
                        size="large"
                        disabled={this.state.buttondisabled}
                        onClick={() => {
                          this.setState({
                            addressDisplayWindow: !this.state
                              .addressDisplayWindow,
                            editAddressWindow: !this.state.editAddressWindow,
                          });
                          this.updatedetails(
                            this.state.result[this.state.selectedIndex].id
                          );
                        }}
                        startIcon={<SaveIcon />}
                      >
                        Save Address
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
          // </Box>
          // </DialogContent>
          //   <DialogActions>
          //     <Button
          //       color="primary"
          //       variant="contained"
          //       disabled={this.state.buttondisabled}
          //       onClick={() => {
          //         this.updatedetails(
          //           this.state.result[this.state.selectedIndex].id
          //         );
          //       }}
          //     >
          //       Update
          //       </Button>
          //     <Button
          //       color="secondary"
          //       variant="contained"
          //       onClick={() =>
          //         this.setState({
          //           updateDialogOpen: false,
          //           selectedIndex: -1,
          //           updatedreason: "",
          //         })
          //       }
          //     >
          //       Cancel
          //       </Button>
          //   </DialogActions>
          // </Dialog>
        )}
      </>
    );
  }

  viewHistoryWindow(index) {
    return (
      <>
        {this.state.isloading ? (
          this.isloading()
        ) : (
          <Grid container spacing={3}>
            <Grid item container xs={12} justify="flex-end">
              <Button
                size="large"
                color="primary"
                variant="contained"
                startIcon={<ArrowBackIcon />}
                onClick={() =>
                  this.setState({
                    historyWindowOpen: !this.state.historyWindowOpen,
                    addressDisplayWindow: !this.state.addressDisplayWindow,
                  })
                }
              >
                Back
              </Button>
            </Grid>
            <Card>
              <Grid
                container
                justify="center"
                direction="row"
                alignItems="center"
                spacing={3}
                style={{ padding: 20 }}
              >
                <Grid item xs={12} style={{ background: "#263238" }}>
                  <Typography variant="h4" style={{ color: "white" }}>
                    Edit History
                  </Typography>
                </Grid>
                {this.state.historyloading ? (
                  this.isloading()
                ) : (
                  <>
                    {history.map((row, index) => (
                      <>
                        <Grid
                          item
                          container
                          xs={12}
                          justify="space-between"
                          alignItems="center"
                        >
                          <Grid item>
                            <Typography variant="h6">
                              Record Edited on:&nbsp;&nbsp;
                              {new Date(row.created_on).toDateString()}
                            </Typography>
                          </Grid>
                        </Grid>

                        <Grid item xs={12}>
                          <TextField
                            size="small"
                            fullWidth
                            variant="outlined"
                            id="state"
                            label="State"
                            defaultValue={row.state_name_field}
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            size="small"
                            variant="outlined"
                            fullWidth
                            id="lga"
                            label="Local Government Area"
                            defaultValue={row.lga_name_field}
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            size="small"
                            variant="outlined"
                            fullWidth
                            id="city"
                            label="City"
                            defaultValue={row.city_name_field}
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <TextField
                            size="small"
                            variant="outlined"
                            fullWidth
                            id="houseNumber"
                            label="House Number"
                            defaultValue={row.house_number}
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <TextField
                            size="small"
                            variant="outlined"
                            fullWidth
                            id="streetName"
                            label="Street Name"
                            defaultValue={row.street_name}
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <TextField
                            size="small"
                            variant="outlined"
                            fullWidth
                            id="addressHint"
                            label="Address Hint"
                            defaultValue={row.address_hint1}
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <TextField
                            size="small"
                            variant="outlined"
                            fullWidth
                            id="addressReason"
                            label="Address Reason"
                            defaultValue={row.address_reason_name_field}
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <TextField
                            size="small"
                            variant="outlined"
                            fullWidth
                            id="addressType"
                            label="Address Type"
                            defaultValue={row.address_type_name_field}
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <TextField
                            size="small"
                            variant="outlined"
                            fullWidth
                            id="updateReason"
                            label="Update Reason"
                            defaultValue={row.update_reason}
                            InputProps={{
                              readOnly: true,
                            }}
                            multiline
                            rows={3}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <Divider />
                        </Grid>
                      </>
                    ))}
                  </>
                )}
              </Grid>
            </Card>
          </Grid>
        )}
      </>
    );
  }

  async lganames(stateid, val) {
    this.setState({ selectedState: stateid });
    await axios
      .get(
        "http://3.22.17.212:9000/api/v1/resManager/address/lgas/?stateId=" +
          stateid,
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((res) => {
        val === "update"
          ? this.setState({ updatedlgastates: res.data })
          : this.setState({ lgaStates: res.data });
      });
  }
  async citynames(lgaid, val) {
    this.setState({ selectedLga: lgaid });
    // if (this.state.updatecity == null) {
    //   await this.setState({ updatecityothercheck: true });
    // }
    await axios
      .get(
        "http://3.22.17.212:9000/api/v1/resManager/address/cities/?lgaId=" +
          lgaid,
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((res) => {
        val === "update"
          ? this.setState({ updatedcityStates: res.data })
          : this.setState({ cityStates: res.data });
      });
  }

  async postAddress() { 

    if( this.state.selectedaddressType.length !== 0 &&
        this.state.selectedaddressReason.length !== 0 &&
        this.state.defaultaddress.length !== 0 &&
        this.state.housenumber.length !== 0 &&
        this.state.Streetname.length !== 0 &&
        this.state.selectedState !== -1 &&
        this.state.selectedLga !== -1 &&
        this.state.addresshint1.length !== 0 &&
        this.state.startedLivingHere.length !== 0
      ) 

      {

        let headers = {
          headers: {
            Authorization: token,
            "Content-Type": "multipart/form-data",
          },
        };
        let bodyFormData = new FormData();
        bodyFormData.append("employee", id);
        bodyFormData.append("address_reason", this.state.selectedaddressReason);
        bodyFormData.append("default_address", this.state.defaultaddress);
        bodyFormData.append("state", this.state.selectedState);
        bodyFormData.append("lga", this.state.selectedLga);
    
        if (this.state.cityothercheck) {
          bodyFormData.append("city_other", this.state.city_other);
          await this.setState({ selectedCity: "" });
        }
        bodyFormData.append("city", this.state.selectedCity);
        bodyFormData.append("street_name", this.state.Streetname);
        bodyFormData.append("house_number", this.state.housenumber);
        bodyFormData.append("address_hint1", this.state.addresshint1);
        bodyFormData.append("address_hint2", this.state.addresshint2);
        bodyFormData.append("address_hint3", this.state.addresshint3);
        bodyFormData.append(
          "google_coordinate1",
          // "17.406"
          this.state.location.latitude
        );
        bodyFormData.append(
          "google_coordinate2",
          // "78.440"
          this.state.location.longtitude
        );
        // bodyFormData.append("address_image", this.state.addressimage);
        bodyFormData.append("since", this.state.startedLivingHere);
        bodyFormData.append("address_type", this.state.selectedaddressType);
    
        await axios
          .post(
            "http://3.22.17.212:9000/api/v1/employees/post-address",
            bodyFormData,
            headers
          )
          .then((response) => {
            this.setState({ addresponse: response.status, addsnackbar: true,
              addDialogOpen: !this.state.addDialogOpen,
                addressDisplayWindow: !this.state.addressDisplayWindow,
                addWindow: !this.state.addWindow,
            });
          });
        await this.getaddressdata();
      }

      else{
        if (this.state.selectedaddressType.length === 0) {
          this.setState({ addressTypeCheck: true });
        }
        if (this.state.selectedaddressReason.length === 0) {
          this.setState({ addressReasonCheck: true });
        }
        if (this.state.defaultaddress.length === 0) {
          this.setState({ defaultAddressCheck: true });
        }
        if (this.state.housenumber.length === 0) {
          this.setState({ houseNumberCheck: true });
        }
        if (this.state.Streetname.length === 0) {
          this.setState({ streetCheck: true });
        }
        if (this.state.selectedState === -1) {
          this.setState({ statesCheck: true });
        }
        if (this.state.selectedLga === -1) {
          this.setState({  LGACheck: true });
        }
        if (this.state.addresshint1.length === 0) {
          this.setState({ addressHint1Check: true });
        }
        if (this.state.startedLivingHere.length === 0) {
          this.setState({ startedLivingHereCheck: true });
        }
      }
    
  }
  addAddressForm() {
    return (
      <Dialog
        // fullWidth={"md"}
        maxWidth={"md"}
        // open={this.state.addDialogOpen}
        // onClose={() => this.setState({ addDialogOpen: false })}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="addNewAddress" justify="center">
          Add new address
        </DialogTitle>
        <DialogContent>
          <Box display="flex" flexDirection="row" width={1}>
            <Box width={1 / 2}>
              <DialogContentText>
                Enter the details of your address
              </DialogContentText>
              <Grid
                container
                justify="flex-start"
                direction="row"
                alignItems="center"
                spacing={3}
              >
                <Grid item xs={12}>
                  <FormControl fullWidth >
                    <InputLabel id="addressType" required>
                      Address types
                    </InputLabel>
                    <Select
                      labelId="addressType"
                      id="addresType"
                      onChange={(event) => {
                        this.setState({
                          selectedaddressType: event.target.value,
                        });
                      }}
                    >
                      {this.state.addressTypes.map((address) => (
                        <MenuItem id={address.id} value={address.id}>
                          {address.addressType}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="addressReason" required>
                      Address Reason
                    </InputLabel>
                    <Select
                      labelId="addressReason"
                      id="addresReason"
                      onChange={(event) => {
                        this.setState({
                          selectedaddressReason: event.target.value,
                        });
                      }}
                    >
                      {this.state.addressReasons.map((address) => (
                        <MenuItem id={address.id} value={address.id}>
                          {address.addressReason}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="defaultAddress" required>
                      Default address
                    </InputLabel>
                    <Select
                      labelId="defaultAddress"
                      id="defaultAddress"
                      onChange={(event) => {
                        this.setState({ defaultaddress: event.target.value });
                      }}
                    >
                      <MenuItem id={1} value="Yes">
                        Yes
                      </MenuItem>
                      <MenuItem id={2} value="No">
                        No
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item fullWidth xs={12}>
                  <TextField
                    required
                    id="houseNumber"
                    label="House number"
                    onChange={(event) => {
                      this.setState({ housenumber: event.target.value });
                    }}
                    type="text"
                    fullWidth
                  />
                </Grid>
                <Grid item fullWidth xs={12}>
                  <TextField
                    required
                    id="street"
                    label="Street"
                    onChange={(event) =>
                      this.setState(
                        {
                          Streetname: event.target.value,
                        }
                        // this.reasonforupdatevalidcheck(event)
                      )
                    }
                    type="text"
                    fullWidth
                  />
                </Grid>

                {/* <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="default"
                    startIcon={<CloudUploadIcon />}
                  >
                    Choose file
                  </Button>
                </Grid>

                <Grid item fullWidth xs={12}>
                  <TextField
                    id="addressImage"
                    label="Choose Image"
                    onChange={(event) => {
                      this.setState({
                        addressimage: event.target.files[0],
                      });
                    }}
                    type="file"
                    fullWidth
                  />
                </Grid> */}

                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="states" required>
                      States
                    </InputLabel>
                    <Select
                      labelId="states"
                      id="states"
                      value={this.state.selectedState}
                      onChange={(event) => {
                        this.setState({
                          selectedState: event.target.value,
                        });

                        this.lganames(event.target.value, "add");
                      }}
                    >
                      {this.state.stateName.map((states) => (
                        <MenuItem id={states.id} value={states.id}>
                          {states.stateName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="lga" required>
                      LGA
                    </InputLabel>
                    <Select
                      labelId="lga"
                      id="lga"
                      value={this.state.selectedLga}
                      onChange={(e) => {
                        this.setState({ selectedLga: e.target.value });
                        this.citynames(e.target.value, "add");
                      }}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>

                      {this.state.lgaStates.map((lgas) => (
                        <MenuItem value={lgas.id}>{lgas.lgaName} </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={8}>
                  <FormControl fullWidth>
                    <InputLabel id="city">City</InputLabel>
                    <Select
                      disabled={this.state.cityothercheck}
                      labelId="city"
                      id="city"
                      onChange={(event) => {
                        this.setState({
                          selectedCity: event.target.value,
                        });
                      }}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>

                      {this.state.cityStates.map((city) => (
                        <MenuItem value={city.id}>{city.cityName}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={2}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.cityothercheck}
                        name="Others"
                        onChange={() =>
                          this.setState({
                            cityothercheck: !this.state.cityothercheck,
                          })
                        }
                        color="primary"
                        inputProps={{
                          "aria-label": "secondary checkbox",
                        }}
                      />
                    }
                    label="Others"
                  />
                </Grid>
                {this.state.cityothercheck ? (
                  <Grid item fullWidth xs={12}>
                    <TextField
                      id="addressHint1"
                      label="Other city"
                      onChange={(event) => {
                        this.setState({ city_other: event.target.value });
                      }}
                      type="text"
                      fullWidth
                    />
                  </Grid>
                ) : null}

                <Grid item fullWidth xs={12}>
                  <TextField
                    required
                    id="addressHint1"
                    label="Address hint 1"
                    onChange={(event) => {
                      this.setState({ addresshint1: event.target.value });
                    }}
                    type="text"
                    fullWidth
                  />
                </Grid>

                <Grid item fullWidth xs={12}>
                  <TextField
                    id="addressHint2"
                    label="Address hint 2"
                    onChange={(event) => {
                      this.setState({ addresshint2: event.target.value });
                    }}
                    type="text"
                    fullWidth
                  />
                </Grid>

                <Grid item fullWidth xs={12}>
                  <TextField
                    id="addressHint3"
                    label="Address hint 3"
                    onChange={(event) => {
                      this.setState({ addresshint3: event.target.value });
                    }}
                    type="text"
                    fullWidth
                  />
                </Grid>

                <Grid item fullWidth xs={12}>
                  <TextField
                    id="startedLivingHereSince"
                    helperText="Started living here since*"
                    onChange={(event) => {
                      this.setState({
                        startedLivingHere: event.target.value,
                      });
                    }}
                    type="date"
                    fullWidth
                  />
                </Grid>

                {/* <Grid container justify="flex-start" direction="row">
                  <Grid class="w3-container">
                    <p>
                      <label>Google coordinates</label>
                      <input
                        class="w3-input"
                        type="text"
                        defaultValue={this.state.location.latitude}
                      />
                      <input
                        class="w3-input"
                        type="text"
                        defaultValue={this.state.location.longtitude}
                      />
                    </p>
                  </Grid>
                </Grid> */}
              </Grid>
            </Box>
            <Box p={1} width={1 / 2}>
              <Map
                initialCenter={{
                  lat: 9.0765,
                  lng: 7.3986,
                }}
                google={this.props.google}
                zoom={12}
                onClick={this.onMarkerClick}
                style={{
                  width: "40%",
                  height: "75%",
                }}
                fullscreenControl={true}
              >
                <Marker
                  position={{
                    lat: this.state.location.latitude,
                    lng: this.state.location.longtitude,
                  }}
                />
              </Map>
            </Box>
          </Box>
        </DialogContent>
        <Box p={1}>
          <DialogActions>
            {/* <Button
              color="primary"
              variant="contained"
              onClick={() => {
                this.setState({
                  addDialogOpen: false,
                });
                this.postAddress();
              }}
            >
              Submit Address
            </Button> */}
            <Button
              color="secondary"
              variant="contained"
              onClick={() =>
                this.setState({
                  addDialogOpen: false,
                })
              }
            >
              Cancel
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    );
  }
  addaddress() {
    return (
      <>
        {this.state.addWindow ? (
          this.addNewAddressWindow()
        ) : (
          <Grid container spacing={3} justify="space-between">
            <Grid item xs={12}>
              <Paper style={{ padding: 20 }} elevation={3}>
                <Box
                  p={1}
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  style={{ height: "50vh" }}
                >
                  <Typography variant="h4" gutterBottom align="center">
                    Add address to improve ratings.
                  </Typography>

                  <Grid container justify="center" style={{ marginTop: 50 }}>
                    <Button
                      size="large"
                      startIcon={<AddCircleOutlineIcon />}
                      color="primary"
                      variant="contained"
                      onClick={() =>
                        this.setState({ addWindow: !this.state.addWindow })
                      }
                    >
                      Add New address
                    </Button>
                  </Grid>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        )}

        {this.state.addDialogOpen ? this.addAddressForm() : null}
      </>
    );
  }

  render() {
    return <>{this.state.isloading ? this.isloading() : this.getaddress()} </>;
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyCNFjFmnGwCekQz-GMUXupRUAEjSkqNmi8",
})(Addresses);
