import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  TextField,
  Paper,
  Grid,
  Typography,
  Button,
  TableContainer,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Divider,
  IconButton
} from "@material-ui/core/";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { CircularProgress } from "@material-ui/core";
// import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from "@material-ui/lab/Alert";

import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';


let result = [];
const styles = (theme) => ({});
let token = "";
let id = "";
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
//   const { classes } = this.props;
export class index extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,

      profileDialogBox: "",
      addressDialogBox: "",
      identityDialogBox: "",
      academicsDialogBox: "",
      phoneDialogBox: "",

      academicVerificationAmount: "",
      profileVerificationAmount: "",
      phoneVerificationAmount: "",
      identityVerificationAmount: "",
      addressVerificationAmount: "",

      newProfileVerificationAmount: "",
      newAdddressVerificationAmount: "",
      newIdentityVerificationAmount: "",
      newAcademicsVerificationAmount: "",
      newPhoneVerificationAmount: "",
    };
  }
  isloading() {
    return (
      <>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          display="flex"
          style={{ minHeight: "0vh" }}
        >
          <CircularProgress />
        </Grid>
      </>
    );
  }

  async getAcademicsAmount() {
    let response = await fetch(
      "http://3.22.17.212:9000/api/v1/resManager/serviceAPI/?serviceName=AcademicVerification",
      {
        headers: {
          Authorization: token,
        },
      }
    );
    response = await response.json();
    // console.log('academicVerificationSuccess:', response)
    this.setState({ academicVerificationAmount: response[0].serviceCharge });
    // console.log('academicVerificationAmount:', this.state.academicVerificationAmount)
  }
  async getAddressAmount() {
    let response = await fetch(
      "http://3.22.17.212:9000/api/v1/resManager/serviceAPI/?serviceName=addressVerification",
      {
        headers: {
          Authorization: token,
        },
      }
    );
    response = await response.json();
    // console.log('addressVerificationSuccess:', response)
    this.setState({ addressVerificationAmount: response[0].serviceCharge });
    // console.log('addressVerificationAmount:', this.state.addressVerificationAmount)
  }
  async getIdentityAmount() {
    let response = await fetch(
      "http://3.22.17.212:9000/api/v1/resManager/serviceAPI/?serviceName=identityverification",
      {
        headers: {
          Authorization: token,
        },
      }
    );
    response = await response.json();
    // console.log('identityVerificationSuccess:', response)
    this.setState({ identityVerificationAmount: response[0].serviceCharge });
    // console.log('identityVerificationAmount:', this.state.identityVerificationAmount)
  }
  async getPhoneAmount() {
    let response = await fetch(
      "http://3.22.17.212:9000/api/v1/resManager/serviceAPI/?serviceName=phoneVerification",
      {
        headers: {
          Authorization: token,
        },
      }
    );
    response = await response.json();
    // console.log('phoneVerificationSuccess:', response)
    this.setState({ phoneVerificationAmount: response[0].serviceCharge });
    // console.log('phoneVerificationAmount:', this.state.phoneVerificationAmount)
  }
  async getProfileAmount() {
    let response = await fetch(
      "http://3.22.17.212:9000/api/v1/resManager/serviceAPI/?serviceName=ProfileVerification",
      {
        headers: {
          Authorization: token,
        },
      }
    );
    response = await response.json();
    // console.log('profileVerificationSuccess:', response)
    this.setState({ profileVerificationAmount: response[0].serviceCharge });
    // console.log('profileVerificationAmount:', this.state.profileVerificationAmount)
  }

  async componentDidMount() {
    this.setState({ loading: true });

    token = localStorage.getItem("Token");
    id = localStorage.getItem("id");

    // await this.getstates();
    await this.getAcademicsAmount();
    await this.getAddressAmount();
    await this.getProfileAmount();
    await this.getPhoneAmount();
    await this.getIdentityAmount();
    this.setState({ loading: false });
  }

  async postProfileAmount() {
    this.setState({ profileDialogBox: false });
    let bodyData = {
      serviceName: "ProfileVerification",
      serviceCharge: this.state.newProfileVerificationAmount,
    };

    // console.log('Body data:', bodyData)

    try {
      let response = await fetch(
        "http://3.22.17.212:9000/modifyServiceCharges",
        {
          method: "POST",
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(bodyData),
        }
      );
      response = await response.json();
      // console.log('postProfileAmountSuccess:', response);

      await this.getProfileAmount();
    } catch (error) {
      // console.log("[!ON_REGISTER] " + error);
    }
  }

  async postAddressAmount() {
    this.setState({ addressDialogBox: false });
    let bodyData = {
      serviceName: "addressVerification",
      serviceCharge: this.state.newAddressVerificationAmount,
    };

    // console.log('Body data:', bodyData)

    try {
      let response = await fetch(
        "http://3.22.17.212:9000/modifyServiceCharges",
        {
          method: "POST",
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(bodyData),
        }
      );
      response = await response.json();
      // console.log('postAddressAmountSuccess:', response);

      await this.getAddressAmount();
    } catch (error) {
      // console.log("[!ON_REGISTER] " + error);
    }
  }

  async postIdentityAmount() {
    this.setState({ identityDialogBox: false });
    let bodyData = {
      serviceName: "identityverification",
      serviceCharge: this.state.newIdentityVerificationAmount,
    };

    // console.log('Body data:', bodyData)

    try {
      let response = await fetch(
        "http://3.22.17.212:9000/modifyServiceCharges",
        {
          method: "POST",
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(bodyData),
        }
      );
      response = await response.json();
      // console.log('postIdentityAmountSuccess:', response);

      await this.getIdentityAmount();
    } catch (error) {
      // console.log("[!ON_REGISTER] " + error);
    }
  }

  async postAcademicsAmount() {
    let bodyData = {
      serviceName: "AcademicVerification",
      serviceCharge: this.state.newAcademicsVerificationAmount,
    };

    this.setState({ academicsDialogBox: false });
    // console.log('Body data:', bodyData)

    try {
      let response = await fetch(
        "http://3.22.17.212:9000/modifyServiceCharges",
        {
          method: "POST",
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(bodyData),
        }
      );
      response = await response.json();
      // console.log('postAcademicsAmountSuccess:', response);

      await this.getAcademicsAmount();
    } catch (error) {
      // console.log("[!ON_REGISTER] " + error);
    }
  }

  async postPhoneAmount() {
    this.setState({ phoneDialogBox: false });
    let bodyData = {
      serviceName: "phoneVerification",
      serviceCharge: this.state.newPhoneVerificationAmount,
    };

    // console.log('Body data:', bodyData)

    try {
      let response = await fetch(
        "http://3.22.17.212:9000/modifyServiceCharges",
        {
          method: "POST",
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(bodyData),
        }
      );
      response = await response.json();
      // console.log('postPhoneAmountSuccess:', response);

      await this.getPhoneAmount();
    } catch (error) {
      // console.log("[!ON_REGISTER] " + error);
    }
  }

  displaytable() {
    return (
      <>
        <Grid container justify="space-between" alignItems="center" spacing={4}>
          <Grid item>
            <Typography variant="h4">Verification Services</Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>

        <Grid
          container
          justify="flex-start"
          alignItems="center"
          style={{ marginTop: 20 }}
          spacing={2}
        >
          <Grid item xs={12}>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead style={{ background: "#031a26" }}>
                  <TableRow>
                    <TableCell>
                      <Typography variant="body1" style={{ fontWeight: "bold", color: "white" }}>
                        Service
                    </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography variant="body1" style={{ fontWeight: "bold", color: "white" }}>
                        Amount
                    </Typography>
                    </TableCell>
                    <TableCell align="left"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.revenuedata === "" ? null : (
                    <>
                      <TableRow>
                        <TableCell>Profile Verification</TableCell>
                        <TableCell align="left">
                          {this.state.profileDialogBox ?
                            (
                              <TextField
                                size='small'
                                id="outlined-basic"
                                label=" Enter Amount"
                                variant="outlined"
                                onChange={(event) => {
                                  this.setState({
                                    newProfileVerificationAmount: event.target.value,
                                  });
                                }}
                              />
                            ) : this.state.profileVerificationAmount}

                        </TableCell>
                        <TableCell align="right">
                          {this.state.profileDialogBox ?
                            (
                              <Grid container justify='flex-end' direction='row'>
                                <Grid item>
                                  <IconButton
                                    color="primary"
                                    onClick={() => {
                                      this.setState({ profileDialogBox: !this.state.profileDialogBox })
                                      this.postProfileAmount();
                                    }}
                                  >
                                    <CheckIcon />
                                  </IconButton>
                                </Grid>

                                <Grid item>
                                  <IconButton
                                    color="secondary"
                                    onClick={() => {
                                      this.setState({ profileDialogBox: !this.state.profileDialogBox });
                                    }}
                                  >
                                    <CloseIcon />
                                  </IconButton>
                                </Grid>
                              </Grid>
                            ) : (
                              <IconButton
                              color="primary"
                              onClick={() => {
                                this.setState({ profileDialogBox: !this.state.profileDialogBox });
                              }}
                            >
                              <EditIcon />
                            </IconButton>
                            )}

                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>Address Verification</TableCell>
                        <TableCell align="left">
                          {this.state.addressDialogBox ?
                            (
                              <TextField
                                size='small'
                                id="outlined-basic"
                                label=" Enter Amount"
                                variant="outlined"
                                onChange={(event) => {
                                  this.setState({
                                    newAddressVerificationAmount: event.target.value,
                                  });
                                }}
                              />
                            ) : this.state.addressVerificationAmount}

                        </TableCell>
                        <TableCell align="right">
                          {this.state.addressDialogBox ?
                            (
                              <Grid container justify='flex-end' direction='row'>
                                <Grid item>
                                  <IconButton
                                    color="primary"
                                    onClick={() => {
                                      this.setState({ addressDialogBoxt: !this.state.addressDialogBox })
                                      this.postAddressAmount();
                                    }}
                                  >
                                    <CheckIcon />
                                  </IconButton>
                                </Grid>

                                <Grid item>
                                  <IconButton
                                    color="secondary"
                                    onClick={() => {
                                      this.setState({ addressDialogBox: !this.state.addressDialogBox });
                                    }}
                                  >
                                    <CloseIcon />
                                  </IconButton>
                                </Grid>
                              </Grid>
                            ) : (
                              <IconButton
                              color="primary"
                              onClick={() => {
                                this.setState({ addressDialogBox: !this.state.addressDialogBox });
                              }}
                            >
                              <EditIcon />
                            </IconButton>
                            )}

                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>Identity Verification</TableCell>
                        <TableCell align="left">
                          {this.state.identityDialogBox ?
                            (
                              <TextField
                                size='small'
                                id="outlined-basic"
                                label=" Enter Amount"
                                variant="outlined"
                                onChange={(event) => {
                                  this.setState({
                                    newIdentityVerificationAmount: event.target.value,
                                  });
                                }}
                              />
                            ) : this.state.identityVerificationAmount}

                        </TableCell>
                        <TableCell align="right">
                          {this.state.identityDialogBox ?
                            (
                              <Grid container justify='flex-end' direction='row'>
                                <Grid item>
                                  <IconButton
                                    color="primary"
                                    onClick={() => {
                                      this.setState({ identityDialogBox: !this.state.identityDialogBox })
                                      this.postIdentityAmount();
                                    }}
                                  >
                                    <CheckIcon />
                                  </IconButton>
                                </Grid>

                                <Grid item>
                                  <IconButton
                                    color="secondary"
                                    onClick={() => {
                                      this.setState({ identityDialogBox: !this.state.identityDialogBox });
                                    }}
                                  >
                                    <CloseIcon />
                                  </IconButton>
                                </Grid>
                              </Grid>
                            ) : (
                              <IconButton
                              color="primary"
                              onClick={() => {
                                this.setState({ identityDialogBox: !this.state.identityDialogBox });
                              }}
                            >
                              <EditIcon />
                            </IconButton>
                            )}

                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>Academics Verification</TableCell>
                        <TableCell align="left">
                          {this.state.academicsDialogBox ?
                            (
                              <TextField
                                size='small'
                                id="outlined-basic"
                                label=" Enter Amount"
                                variant="outlined"
                                onChange={(event) => {
                                  this.setState({
                                    newAcademicsVerificationAmount: event.target.value,
                                  });
                                }}
                              />
                            ) : this.state.academicVerificationAmount}

                        </TableCell>
                        <TableCell align="right">
                          {this.state.academicsDialogBox ?
                            (
                              <Grid container justify='flex-end' direction='row'>
                                <Grid item>
                                  <IconButton
                                    color="primary"
                                    onClick={() => {
                                      this.setState({ academicsDialogBox: !this.state.academicsDialogBox })
                                      this.postAcademicsAmount();
                                    }}
                                  >
                                    <CheckIcon />
                                  </IconButton>
                                </Grid>

                                <Grid item>
                                  <IconButton
                                    color="secondary"
                                    onClick={() => {
                                      this.setState({ academicsDialogBox: !this.state.academicsDialogBox })
                                    }}
                                  >
                                    <CloseIcon />
                                  </IconButton>
                                </Grid>
                              </Grid>
                            ) : (
                              <IconButton
                                color="primary"
                                onClick={() => {
                                  this.setState({ academicsDialogBox: !this.state.academicsDialogBox })
                                }}
                              >
                                <EditIcon />
                              </IconButton>
                            )}

                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>Phone Verification</TableCell>
                        <TableCell align="left">
                          {this.state.phoneDialogBox ?
                            (
                              <TextField
                                size='small'
                                id="outlined-basic"
                                label=" Enter Amount"
                                variant="outlined"
                                onChange={(event) => {
                                  this.setState({
                                    newPhoneVerificationAmount: event.target.value,
                                  });
                                }}
                              />
                            ) : this.state.phoneVerificationAmount}

                        </TableCell>
                        <TableCell align="right">
                          {this.state.phoneDialogBox ?
                            (
                              <Grid container justify='flex-end' direction='row'>
                                <Grid item>
                                  <IconButton
                                    color="primary"
                                    onClick={() => {
                                      this.setState({ phoneDialogBox: !this.state.phoneDialogBox })
                                      this.postPhoneAmount();
                                    }}
                                  >
                                    <CheckIcon />
                                  </IconButton>
                                </Grid>

                                <Grid item>
                                  <IconButton
                                    color="secondary"
                                    onClick={() => {
                                      this.setState({ phoneDialogBox: !this.state.phoneDialogBox });
                                    }}
                                  >
                                    <CloseIcon />
                                  </IconButton>
                                </Grid>
                              </Grid>
                            ) : (
                              <IconButton
                                color="primary"
                                onClick={() => {
                                  this.setState({ phoneDialogBox: !this.state.phoneDialogBox });
                                }}
                              >
                                <EditIcon />
                              </IconButton>
                            )}
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>

          </Grid>

          <Dialog
            // open={this.state.profileDialogBox}
            // onClose={() => this.setState({ profileDialogBox: false })}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="alert-dialog-title">
              {"Service Amount"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Please enter the amount that you want to set.
              </DialogContentText>

              <TextField
                fullWidth
                id="outlined-basic"
                label="Amount"
                variant="outlined"
                onChange={(event) => {
                  this.setState({
                    newProfileVerificationAmount: event.target.value,
                  });
                }}
              />
            </DialogContent>
            <DialogActions style={{ padding: 15 }}>
              <Button
                style={{ width: 85 }}
                color="primary"
                variant="contained"
                onClick={() => {
                  this.postProfileAmount();
                }}
              >
                Set
              </Button>
              <Button
                color="secondary"
                variant="contained"
                onClick={() => this.setState({ profileDialogBox: false })}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            // open={this.state.addressDialogBox}
            // onClose={() => this.setState({ addressDialogBox: false })}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="alert-dialog-title">
              {"Service Amount"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Please enter the amount that you want to set.
              </DialogContentText>

              <TextField
                fullWidth
                id="outlined-basic"
                label="Amount"
                variant="outlined"
                onChange={(event) => {
                  this.setState({
                    newAddressVerificationAmount: event.target.value,
                  });
                }}
              />
            </DialogContent>
            <DialogActions style={{ padding: 15 }}>
              <Button
                style={{ width: 85 }}
                color="primary"
                variant="contained"
                onClick={() => this.postAddressAmount()}
              >
                Set
              </Button>
              <Button
                color="secondary"
                variant="contained"
                onClick={() =>
                  this.setState({ addressDialogBox: false, selectedIndex: -1 })
                }
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            // open={this.state.identityDialogBox}
            // onClose={() => this.setState({ identityDialogBox: false })}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="alert-dialog-title">
              {"Service Amount"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Please enter the amount that you want to set.
              </DialogContentText>

              <TextField
                fullWidth
                id="outlined-basic"
                label="Amount"
                variant="outlined"
                onChange={(event) => {
                  this.setState({
                    newIdentityVerificationAmount: event.target.value,
                  });
                }}
              />
            </DialogContent>
            <DialogActions style={{ padding: 15 }}>
              <Button
                style={{ width: 85 }}
                color="primary"
                variant="contained"
                onClick={() => this.postIdentityAmount()}
              >
                Set
              </Button>
              <Button
                color="secondary"
                variant="contained"
                onClick={() => this.setState({ identityDialogBox: false })}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            // open={this.state.academicsDialogBox}
            // onClose={() => this.setState({ academicsDialogBox: !this.state.academicsDialogBox })}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="alert-dialog-title">
              {"Service Amount"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Please enter the amount that you want to set.
              </DialogContentText>

              <TextField
                fullWidth
                id="outlined-basic"
                label="Amount"
                variant="outlined"
                onChange={(event) => {
                  this.setState({
                    newAcademicsVerificationAmount: event.target.value,
                  });
                }}
              />
            </DialogContent>
            <DialogActions style={{ padding: 15 }}>
              <Button
                style={{ width: 85 }}
                color="primary"
                variant="contained"
                onClick={() => this.postAcademicsAmount()}
              >
                Set
              </Button>
              <Button
                color="secondary"
                variant="contained"
                onClick={() =>
                  this.setState({
                    academicsDialogBox: false,
                    selectedIndex: -1,
                  })
                }
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            // open={this.state.phoneDialogBox}
            // onClose={() => this.setState({ phoneDialogBox: false })}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="alert-dialog-title">
              {"Service Amount"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Please enter the amount that you want to set.
              </DialogContentText>

              <TextField
                fullWidth
                id="outlined-basic"
                label="Amount"
                variant="outlined"
                onChange={(event) => {
                  this.setState({
                    newPhoneVerificationAmount: event.target.value,
                  });
                }}
              />
            </DialogContent>
            <DialogActions style={{ padding: 15 }}>
              <Button
                style={{ width: 85 }}
                color="primary"
                variant="contained"
                onClick={() => this.postPhoneAmount()}
              >
                Set
              </Button>
              <Button
                color="secondary"
                variant="contained"
                onClick={() =>
                  this.setState({ phoneDialogBox: false, selectedIndex: -1 })
                }
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
      </>
    );
  }
  render() {
    return (
      <div style={{ marginTop: 20 }}>
        {/* <Paper style={{ padding: 20, height: '100vh' }}> */}
        {this.state.loading ? this.isloading() : this.displaytable()}

        {/* </Paper> */}
      </div>
    );
  }
}

export default withStyles(styles)(index);
