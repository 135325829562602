import React, { Component } from "react";
import axios from "axios";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {
  Typography,
  Button,
  Paper,
  Avatar,
  Grid,
  TextField,
  Card,
  Box,
  Snackbar,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  CardContent,
  CardActions,
  Divider,
} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import MuiAlert from "@material-ui/lab/Alert";
// import Autocomplete from "@material-ui/lab/Autocomplete";
import TablePagination from "@material-ui/core/TablePagination";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
// import ThumbUpIcon from "@material-ui/icons/ThumbUp";
// import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import WorkOutlineIcon from "@material-ui/icons/WorkOutline";
import PinDropIcon from "@material-ui/icons/PinDrop";
import MaterialTable from "material-table";
import Search from "@material-ui/icons/Search";
import { forwardRef } from "react";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import SearchBar from "material-ui-search-bar";
import ViewColumn from "@material-ui/icons/ViewColumn";
import ValidationMessage from "../../ValidationMessage";
import GradientButton from "../../GradientButton";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import NotInterestedOutlinedIcon from "@material-ui/icons/NotInterestedOutlined";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import AccountBalanceWalletOutlinedIcon from "@material-ui/icons/AccountBalanceWalletOutlined";
import Tooltip from "@material-ui/core/Tooltip";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import CreateIcon from "@material-ui/icons/Create";
import FormControlLabel from "@material-ui/core/FormControlLabel";

let token = "";
let categoriesdata = {};
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
export default class employerlist extends Component {
  constructor(props) {
    super(props);

    this.state = {
      employerlist: [],
      page: 0,
      rowsPerPage: 10,
      count: 0,
      nextpagelink: "",
      previouspagelink: "",
      kpidata: "",
      updateDialogOpen: false,
      result: [],
      value: "",
      companyName: "",
      firstname: "",
      firstnamevalid: false,
      middlename: "",
      surname: "",
      username: "",
      usernameValid: false,
      email: "",
      emailValid: false,
      password: "",
      passwordValid: false,
      passwordConfirm: "",
      passwordConfirmValid: false,
      errorMsg: {},
      formValid: "disabled",
      submitDisabled: "disabled",
      Dob: "",
      gender: "",
      dobValue: false,
      genderValue: false,
      companyvalid: false,
      capthavalid: false,
      phone: "",
      rcnum: "",
      staffctn: "",
      captha: "",
      signup: false,
      signupemail: false,
      signupusername: false,
      categoriesdata: [],
      options: "",
      amount: "",
      currentid: "",
      unregisteredaccount: [],
      cname: "",
      adminreg: [],
      adminregistered: false,
    };
    this.addemployer = this.addemployer.bind(this);
  }
  transaction = () => {
    this.setState({ walletdialog: false });
    var myHeaders = new Headers();
    myHeaders.append("Authorization", token);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      "http://3.22.17.212:9000/walletAdminTrx?ontracId=" +
        this.state.currentid +
        "&type=" +
        this.state.options +
        "&amount=" +
        this.state.amount +
        "&description=AdminTransaction",
      requestOptions
    )
      .then((response) =>
        response.status === 200 ? this.getbalance(this.state.currentid) : null
      )
      // .then((result) => console.log(result))
      .catch((error) => console.log("error", error));
  };

  validatefirstname = (firstname1) => {
    // console.log(firstname1.length);
    firstname1 = firstname1.charAt(0).toUpperCase() + firstname1.slice(1);

    // console.log("firstname", firstname1);
    this.setState({ firstname: firstname1 });
    let firstnameValid = true;
    if (firstname1.length === 0) {
      firstnameValid = false;
    }
    // console.log("/////////////", firstnameValid);
    this.setState({ firstnamevalid: firstnameValid }, this.validateForm);
  };
  Capitalizemiddlename = (middlename1) => {
    middlename1 = middlename1.charAt(0).toUpperCase() + middlename1.slice(1);

    console.log("middlename", middlename1);
    this.setState({ middlename: middlename1 });
  };
  capitalizelastname = (lastname1) => {
    lastname1 = lastname1.charAt(0).toUpperCase() + lastname1.slice(1);

    // console.log("lastname1", lastname1);
    this.setState({ surname: lastname1 });
  };
  companyvalue = (event) => {
    if (event.target.value.length > 0) {
      this.setState(
        { companyvalid: true },
        this.validateForm
        // console.log("////////////", this.state.companyvalid)
      );
    } else {
      this.setState({ companyvalid: false }, this.validateForm);
    }
  };
  validateUsername = (event) => {
    // const { username } = this.state;
    // console.log("username", event);
    let usernameValid = true;
    let errorMsg = { ...this.state.errorMsg };

    if (event.length < 5) {
      usernameValid = false;
      errorMsg.username = "Must be at least 5 characters long";
    }

    this.setState({ usernameValid, errorMsg }, this.validateForm);
  };

  updateEmail = (email) => {
    this.setState({ email }, this.validateEmail);
  };

  validateEmail = () => {
    const { email } = this.state;
    let emailValid = true;
    let errorMsg = { ...this.state.errorMsg };

    // checks for format _@_._
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      emailValid = false;
      errorMsg.email = "Invalid email format";
    }

    this.setState({ emailValid, errorMsg }, this.validateForm);
  };

  updatePassword = (password) => {
    this.setState({ password }, this.validatePassword);
  };

  validatePassword = () => {
    const { password } = this.state;
    let passwordValid = true;
    let errorMsg = { ...this.state.errorMsg };

    // must be 6 chars
    // must contain a number
    // must contain a special character

    if (password.length < 6) {
      passwordValid = false;
      errorMsg.password = "Password must be at least 6 characters long";
    } else if (!/\d/.test(password)) {
      passwordValid = false;
      errorMsg.password = "Password must contain a digit";
    } else if (!/[!@#$%^&*]/.test(password)) {
      passwordValid = false;
      errorMsg.password = "Password must contain special character: !@#$%^&*";
    }

    this.setState({ passwordValid, errorMsg }, this.validateForm);
  };

  updatePasswordConfirm = (passwordConfirm) => {
    this.setState({ passwordConfirm }, this.validatePasswordConfirm);
  };
  genderValidation = (data) => {
    if (data.target.value.length > 0) {
      this.setState({ genderValue: true }, this.validateForm);
    }
  };
  dobeval = (data) => {
    if (data.target.value.length > 0) {
      this.setState({ dobValue: true }, this.validateForm);
    }
  };

  validatePasswordConfirm = () => {
    const { passwordConfirm, password } = this.state;
    let passwordConfirmValid = true;
    let errorMsg = { ...this.state.errorMsg };

    if (password !== passwordConfirm) {
      passwordConfirmValid = false;
      errorMsg.passwordConfirm = "Passwords do not match";
    }

    this.setState({ passwordConfirmValid, errorMsg }, this.validateForm);
  };
  handleChange = (value) => {
    // console.log("Captcha value:", value);
    this.setState({ captha: value, capthavalid: true }, this.validateForm);

    if (value === null) this.setState({ expired: "true" });
  };
  async getemployerlist() {
    await axios
      .get("http://3.22.17.212:9000/getEmployerList?page=1&filter=all", {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        this.setState({
          employerlist: res.data.results,
          count: res.data.count,
          nextpagelink: res.data.next,
          previouspagelink: res.data.previous,
        });
        res.data.results.map((item) => {
          if (item.is_admin_added) {
            //  console.log("item///////////////////////",item)
            this.setState({ adminreg: [...this.state.adminreg, item] });
          }
          //  this.setState({ unregisteredaccount: unregisteraccounts });
        });
      });
  }
  getbalance = async (id) => {
    await axios
      .get("http://3.22.17.212:9000/wallet/getBalance?ontracId=" + id, {
        headers: {
          Authorization: token,
        },
      })
      .then((response) => {
        this.setState({ balance: response.data[0].balance });
      });
    let today = new Date();
    let dd = today.getDate();

    let mm = today.getMonth() + 1;
    let yyyy = today.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }

    if (mm < 10) {
      mm = "0" + mm;
    }
    await axios
      .get(
        "http://3.22.17.212:9000/wallet/accTransactions?sdate=2020-01-01&edate=" +
          yyyy +
          "-" +
          mm +
          "-" +
          dd +
          "&ontracId=" +
          id,
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => {
        this.setState({ transactionlist: response.data });
      });
  };
  vdate = async (date) => {
    // let bodyFormData = new FormData();
    // bodyFormData.append("NewValidateDate",date);
    await axios
      .post(
        "http://3.22.17.212:9000/modifyValidityDate?ontracId=" +
          this.state.currentid +
          "&date=" +
          date,
        // bodyFormData,
        "",

        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => {
        // console.log(response);
      });
  };
  async updaterow(newData) {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", token);

    var formdata = new FormData();
    formdata.append("phone", newData.phone);
    formdata.append("email", newData.email);
    formdata.append("fax", newData.fax);
    formdata.append("logo", newData.logo);
    formdata.append("category", newData.category);
    formdata.append("regNum", newData.regNum);
    formdata.append("regDate", newData.regDate);

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(
      "http://3.22.17.212:9000/updateComapnyDetailsAdmin?ontracid=" +
        newData.ontrac_id_field,
      requestOptions
    )
      .then((response) => this.getemployerlist())
      // .then((result) => console.log("result", result))
      .catch((error) => console.log("error", error));
  }
  async fetchkpidata(companyName, value) {
    await axios
      .get(
        "http://3.22.17.212:9000/getEmployerKpi?company=" +
          companyName +
          "&filter=" +
          value,
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((res) => {
        this.setState({
          kpidata: res.data,
        });
      });
  }
  async nextpage() {
    await axios
      .get(this.state.nextpagelink, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        this.setState({
          employerlist: res.data.results,
          count: res.data.count,
          nextpagelink: res.data.next,
          previouspagelink: res.data.previous,
        });
        res.data.results.map((item) => {
          if (item.is_admin_added) {
            //  console.log("item///////////////////////",item)
            this.setState({ adminreg: [...this.state.adminreg, item] });
          }
        });
      });
  }
  async previouspage() {
    await axios
      .get(this.state.previouspagelink, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        this.setState({
          employerlist: res.data.results,
          count: res.data.count,
          nextpagelink: res.data.next,
          previouspagelink: res.data.previous,
        });
        res.data.results.map((item) => {
          if (item.is_admin_added) {
            // console.log("item///////////////////////", item);
            this.setState({ adminreg: [...this.state.adminreg, item] });
          }
        });
      });
  }
  async searchcompany(companyName) {
    await axios
      .get("http://3.22.17.212:9000/getEmployerList?filter=" + companyName, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        this.setState({
          employerlist: res.data.results,
          count: res.data.count,
          nextpagelink: res.data.next,
          previouspagelink: res.data.previous,
        });
      });
  }
  async componentDidMount() {
    token = localStorage.getItem("Token");
    await this.categories();
    await this.getemployerlist();
  }
  categories = async () => {
    await axios
      .get("http://3.22.17.212:9000/api/v1/resManager/employer/categories/", {
        headers: {
          Authorization: token,
        },
      })
      .then((response) => {
        response.data.map((index) => {
          // console.log("index", index);
          // let newdata={"question":index.id,"answerRating":index.question}
          categoriesdata[index.id] = index.category;
          this.setState({ categoriesdata: categoriesdata });
          this.setState((state) => ({
            person: {
              ...state.stateObj,
              attr1: "value1",
              attr2: "value2",
            },
          }));
        });
      });
  };

  nextpageclick = (event, newPage) => {
    // console.log("typeof", typeof newPage);
    if (newPage > this.state.page) {
      // console.log("nextpage");
      this.nextpage();
    } else {
      // console.log("prevpage");
      this.previouspage();
    }
    this.setState({
      page: newPage,
    });
  };
  Employeractive = async (ontracid) => {
    this.setState({ activeopen: false });
    await axios
      .post(
        "http://3.22.17.212:9000/changeAccountStatus?ontracId=" +
          ontracid +
          "&status=ACTIVATE&reason=" +
          this.state.reason +
          "&remarks=" +
          this.state.remarks,
        "",
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((res) => {
        this.getemployerlist();
      });
  };
  Employerdeactive = async (ontracid) => {
    this.setState({ deactiveopen: false });
    await axios
      .post(
        "http://3.22.17.212:9000/changeAccountStatus?ontracId=" +
          ontracid +
          "&status=DEACTIVATE&reason=" +
          this.state.reason +
          "&remarks=" +
          this.state.remarks,
        "",
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((res) => {
        this.getemployerlist();
      });
  };
  render() {
    return (
      <div>
        <>
          <Box py={1}>
            <Grid
              container
              justify="space-between"
              alignItems="center"
              spacing={2}
            >
              <Grid item xs={12}>
                <Grid container justify="space-between">
                  <Grid item xs={8}>
                    <Typography variant="h4">Employer List</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <SearchBar
                      value={this.state.value}
                      onChange={(newValue) =>
                        this.setState({ value: newValue })
                      }
                      onRequestSearch={() =>
                        this.searchcompany(this.state.value)
                      }
                      cancelOnEscape={true}
                      onCancelSearch={() => this.getemployerlist()}
                      placeholder={"enter your company name and press Enter"}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    this.setState({ addemployerdialog: true });
                  }}
                >
                  Add employer
                </Button>
                &nbsp;&nbsp;&nbsp; &nbsp;
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.adminregistered}
                      onClick={() =>
                        this.setState({
                          adminregistered: !this.state.adminregistered,
                        })
                      }
                      name="checkedB"
                      color="primary"
                    />
                  }
                  label="Admin Added"
                />
                {/* <Checkbox
                  color="primary"
                  label=""
                  inputProps={{ "aria-label": "secondary checkbox" }}
                /> */}
              </Grid>
            </Grid>
          </Box>
          {this.state.employerlist === "" ? null : this.state
              .updateDialogOpen ? (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container justify="flex-end">
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() =>
                      this.setState({
                        updateDialogOpen: false,
                      })
                    }
                  >
                    Back
                  </Button>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Card>
                  <DialogTitle id="form-dialog-title" align="center">
                    Company data
                  </DialogTitle>
                  <CardContent>
                    {/* Enter the details of your profile to be updated */}
                    <ButtonGroup
                      variant="contained"
                      color="primary"
                      aria-label="contained primary button group"
                    >
                      <Button
                        onClick={() => {
                          this.fetchkpidata(this.state.cname, "today");
                        }}
                      >
                        Today
                      </Button>
                      <Button
                        onClick={() => {
                          this.fetchkpidata(this.state.cname, "week");
                        }}
                      >
                        This Week
                      </Button>
                      <Button
                        onClick={() => {
                          this.fetchkpidata(this.state.cname, "month");
                        }}
                      >
                        This Month
                      </Button>
                      <Button
                        onClick={() => {
                          this.fetchkpidata(this.state.cname, "year");
                        }}
                      >
                        This Financial Year
                      </Button>
                    </ButtonGroup>

                    <Box p={1}>
                      <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="flex-start"
                        spacing={3}
                      >
                        <Grid item xs={6}>
                          <Card
                            elevation={6}
                            style={{
                              minHeight: 175,
                              padding: 10,
                              background: "#651fff",
                            }}
                          >
                            <Grid
                              item
                              xs
                              container
                              direction="row"
                              justify="center"
                              alignItems="center"
                              spacing={2}
                            >
                              <Grid item xs>
                                <Typography
                                  variant="h5"
                                  component="h5"
                                  style={{ color: "white" }}
                                >
                                  Locations
                                </Typography>
                              </Grid>
                              <Grid item xs>
                                <PinDropIcon style={{ color: "white" }} />
                              </Grid>
                              <Grid item xs={6}>
                                <Typography
                                  variant="h2"
                                  component="h2"
                                  style={{
                                    fontWeight: "bold",
                                    color: "white",
                                  }}
                                >
                                  {this.state.kpidata.locationCnt}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Card>
                        </Grid>

                        <Grid item xs={6}>
                          <Card
                            elevation={6}
                            style={{
                              minHeight: 175,
                              padding: 10,
                              background: "#00b0ff",
                            }}
                          >
                            <Grid
                              item
                              xs
                              container
                              direction="row"
                              justify="center"
                              alignItems="center"
                              spacing={2}
                            >
                              <Grid item xs>
                                <Typography
                                  variant="h5"
                                  component="h5"
                                  style={{ color: "white" }}
                                >
                                  Employees
                                </Typography>
                              </Grid>
                              <Grid item xs>
                                <SupervisorAccountIcon
                                  style={{ color: "white" }}
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <Typography
                                  variant="h2"
                                  component="h2"
                                  style={{
                                    fontWeight: "bold",
                                    color: "white",
                                  }}
                                >
                                  {this.state.kpidata.employeeCnt}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Card>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box p={1}>
                      <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="flex-start"
                        spacing={3}
                      >
                        <Grid item xs={6}>
                          <Card
                            elevation={6}
                            style={{
                              minHeight: 175,
                              padding: 10,
                              background: "#1de9b6",
                            }}
                          >
                            <Grid
                              item
                              xs
                              container
                              direction="row"
                              justify="center"
                              alignItems="center"
                              spacing={2}
                              style={{ marginBottom: 10 }}
                            >
                              <Grid item xs>
                                <Typography
                                  variant="h5"
                                  component="h5"
                                  style={{ color: "white" }}
                                >
                                  Access
                                </Typography>
                              </Grid>
                              <Grid item xs>
                                <VpnKeyIcon style={{ color: "white" }} />
                              </Grid>
                              <Grid item xs={6}>
                                <Typography
                                  variant="h2"
                                  component="h2"
                                  style={{
                                    fontWeight: "bold",
                                    color: "white",
                                  }}
                                >
                                  {this.state.kpidata.pendingAccessCodeCnt}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Card>
                        </Grid>

                        <Grid item xs={6}>
                          <Card
                            elevation={6}
                            style={{
                              minHeight: 175,
                              padding: 10,
                              background: "#ff9800",
                            }}
                          >
                            <Grid
                              item
                              xs
                              container
                              direction="row"
                              justify="center"
                              alignItems="center"
                              spacing={2}
                            >
                              <Grid item xs>
                                <Typography
                                  variant="h6"
                                  component="h6"
                                  style={{ color: "white" }}
                                >
                                  Employement
                                </Typography>
                              </Grid>
                              <Grid item xs>
                                <WorkOutlineIcon style={{ color: "white" }} />
                              </Grid>
                              <Grid item xs={6}>
                                <Typography
                                  variant="h2"
                                  component="h2"
                                  style={{
                                    fontWeight: "bold",
                                    color: "white",
                                  }}
                                >
                                  {this.state.kpidata.pendingemploymentCodeCnt}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Card>
                        </Grid>
                      </Grid>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          ) : this.state.walletdialog ? (
            <>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid container justify="flex-end">
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        this.setState({
                          walletdialog: false,
                        });
                      }}
                    >
                      Back
                    </Button>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Card>
                    <DialogTitle id="form-dialog-title" align="center">
                      Credit/Debit money to this wallet
                    </DialogTitle>
                    <CardContent>
                      {/* Enter the details of your profile to be updated */}
                      <Grid
                        container
                        direction="row"
                        justify="flex-end"
                        alignItems="center"
                      >
                        <Typography variant="subtitle1" gutterBottom>
                          Current Balance:{this.state.balance}
                        </Typography>
                      </Grid>
                    </CardContent>
                    <Box px={5} m={1}>
                      <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        spacing={3}
                      >
                        <Grid item fullWidth xs={6}>
                          <FormControl fullWidth>
                            <InputLabel htmlFor="age-native-simple">
                              Choose your action
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={this.state.options}
                              onChange={(e) =>
                                this.setState({ options: e.target.value })
                              }
                            >
                              <MenuItem value={"CREDIT"}>Credit</MenuItem>
                              <MenuItem value={"DEBIT"}>Debit</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item fullWidth xs={6}>
                          <FormControl fullWidth>
                            <TextField
                              id="standard-basic"
                              label="Add amount"
                              onChange={(e) => {
                                this.setState({ amount: e.target.value });
                              }}
                            ></TextField>
                          </FormControl>
                        </Grid>
                        <Grid item fullWidth xs={8} align="center">
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => {
                              this.transaction();
                            }}
                          >
                            Credit/Debit
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box p={2}>
                      <TableContainer component={Paper} elevation={16} p={3}>
                        <Table stickyHeader>
                          <TableHead>
                            <TableRow style={{ backgroundColor: "black" }}>
                              {[
                                "Transaction Date",
                                "Transaction Type",
                                "Transaction Amount",
                                "Balance before transaction",
                                "Balance After transaction",
                                "Currency",
                                "Description",
                              ].map((text, index) => (
                                <TableCell
                                  style={{ fontWeight: "bolder" }}
                                  align="center"
                                >
                                  {text}
                                </TableCell>
                              ))}
                            </TableRow>
                          </TableHead>

                          <TableBody>
                            {this.state.transactionlist
                              ? this.state.transactionlist.map((row, index) =>
                                  index < 10 ? (
                                    <TableRow key={row.id}>
                                      <TableCell
                                        align="center"
                                        style={{ textTransform: "capitalize" }}
                                      >
                                        {new Date(row.trxDate).toDateString()}
                                      </TableCell>

                                      <TableCell align="center">
                                        {row.trxType}
                                      </TableCell>
                                      <TableCell align="center">
                                        {row.trxAmount}
                                      </TableCell>
                                      <TableCell align="center">
                                        {row.balanceBeforeTrx}
                                      </TableCell>
                                      <TableCell align="center">
                                        {row.balanceAfterTrx}
                                      </TableCell>
                                      <TableCell align="center">
                                        {row.trxAmount_currency}
                                      </TableCell>

                                      <TableCell align="center">
                                        {row.trxdescription}{" "}
                                      </TableCell>
                                    </TableRow>
                                  ) : null
                                )
                              : null}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                  </Card>
                </Grid>
              </Grid>
            </>
          ) : this.state.changevdatedialog ? (
            <>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid container justify="flex-end">
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        this.setState({
                          changevdatedialog: false,
                        });
                      }}
                    >
                      Back{" "}
                    </Button>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Card open={this.state.changevdatedialog}>
                    <DialogTitle id="form-dialog-title" align="center">
                      Modify Validity Date
                    </DialogTitle>
                    <CardContent>
                      <Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="center"
                      >
                        <Grid item fullWidth xs={8}>
                          <TextField
                            id="dob"
                            defaultValue={this.state.vdate}
                            onChange={(event) => {
                              this.setState({ vdate: event.target.value });
                            }}
                            type="date"
                            fullWidth
                          />
                          <InputLabel htmlFor="dob" align="left">
                            Validity Date
                          </InputLabel>
                        </Grid>
                      </Grid>
                      {/* </DialogContentText> */}
                    </CardContent>
                    <CardActions>
                      <Grid container justify="flex-end">
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => {
                            this.vdate(this.state.vdate);
                            this.setState({
                              changevdatedialog: false,
                            });
                          }}
                        >
                          Change date
                        </Button>
                      </Grid>
                    </CardActions>
                  </Card>
                </Grid>
              </Grid>
            </>
          ) : this.state.activeopen ? (
            <>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid container justify="flex-end">
                    <Button
                      color="secondary"
                      variant="contained"
                      onClick={() => this.setState({ activeopen: false })}
                    >
                      Back
                    </Button>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Card>
                    <DialogTitle id="form-dialog-title" align="center">
                      Activate user
                    </DialogTitle>
                    <CardContent>
                      <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        spacing={3}
                      >
                        <Grid item fullWidth xs={8}>
                          <FormControl fullWidth>
                            <InputLabel htmlFor="age-native-simple">
                              Choose your reason
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={this.state.reason}
                              onChange={(e) =>
                                this.setState({
                                  reason: e.target.value,
                                })
                              }
                            >
                              <MenuItem value={"Non Payment"}>
                                Non Payment
                              </MenuItem>
                              <MenuItem value={"Invalid Information"}>
                                Invalid Information
                              </MenuItem>
                              <MenuItem value={"User Request"}>
                                User Request
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item fullWidth xs={8}>
                          <TextField
                            required
                            id="standard-required"
                            label="remarks"
                            onChange={(e) =>
                              this.setState({ remarks: e.target.value })
                            }
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                    <CardActions>
                      <Grid container justify="flex-end">
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={() =>
                            this.Employeractive(this.state.currentid)
                          }
                        >
                          Activate user
                        </Button>
                      </Grid>
                    </CardActions>
                  </Card>
                </Grid>
              </Grid>
            </>
          ) : this.state.deactiveopen ? (
            <>
              <Grid container justify="flex-end">
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={() => this.setState({ deactiveopen: false })}
                >
                  Back
                </Button>
              </Grid>
              <Card>
                <DialogTitle id="form-dialog-title" align="center">
                  Deactivate user
                </DialogTitle>
                <CardContent>
                  <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    spacing={3}
                  >
                    <Grid item fullWidth xs={8}>
                      <FormControl fullWidth>
                        <InputLabel htmlFor="age-native-simple">
                          Choose your reason
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={this.state.reason}
                          onChange={(e) =>
                            this.setState({
                              reason: e.target.value,
                            })
                          }
                        >
                          <MenuItem value={"Non Payment"}>Non Payment</MenuItem>
                          <MenuItem value={"Invalid Information"}>
                            Invalid Information
                          </MenuItem>
                          <MenuItem value={"User Request"}>
                            User Request
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item fullWidth xs={8}>
                      <TextField
                        required
                        id="standard-required"
                        label="remarks"
                        onChange={(e) =>
                          this.setState({ remarks: e.target.value })
                        }
                      />
                    </Grid>
                  </Grid>
                </CardContent>
                <CardActions>
                  <Grid container justify="flex-end">
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() =>
                        this.Employerdeactive(this.state.currentid)
                      }
                    >
                      Deactivate user
                    </Button>
                  </Grid>
                </CardActions>
              </Card>
            </>
          ) : this.state.addemployerdialog ? (
            <Card>
              <DialogTitle id="form-dialog-title" align="center">
                Add new Employer
              </DialogTitle>
              <CardContent>
                <Grid
                  container
                  component="main"
                  direction="row"
                  justify="center"
                >
                  <Grid
                    container
                    xs={false}
                    sm={12}
                    md={12}
                    square
                    direction="row"
                    justify="center"
                  >
                    <Grid item sm={12} md={12} lg={12}>
                      <Card
                        style={{
                          padding: 50,
                          marginLeft: 40,
                          marginRight: 40,
                        }}
                        raised="true"
                      >
                        <form noValidate>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                style={{ marginRight: 10 }}
                                // margin="dense"
                                id="companyName"
                                label="Company Name"
                                value={this.state.companyName}
                                onChange={(event) => {
                                  this.setState({
                                    companyName: event.target.value,
                                  });
                                  this.companyvalue(event);
                                }}
                                type="text"
                                autoComplete="companyName"
                                autoFocus
                                fullWidth
                                size="medium"
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                style={{ marginRight: 10 }}
                                // margin="dense"
                                id="companyName"
                                label="Registration Number"
                                value={this.state.rcnum}
                                onChange={(event) => {
                                  this.setState({
                                    rcnum: event.target.value,
                                  });
                                  // this.companyvalue(event);
                                }}
                                type="text"
                                autoComplete="companyName"
                                autoFocus
                                fullWidth
                                size="medium"
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                style={{ marginRight: 10 }}
                                // margin="dense"
                                id="companyName"
                                label="Phone"
                                value={this.state.phone}
                                onChange={(event) => {
                                  this.setState({
                                    phone: event.target.value,
                                  });
                                  // this.companyvalue(event);
                                }}
                                type="text"
                                autoComplete="companyName"
                                autoFocus
                                fullWidth
                                size="medium"
                              />
                            </Grid>

                            <Grid item xs={12}>
                              <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                style={{ marginRight: 10 }}
                                // margin="dense"
                                id="firstname"
                                label="First Name"
                                value={this.state.firstname}
                                onChange={(event) => {
                                  this.validatefirstname(event.target.value);
                                }}
                                type="text"
                                autoComplete="firstname"
                                autoFocus
                                fullWidth
                              />
                            </Grid>

                            <Grid item xs={12}>
                              <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                style={{ marginRight: 10 }}
                                id="middlename"
                                label="Middle Name"
                                value={this.state.middlename}
                                onChange={(event) =>
                                  this.Capitalizemiddlename(event.target.value)
                                }
                                type="text"
                                autoComplete="middlename"
                                autoFocus
                                fullWidth
                                size="medium"
                              />
                            </Grid>

                            <Grid item xs={12}>
                              <TextField
                                variant="outlined"
                                required
                                style={{ marginRight: 10 }}
                                margin="dense"
                                id="surname"
                                label="Surname"
                                value={this.state.surname}
                                onChange={(event) =>
                                  this.capitalizelastname(event.target.value)
                                }
                                type="text"
                                autoComplete="surname"
                                autoFocus
                                fullWidth
                                size="medium"
                              />
                            </Grid>

                            <Grid item xs={12}>
                              <ValidationMessage
                                valid={this.state.usernameValid}
                                message={this.state.errorMsg.username}
                              />
                              <TextField
                                variant="outlined"
                                required
                                style={{ marginRight: 10 }}
                                margin="dense"
                                id="username"
                                label="Username"
                                value={this.state.username}
                                onChange={(event) =>
                                  this.setState(
                                    {
                                      username: event.target.value,
                                    },
                                    this.validateUsername(event.target.value)
                                  )
                                }
                                type="text"
                                autoComplete="username"
                                autoFocus
                                fullWidth
                                size="medium"
                              />
                            </Grid>

                            <Grid item xs={12}>
                              <ValidationMessage
                                valid={this.state.emailValid}
                                message={this.state.errorMsg.email}
                              />
                              <TextField
                                variant="outlined"
                                required
                                style={{ marginRight: 10 }}
                                margin="dense"
                                id="email"
                                label="Email Address"
                                value={this.state.email}
                                onChange={(event) =>
                                  this.setState(
                                    {
                                      email: event.target.value,
                                    },
                                    this.validateEmail
                                  )
                                }
                                name="email"
                                autoComplete="email"
                                autoFocus
                                fullWidth
                                size="small"
                              />
                            </Grid>

                            <>
                              <Grid item fullWidth xs={12}>
                                <FormControl
                                  variant="outlined"
                                  fullWidth
                                  size="small"
                                >
                                  <InputLabel htmlFor="gender">
                                    Estimated count
                                  </InputLabel>
                                  <Select
                                    label=" Estimated count"
                                    margin="dense"
                                    // value={age}
                                    onChange={(event) => {
                                      this.setState(
                                        {
                                          staffctn: event.target.value,
                                        }
                                        // this.genderValidation(event)
                                      );
                                    }}
                                  >
                                    <MenuItem value={"1-5"}>1-5</MenuItem>
                                    <MenuItem value={"6-10"}>6-10</MenuItem>
                                    <MenuItem value={"11-15"}>11-15</MenuItem>
                                    <MenuItem value={"16-50"}>16-50</MenuItem>
                                    <MenuItem value={"Above 50"}>
                                      Above 50
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                              </Grid>

                              {/* <Grid item fullWidth xs={12}>
                                <TextField
                                  id="dob"
                                  size="small"
                                  variant="outlined"
                                  label="Date of birth"
                                  format={false}
                                  margin="dense"
                                  InputLabelProps={{
                                    shrink: true,
                                    required: true,
                                  }}
                                  // defaultValue={result[this.state.selectedIndex].dob}
                                  onChange={(event) => {
                                    this.setState(
                                      {
                                        Dob: event.target.value,
                                      },
                                      this.dobeval(event)
                                    );
                                  }}
                                  type="date"
                                  fullWidth
                                />
                              </Grid> */}
                            </>

                            <Grid item xs={12}>
                              <ValidationMessage
                                valid={this.state.passwordValid}
                                message={this.state.errorMsg.password}
                              />
                              <TextField
                                variant="outlined"
                                required
                                style={{ marginRight: 10 }}
                                margin="dense"
                                name="password"
                                label="Password"
                                value={this.state.password}
                                onChange={(event) =>
                                  this.setState(
                                    {
                                      password: event.target.value,
                                    },
                                    this.validatePassword
                                  )
                                }
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                fullWidth
                                size="small"
                              />
                            </Grid>

                            <Grid item xs={12}>
                              <ValidationMessage
                                valid={this.state.passwordConfirmValid}
                                message={this.state.errorMsg.passwordConfirm}
                              />
                              <TextField
                                variant="outlined"
                                required
                                style={{ marginRight: 10 }}
                                margin="dense"
                                name="confirmPassword"
                                label="Confirm Password"
                                value={this.state.passwordConfirm}
                                onChange={(event) =>
                                  this.setState(
                                    {
                                      passwordConfirm: event.target.value,
                                    },
                                    this.validatePasswordConfirm
                                  )
                                }
                                type="password"
                                id="confirmPassword"
                                autoComplete="current-password"
                                fullWidth
                                size="small"
                              />
                            </Grid>
                          </Grid>
                          <Snackbar
                            open={this.state.signup}
                            autoHideDuration={3000}
                            onClick={() => this.setState({ signup: false })}
                          >
                            <Alert
                              onClose={() => {
                                this.setState({
                                  signup: !this.state.signup,
                                });
                              }}
                              severity="error"
                            >
                              This username and email already exists
                            </Alert>
                          </Snackbar>
                          <Snackbar
                            open={this.state.signupusername}
                            autoHideDuration={3000}
                            onClick={() =>
                              this.setState({
                                signupusername: false,
                              })
                            }
                          >
                            <Alert
                              onClose={() => {
                                this.setState({
                                  signupusername: !this.state.signupusername,
                                });
                              }}
                              severity="error"
                            >
                              This username already exists
                            </Alert>
                          </Snackbar>
                          <Snackbar
                            open={this.state.signupemail}
                            autoHideDuration={3000}
                            onClick={() =>
                              this.setState({
                                signupemail: false,
                              })
                            }
                          >
                            <Alert
                              onClose={() => {
                                this.setState({
                                  signupemail: !this.state.signupemail,
                                });
                              }}
                              severity="error"
                            >
                              This email already exists
                            </Alert>
                          </Snackbar>

                          <Grid container spacing={1}>
                            <Grid item xs={12}>
                              <GradientButton
                                onClick={this.addemployer}
                                title={"Sign Up"}
                                // disabled={this.state.submitDisabled}
                                center
                                style={{
                                  marginTop: 16,
                                  marginBottom: 16,
                                  fontFamily: "Montserrat",
                                  fontWeight: "bold",
                                }}
                                fullWidth
                              />
                            </Grid>
                          </Grid>
                        </form>
                      </Card>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
              <CardActions>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() =>
                    this.setState({
                      addemployerdialog: false,
                    })
                  }
                >
                  Back
                </Button>
              </CardActions>
            </Card>
          ) : (
            <>
              <MaterialTable
                icons={tableIcons}
                title="Employer List"
                columns={[
                  {
                    title: "Company Name",
                    field: "companyName",
                    cellStyle: { padding: "3px" },
                  },

                  {
                    field: "logo",
                    title: "Avatar",
                    cellStyle: { padding: "3px" },

                    editComponent: (props) => (
                      <>
                        <TextField
                          type="file"
                          value={props.logo}
                          onChange={(e) => {
                            var data = { ...props.rowData };
                            data.logo = e.target.files[0];

                            props.onRowDataChange(data);
                          }}
                        ></TextField>
                      </>
                    ),

                    render: (rowData) => <Avatar src={rowData.logo} />,
                  },

                  {
                    title: "Phone",
                    field: "phone",
                    cellStyle: { padding: "3px" },
                    // type: "numeric",
                  },
                  // {
                  //   title: "Fax",
                  //   field: "fax",
                  //   cellStyle: { padding: "3px" },
                  //   // type: "numeric",
                  // },
                  {
                    title: "Reg. Date",
                    // field: "regDate",
                    render: (rowData) =>
                      new Date(rowData.regDate).toDateString(),
                    cellStyle: { padding: "3px" },
                    editComponent: (props) => (
                      <>
                        <TextField
                          type="date"
                          value={props.regDate}
                          onChange={(e) => {
                            var data = { ...props.rowData };
                            data.regDate = e.target.value;

                            props.onRowDataChange(data);
                          }}
                        ></TextField>
                      </>
                    ),
                  },
                  {
                    title: "Category",
                    field: "category",
                    cellStyle: { padding: "3px" },

                    lookup: this.state.categoriesdata,
                  },
                  {
                    title: "Reg#",
                    field: "regNum",
                    cellStyle: { padding: "3px" },
                  },
                  {
                    title: "Email",
                    field: "email",
                    cellStyle: { padding: "3px" },
                  },
                  {
                    title: "Name",
                    render: (rowData) =>
                      rowData.firstName + " " + rowData.lastName,
                    cellStyle: { padding: "3px" },
                  },
                  {
                    title: "Created On",
                    field: "createdOn",
                    cellStyle: { padding: "3px" },
                  },
                  {
                    title: "View",
                    editable: "never",
                    cellStyle: { padding: "3px" },
                    size: "small",
                    render: (rowData) => (
                      <Button
                        color="primary"
                        onClick={() => {
                          this.fetchkpidata(rowData.companyName, "");
                          this.setState({
                            updateDialogOpen: true,
                            cname: rowData.companyName,
                          });
                        }}
                      >
                        <VisibilityOutlinedIcon />
                      </Button>
                    ),
                  },
                  {
                    title: "Wallet",
                    editable: "never",
                    cellStyle: { padding: "3px" },
                    size: "small",
                    render: (rowData) => (
                      <Button
                        color="primary"
                        onClick={() => {
                          this.setState({
                            walletdialog: true,
                            currentid: rowData.ontrac_id_field,
                          });
                          this.getbalance(rowData.ontrac_id_field);
                        }}
                      >
                        <AccountBalanceWalletOutlinedIcon />
                      </Button>
                    ),
                  },
                  {
                    title: "Change Subscription Date ",
                    editable: "never",
                    cellStyle: { padding: "3px" },
                    size: "small",
                    render: (rowData) => (
                      <Button
                        color="primary"
                        onClick={() => {
                          this.setState({
                            changevdatedialog: true,
                            currentid: rowData.ontrac_id_field,
                          });
                          // this.getbalance(rowData.ontrac_id_field);
                        }}
                      >
                        <CreateIcon />
                      </Button>
                    ),
                  },
                  {
                    title: "Enable/Disable",
                    editable: "never",
                    size: "small",
                    cellStyle: { padding: "3px" },
                    field: "approvedFlag",
                    render: (rowData) =>
                      rowData.approvedFlag === "Deactivated" ? (
                        <Tooltip title="Activate" placement="right">
                          <Button
                            // variant="outlined"
                            color="primary"
                            onClick={() => {
                              this.setState({
                                activeopen: true,
                                currentid: rowData.ontrac_id_field,
                              });
                            }}
                          >
                            <AddCircleOutlineIcon />
                          </Button>
                        </Tooltip>
                      ) : rowData.approvedFlag === "Approved" ||
                        rowData.approvedFlag === "Account Reactivated" ? (
                        <>
                          <Tooltip title="Deactivate" placement="right">
                            <Button
                              // variant="outlined"
                              color="secondary"
                              onClick={() => {
                                this.setState({
                                  deactiveopen: true,
                                  currentid: rowData.ontrac_id_field,
                                });
                              }}
                            >
                              <NotInterestedOutlinedIcon />
                            </Button>
                          </Tooltip>
                        </>
                      ) : null,
                  },
                ]}
                data={
                  this.state.adminregistered === false
                    ? this.state.employerlist
                    : this.state.adminreg
                }
                // editable={{
                //   onRowUpdate: (newData, oldData) =>
                //     new Promise((resolve, reject) => {
                //       setTimeout(() => {
                //         const dataUpdate = [...this.state.employerlist];
                //         const index = oldData.tableData.id;
                //         dataUpdate[index] = newData;
                //         //  setData([...dataUpdate]);

                //         this.updaterow(newData);
                //         this.setState({
                //           employerlist: dataUpdate,
                //         });
                //         resolve();
                //       }, 1000);
                //     }),
                // }}
                options={{
                  sorting: true,
                  paging: false,
                  search: true,
                  grouping: true,
                  actionsColumnIndex: -1,
                  headerStyle: {
                    backgroundColor: "#031a26",
                    color: "#FFF",
                    fontWeight: "bold",
                  },
                  toolbar: false,
                }}
              />
              <TablePagination
                component="div"
                rowsPerPageOptions={[]}
                count={this.state.count}
                rowsPerPage={this.state.rowsPerPage}
                page={this.state.page}
                onChangePage={this.nextpageclick}
              />
            </>
          )}

          {}
        </>
      </div>
    );
  }
  async addemployer() {
    let apiEndpoint =
      "http://3.22.17.212:9000/api/v1/accounts/auth/employer/register";

    let requestBody = {
      designation: "Employer",
      companyName: this.state.companyName,
      firstname: this.state.firstname,
      middlename: this.state.middlename,
      surname: this.state.surname,
      username: this.state.username,
      email: this.state.email,
      password: this.state.password,
      phone: this.state.phone,
      rcnum: this.state.rcnum,
      staffCnt: this.state.staffctn,
    };
    let response = await fetch(apiEndpoint, {
      method: "POST",
      body: JSON.stringify(requestBody),
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
        Accept: "*/*",
      },
    });
    this.setState({ addemployerdialog: false });
  }
}
